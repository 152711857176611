.breadcrumbs {
    @include lib-breadcrumbs();

    @media only screen and (min-width: 769px) {
        min-height: 85px;
    }

    .items {
      padding: 10px 0 10px 20px;
      margin-top: 15px;
      color: $primary__color3;
      font-weight: 100;
      background-color: $primary__color12;
    }

    strong {
        background: inherit;
        border: none;
        display: inherit;
        font-weight: lighter;
        padding: inherit;
    }
 a:visited {
    background: inherit;
    border: none;
    color: $primary__color1;
    font-weight: lighter;
    text-decoration: none;
}
}

@include max-screen(768px) {
    .breadcrumbs {display: none;}
}
