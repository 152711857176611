.quotation-quote-index {
    .cart.table-wrapper {
        .product-item-photo {
            height: auto !important;
            background-color: white;
            float: left;
            width: 30%;
            position: initial;
            display: inline-block;
        }

        .product-item-details {
            display: block;
            margin-top: 20px;

            .product-item-name {
                height: auto;
                display: block;
                float: left;
                width: 100%;
            }

            .field.qty {
                height: auto;
                overflow: auto;
                margin-top: 5px;

                label {
                    float: left;
                    line-height: 30px;
                }

                input {
                    max-width: 100px;
                    position: initial;
                    display: inline-block;
                    float: left;
                    margin-left: 10px;
                    margin-right: 0;
                }
            }
        }
    }
}

.delivery-available {
    font-weight: bold;
    white-space: normal;

    &.directly-available {
        color: #2FDF66;
    }

    &.available-from {
        color: #FFA500;
    }
}

.checkout-cart-index .column.main .cart-summary .block > .title strong {
    font-size: 13px;
}

/*reset on checkout cart*/
.cart.table-wrapper td {
    padding: 0 !important;
}

// Borderline  om item cart heen
.cart.item {
    border: solid #D8D8D8 1px;
    height: 240px;

    .message.error {
        height: auto !important;
        margin: 15px 15px 10px;
        white-space: normal;
        width: auto;
    }
}

.cart.item {
    .control.qty {
        right: 10px;
        bottom: 90px;
        position: absolute;
    }
}

tbody.cart.item {
    display: list-item;
    margin-bottom: 30px;
}


.actions-toolbar {

}

button.action.apply.primary {
    background: $primary__color;
    border: 0px;
    color: white;

}

button.action.apply.primary:hover {
    border: 0px;
    background: $primary__color1;
    color: white;
}


.update-cart-hidden {
    display: none;
}


#increaseQty,
#decreaseQty {
    background: #f2f2f2;
    border: 1px solid #f2f2f2;
    color: $primary__color3;
    height: 35px;
}

//  Shopping cart
//  ---------------------------------------------

//  Summary block
.opc-block-summary {
    .data.table.table-totals {
        background: none;
    }
}

.cart-summary {
    @extend .abs-add-box-sizing;
    @extend .abs-adjustment-incl-excl-tax;
    margin-bottom: $indent__m;
    padding: 20px 15px $indent__m;
    border: 1px solid #D8D8D8;
    font-size: 13px;

    table {
        background-color: white;
    }

    & > .title {
        display: block;
        font-weight: 600 !important;
        margin-left: 5px;
        @include lib-heading(h3);
    }

    .block {
        @extend .abs-discount-block;
        margin-bottom: 0;

        .item-options {
            margin-left: 0;
        }

        .fieldset {
            margin: 15px 0 $indent__m $indent__xs;

            .field {
                margin: 0 0 $indent__s;
            }

            .methods {
                .field {
                    & > .label {
                        display: inline;
                    }
                }
            }
        }

        .fieldset.estimate {
            & > .legend,
            & > .legend + br {
                @extend .abs-no-display;
            }
        }
    }

    #co-shipping-method-form {
        display: none;
    }

    .actions-toolbar {
        & > .primary {
            button {
                @extend .abs-revert-secondary-color;
            }
        }
    }
}

//  Totals block
.cart-totals {
    @extend .abs-sidebar-totals;
    border-top: 1px solid $border-color__base;
    padding-top: $indent__s;

    .totals {
        .mark strong {
            font-weight: bold !important;
        }

        &.excl.both {
            strong {
                font-weight: normal !important;
            }
        }
    }

    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;
    }
}

//  Products table
.cart-items {
    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        border: solid #D8D8D8 1px;
        margin-bottom: 35px;
        padding: 10px;

        .product-item-photo {
            width: 18%;
            display: inline;

            @include max-screen(1050px) {
                width: 25%;
            }
        }

        .product-image-container {
            background: none;
        }

        .product-item-info {
            width: 40%;
            padding: 20px 0;

            @include max-screen(1050px) {
                padding: 0 10px;
                width: 75%;
            }

            .product-item-name {
                height: unset;
                margin: 0;
            }

            .product-item-price {
                margin-top: 15px;

                .price {
                    font-weight: 600;
                }
            }
        }

        .product-item-actions {
            display: block !important;
            width: 50px;
            text-align: right;

            @include max-screen(450px) {
                width: 25px;
                margin-top: 15px;
            }

            .remove-item {
                color: $primary__color3;
                font-size: 14px;

                span {
                    display: none;
                }

                &:hover {
                    text-decoration: none;
                }

                &:before {
                    content: "\e902";
                    font-family: 'icomoon';
                    font-size: 15px;
                    line-height: 50px;
                    color: $primary__color1;

                }
            }
        }

        .product-item-qty {
            width: 19%;

            @include max-screen(1050px) {
                width: 35%;
                text-align: right;
                margin-left: auto;
            }


            @include max-screen(450px) {
                width: 45%;
                text-align: left;
                margin-left: unset;
                margin-top: 15px;
            }

            @include max-screen(320px) {
                width: 54%;
            }


            input {
                background: #f2f2f2;
                width: 50px;
                text-align: center;
            }
        }

        .product-item-subtotal {
            width: 16%;
            text-align: center;

            @include max-screen(1050px) {
                width: 25%;
            }

            @include max-screen(450px) {
                width: 45%;
                margin-top: 15px;
            }

            @include max-screen(320px) {
                width: 36%;
            }

            .price {
                font-size: 20px;
                font-weight: 600;
            }
        }
    }
}

.cart.table-wrapper {
    .items {
        thead + .item {
            /*    border-top: $border-width__base solid $border-color__base;*/
        }

        & > .item {
            /*border-bottom: $border-width__base solid $border-color__base;*/
            position: relative;
        }
    }
    .col {
        padding-top: $indent__base;

        &.qty {
            .input-text {
                @extend .abs-input-qty;
                margin-top: -5px;
            }

            .label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .item {
        &-actions td {
            padding-bottom: $indent__s;
            text-align: center;
            white-space: normal;
        }
    }

    .actions-toolbar {
        @extend .abs-add-clearfix;

        & > .action {
            @extend button;
            @include lib-link-as-button();
            margin-bottom: $indent__s;
            margin-right: $indent__s;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .action {
        &.help.map {
            @extend .abs-action-button-as-link;
            font-weight: $font-weight__regular;
        }
    }

    .gift-registry-name-label {
        &:after {
            content: ':';
        }
    }

    //  Product options
    .item-options {
        @extend .abs-product-options-list;
        @extend .abs-add-clearfix;
        font-size: $font-size__s;
        margin-bottom: $indent__s;
    }

    .product-item-name + .item-options {
        margin-top: $indent__s;
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent__base;
    }
}

.cart-container {
    .form-cart {
        @extend .abs-shopping-cart-items;
        width: 70%;
    }

    .checkout-methods-items {
        @extend .abs-reset-list;
        margin-top: $indent__base;
        text-align: center;

        .action.primary {
            @extend .abs-button-l;
            width: 100%;
        }

        .item {
            & + .item {
                margin-top: $indent__base;
            }
        }

        .shipping-cart-error {
            margin-bottom: 5px;

            span {
                color: red;
            }
        }

        .cart-summary-trustedshops {
            margin:0 auto;
            width: 175px;
        }
    }
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
    margin-top: 70px;
}

.cart-tax-info,
.cart .cart-tax-info {
    & + .cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper,
.order-items.table-wrapper {
    .col.price,
    .col.qty,
    .col.subtotal,
    .col.msrp {
        text-align: right;
    }
}

@media only screen and (min-width: 371px) and (max-width: 768px) {
    .cart {
        .col {
            &.qty {
                .field.qty {
                    margin-left: 15px;
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .cart {
        &.table-wrapper {
            overflow: inherit;

            thead {
                .col {
                    &:not(.item) {
                        display: none;
                    }
                }
            }

            .col {
                &.qty,
                &.price,
                &.subtotal,
                &.msrp {
                    box-sizing: border-box;
                    display: block;
                    float: left;
                    text-align: center;
                    white-space: nowrap;
                    width: 33%;

                    &:before {
                        content: attr(data-th) ':';
                        display: block;
                        font-weight: $font-weight__bold;
                        padding-bottom: $indent__s;
                    }
                }

                &.msrp {
                    white-space: normal;
                }
            }

            .item {
                .col.item {
                    padding-bottom: 0;
                }
            }
        }
    }

    .cart-container {
        .form-cart {
            @extend .abs-shopping-cart-items-mobile;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .cart-container {
        @extend .abs-add-clearfix-desktop;

        .form-cart {
            @extend .abs-shopping-cart-items-desktop;
        }

        .widget {
            float: left;
        }
    }

    .cart-summary {
        float: right;
        position: relative;
        width: 29%;
        background: white;

        .actions-toolbar {
            .column.main & {
                @extend .abs-reset-left-margin-desktop;

                & > .secondary {
                    float: none;
                }
            }
        }

        .block {
            .fieldset {
                .field {
                    @include lib-form-field-type-revert($_type: block);
                    margin: 0 0 $indent__s;
                }
            }
        }

        .action.primary.checkout {
            margin-bottom: 0 !important;
        }
    }
}


.steps-container {
    margin-top: 0;
    margin-bottom: 0;

    .steps-container-centered {
        position: relative;
        margin-left: -10px;
    }

    .steps-bar {
        margin: 0;
        list-style: none;
        padding: 0;
        overflow: hidden;
        width: 80%;
        margin-left: 10%;
        //11-01-2018
        margin-top: 30px;
        margin-bottom: 30px;

        &.step1step {
            .step1 {
                .step-number {
                    background: $primary__color1;
                }
            }
        }

        &.step2step {
            .step1,
            .step2 {
                .step-number {
                    background: #037793;
                }
            }
        }

        &.step3step {
            .step1,
            .step2,
            .step3 {
                .step-number {
                    background: $primary__color1;
                }
            }
        }

        li {
            margin: 0;
        }

        .step {
            float: left;
            width: 33.333%;
            text-align: center;

            .step-number {
                display: inline-block;
                height: 33px;
                width: 33px;
                background: #F8F8F8;
                color: #c9d2d9;
                text-align: center;
                line-height: 33px;
                font-size: 20px;
                margin-top: 25px;
                font-weight: 600;
                position: relative;
                z-index: 1;
            }

            .step-text {
                line-height: 50px;
                font-size: 18px;
                font-weight: 400;
                color: $primary__color1;

                @media only screen and (max-width: 480px) {
                    font-size: 12px;
                }
            }
        }

        .step-bar {
            position: absolute;
            width: 100%; //09-01-2018 96.6%;
            height: 4px;
            margin-left: calc(-9.6% - 5px);
            top: 39px;
            background: #C9D2D9;


            .step-line {
                height: 4px;
                display: inline-block;
                background: $primary__color1;
                position: absolute;
                left: 0;
                top: 0;

                &.step-size1 {
                    width: calc(33.333% - 10%);
                }

                &.step-size2 {
                    width: calc(50%);
                }

                &.step-size3 {
                    width: 100%;
                }
            }
        }
    }
}

body.step2 .steps-container .steps-bar {
    .step1,
    .step2,
    .step3 {
        .step-number {
            background: $primary__color;
        }
    }

    .step-bar {
        .step-line {
            &.step-size2 {
                width: 100%;
            }
        }
    }
}


@include max-screen(768px) {
    .cart.table-wrapper .col {
        &.qty:before,
        &.price:before,
        &.subtotal:before,
        &.msrp:before {
            content: none;
        }
    }


    .cart-container {
        display: flex;
        flex-wrap: wrap;

        .cart-summary {
            width: 100%;
            order: 2;
        }

        .form.form-cart {
            order: 1;
            width: 100%;

            .table-wrapper {
                display: block;

                .data.table {
                    display: block;
                }
            }
        }
    }
}
