$quickcart-width: 400px;
$quickcart-mobile-width: 90%;
$quickcart-top-background-color: #333333;
$quickcart-top-text-color: #ffffff;
$quickcart-top-height: 50px;
$quickcart-subtotal-background-color: #F6F6F6;


.minicart-custom {
  .mage-dropdown-dialog {
    display: block !important;
  }

  &.minicart-wrapper {
    .block-minicart {
      z-index: 1001;
    }
  }
}

.box-sized {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.header {
  .quickcart-wrapper .block-quickcart {
    border: none;
    height: 100%;
    width: $quickcart-width;
    min-width: inherit;
    overflow: hidden;
    padding: 0;
    position: fixed !important;
    right: -$quickcart-width - 100px !important;
    top: -4px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }


    .quickcart-content-wrapper {
      width: 100%;
    }
    .block-content-quickcart {
      width: 100%;

      .quickcart-top {
        background: $quickcart-top-background-color;
        color: $quickcart-top-text-color;
        line-height: $quickcart-top-height;
        text-align: center;

        .close {

        }
      }

      .quickcart-items-wrapper {
        border: 0 none;
        margin: 0;
        z-index: -1;
        overflow-y: auto;
        width: 100%;
        &::-webkit-scrollbar-track{
          //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
          background-color: #F5F5F5;
        }
        &::-webkit-scrollbar,
        &::-webkit-scrollbar-thumb{
          background-color: #c2c2c2;
        }
      }

      .quickcart-bottom-container {
        width: 100%;
        margin-top: 20px;

        .items-total {
          display: none;
        }
        .subtotal {
          margin: 0;
          padding: 20px;
          width: 100%;
          background-color: $quickcart-subtotal-background-color;

          span.label {
            float: left;

            span {
              font-size: 16px;
            }
          }
          .price-container {

            span.price {
              font-size: 16px;
              font-weight: 400;
              margin-left: 10px;
            }
          }
        }
        .actions.action-viewcart {
          background: transparent;
          margin: 0;
          padding: 0;
          text-align: center;
          width: 100%;

          .secondary {
            padding: 15px;
          }
        }
        .actions.action-checkout {
          margin: 0;
          padding: 0 10px;
          width: 100%;

          button.checkout {
            display: block;
            margin: 0 auto 10px;
            min-width: 200px;
            font-size: 1.2em;
            padding: 15px 0;
            width: 100%;
          }
        }
        .minicart-widgets {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
  .quickcart-wrapper.active .block-quickcart {
    right: 0 !important;
  }


.quickcart-wrapper .quickcart-items {
  .quickcart-product-item {
    .product-item-name {
      max-width: 90%;
    }

    .actions {
      float: right;
      margin: -28px 0 0;
    }

    .delete {

    }

    .details-qty {
      float: left;
      margin-top: 5px;

      .spinner {
        display: none;
        width: 10px;
        height: 10px;
        background-color: transparent;
        margin: 4px 12px 4px 13px;
        float: left;
        border: 1px solid #000;
        border-radius: 50%;
        border-bottom: 0 none;
        border-top: 0 none;
        top: -2px;
        position: relative;
        -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
        animation: sk-rotateplane 1.2s infinite ease-in-out;
      }
    }

    .price-container {
      float: right;
      margin-top: 6px;

      span {
        margin: 0;

        span.price-including-tax {
          width: 100%;
          display: table;
          text-align: right;
          line-height: .5;
        }

        span.price-excluding-tax {
          width: 100%;

          span.minicart-price {
            span.price {
              font-size: 12px
            }
          }
        }
      }

      span.price {
        font-size: 14px;
        margin: 0 0 10px 0;
        float: right;
      }
    }

    .options {
      margin: 0;
      min-height: 18px;

      .option-wrapper {
        float: left;
        margin-bottom: 5px;
        width: 100%;

        dt.label,
        dd.values {
          float: left;
          margin: 0 5px 0 0;
        }

        dd.values {
          margin: 0;
        }
      }
    }

    .product-item-pricing {
      .label {
        line-height: 12px;
        float: left;
        padding: 0 20px 0 0;
        width: auto;
      }
    }

    span.qty-update {
      color: #000;
      font-size: 13px;
      float: left;
      padding: 0 5px;
      line-height: 10px;

      &.item-minus {
        font-family: 'luma-icons';
        &:after {
          content: '-';
        }
      }

      &.item-plus {
        font-family: 'luma-icons';
        &:after {
          content: '+';
        }
      }
    }

    .cart-item-qty {
      color: #000;
      border: 0 none;
      float: left;
      height: auto;
      line-height: 14px;
      margin: 0;
      opacity: 1;
      padding: 0;
      text-align: center;
      font-size: 13px;
      width: auto;
      max-width: 35px;

    }

    button.update-cart-item {
      display: none;
    }
  }

  .quickcart-product-item:last-child {
    border-bottom: 1px solid #ccc;
  }
}


//
//  Mobile small
//  _____________________________________________

@media only screen and (max-width: 400px) {

  .header {
    .quickcart-wrapper .block-quickcart {
      width: $quickcart-mobile-width;
      .block-content-quickcart {
        .quickcart-items-wrapper {
          position: absolute;
        }

        .quickcart-bottom-container {
          bottom: 0;
          position: absolute;
        }
      }
    }
  }

  .quickcart-wrapper {
    .quickcart-items {
      .quickcart-product-item {
        .product-item-pricing {
          .label {
            padding: 0 5px 0 0;
          }
        }

        .cart-item-qty {
          max-width: 20px;
        }

        .details-qty {
          .spinner {
            margin: 4px 6px 4px 4px;
          }
        }
      }
    }
  }
}


