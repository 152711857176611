h4.choose {
    a {
        color: #2FDF66;
    }

    @media only screen and (min-width: 769px) {
        padding: 30px 30px 0;
        display: -webkit-box;
    }
}

.delivery-available {
    display: block;
}

.group-buttons {
    @media only screen and(min-width: 769px) {
        padding-left: 30px;
        float: left;
        width: calc(100% - 400px);
    }
    @media only screen and (max-width: 768px) {
        text-align: center;
    }
    margin-top: 25px;

    .btn-group {
        font-weight: 700;
        background: #014A5B;
        color: #fff;
        cursor: pointer;
        vertical-align: initial;
        text-align: center;
        padding: 20px 0;
        text-decoration: none;
        margin-bottom: 15px;

        @media only screen and (max-width: 768px) {
            display: block;
        }
        @media only screen and (min-width: 769px) {
            display: inline-block;
            width: 200px;
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

div.tier-group:last-child {
    font-weight: bolder;

    .tier-qty{
        background-color:#05596B;
    }
}

.input-text.input-aantal-meter {
    margin-left: 15px;

    @media only screen and (max-width: 370px) {
        margin-left: 2px;
    }
}

.page-title-wrapper.product {
    @media only screen and (max-width: 768px) {
        text-align: center;
        display: block;
        width: 100%;
    }
}

.product-info-main .price-box {
    @media only screen and (max-width: 768px) {
        text-align: center;
    }
}

.table.grouped {
    @include lib-table-bordered($_table_type: horizontal);

    & > thead > tr > th {
        border-bottom: 0;
    }

    .product-item-name {
        font-weight: $font-weight__regular;
    }

    .price-box {
        .price {
            font-size: $font-size__base;
        }
    }

    .control {
        .qty {
            @extend .abs-input-qty;
            margin-left: 49px;
            margin-top: 20px;
            background: #F8F8F8;
            border-left: solid #eee 1px;
            border-top: solid #eee 1px;
            height: 48px;
            padding: 10px;
            width: 87%;
        }
    }

    .stock.unavailable {
        font-weight: normal;
        margin: 0;
        text-transform: none;
        white-space: nowrap;
    }

    .row-tier-price {
        td {
            border-top: 0;
        }

        .prices-tier {
            margin: 0 -10px 10px;
        }
    }
}

.block.widget.block-products-list.grid {
    margin-bottom: 0 !important;

    .price-label {
        display: block;
    }

    .price-container {
        display: block;
        font-weight: 700;
        font-size: 24px;
        color: #6d6d6d;

    }

}

.zigzag-container2 {
    display: inline;
    margin-left: 20px;
    margin-right: 20px;

    .input-checkbox.input-zigzag {
        margin-right: -22px !important;
        font-size: 10px !important;
    }

    span.tip {
        font-size: 15px;
    }

    @media only screen and (max-width: 768px) {
        display: block;
        margin-bottom: 7px;
        .input-checkbox.input-zigzag {
            margin-right: -10px !important;
        }
    }
}

.table-wrapper {
    &.grouped {
        .delivery-icon {
            float: right;
            position: relative;
            right: unset;
            top: unset;
            @media only screen and (max-width: 768px) {
                float: left;
                width: auto;
            }
        }

        .directly-available,
        .stock.unavailable{
            @media only screen and (min-width: 769px) {
                float: right;
                padding: 20px 10px 0 10px;
                font-weight: bold;
                text-align: right;
            }
            @media only screen and (max-width: 768px) {
                clear: both;
                font-weight: bold;
                float: left;
                padding: 20px 0 10px 0;
            }
        }
    }

    &.simple {
        margin-top: 30px;

        @media only screen and (max-width: 768px) {
            text-align: center;
        }

        .input-checkbox.input-zigzag {
            position: absolute;
            margin-top: 13px;
            display: grid;
        }

        .zigzag-container {
            display: inline;
        }

        .zigzag {
            display: block;
            margin-left: 68px;
            margin-top: 7px;
        }

        .actionbar {
            margin-left: 0;
            position: relative;
            margin-top: 23px;

            @media only screen and (max-width: 768px) {
                text-align: center;
            }
        }

        .control.qty {
            width: auto;
        }

        .actionbar .control.qty .total {
            font-weight: 700;
            background: none;
            border: none;
            font-size: 26px;
            display: -webkit-inline-box;
        }

        .actionbar .control.qty .btn-cart,
        .actionbar .control.qty .btn-quote {
            font-weight: 700;
            background: #EB8500;
            color: #fff;
            cursor: pointer;
            height: 66px;
            width: 190px;
            vertical-align: initial;
            line-height: 68px;
            text-align: center;
            float: unset;
            width: auto;
            display: block;
            margin-top: 5px;
        }

        .actionbar .control.qty input {
            border: 1px solid #eee;
            width: 62px;
            background: #fff;
            font-size: 1rem;
            font-weight: 700;
            text-align: center;
            color: #95989A;
            font-size: 20px;
            font-weight: 100;
            margin-bottom: 15px;
        }

        .delivery-icon {
            margin-left: 0;
            top: 0;
            position: relative;
            margin-top: 15px;
            width: 300px !important;
        }
        /*.grouped .item */

        .tier-price {
            font-size: 0.5rem;
            // color: #404243;
            border: 1px solid #eee;
        }

        .icon-icon-Bezorgen3::before {
            font-family: "icomoon";
            font-size: 30px !important;
            display: inline-block;
            color: #014A5B;
            line-height: 56px;
            text-align: center;
            cursor: pointer;
            content: "\e94e";
            float: left;
            padding-right: 5px;
        }

        .table-wrapper.simple .delivery-icon {
            margin-left: 0;
            position: relative;
            margin-top: -10px;
        }

        .delivery-icon {
            width: auto !important;
            margin-left: 0;
            display: inline-block;
            line-height: 56px;
            position: relative;

            span {
                font-size: 13px;
                font-weight: 100;
                width: auto;
                display: block;
                line-height: 67px;
                margin-right: 20px;

                @media only screen and (max-width: 768px) {
                    text-align: center;
                }
            }
        }

        input[type="number"] {
            background: #fff;
            background-clip: border-box;
            background-clip: padding-box;
            border: 1px solid #c2c2c2;
            border-radius: 1px;
            color: inherit;
            font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 14px;
            font-style: inherit;
            font-weight: inherit;
            height: 32px;
            line-height: 1.42857;
            margin: 0;
            padding: 0 9px;
            vertical-align: baseline;
            width: 280px;
            margin-left: 0;
        }

        .actionbar .control.qty input {
            border: 1px solid #eee;
            width: 62px;
            background: #fff;
            font-size: 1rem;
            font-weight: 700;
            text-align: center;
            color: #95989A;
            font-size: 20px;
            font-weight: 100;
            margin-bottom: 15px;
            margin-left: 0;
        }
    }
}
/*CSS grouped products*/

.grouped .item {
    border: 1px solid #eee;
    padding-left: 1rem;
    margin-bottom: 30px;
    overflow: hidden;
    width: 100%;
}

.grouped .item .actionbar {
    color: #005464;
}

.grouped .item .actionbar .control.qty,
.grouped .item .actionbar .control.qty,
.grouped .item .actionbar .control.qty .total,
.grouped .item .actionbar .tier-prices {
    display: inline-block;
    margin-top: 11px;
    border-top: 1px solid #EEE;
    border-left: 1px solid #EEE;
    background-color: #F8F8F8;
    border-bottom: 1px solid #f8f8f8;
}

.actionbar {
    margin-left: 235px;
    margin-top: 23px;
}

.levering {
    font-weight: 300;
    font-size: 23px;
    margin-top: 50px;
    margin-bottom: 25px;
}

.actionbar .control.qty .total {
    font-weight: 600;
    background: #f8f8f8;
    border: none;
    font-size: 20px;
    border:0px !important;
}

.grouped .item .actionbar .control.qty .tip{
    display: inline-flex;
    margin-top: 23px;
    margin-left: -6px;
}

.actionbar .control.qty .btn-cart,
.actionbar .control.qty .btn-quote {
    font-weight: 700;
    background: #EB8500;
    color: #fff;
    cursor: pointer;
    width: 190px;
    vertical-align: initial;
    line-height: 72px;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    height: 100%;
}

.btn-shipping-style {
    line-height: 85px !important;
    height: 84px;
    padding-top: 2px;
}

.actionbar .control.qty input {
    border: 1px solid #eee;
    width: 60px;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    color: #95989A;
    font-size: 20px;
    font-weight: 100;
    margin-bottom: 15px;
    margin-top: 15px;
    margin-right: 5px;

    @media only screen and (max-width: 1060px) {
        width: 40px;
    }
}

.tier-group {
    display: inline-block;
    text-align: center;
    width: 90px;
    /*20-2*/
    float: left;
    padding-right: 5px;
}

.tier-price,
.tier-qty {
    padding: 0.5rem;
    margin-top: 0;
}

.tier-qty {
    background-color: #00404D;
    color: #fff;
    font-weight: 700;
    font-size: 0.9rem;
}

.tier-price {
    font-size: 0.5rem;
    color: #404243;
    border: 1px solid #eee;
}


.delivery-icon {
    width: 190px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 80px;

    @media only screen and (max-width: 920px) {
        text-align: left;
        position: relative;
        right: 0;
        top: 0;
        margin-top: 5px;
        width: 100%;
    }

    span {
        font-size: 13px;
        font-weight: 100;
        width: auto;
        display: grid;
        line-height: 30px;
    }
}

.input-checkbox.input-zigzag {
    margin-right: -28px;

}


.grouped-item-container {
    border: 1px solid #eee;
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;

    .grouped-container-left {
        float: left;
        width: 250px;
        padding: 20px;
        box-sizing: border-box;

        .image-staffel {
            max-height: 200px;
            position: relative;
            display: block;
            left: 50%;
            transform: translateX(-50%);
            width: auto;

            @media only screen and (min-width: 768px) {
                min-height: 200px;
            }
        }



        @media only screen and (max-width: 960px) {
            padding: 20px 0 0;
            width: 200px;
        }

        @media only screen and (max-width: 620px) {
            padding: 10px 0 0 0;
            width: 200px;
        }

        @media only screen and (max-width: 540px) {
            width: 150px;
        }

        @media only screen and (max-width: 440px) {
            width: 100px;
        }

        @media only screen and (max-width: 360px) {
            margin: 0 auto;
            float: unset;
        }
    }

    .grouped-container-right {
        position: relative;
        float: left;
        width: calc(100% - 250px);
        box-sizing: border-box;
        padding: 20px 0 0;
        right: 0;
        top: 0;
        bottom: 0;

        @media only screen and (max-width: 1010px) {
            .product-item-name {
                h3 {
                    line-height: 20px;
                }
            }
        }

        .staffels-row {
            float: left;

            @media only screen and (min-width: 769px) {
                width: 350px;
                min-height: 100px;

                .product-item-name {
                    min-height: 60px;
                    height: auto;
                }
            }
        }

        .tier-prices {
            margin-top: 30px;
            margin-right: 5px;
            width: 360px;
            float: right;

            @media only screen and (max-width: 1064px) {
                width: 100%;
            }

            @media only screen and (max-width: 920px) {
                /*20-2*/
                 display: block;
                justify-content: space-between;
            }

            @media only screen and (max-width: 620px) {
                margin-bottom: 10px;
            }
        }

        .staffels-row {
            strong {
                display: block;
            }
        }

        @media only screen and (max-width: 960px) {
            width: calc(100% - 200px);
            padding-left: 10px;
        }

        @media only screen and (max-width: 640px) {
            .product-item-name {
                height: auto;
            }
        }

        @media only screen and (max-width: 540px) {
            width: calc(100% - 150px);
            padding-top: 5px;
        }

        @media only screen and (max-width: 440px) {
            width: calc(100% - 100px);
        }

        @media only screen and (max-width: 360px) {
            width: 100%;
        }

    }

    .tier-prices {
        @media only screen and (max-width: 768px) {
            display: block;
            width: 100%;
            overflow: hidden;
            transform: unset;
            left: unset;

            .tier-group {
                width: 25%;
                padding-left: 5px;
                padding-right: 5px;
                margin: 20px 0;
            }
        }
    }

    .actionbar {
        margin-left: 0;
        border: 1px solid #eee;
        border-width: 1px 0 0 1px;
        background: #f8f8f8;
        box-sizing: border-box;
        position: relative;
        width: 100%;
        float: right;
        margin-top: 0;

        @media only screen and (max-width: 1010px) {
            text-align: center;
        }

        &.zigzag .action-bar-formula {
            height: 62px;
            line-height: 62px;

            @media only screen and (max-width: 768px) {
                height: 30px;
                line-height: 30px;
                padding-top: 10px;
            }

            .tip {
                line-height: normal;
            }

            @media only screen and (max-width:1300px) {
                height: auto;
            }

            @media only screen and (max-width: 1150px) {
                .input {
                    display: block !important;
                }
            }
        }

        .action-bar-formula {
            .input {
                display: inline-block;
                @media only screen and (min-width: 1300px) {
                    font-size: 20px;
                }
            }

            .tip {
                &:hover {
                    &:after {
                        left: -100px;
                    }
                }
            }
        }

        .control.qty {
            @media only screen and (max-width:1300px) {
                font-size: 13px;
                line-height: 1;

                input {
                    font-size: 13px;
                    font-weight: 600;
                }

                .total {
                    font-weight: 600;
                    font-size: 20px;
                    margin-left: 5px;
                }
            }

            .action-label {
                @media only screen and (min-width: 1011px) {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 225px;
                }
            }

            .btn-cart,
            .btn-quote {
                @media only screen and (min-width: 1011px) {
                    float: none;
                    position: absolute;
                    right: 0;
                    top: 0;
                }

                @media only screen and (max-width: 1011px) {
                    margin: 0 auto;
                }

                @media only screen and (max-width: 777px) {
                    position: relative;
                    width: 100%;
                    box-sizing: border-box;
                }
            }

            .total {
                display: inline-block;

                @media only screen and (max-width: 420px) {
                    display: block;
                    margin-bottom: 10px;
                }

                .old {
                    text-decoration: line-through;
                    margin-right: 5px;
                }
            }

            .price-on-request {
                display: inline-block;
                font-weight: 600;

                @media only screen and (max-width: 420px) {
                    display: block;
                    margin-bottom: 10px;
                }

                @media only screen and (max-width: 1300px) {
                    font-size: 20px;
                }
            }

            .action.primary.toquote {
                background: #EB8500;
                font-size: 16px;
                line-height: 70px;
                padding: 0 40px;
                text-transform: uppercase;
            }

            @media only screen and (max-width: 777px) {
                .action.primary.toquote {
                    position: relative;
                    width: 100%;
                    margin: 0 auto;
                    margin-bottom: 0 !important;
                    font-weight: 700;
                    font-size: 13px;
                }
            }
        }

        &.zigzag {
            .control.qty {
                input {
                    margin-top: 2px;
                    margin-bottom: 2px;
                }

                .zigzag-container2 {
                    input {
                        width: 40px;
                    }
                }
            }

            .zigzag-container {
                padding-left: 15px;
                padding-top: 1px;

                input {
                    width: auto;
                }
            }
        }

        @media only screen and (max-width:620px) {
            width: 100%;
            text-align: center;
            left: 0;
            margin-top: 0;
        }
    }
}

.erklaren{
    font-size: 13px;
    font-style: italic;
    margin-bottom: 20px;
}

.erklaren-grouped {
    font-size: 11px;
    font-style: italic;
    width: 70%;
}

.table-wrapper.simple .simple-product-zigzag {
    display: block;
    width: 200px;
    height: 55px;

    @include max-screen(768px) {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    input.input-checkbox.input-zigzag {
        float: left;
        position: relative;
        display: block;
        width: auto;
        margin: 5px 10px 0 0;
    }

    .tooltip {
        float: left;
    }
}

/*20-2*/
.product-info-main .product.attribute.overview {
    margin: 20px 0;
    display: inline-block;
}

.product-info-main .product-info-price .price-box .price-container .price {
    display: none;
}
