// more less nog in apparte scss doen

/*Artibute.phtml op grouped product een lees meer naar beneden*/
.read-more a{
        color: $primary__color3;
        font-weight: 600;
        opacity: 1.0;
        &:hover{
            opacity: 0.8;
        }
    }

///read more
.btn-overflow {
    display: block;
    text-decoration: none;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.9em;
    line-height: 2;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 0.25em;
    cursor: pointer;
    width: 140px;
    text-align: center;
    margin-top: 20px;
    color: rgb(102, 102, 102)  !important;
    padding-left: 5px;
    padding-right: 5px;
    position: relative;

    &:hover{
        text-decoration: none;
        opacity: 0.8;
        border-color: $primary__color;
        border-radius: 0px;
    }
}

.text-overflow {
    width: 70%;
    height: 170px;
    margin-left: 15%;
    display: block;
    overflow: hidden;
    word-break: break-word;
    word-wrap: break-word;
    text-align: center;
    transition: 0.4s height, 0.4s padding, 0s visibility 0.4s;

    @media only screen and (max-width: 1024px) {
        width: 80%;
        margin-left: 10%;
    }

    @media only screen and (max-width: 768px) {
        height: 230px;
    }

    @media only screen and (max-width: 610px) {
        height: 135px;
    }

    @media only screen and (max-width: 503px) {
        height: 185px;
    }

    h2 {
        margin-top: 0;
    }

    h3 {
        margin-top: 35px;
        font-size: 25px;
    }

    b,
    strong {
        font-weight: 600;
        color: $primary__color2;
    }
}

////end read more

// 4 x category row margin-top heading. remove space from empty heading
.content-heading {
    display: none;
}

// productlist actie
 .alink {
    color: $primary__color1;
    text-decoration: none;
    font-weight: 600;
}
 .alink:hover {
    text-decoration: none !important;
    opacity: 0.7;
}

.actie-label {
    background: #ED8400;
    width: 84px;
    text-align: center;
    color: white;
    height: 80px;
    line-height: 80px;
    font-size: 20px;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 0;
}
//  Footer
//  ---------------------------------------------

.paymentmethod {
    margin-top: 35px;
    justify-content: space-between;
    display: flex;

    img {
        height: 29px;
        margin-right: 20px;
        margin-bottom: 10px;

        @media only screen and (max-width: 980px) and (min-width: 769px) {
            height: 20px;
        }

        @include max-screen(500px) {
            height: 20px;
        }

        @include max-screen(400px) {
            height: 15px;
        }
    }

    @include max-screen(768px) {
        padding: 0 20px;
        box-sizing: border-box;
    }
}

.paymentmethod ul {
    list-style-type: none;
    overflow: hidden;
    float: right;
    width: 1350px;

    li {
        float: right;
        margin-left: 7%;
    }

    img {
        height: 30px !important;
        padding-top: 5px;
        text-align: center;
    }
}

.block.newsletter .form.subscribe {
    display: table;
    width: 100%;
    margin-top: 45px;

    @media only screen and (max-width: 560px) {
        margin-top: 0;
    }
}

// Todo LIST
.todo {
    position: relative;
    background-color: #f1f8f1;
    overflow: hidden;
    padding-top: 30px;
    padding-bottom: 30px;

    ul{
        display: flex;
    }

    img {
        position: relative;
        left: 10%;
        bottom: 0;
        z-index: 1;
        // display: block; tijdelijk
        display: none;

        @media only screen and (max-width: 1280px) {
            left: 2%;
            margin-top: 5%;
            max-width: 350px;
        }

        @media only screen and (max-width: 767px) {
            display: none;
        }
    }

    .centre-container {
        z-index: 2;
        width: 800px;
        margin: 0 auto;
        text-align: center;

        > div {
            position: relative;
        }

        .expanded{ // tekst expanded onder icon
            margin-top: 100px;
            position: absolute;
            color: $primary__color10;
        }
        .icons{
            float: left;
            padding-left: 40px;
            margin-top: -70px;
            padding-bottom: 250px;

            @media only screen and (max-width: 640px) {
                padding-left: 20px;
            }

            @media only screen and (max-width: 684px) and (min-width: 641px) {
                padding-left: 30px;
            }

            b {
                margin-top: -60px;
                font-size: 17px;
            }
        }

        h1{
            margin-bottom: 100px;
        }

        @media only screen and (max-width: 640px) {
            div[data-role="collapsible"] {
                .icons {
                    b {
                        font-size: 13px !important;
                    }
                }
            }
        }

        @media only screen and (max-width: 680px) {
            width: 100%;
            padding-left: 25px;
            padding-right: 25px;
        }

        @media only screen and (max-width: 684px) {
            .icons {
                > span {
                    &:before {
                        font-size: 40px !important;
                    }
                }
            }
        }

        @media only screen and (max-width: 800px) {
            width: 645px;

            div[data-role="collapsible"] {
                .icons {
                    > span {
                        display: block;

                        &:before {
                            font-size: 60px;
                        }
                    }

                    > br {
                        display: none;
                    }

                    b {
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }
        }

        @media only screen and (max-width: 580px) {
            width: 100%;
            box-sizing: border-box;
            padding: 0 15px;
            left: 0;
            transform: none;
        }

        @media only screen and (max-width: 550px) {
            #element {
                border-bottom: 1px solid $primary__color2;
            }

            .expanded {
                margin-top: 10px;
                position: relative;
                padding-bottom: 10px;

                b, br {
                    display: none;
                }
            }

            div[data-role="collapsible"] {
                border-top: 1px solid $primary__color2;
                .icons {
                    padding-right: 0;
                    width: 100%;
                    margin-top: 0;
                    cursor: pointer;

                    float: none;
                    padding-bottom: 7px;
                    text-align: center;

                    > span {
                        display: none;
                    }
                }
            }

            h1 {
                margin-bottom: 20px;
            }
        }
    }

    @media only screen and (max-width: 550px) {
        padding-bottom: 40px;
    }

}

@media only screen and (max-width: 610px) {
    .homepage-slider {
        display: none;
    }
}
