//
//  Variables
//  ---------------------------------------------

$product-name-link__color                   : $text__color !default;
$product-name-link__color__active           : $text__color !default;
$product-name-link__color__hover            : $text__color !default;
$product-name-link__color__visited          : $text__color !default;

$product-name-link__text-decoration         : none !default;
$product-name-link__text-decoration__active : $link__hover__text-decoration !default;
$product-name-link__text-decoration__hover  : $link__hover__text-decoration !default;
$product-name-link__text-decoration__visited: $link__hover__text-decoration !default;

//
//  Product Lists
//  _____________________________________________







/*gemixte haag nl de*/
.page-with-filter.page-products.categorypath-gemengde-hagen.category-gemengde-hagen.catalog-category-view.page-layout-2columns-left,
.page-with-filter.page-products.categorypath-gemischte-hecken.category-gemischte-hecken.catalog-category-view.page-layout-2columns-left{
    .item.product.product-item .product-item-photo .height-reset img{
        width: 100%;
    }
}


/* end gemixte haag nl de*/

.product-item-catalog {
    border: 1px #e2e2e2 solid;
    &:hover {
        border: 1px #c7c7c7 solid;

        .product-item-details {
            background-color: #e8e8e8;
        }
    }

    .product-item-details {
        .price-box {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            gap: 10px;

            .special-price {
                .price-label {
                    display: none;
                }

                .price-container {
                    span.price {
                        color: $primary__color;
                    }
                }
            }

            .old-price {
                .price-label {
                    display: none;
                }

                .price {
                    text-decoration: line-through;
                    font-size: 20px;
                }
            }
        }
    }
}

.product-item-details {
    color: #000000;
}

/* related products image left*/
/*.wrapper.products-related {
    &.products-grid {
        .product-item {
            width: 100%;
        }
    }

    .product-item {
        width: 100%;

        .price-box {
            margin: 0;
        }
    }

    .product-item-info {
        width: 60%;
        padding-top: 20px;
        padding-bottom: 20px;
        align-items: center;

        .product-item-photo {
            background: #fcfcfc;
            padding-top: 55px;
            padding-bottom: 45px;
            text-align: left;
            display: block;
        }

        .product.photo.product-item-photo2 {
            max-height: 100px;
            float: left;
            margin-top: 0;
            padding: 0;

            .product-image-container {
                max-height: 100px;
            }

            img {
                max-height: 100px;
                margin: 0;
            }
        }

        .product.name.product-item-name {
            height: 40px;
            width: 60%;
            float: left;
            margin: 0;

            .product-item-link2 {
                width: 100%;
                margin: 0;
                padding: 5px 10px 0;
                position: relative;
            }
        }

        .product.details.product-item-details {
            min-width: 100px;
            height: 60px;
            float: left;
            margin-right: 40%;

            .price-box.price-label {
                float: left;
            }
        }

        .field.choice.related {
            width: 50px;
            float: right;
            margin-top: 25px;

            .product-item-link2 {
                width: auto;
                margin: 0;
                position: relative;
            }
        }

        &.related-available {
            .product-item-photo2 {
                .product-image-container {
                    background-color: #fff;

                    .product-image-wrapper {
                        .product-image-photo {
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}*/

/*Hover grey home product widget*/
li.product-item:hover {

    img {
        -webkit-filter: brightness(1.5);
        filter: brightness(1.5);
        transition: 1.5s;
        transition-timing-function: ease;
    }
}

.products2.wrapper.grid.products-grid.products-upsell .product-item-photo {
    background: #fcfcfc;
    padding-top: 55px;
    padding-bottom: 45px;
    text-align: left !important;
    display: block;
}


.block.upsell {

    .price {
        position: absolute !important;
        margin-top: -60px !important;
        margin-left: 70px !important;
    }
}

.product-item-link2 {
    margin-top: 3px;
    margin-left: 10px;
    display: block;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    font-size: 20px;
    margin-bottom: 20px;
    position: absolute;
    width: 225px;
    font-weight: 600 !important;

}
//.block.related {
//        margin-top: -80px;
//}


.product-item-link2:hover {
text-decoration: none !important;
}

//.field.choice.related {
//    margin-top: -115px;
//    text-align: right;
//}

.block-title.title2 {
    color: $primary__color1;
    font-size: 33px;
    font-weight: 600;
    margin-top: 90px;
}

.products2 {
    margin: 0px 0;
        margin-left: 0px;
}


//.checkbox.related2{
//     display: none !important;
//}

.label-icon{
    &:before {
      font-family: $icons-name;
      font-size: 30px;
      display: inline-block;
      color: white;
      line-height: 30px;
      padding: 10px;
      text-align: center;
      cursor: pointer;
      content: "\e967";
      background-color: $primary__color5;
    }
}


.products {
    margin: 30px 0 0 35px;

    @media only screen and (max-width: 768px) {
        margin-left: 0;
    }
}

.product-item-smaller {
    font-size: 0.7em;

    @media only screen and (max-width: 640px) {
        display: block;
        margin-top: 4px;
    }
}

.product {
    &-items {
        @extend .abs-reset-list;
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;

        .products-grid & {
            display: inline-block;
            width: 50%;

            @media only screen and (max-width: 639px) {
                width: 48%;
            }
        }

        &-name {
            @extend .abs-product-link;
            display: block;
            margin: 5px 0;
            //word-wrap: break-word;
            //hyphens: auto;
                overflow: hidden;
            font-size: 18px;
            height: 50px;

            &-latin {
                font-weight: 600;
                display: block;
                height: 45px;
                overflow: hidden;
            }

            a {
                color: $primary__color3 !important;
                font-weight: 600;
            }
        }

        &-details {
            overflow: hidden;
            padding-left: 15px;
        }

        /*remove underline on hover*/
        .product-item-name a:hover {
            text-decoration: none;
            opacity: 0.7;
        }

        &-info {
            position: relative;

            .page-products & {

            }

            .product-labels {
                position: absolute;
                z-index: 9999;
                top: 0;
                right: 0;
                width: 65%;
                display: block;
                text-align: center;

                @media only screen and (max-width: 768px) {
                    width: 90%;
                }

                .buxusvervanger {
                    background-color: #EB8500;
                    color: #fff;
                    display: block;
                    font-weight:600;
                    padding: 10px;
                }

                .uitverkocht {
                    background-color: #ffb3b3;
                    color: #fff;
                    display: block;
                    font-weight:600;
                    padding: 10px;
                }
            }
        }

        &-actions {
            display: none;
            .actions-primary{
                display: none !important;
            }

            .actions-secondary {
                & > .action {
                    @extend .abs-actions-addto-gridlist;
                    display: none;
                    &:before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        &-description {
            margin: $indent__m 0;
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-box {
            margin: $indent__s 0 $indent__m;

            .price {
                text-decoration: none;
                margin-left: 0px;
                font-size: 24px;
                color: #6D6D6D;
                display: block;
                font-weight: bold;
            }

            .price-label {
                font-size: 16px;
                font-weight: 100;
            }
        }


        .special-price {
            display: block;
        }

        .old-price {
            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            color: #6D6D6D;
            font-size: 24px;
            font-weight: 800;
            display: block;
            float: left;
            padding-right: 25px;

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
            text-decoration: none;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        .tocart {
            white-space: nowrap;
        }
    }
}

.column.main {
    .product {
        &-items {
            margin-left: -$indent__base;

            @media only screen and (max-width: 768px) {
                margin-left: 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }

        &-item {
            padding-left: $indent__base;


            @media only screen and (max-width: 768px) {
                padding-left: 0;
            }
        }
    }

}

.price-container {
    .price {
        font-size: 14px;
        }

    .price2{
        font-size: 14px;

    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }


    .weee {
        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            display: table;
            width: 100%;

            &-info {
                display: table-row;
            }

            &-photo {
                display: table-cell;
                padding: 0 $indent__l $indent__l 0;
                vertical-align: top;
                width: 1%;
            }

            &-details {
                display: table-cell;
                vertical-align: top;
                margin-left: 18px;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-grid .product {
        &-item {
            margin-bottom: 25px;
        }
    }

    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }
}

@include min-screen($screen__s) {
    .product {
        &-item {
            .products-grid & {
                margin-bottom: $indent__l;
            }

            &-actions {
                display: block;

                .products-grid & {
                    margin: $indent__s 0;
                }

                .actions-primary + .actions-secondary {
                    display: table-cell;
                    padding-left: 5px;
                    white-space: nowrap;
                    width: 50%;
                    & > * {
                        white-space: normal;
                    }
                }

                .actions-primary {
                    display: table-cell;
                }
            }
        }
    }

    .products-grid .product-item {
        width: 33.3333%;
    }

    .page-products,
    .page-layout-1column,
    .page-layout-3columns,
    .page-products.page-layout-1column,
    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 33.3333%;

                @media only screen and (max-width: 768px) {
                    width: 32%;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .products-grid {
            .product-item {
                width: 33.3333%;
            }
        }
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 50%;
            }
        }
    }
}
@include min-screen($screen__l) {
    .products-grid {
        .product-item {
            /*width: 20%;*/
            width: 25%;
        }
    }

    .page-layout-3columns {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                margin-left: 1.77%; // calc((100% - 4 * 23.233%) / 3);
                padding: 0;
                width: 23.233%;

                &:nth-child(4n + 1) {
                    margin-left: 0;
                }
            }
        }
    }

    .page-products {
        &.page-layout-1column {
            .products-grid {
                .product-item {
                    margin-left: 0;
                    width: 20%;
                }
            }
        }

        &.page-layout-3columns {
            .products-grid {
                .product-item {
                    margin-left: 1%;
                    width: 32.667%;

                    &:nth-child(3n) {
                        margin-left: 1%;
                    }

                    &:nth-child(3n + 1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
