//
//  Variables
//  _____________________________________________

$checkout-field-validation__border-color: $form-element-validation__border-error;

//
//  Common
//  _____________________________________________

.control .field._required .label {
    display: none;
}
.opc-wrapper .fieldset>.field.experius-address-line-one {
    width: 100%;
}
.field-tooltip {
    .label {
        display: none;
    }
}
.field._required .label::after {
    content: '*';
    color: #e02b27;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-size: 12px;
    margin: 0 0 0 5px;
    margin-bottom: 0px;
}

.field {
    .control {
        &._with-tooltip {
            @extend .abs-field-tooltip;
        }
    }

    &.choice {
        .field-tooltip {
            display: inline-block;
            margin-left: $indent__s;
            position: relative;
            top: -3px;
        }

        .field-tooltip-action {
            line-height: $checkout-tooltip-icon__font-size;
        }
    }

    .field-note {
        padding-top: 7px;
        font-size: 13px;
    }

    &._error {
        .control {
            input,
            select,
            textarea {
                border-color: $checkout-field-validation__border-color;
            }
        }
    }
}

.opc-wrapper {
    #customer-email-fieldset.fieldset {
        padding-bottom: 20px;

        .field.required {
            input.input-text {
                width: 100%;
            }
        }

        .fieldset.hidden-fields {
            .actions-toolbar {
                @include min-screen($screen__m) {
                    margin-left: 20%;
                }
            }
        }
    }

    .form.form-login {
        .fieldset.hidden-fields {
            width: 100%;
            margin-top: 40px;
        }
    }

    .fieldset {
        & > .field {
            width: 100%;
            margin-bottom: 20px;

            &.experius-address-lines {
                margin-bottom: 0;
            }

            input,
            select {
                border: 1px solid $secondary__color;
                height: 40px;
            }

            input[type="checkbox"] {
                height: unset;
                margin-top: 30px;
            }

            .control {
                width: 100%;

                .field {
                    &:first-child {
                        width: 70%;
                        margin-right: 5%;
                    }
                    width: 25%;
                    float: left;
                    margin-top: 0;
                }
            }
            & > .label {
                font-weight: $font-weight__regular;
                width: 100%;
                font-weight: 600;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    //  ToDo UI: remove with global blank theme .field.required update
    .opc-wrapper {
        #customer-email-fieldset.fieldset {
            padding-bottom: unset;
        }

        .fieldset {
            & > .field {

                .control {
                    width: 80%;
                    float: right;
                }
                & > .label {
                    width: 20%;
                }

                input[type="checkbox"] {
                    margin-top: 10px;
                }

                &.required,
                &._required {
                    position: relative;

                    & > label {
                        padding-right: 25px;

                        &:after {
                            margin-left: $indent__s;
                            position: absolute;
                            top: 9px;
                        }
                    }
                }
            }
        }
    }
}
