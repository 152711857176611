
//
//  Variables
//  _____________________________________________
$active-nav-indent: 54px;
$navigation__background: $primary__color1; //
//  Common
//  _____________________________________________

.nav-open {
    #trustbadge-container-98e3dadd90eb493088abdc5597a70810 {
        z-index: 1 !important;
    }
}

    .navigation {
        .ui-menu {
            .megamenu.level-top-sectioned {
                .submenu {
                    .submenu-child {
                        width: 100% !important;
                    }
                    .level1 {
                        .submenu {
                            li > a {
                                margin-top: 0;
                                padding: 0 10px;
                            }
                            li > a:first-child {
                                margin-top: 0;
                                padding: 0 10px;
                            }
                        }
                    }

                    .columns-group {
                        li > a span {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .navigation {
        .ui-menu {
            .megamenu a {
                text-transform: none;
            }
        }
    }

    .navigation .level0 {
        .submenu a {
            line-height: inherit;
            color: $primary__color1 !important;
            font-size: 14px;

            &:hover {
                color: #3f3f3f !important;
            }
        }
    }

    .panel.header {
        .links,
        .switcher {
            display: none;
        }
    }

    .nav-sections {
        background: $navigation__background;
    }

    .nav-toggle {
        @include lib-icon-font( $icon-menu, $_icon-font-size : 28px, $_icon-font-color : $header-icons-color, $_icon-font-color-hover: $header-icons-color-hover );
        @include lib-icon-text-hide();
        cursor: pointer;
        display: block;
        font-size: 0;
        left: 15px;
        position: absolute;
        top: 15px;
        z-index: 14;

        @media only screen and (max-width: 480px) {
            top: 0;
        }
    }
    //
    //  Mobile
    //  _____________________________________________
    @include max-screen(1040px) {
        .navigation {
            .nav-1 {
                .columns-group.starter {
                    display: none !important;
                }
            }
        }

        .has-menu-block .columns-group-block.top-group {
            display: inline-block !important;
        }
        .megamenu-custom-group { margin-bottom: 20px !important; }
        .megamenu-custom-group { padding-left: 10px !important; }

        .megamenu-custom-group {
            display:block !important;
        }

        .action.nav-toggle:before {
            color: $primary__color2;
            font-size: 48px;
        }

        .mobile-nav {
            .nav-sections {
                .section-item-content {
                    .navigation {
                        .parent {
                            .level-top {
                                @include lib-icon-font(
                                    $_icon-font-content: $icon-down,
                                    $_icon-font-size: 42px,
                                    $_icon-font-position: after,
                                    $_icon-font-display: block
                                );
                            }
                        }

                        .level0 {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .navigation {
            padding: 0 0 25px 0;

            .parent {
                .level-top {
                    position: relative;
                    padding-right: 20px;
                    @include lib-icon-font(
                            $_icon-font-content : $icon-down,
                            $_icon-font-size : 42px,
                            $_icon-font-position: after,
                            $_icon-font-display : block );
                    font-size: 16px;
                    font-weight:600;

                    &:after {
                        position: absolute;
                        right: 0;
                    }

                    &.ui-state-active {
                        @include lib-icon-font-symbol(
                                $_icon-font-content : $icon-up,
                                $_icon-font-position: after );
                    }
                }
            }

            .submenu {
                &:not(:first-child) .level1.active > a {
                    border-color: $primary__color2;
                }
            }
        }

        .nav-sections {
            -webkit-overflow-scrolling: touch;
            transition: left 0.3s;
            height: 100%;
            left: -80%;
            left: calc(-1 * (100% - #{$active-nav-indent}));
            overflow: auto;
            position: fixed;
            top: 0;
            width: 80%;
            background: #fff;
            width: calc(100% - #{$active-nav-indent});

            .switcher {
                border-top: 1px solid lighten($primary__color__lighter, 60);
                font-size: 1.6rem;
                font-weight: 600;
                margin: 0;
                padding: 0.8rem 1.5rem 0.8rem 1.5rem;

                .switcher-options {
                    border: 1px solid $primary__color__darker;
                }

                .label {
                    display: none;

                }

                &:last-child {
                    // border-bottom: 1px solid $color-gray82;
                }
            }

            .switcher-trigger {
                border-bottom: 0;

                strong {
                    position: relative;
                    line-height: 60px;
                    padding-left: 20px;
                    color: $primary__color__darker;
                    font-weight: 300;

                    &:before {
                        content: "";
                        width: 30px;
                        height: 20px;
                        position: relative;
                        top: 2px;
                        margin-right: 10px;
                        display: inline-block;
                    }

                    @include lib-icon-font( $_icon-font-content : $icon-down, $_icon-font-size : 60px, $_icon-font-position: after, $_icon-font-display : block );

                    &:after {
                        position: absolute;
                        right: 0;
                        top: 0;
                        height: 60px;
                        width: 60px;
                        line-height: 60px;
                        background: none;
                    }
                }

                &.active strong {
                    @include lib-icon-font-symbol( $_icon-font-content : $icon-up, $_icon-font-position: after );
                }
            }

            .switcher-dropdown {
                @include lib-list-reset-styles();
                padding: 0;

                li {
                    margin: 0;
                    line-height: 60px;
                    border-bottom: 1px solid lighten($primary__color__lighter, 60);
                    font-weight: 300;
                    padding: 0;

                    a {
                        display: block;
                        padding-left: 20px;
                        box-sizing: border-box;

                        &:before {
                            content: "";
                            width: 30px;
                            height: 20px;
                            position: relative;
                            top: 4px;
                            margin-right: 10px;
                            display: inline-block;
                        }

                        &:visited {
                            color: $secondary__color;
                        }

                        &:hover {
                            text-decoration: none;
                            background: $primary__color__light;
                            color: $secondary__color;
                        }
                    }
                }
            }

            .header.links {
                @include lib-list-reset-styles();
                border-bottom: 1px solid $primary__color__darker;

                li {
                    font-size: 1.6rem;
                    margin: 0;
                    padding-right: 0;
                    display: block !important;

                    &.for-questions,
                    &.whatsapp {
                        display: none;
                    }

                    &.greet.welcome {
                        border-top: 1px solid $color-gray82;
                        font-weight: 700;
                        padding: 0.8rem $indent__base;
                    }

                    & > a {
                        border-top: 1px solid $primary__color__darker;
                        display: block;
                        width: 100%;
                        box-sizing: border-box;
                        color: $primary__color__darker;
                        font-size: 16px;
                        font-weight:600;
                    }

                    &.authorization-link:before {
                        display: none;
                    }

                }

                a,
                a:hover {
                    color: $primary__color2; /*$navigation-level0-item__color*/;
                    text-decoration: $navigation-level0-item__text-decoration;
                    display: block;
                    font-weight: 700;
                    padding: 0.8rem $indent__base;


                }

                .header.links {
                    border: 0;
                }
            }
        }

        .nav-before-open {
            height: 100%;
            overflow-x: hidden;
            width: 100%;

            .page-wrapper {
                transition: left 0.3s;
                height: 100%;
                left: 0;
                overflow: hidden;
                position: relative;
            }

            body {
                height: 100%;
                overflow: hidden;
                position: relative;
                width: 100%;
            }
        }

        .nav-open {
            .page-wrapper {
                left: 80%;
                left: calc(100% - #{$active-nav-indent});
            }

            .nav-sections {
                $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
                box-shadow: $_shadow;
                left: 0;
                z-index: 99;

                .navigation {
                    background: transparent;
                }
            }

            .nav-toggle {
                &:after {
                    background: rgba(0, 0, 0, 0.7);
                    content: '';
                    display: block;
                    height: 100%;
                    position: fixed;
                    right: 0;
                    top: 0;
                    width: 100%;
                    z-index: 1;
                }
            }
        }

        .nav-sections-items {
            @include lib-clearfix();
            position: relative;
            z-index: 1;
        }

        .nav-sections-item-title {
            background: darken($navigation__background, 5%);
            border: solid darken($navigation__background, 10%);
            border-width: 0 0 1px 0;
            box-sizing: border-box;
            float: left;
            height: 71px;
            font-weight:600;
            color: #fff;
            padding-top: 24px;
            text-align: center;
            width: 33.33%;

            > a {
                color: #fff;
            }

            &.active {
                background: transparent;
                border-bottom: 0;

                > a {
                    color: $primary__color2;
                }
            }

            .nav-sections-item-switch {
                &:hover {
                    text-decoration: none;
                }
            }
        }

        .nav-sections-item-content {
            @include lib-clearfix();
            box-sizing: border-box;
            float: right;
            margin-left: -100%;
            margin-top: 71px;
            width: 100%;

            &.active {
                display: block;
            }
            padding: $indent__m 0 0;
        }
        @include lib-main-navigation();
    }
    //
    //  Desktop
    //  _____________________________________________
    @include min-screen(1041px) {
        .navigation {
            .nav-1 {
                .columns-group.starter {
                    display: none !important;
                }
            }

            .ui-menu {
                position: relative !important;

                .megamenu {
                    &.level-top-sectioned {
                        position: static;
                    }

                    &.level-top-default {
                        position: relative;
                    }

                    .submenu {
                        position: absolute !important;
                        top: 60px !important;
                        width: auto;
                        z-index: 2;
                    }
                }
            }
        }

        .menu-block.top-block {
            display: flex !important;
            flex-wrap: wrap;
        }

        .megamenu-custom-group {
            flex: 1 1 25%;
            padding-right: 15px !important;
        }

        .nav-toggle {
            display: none;
        }

        .nav-sections {
            flex-shrink: 0;
            flex-basis: auto;
        }

        .nav-sections-item-title {
            display: none;
        }

        .nav-sections-item-content {
            display: block !important;
        }

        .nav-sections-item-content > * {
            display: none;
        }

        .nav-sections-item-content {
            > .navigation {
                display: block;
            }
        }
        @include lib-main-navigation-desktop(
                $_nav-background-color : $primary__color1,
                $_nav-level0-item-color : #fff,
                $_nav-font-size : 17px,
                $_nav-font-weight : 400,
                $_nav-level0-item-margin : 0,
                $_nav-level0-item__active__border-width : 0px,
                $_nav-level0-item-color-active : $primary__color,
                $_nav-level0-item-line-height : 60px );

        .navigation {

            .submenu a {
                border-color: $primary__color !important;
                line-height: inherit;
                color: $primary__color1 !important;
                font-size: 14px;

                &:hover {
                    color: #3f3f3f !important;
                }
            }
            ul {
                font-weight: 600;
            }

            .level0 .level-top {
                padding-left: 0 ;
                margin-left: -5px;
                margin-right: 20px;
                padding: 0 9px;
            }
        }

        .panel.header {
            .links,
            .switcher {
                display: inline-flex;
            }

            .switcher {
                float: right;
                margin-right: 0;

                .view-default {
                    span {
                        display: none;
                    }
                }

                .view-storeview_nl {
                    span {
                        display: none;
                    }

                    &:before {
                        display: inline-block;
                        width: 30px;
                        height: 20px;
                    }
                }

                .view-storeview_at {
                    span {
                        display: none;
                    }

                    &:before {
                        display: inline-block;
                        width: 30px;
                        height: 20px;
                    }
                }

                .view-storeview_ch {
                    span {
                        display: none;
                    }

                    &:before {
                        display: inline-block;
                        width: 30px;
                        height: 20px;
                    }
                }

                ul.dropdown .switcher-option {
                    a {
                        display: inline-block;
                    }

                    &:before {
                        position: relative;
                        top: 6px;
                        margin-left: 5px;
                        left: 3px;
                    }
                }
            }
        }
    }

    //
    //  Desktop menu item
    //  _____________________________________________

    @include min-screen(1400px) {
        .navigation {
            .level0 .level-top {
                font-size: 16px;
            }
        }
    }

    @media only screen and (max-width: 1400px) and (min-width: 1310px) {
        .navigation {
            .level0 .level-top {
                font-size: 15px;
                margin-right: 12px;
            }
        }
    }



    //  _____________________________________________

    @include max-screen(1440px) {
        .section-item-content .switcher-language {
            display: none;
        }
        .section-item-content > .switcher-language {
            display: block;
        }
        .navigation {
            font-size: 14px;

            li.level0 {
                border-top-color: $primary__color2;

                > a {
                    color: $primary__color__darker;
                }

                &:last-child {
                    border-bottom-color: $primary__color2;
                }

                .all-category {
                    font-weight: 600;
                }


                &.active,
                &.has-active {
                    > a:not(.ui-state-active),
                    .all-category .ui-state-focus {
                        border-color: $primary__color2;
                    }


                }
            }
        }
    }

    @include max-screen(1260px) {
        .navigation {
            font-size: 13px;

            .level0  {
                > .level-top {
                    margin-right: 0;
                }
            }
        }
    }
    @include max-screen(1040px) {
        .navigation {
            > .level-top {
                font-weight: 600;
                color: $primary__color__darker;
                padding: 0.8rem 20px;
                margin-right: 10px;
            }
        }
    }

    @include max-screen(480px) {
        .nav-sections-item-title {
            font-size: 13px;
        }
    }
