
.blog__post-pinned {
    @include lib-clearfix();
    margin-bottom: 3rem;
    position: relative;

    .block-title {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.5rem 1rem;
        background: rgba(255, 255, 255, 0.6);
        color: #333333;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 1.8rem;
        width: 100%;
    }

    .items {
        .item {
            float: left;
            width: 32%;
            margin-right: 2%;

            img {
                width: 100%;
                margin-bottom: 1rem;
            }

            &:last-child {
                margin-right: 0;
            }

            h2 {
                display: block;
                margin-top: 0;
                font-weight: 500;

                a.title {
                    color: #333;
                    font-size: 1.8rem;
                }
            }
        }
    }
}
