.header-checkout-contact {
    display: flex;
    float: right;
    align-items: center;
    margin-top: 8px;
    font-size: 20px;

    .tel-title {
        @include max-screen($screen__s) {
            display: none;
        }
    }

    .phone {
        padding-left: 15px;
        color: #20272b !important;

        &:visited {
            color: #20272b !important;
        }

        &:hover {
            text-decoration: none;
        }

        &:before {
            content: "\e969";
            font-size: 20px;
            font-weight: 200;
            font-family: "icomoon";
            color: $primary__color;
        }
    }
}
