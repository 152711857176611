.stepper-wrapper {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0;
    overflow: hidden;

    .stepper-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;

        &:before,
        &:after {
            position: absolute;
            content: "";
            border-bottom: 4px solid #ccc;
            width: 100%;
            top: 20px;
            z-index: 2;
            margin-top: -5px;
        }

        &:before {
            left: -50%;
        }

        &:after {
            left: 50%;
        }

        .step-name {
            color: #014a5b;
        }

        .step-counter {
            color: #c9d2d9;
            background: #f8f8f8;
            position: relative;
            z-index: 5;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 33px;
            height: 33px;
            margin-bottom: 6px;
            font-weight: bold;
        }

        &.active {
            .step-counter {
                background: #014A5B;
            }

            &:before,
            &:after {
                border-bottom: 4px solid #014A5B;
            }
        }
    }
}


.stepper-item.completed .step-counter {
    background-color: #4bb543;
}

.stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
}
