button {
    &:active {
        box-shadow: $button__shadow;
    }
}
a.action.primary {
    @include lib-link-as-button();
}
.action.primary {
    @include lib-button-primary();

    &.out-of-stock-notify {
      width: 100%;
      line-height: 46px;
      background: $primary__color1;
      border: 2px solid $secondary__color;
      padding: 0 33px;

     &:hover {
         background: transparent;
         color: $secondary__color;
     }
    }
}



#btn-minicart-close {
    color: $primary__color3;
}

/**/
.checkout-index-index.page-layout-checkout{

        .action.action-apply{
            background-color: #2fdf66 !important;
            height: 50px !important;
            padding-left: 30px !important;
            padding-right: 30px !important;
            color: white !important;
            border: 0px;
            margin-right: 0px !important;
        }

}

.action.primary.checkout {
    background-image: none;
    background: $primary__color5;
    border: 0px solid $primary__color5;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
    margin: 0;
    padding: 7px 15px;
    width: auto;
    font-size: 14px;
    line-height: 16px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    &:hover {
        background: transparent;
        color: white;
        background: $primary__color;
    }
}

.action.primary {
    line-height: 46px;
    padding: 0 33px;
    border: 0px solid;
    margin-bottom: 30px !important;
    border: 0px solid $primary__color1;
    background: $primary__color;
    color: white;

    &:hover{
        border: 0px solid $primary__color;
        background: $primary__color1;
        color: white;
    }
}




.cart-container .form-cart .action.continue {
    display: inline-block;
    text-decoration: none;
    background: $primary__color1;
    border: 0px;
    margin-bottom: 20px;
    border-radius: 0px;
    color: white;
    cursor: pointer;
    font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 0;
    padding: 16px 24px 0px 0;
    width: auto;
    font-size: 14px;
    line-height: 16px;
    vertical-align: middle;
    height: 48px;
    margin-bottom: 20px;

    &:hover{
        background: $primary__color;
        color: white;
        border:0px;
    }
}


#empty_cart_button {
    padding-left: 20px;
}

.action.clear,
.action.update {
    //display: inline-block;
    //text-decoration: none;
    //background: $primary__color1;
    border: 0px;
    border-radius: 0px;
    color: black;
    cursor: pointer;
    font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 0;
    padding: 0px 24px 0px 0;
    width: auto;
    font-size: 14px;
    line-height: 16px;
    vertical-align: middle;
    height: 48px;
    &:hover{
        //background: $primary__color;
        color: black;
        border:0px;
    }

}

.action.remove {
    vertical-align: middle;
}
