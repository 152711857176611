.icon-email:before {
    font-family: $icons-name;
    content: "\e94f";
    color: white;
    padding-right: 8px;
}

.icon-headphones:before {
    font-family: $icons-name;
    content: "\e956";
    color: white;
    padding-right: 8px;
    font-weight: 600;
}

.block.newsletter .field .control::before {
    -webkit-font-smoothing: antialiased;
    font-size: 35px;
    line-height: 33px;
    color: #c2c2c2;
    content: "";
    font-family: "icons-blank-theme";
    margin: inherit;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: middle;
    display: none !important;
}

.action.subscribe.primary {
    font-size: 0px;
    border: 0px;
    background: none;
    &:before {
        font-family: $icons-name;
        font-size: 20px;
        display: inline-block;
        color: white;
        line-height: 12px;
        text-align: center;
        cursor: pointer;
        content: "\e94f";
        font-weight: 100;
    }
}

.authorization-link {
    display: flex !important;
    padding-right: 14px;
    &:before {
        font-family: $icons-name;
        font-size: 12px;
        display: inline-block;
        color: #4A4A49;
        line-height: 18px;
        text-align: center;
        cursor: pointer;
        content: "\e951";
        padding-right: 5px;
    }
}

.icon-icon-planten-11 {
    &:before {
        font-family: $icons-name;
        font-size: 70px;
        display: inline-block;
        color: white;
        line-height: 70px;
        text-align: center;
        cursor: pointer;
        content: "\e961";
    }

}

.icon-icon-info {
    &:before {
        font-family: $icons-name;
        font-size: 70px;
        display: inline-block;
        color: white;
        line-height: 70px;
        text-align: center;
        cursor: pointer;
        content: "\e958";
    }

    &:hover:before {
        color: none;
    }

}

.icon-icon-headphones {
    &:before {
        font-family: $icons-name;
        font-size: 70px;
        display: inline-block;
        color: white;
        line-height: 70px;
        text-align: center;
        cursor: pointer;
        content: "\e956";
    }

    &:hover:before {
        color: none;
    }

}

.icon-icon-veilig {
    &:before {
        font-family: $icons-name;
        font-size: 70px;
        display: inline-block;
        color: white;
        line-height: 70px;
        text-align: center;
        cursor: pointer;
        content: "\e964";
    }

    &:hover:before {
        color: none;
    }

}

.icon-icon-Bezorgen22 {
    &:before {
        font-family: $icons-name;
        font-size: 70px;
        display: inline-block;
        color: white;
        line-height: 70px;
        text-align: center;
        cursor: pointer;
        content: "\e94e";
        float: left;
        padding-bottom: 1px;
    }

    &:hover:before {
        color: none;
    }

}

.icon-icon-Bezorgen2 {
    &:before {
        font-family: $icons-name;
        font-size: 90px;
        display: inline-block;
        color: #606260;
        line-height: 80px;
        text-align: center;
        cursor: pointer;
        content: "\e94e";
        padding-bottom: 15px;
    }

    &:hover:before {
        color: $primary__color1;
    }

}

.icon-icon-happy2 {
    &:before {
        font-family: $icons-name;
        font-size: 90px;
        display: inline-block;
        color: #606260;
        line-height: 80px;
        text-align: center;
        cursor: pointer;
        content: "\e954";
        padding-bottom: 15px;
    }

    &:hover:before {
        color: $primary__color1;
    }

}

.icon-icon-happy3 {
    &:before {
        font-family: $icons-name;
        font-size: 90px;
        display: inline-block;
        color: #606260;
        line-height: 80px;
        text-align: center;
        cursor: pointer;
        content: "\e955";
        padding-bottom: 15px;
    }

    &:hover:before {
        color: $primary__color1;
    }

}

.icon-icon-think {
    &:before {
        font-family: $icons-name;
        font-size: 90px;
        display: inline-block;
        color: #606260;
        line-height: 80px;
        text-align: center;
        cursor: pointer;
        content: "\e962";
        padding-bottom: 15px;
    }

    &:hover:before {
        color: $primary__color1;
    }

}

.icon-icon-planten-1 {
    &:before {
        font-family: $icons-name;
        font-size: 90px;
        display: inline-block;
        color: #606260;
        line-height: 80px;
        text-align: center;
        cursor: pointer;
        content: "\e960";
        padding-bottom: 15px;
    }

    &:hover:before {
        color: $primary__color1;
    }

}

ul {
    .icon-icon-Bezorgen2usp {
        &:before {
            font-family: $icons-name;
            font-size: 80px;
            display: inline-block;
            color: #606260;
            line-height: 180px;
            text-align: center;
            cursor: pointer;
            content: "\e94e";
        }
    }
}

.icon-icon-Bezorgen3 {
    &:before {
        font-family: $icons-name;
        font-size: 30px !important;
        display: inline-block;
        color: $primary__color1;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        float: left;
        margin-top: 15px;
        content: "\e94e";
    }
}

ul {
    .icon-icon-think {
        &:before {
            font-family: $icons-name;
            font-size: 80px;
            display: inline-block;
            color: #606260;
            line-height: 180px;
            text-align: center;
            cursor: pointer;
            content: "\e962";
        }
    }
}

ul {
    .icon-icon-planten-1 {
        &:before {
            font-family: $icons-name;
            font-size: 80px;
            display: inline-block;
            color: #606260;
            line-height: 180px;
            text-align: center;
            cursor: pointer;
            content: "\e960";
        }
    }
}

ul {
    .icon-icon-happy2 {
        &:before {
            font-family: $icons-name;
            font-size: 80px;
            display: inline-block;
            color: #606260;
            line-height: 180px;
            text-align: center;
            cursor: pointer;
            content: "\e954";
        }
    }
}

ul {
    .icon-icon-gezond {
        &:before {
            font-family: $icons-name;
            font-size: 80px;
            display: inline-block;
            color: #606260;
            line-height: 180px;
            text-align: center;
            cursor: pointer;
            content: "\e952";
        }
    }
}

ul {
    .icon-icon-gezond2 {
        &:before {
            font-family: $icons-name;
            font-size: 80px;
            display: inline-block;
            color: #606260;
            line-height: 180px;
            text-align: center;
            cursor: pointer;
            content: "\e953";
        }
    }
}

ul {
    .icon-icon-headphones {
        &:before {
            font-family: $icons-name;
            font-size: 80px;
            display: inline-block;
            color: #606260;
            line-height: 180px;
            text-align: center;
            cursor: pointer;
            content: "\e956";
        }
    }
}

ul {
    .icon-icon-height_plant {
        &:before {
            font-family: $icons-name;
            font-size: 80px;
            display: inline-block;
            color: #606260;
            line-height: 180px;
            text-align: center;
            cursor: pointer;
            content: "\e957";
        }
    }
}

ul {
    .icon-icon-info {
        &:before {
            font-family: $icons-name;
            font-size: 80px;
            display: inline-block;
            color: #606260;
            line-height: 180px;
            text-align: center;
            cursor: pointer;
            content: "\e958";
        }
    }
}

ul {
    .icon-icon-instagram {
        &:before {
            font-family: $icons-name;
            font-size: 80px;
            display: inline-block;
            color: #606260;
            line-height: 180px;
            text-align: center;
            cursor: pointer;
            content: "\e959";
        }
    }
}

ul {
    .icon-icon-ligging {
        &:before {
            font-family: $icons-name;
            font-size: 80px;
            display: inline-block;
            color: #606260;
            line-height: 180px;
            text-align: center;
            cursor: pointer;
            content: "\e95d";
        }
    }
}

ul {
    .icon-icon-medewerkers {
        &:before {
            font-family: $icons-name;
            font-size: 80px;
            display: inline-block;
            color: #606260;
            line-height: 180px;
            text-align: center;
            cursor: pointer;
            content: "\e95e";
        }
    }
}

ul {
    .icon-icon-twitter {
        &:before {
            font-family: $icons-name;
            font-size: 80px;
            display: inline-block;
            color: #606260;
            line-height: 180px;
            text-align: center;
            cursor: pointer;
            content: "\e963";
        }
    }
}

ul {
    .icon-icon-veilig {
        &:before {
            font-family: $icons-name;
            font-size: 80px;
            display: inline-block;
            color: #606260;
            line-height: 180px;
            text-align: center;
            cursor: pointer;
            content: "\e964";
        }
    }
}

ul {
    .icon-icon-veilig2 {
        &:before {
            font-family: $icons-name;
            font-size: 80px;
            display: inline-block;
            color: #606260;
            line-height: 180px;
            text-align: center;
            cursor: pointer;
            content: "\e965";
        }
    }
}

ul {
    .icon-icon-Whatsapp {
        &:before {
            font-family: $icons-name;
            font-size: 80px;
            display: inline-block;
            color: #606260;
            line-height: 180px;
            text-align: center;
            cursor: pointer;
            content: "\e966";
        }
    }
}

ul {
    .icon-icon-winkelwagen {
        &:before {
            font-family: $icons-name;
            font-size: 80px;
            display: inline-block;
            color: #606260;
            line-height: 180px;
            text-align: center;
            cursor: pointer;
            content: "\e967";
        }
    }
}
