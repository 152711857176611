.blog__post-recent {
    width: 100vw;
    max-width: 100vw;
    margin-left: calc(50% - 50vw);
    background-color: $primary__color11;
    padding: 20px 0;

    .blog-container {
        max-width: 1445px;
        margin: 0 auto;

        .block-title {
            display: block;
            margin-bottom: 10px;
            padding: 0 20px;

            @media only screen and (min-width: 992px) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 15px 0 0;
            }

            .blog-title-container {
                font-size: 30px;

                @media only screen and (max-width: 565px) {
                    font-size: 20px;
                }
            }

            .blog-show-all {
                font-size: 20px;

                @media only screen and (max-width: 565px) {
                    font-size: 15px;
                    margin: 5px 0;
                }

                a.show-all-link {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    color: black;

                    &:after {
                        display: inline-block;
                        font-family: $icons-name;
                        content: "\e900";
                        font-size: 22px;
                        line-height: 24px;
                        text-align: center;
                        color: $primary__color5;
                        cursor: pointer;
                        transform: rotate(-90deg);
                    }
                }
            }
        }

        .block-content {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 0 20px;

            @media only screen and (min-width: 565px) {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }

            @media only screen and (min-width: 992px) {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            .item {
                background-color: $primary__color4;
                border-radius: 5px;
                overflow: hidden;

                .blog-image-container {
                    width: 100%;

                    img.blog__post-featured-image {
                        object-fit: cover;
                        overflow: hidden;
                        min-height: 160px;
                        width: 100%;
                    }
                }

                a.blog-name {
                    display: block;
                    font-weight: 300;
                    color: black;
                    position: relative;
                    padding: 15px 10px;

                    &:after {
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                        font-family: $icons-name;
                        content: "\e900";
                        font-size: 22px;
                        line-height: 24px;
                        text-align: center;
                        color: $primary__color5;
                        cursor: pointer;
                        margin: 0 0 0 5px;
                        transform: rotate(-90deg);
                    }
                }
            }
        }
    }
}
