.opc-wrapper {

    .step-title {
        &.discount-code {
            margin-top: 25px;
        }
    }

    .opc-payment-additional.discount-code {
        .discount-description {
            padding: 10px 0;
        }

        .payment-option-content {
            padding: 10px 0 0 0;
        }

        .payment-option-title {
            border-top: none;
            padding: 20px 0;
        }

    }

    .form-discount {
        width: 100%;

        .field {
            .label {
                @extend .abs-visually-hidden;
            }
        }

        .action-apply {
            line-height: 22px;
            padding: 14px 17px;
            font-size: 18px;
            font-weight: 600 !important;
        }
    }
}
