.catalog-product-view {
    .product-info-main {
        .table-wrapper.simple {
            .actionbar {
                .control.qty {
                    .total.price-on-request {
                        color: $primary__color3;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.btn-wrapper {
    display: block;
}
.request-quote-dialog {
    position: fixed;
    inset: 0;
    max-width: 750px;

    .head {
        display: flex;
        justify-content: space-between;
    }

    .webforms {
        .actions-toolbar {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .webforms-fields-customer_street {
        width: 66%;
        margin: 0 1% 29px 0;
    }

    .webforms-fields-customer_house_number {
        width: 32%;
        margin: 0 0 29px 1%;
    }

    .webforms-fields-customer_zipcode {
        width: 49%;
        margin: 0 1% 29px 0;
    }

    .webforms-fields-customer_city {
        width: 49%;
        margin: 0 0 29px 1%;
    }

    @media only screen and (max-width: 512px) {
        .webforms-fields-customer_zipcode,
        .webforms-fields-customer_city,
        .webforms-fields-customer_street,
        .webforms-fields-customer_house_number {
            width: 100%;
        }
    }

    input,
    textarea {
        background: #fff;
        color: #95989A;
        font-size: 20px;
        font-weight: 100;
        padding: 0 9px;
    }
}

.btn-request-quote {
    cursor: pointer;
    font-size: 20px;
    text-decoration: none;
    color: $primary__color1;
    padding-left: 10px;

    &:hover {
        text-decoration: none;
        color: $primary__color3;
    }
}

#rfq-confirm {
    h1 {
        background: #014a5b !important;
    }
    a {
        color: #014a5b;
    }
    #confirmButtons {
        .action.primary {
            -webkit-appearance: unset;
            color: #fff;

        }
        button {
            background: #014a5b;
            color: #fff;
        }
    }

}
.quotation-quote-index {
    .form-cart {
        padding-right: 30px;
    }
    .cart.table-wrapper {
        .col.price {
            display: none;
        }
        .cart.item {
            position:revert;
            display: table-row-group;

            &:last-child {
                border-bottom: solid #D8D8D8 1px !important;
            }

            .control.qty {
                position: revert;
            }
        }
    }
}

@include max-screen(1040px) {
    .header.content {
        .miniquote-wrapper {
            display: none;
        }
    }
}

.webforms {
    .product-price-quote {
        .fieldset {
            margin-top: 10px;
            padding-left: 0;
            padding-right: 0;

            .field {
                padding: 0;

                .label {
                    float: none;
                    width: 100%;
                    margin-bottom: 5px;
                    padding: 0;
                }

                .control {
                    float: unset;
                    width: 100%;

                    input {
                        text-align: left !important;
                        width: 100% !important;
                        margin: 0;
                    }

                    .input-text {
                        border: 1px solid #c2c2c2 !important;
                    }
                }
            }
        }

        .actions-toolbar.submit-right {
            margin-left: auto !important;

            .primary {
                .action.submit.primary {
                    margin-right: 0 !important;
                    margin-bottom: 0 !important;
                }
            }
        }
    }
}
