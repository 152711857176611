.view-default {
  padding-left: 11px;
}

.logo img {
  height: 60px;
  width: auto;
  margin-left: 0px;
}

.header.content {
  padding: 30px 8px 0 !important;
}

._keyfocus :focus,
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
  -webkit-box-shadow: 0 0 3px 1px $primary__color;
  box-shadow: 0 0 3px 1px $primary__color;
}

.page-header {
  .switcher {
    .view-current-store-switch,
    .switcher-option {
      &:before {
        content: "";
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 30px;
        height: 20px;
      }
    }

    .view-default:before {
      background-image: url("../images/flags/de.svg");
    }

    .view-storeview_at:before {
      background-image: url("../images/flags/at.svg");
    }

    .view-storeview_be:before {
      background-image: url("../images/flags/be.svg");
    }

    .view-storeview_ch:before {
      background-image: url("../images/flags/ch.svg");
    }

    .view-storeview_dk:before {
      background-image: url("../images/flags/dk.svg");
    }

    .view-storeview_fr:before {
      background-image: url("../images/flags/fr.svg");
    }

    .view-storeview_gb:before {
      background-image: url("../images/flags/gb.svg");
    }

    .view-storeview_nl:before {
      background-image: url("../images/flags/nl.svg");
    }

    .view-storeview_se:before {
      background-image: url("../images/flags/se.svg");
    }

    .options {
      ul.dropdown.switcher-dropdown {
        right: 0;

        &:before {
          right: 10px;
          left: auto;
        }

        &:after {
          right: 9px;
          left: auto;
        }
      }
    }
  }
}
