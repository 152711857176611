.cms-no-route,
.cms-page-view {
    .page-main {
        padding: 0 0 50px 8px;
        box-sizing: border-box;
    }
}
.contact-index-index {
    .page-main {
        max-width: none;
      
        .form-container {
            background: #f1f8f1;

            @include max-screen(768px) {
                .contact-text {
                    text-align: center;
                }
            }

            .inner-form-center-container {
                max-width: 1445px;
                margin: 0 auto;
                box-sizing: border-box;
                padding: 0 8px;
                display: flex;
                position: relative;

                .contact-img {
                    width: 40%;
                    position: relative;

                    @include max-screen(1200px) {
                        width: 50%;
                    }

                    @include max-screen(768px) {
                        display: none;
                    }

                    img {
                        float: none;
                        margin-top: 20px;
                        margin-right: 20px;
                        display: block;
                        position: absolute;
                        bottom: 0;
                        width: calc(100% - 20px);

                    }
                }

                .formcontact {
                    width: 60%;
                    padding-bottom: 80px;

                    .fieldset {
                        padding-top: 30px;

                        @include max-screen(768px) {
                            text-align: center;
                        }

                        > .field:not(.choice) {
                            @include max-screen(768px) {
                                padding: 0;
                                width: 100%;
                            }

                            > .label {
                                width: 100%;
                                box-sizing: border-box;
                            }
                        }
                        @include max-screen(768px) {
                            .send-btn {
                                float: none;
                                display: inline-block;
                            }
                        }
                    }

                    @include max-screen(1200px) {
                        width: 50%;
                    }

                    @include max-screen(768px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}