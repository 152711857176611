
.blog__copyright-wrapper {
    display: block;
    float: right;
    clear: both;
    margin-top: 50px;
    font-size: 10px !important;
    text-transform: none;
    color: #c2c2c2 !important;

    * {
        font-size: 10px !important;
        color: #c2c2c2 !important;

    }

    h3 {
        margin-top: 0;
        display: block;
    }

    a {
        text-decoration: underline !important;
    }
}
