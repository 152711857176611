@charset "UTF-8";
html {
  font-family: sans-serif;
  text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

progress {
  vertical-align: baseline; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline-width: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

code,
kbd,
pre,
samp {
  /* stylelint-disable */
  font-family: monospace, monospace;
  /* stylelint-enable */
  font-size: 1em; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

button, .cart.table-wrapper .actions-toolbar > .action,
input,
select,
textarea {
  font: inherit; }

optgroup {
  font-weight: bold; }

button, .cart.table-wrapper .actions-toolbar > .action,
input,
select {
  overflow: visible; }

button, .cart.table-wrapper .actions-toolbar > .action,
input,
select,
textarea {
  margin: 0; }

button, .cart.table-wrapper .actions-toolbar > .action,
select {
  text-transform: none; }

[type="button"],
[type="reset"],
[type="submit"], button, .cart.table-wrapper .actions-toolbar > .action {
  cursor: pointer; }

[disabled] {
  cursor: default; }

[type="reset"],
[type="submit"], button, .cart.table-wrapper .actions-toolbar > .action,
html [type="button"] {
  appearance: button; }

button::-moz-focus-inner, .cart.table-wrapper .actions-toolbar > .action::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button:-moz-focusring, .cart.table-wrapper .actions-toolbar > .action:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  appearance: field; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  appearance: none; }

body {
  margin: 0;
  padding: 0; }

article,
aside,
details,
figcaption,
figure,
main,
footer,
header,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

nav ul,
nav ol {
  list-style: none none; }

img {
  border: 0; }

img,
object,
video,
embed {
  height: auto;
  max-width: 100%; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

html {
  font-size: 14px; }

body {
  color: #333;
  font-family: "Lexend", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  font-size: 14px; }

p {
  margin-top: 7px;
  margin-bottom: 7px; }

abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #d1d1d1; }

b,
strong {
  font-weight: 700; }

em,
i {
  font-style: italic; }

mark {
  background: #f0f0f0;
  color: black; }

small,
.small {
  font-size: 12px; }

hr {
  border: 0;
  border-top: 1px solid #d1d1d1;
  margin-bottom: 20px;
  margin-top: 20px; }

sub,
sup {
  font-size: 78.57143%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -10px; }

sub {
  bottom: -5px; }

dfn {
  font-style: italic; }

h1 {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: 300;
  line-height: 1.1;
  font-size: 26px;
  margin-top: 7px;
  margin-bottom: 7px; }

h2 {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: 300;
  line-height: 1.1;
  font-size: 26px;
  margin-top: 7px;
  margin-bottom: 7px; }

h3 {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: 300;
  line-height: 1.1;
  font-size: 18px;
  margin-top: 7px;
  margin-bottom: 7px; }

h4 {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: 700;
  line-height: 1.1;
  font-size: 14px;
  margin-top: 7px;
  margin-bottom: 7px; }

h5 {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: 700;
  line-height: 1.1;
  font-size: 12px;
  margin-top: 7px;
  margin-bottom: 7px; }

h6 {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: 700;
  line-height: 1.1;
  font-size: 10px;
  margin-top: 7px;
  margin-bottom: 7px; }

h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small {
  color: #333;
  font-family: "Lexend", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  font-size: 78.57143%; }

a,
.alink {
  color: #1979c3;
  text-decoration: none; }
  a:visited,
  .alink:visited {
    color: #1979c3;
    text-decoration: none; }
  a:hover,
  .alink:hover {
    color: #006bb4;
    text-decoration: underline; }
  a:active,
  .alink:active {
    color: #ff5501;
    text-decoration: underline; }

ul,
ol {
  color: inherit;
  font-family: "Lexend", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  font-size: inherit; }
  ul,
  ol {
    margin-top: 7px; }
  ul,
  ol {
    margin-bottom: 7px; }
  ul > li,
  ol > li {
    margin-top: 7px; }
  ul > li,
  ol > li {
    margin-bottom: 7px; }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-bottom: 0; }

dl {
  margin-bottom: 20px;
  margin-top: 0; }

dt {
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 0; }

dd {
  margin-bottom: 10px;
  margin-top: 0;
  margin-left: 0; }

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace; }

code {
  background: #f0f0f0;
  color: #111111;
  padding: 2px 4px;
  font-size: 12px;
  white-space: nowrap; }

kbd {
  background: #f0f0f0;
  color: #111111;
  padding: 2px 4px;
  font-size: 12px; }

pre {
  background: #f0f0f0;
  border: 1px solid #d1d1d1;
  color: #111111;
  line-height: 1.42857;
  margin: 0 0 10px;
  padding: 10px;
  font-size: 12px;
  display: block;
  word-wrap: break-word; }
  pre code {
    background-color: transparent;
    border-radius: 0;
    color: inherit;
    font-size: inherit;
    padding: 0;
    white-space: pre-wrap; }

blockquote {
  border-left: 0 solid #d1d1d1;
  margin: 0 0 20px 40px;
  padding: 0;
  color: #333;
  font-family: "Lexend", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 400;
  line-height: 1.42857;
  font-size: 14px; }
  blockquote p:last-child,
  blockquote ul:last-child,
  blockquote ol:last-child {
    margin-bottom: 0; }
  blockquote footer,
  blockquote small,
  blockquote .small {
    color: #333;
    line-height: 1.42857;
    font-size: 11px;
    display: block; }
    blockquote footer:before,
    blockquote small:before,
    blockquote .small:before {
      content: "— "; }
  blockquote cite {
    font-style: normal; }

blockquote:before,
blockquote:after {
  content: ''; }

q {
  quotes: none; }

q:before,
q:after {
  content: '';
  content: none; }

cite {
  font-style: normal; }

table {
  margin-bottom: 20px;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%; }
  table th {
    text-align: left; }
  table > tbody > tr > th,
  table > tbody > tr > td,
  table > tfoot > tr > th,
  table > tfoot > tr > td {
    vertical-align: top; }
  table > thead > tr > th,
  table > thead > tr > td {
    vertical-align: bottom; }
  table > thead > tr > th,
  table > thead > tr > td,
  table > tbody > tr > th,
  table > tbody > tr > td,
  table > tfoot > tr > th,
  table > tfoot > tr > td {
    padding: 11px 10px; }

button, .cart.table-wrapper .actions-toolbar > .action {
  background-image: none;
  background: #f2f2f2;
  border: 1px solid #cdcdcd;
  color: #2FDF66;
  cursor: pointer;
  display: inline-block;
  font-family: "Lexend", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 7px 15px;
  width: auto;
  font-size: 14px;
  line-height: 16px;
  box-sizing: border-box;
  vertical-align: middle; }
  button:focus, .cart.table-wrapper .actions-toolbar > .action:focus, button:active, .cart.table-wrapper .actions-toolbar > .action:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #2FDF66; }
  button:hover, .cart.table-wrapper .actions-toolbar > .action:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #555; }
  button.disabled, .cart.table-wrapper .actions-toolbar > .disabled.action, button[disabled], .cart.table-wrapper .actions-toolbar > .action[disabled],
  fieldset[disabled] button,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .action {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
  button::-moz-focus-inner, .cart.table-wrapper .actions-toolbar > .action::-moz-focus-inner {
    border: 0;
    padding: 0; }

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  color: inherit;
  font-family: "Lexend", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: inherit;
  font-weight: inherit;
  height: 32px;
  line-height: 1.42857;
  margin: 0;
  padding: 0 9px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box; }
  input[type="text"]:disabled,
  input[type="password"]:disabled,
  input[type="url"]:disabled,
  input[type="tel"]:disabled,
  input[type="search"]:disabled,
  input[type="number"]:disabled,
  input[type="datetime"]:disabled,
  input[type="email"]:disabled {
    opacity: 0.5; }
  input[type="text"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder,
  input[type="url"]::-moz-placeholder,
  input[type="tel"]::-moz-placeholder,
  input[type="search"]::-moz-placeholder,
  input[type="number"]::-moz-placeholder,
  input[type="datetime"]::-moz-placeholder,
  input[type="email"]::-moz-placeholder {
    color: #c2c2c2; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder,
  input[type="url"]::-webkit-input-placeholder,
  input[type="tel"]::-webkit-input-placeholder,
  input[type="search"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="datetime"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder {
    color: #c2c2c2; }
  input[type="text"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder,
  input[type="url"]:-ms-input-placeholder,
  input[type="tel"]:-ms-input-placeholder,
  input[type="search"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="datetime"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder {
    color: #c2c2c2; }

input[type="number"] {
  appearance: textfield; }
  input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

input[type="search"] {
  appearance: none; }
  input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration {
    appearance: none; }

select {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  color: inherit;
  font-family: "Lexend", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: inherit;
  font-weight: inherit;
  height: 32px;
  line-height: 1.42857;
  margin: 0;
  padding: 4px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box; }
  select:disabled {
    opacity: 0.5; }

select[multiple="multiple"] {
  height: auto;
  background-image: none; }

textarea {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  color: inherit;
  font-family: "Lexend", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: inherit;
  font-weight: inherit;
  height: auto;
  line-height: 1.42857;
  margin: 0;
  padding: 10px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box;
  resize: vertical; }
  textarea:disabled {
    opacity: 0.5; }
  textarea::-moz-placeholder {
    color: #c2c2c2; }
  textarea::-webkit-input-placeholder {
    color: #c2c2c2; }
  textarea:-ms-input-placeholder {
    color: #c2c2c2; }

input[type="checkbox"] {
  margin: 2px 5px 0 0;
  vertical-align: auto; }
  input[type="checkbox"]:disabled {
    opacity: 0.5; }

input[type="radio"] {
  margin: 2px 5px 0 0;
  vertical-align: auto; }
  input[type="radio"]:disabled {
    opacity: 0.5; }

input.mage-error,
select.mage-error,
textarea.mage-error {
  background: inherit;
  border-color: #ed8380;
  color: inherit; }
  input.mage-error:focus,
  select.mage-error:focus,
  textarea.mage-error:focus {
    border-color: inherit; }
  input.mage-error:disabled,
  select.mage-error:disabled,
  textarea.mage-error:disabled {
    border-color: inherit; }
  input.mage-error::-webkit-input-placeholder,
  select.mage-error::-webkit-input-placeholder,
  textarea.mage-error::-webkit-input-placeholder {
    color: inherit; }
  input.mage-error:-moz-placeholder,
  select.mage-error:-moz-placeholder,
  textarea.mage-error:-moz-placeholder {
    color: inherit; }
  input.mage-error::-moz-placeholder,
  select.mage-error::-moz-placeholder,
  textarea.mage-error::-moz-placeholder {
    color: inherit; }
  input.mage-error:-ms-input-placeholder,
  select.mage-error:-ms-input-placeholder,
  textarea.mage-error:-ms-input-placeholder {
    color: inherit; }

input.valid,
select.valid,
textarea.valid {
  background: inherit;
  border-color: inherit;
  color: inherit; }
  input.valid::-webkit-input-placeholder,
  select.valid::-webkit-input-placeholder,
  textarea.valid::-webkit-input-placeholder {
    color: inherit; }
  input.valid:-moz-placeholder,
  select.valid:-moz-placeholder,
  textarea.valid:-moz-placeholder {
    color: inherit; }
  input.valid::-moz-placeholder,
  select.valid::-moz-placeholder,
  textarea.valid::-moz-placeholder {
    color: inherit; }
  input.valid:-ms-input-placeholder,
  select.valid:-ms-input-placeholder,
  textarea.valid:-ms-input-placeholder {
    color: inherit; }

div.mage-error[generated] {
  color: #e02b27;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-size: 12px;
  display: inline-block;
  text-decoration: none; }
  div.mage-error[generated]:before {
    -webkit-font-smoothing: antialiased;
    font-size: 24px;
    line-height: 24px;
    color: #e02b27;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: middle; }
  div.mage-error[generated]:hover:before {
    color: inherit; }
  div.mage-error[generated]:active:before {
    color: inherit; }

input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  appearance: button;
  overflow: visible; }

input::-moz-focus-inner {
  border: 0;
  padding: 0; }

form {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

address {
  font-style: normal; }

*:focus {
  box-shadow: none;
  outline: 0; }

._keyfocus *:focus,
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
  box-shadow: 0 0 3px 1px #68a8e0; }

.abs-reset-list, .bundle-options-container .block-bundle-summary .bundle.items, .product-items, .prices-tier, .cart-container .checkout-methods-items, .opc-wrapper .opc, .checkout-agreements-items, .items, .block-wishlist-management .wishlist-select-items, .paypal-review .items-qty, .order-details-items .items-qty, .footer.content ul, .block-banners .banner-items,
.block-banners-inline .banner-items, .block-event .slider-panel .slider {
  margin: 0;
  padding: 0;
  list-style: none none; }
  .abs-reset-list > li, .bundle-options-container .block-bundle-summary .bundle.items > li, .product-items > li, .prices-tier > li, .cart-container .checkout-methods-items > li, .opc-wrapper .opc > li, .checkout-agreements-items > li, .items > li, .block-wishlist-management .wishlist-select-items > li, .paypal-review .items-qty > li, .order-details-items .items-qty > li, .footer.content ul > li, .block-banners .banner-items > li, .block-banners-inline .banner-items > li, .block-event .slider-panel .slider > li {
    margin: 0; }

.abs-action-link-button, .abs-action-addto-product, .bundle-options-container .block-bundle-summary .product-addto-links > .action, .product-info-main .action.tocompare,
.product-options-bottom .action.tocompare, .product-info-main .action.towishlist,
.product-options-bottom .action.towishlist,
.block-bundle-summary .action.towishlist, .block-event .slider-panel .slider .item .action.event {
  background-image: none;
  background: #f2f2f2;
  border: 1px solid #cdcdcd;
  color: #2FDF66;
  cursor: pointer;
  display: inline-block;
  font-family: "Lexend", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 7px 15px;
  width: auto;
  font-size: 14px;
  line-height: 16px;
  box-sizing: border-box;
  vertical-align: middle;
  display: inline-block;
  text-decoration: none;
  border-radius: 3px; }
  .abs-action-link-button:focus, .abs-action-addto-product:focus, .bundle-options-container .block-bundle-summary .product-addto-links > .action:focus, .product-info-main .action.tocompare:focus,
  .product-options-bottom .action.tocompare:focus, .product-info-main .action.towishlist:focus,
  .product-options-bottom .action.towishlist:focus,
  .block-bundle-summary .action.towishlist:focus, .block-event .slider-panel .slider .item .action.event:focus, .abs-action-link-button:active, .abs-action-addto-product:active, .bundle-options-container .block-bundle-summary .product-addto-links > .action:active, .product-info-main .action.tocompare:active,
  .product-options-bottom .action.tocompare:active, .product-info-main .action.towishlist:active,
  .product-options-bottom .action.towishlist:active,
  .block-bundle-summary .action.towishlist:active, .block-event .slider-panel .slider .item .action.event:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #2FDF66; }
  .abs-action-link-button:hover, .abs-action-addto-product:hover, .bundle-options-container .block-bundle-summary .product-addto-links > .action:hover, .product-info-main .action.tocompare:hover,
  .product-options-bottom .action.tocompare:hover, .product-info-main .action.towishlist:hover,
  .product-options-bottom .action.towishlist:hover,
  .block-bundle-summary .action.towishlist:hover, .block-event .slider-panel .slider .item .action.event:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #555; }
  .abs-action-link-button.disabled, .disabled.abs-action-addto-product, .bundle-options-container .block-bundle-summary .product-addto-links > .disabled.action, .product-info-main .disabled.action.tocompare,
  .product-options-bottom .disabled.action.tocompare, .product-info-main .disabled.action.towishlist,
  .product-options-bottom .disabled.action.towishlist,
  .block-bundle-summary .disabled.action.towishlist, .block-event .slider-panel .slider .item .disabled.action.event, .abs-action-link-button[disabled], .abs-action-addto-product[disabled], .bundle-options-container .block-bundle-summary .product-addto-links > .action[disabled], .product-info-main .action.tocompare[disabled],
  .product-options-bottom .action.tocompare[disabled], .product-info-main .action.towishlist[disabled],
  .product-options-bottom .action.towishlist[disabled],
  .block-bundle-summary .action.towishlist[disabled], .block-event .slider-panel .slider .item .action.event[disabled],
  fieldset[disabled] .abs-action-link-button,
  fieldset[disabled] .abs-action-addto-product,
  fieldset[disabled] .bundle-options-container .block-bundle-summary .product-addto-links > .action,
  .bundle-options-container .block-bundle-summary fieldset[disabled] .product-addto-links > .action,
  fieldset[disabled] .product-info-main .action.tocompare,
  .product-info-main fieldset[disabled] .action.tocompare,
  fieldset[disabled] .product-options-bottom .action.tocompare,
  .product-options-bottom fieldset[disabled] .action.tocompare,
  fieldset[disabled] .product-info-main .action.towishlist,
  .product-info-main fieldset[disabled] .action.towishlist,
  fieldset[disabled] .product-options-bottom .action.towishlist,
  .product-options-bottom fieldset[disabled] .action.towishlist,
  fieldset[disabled] .block-bundle-summary .action.towishlist,
  .block-bundle-summary fieldset[disabled] .action.towishlist,
  fieldset[disabled] .block-event .slider-panel .slider .item .action.event,
  .block-event .slider-panel .slider .item fieldset[disabled] .action.event {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
  .abs-action-link-button:hover, .abs-action-addto-product:hover, .bundle-options-container .block-bundle-summary .product-addto-links > .action:hover, .product-info-main .action.tocompare:hover,
  .product-options-bottom .action.tocompare:hover, .product-info-main .action.towishlist:hover,
  .product-options-bottom .action.towishlist:hover,
  .block-bundle-summary .action.towishlist:hover, .block-event .slider-panel .slider .item .action.event:hover, .abs-action-link-button:active, .abs-action-addto-product:active, .bundle-options-container .block-bundle-summary .product-addto-links > .action:active, .product-info-main .action.tocompare:active,
  .product-options-bottom .action.tocompare:active, .product-info-main .action.towishlist:active,
  .product-options-bottom .action.towishlist:active,
  .block-bundle-summary .action.towishlist:active, .block-event .slider-panel .slider .item .action.event:active, .abs-action-link-button:focus, .abs-action-addto-product:focus, .bundle-options-container .block-bundle-summary .product-addto-links > .action:focus, .product-info-main .action.tocompare:focus,
  .product-options-bottom .action.tocompare:focus, .product-info-main .action.towishlist:focus,
  .product-options-bottom .action.towishlist:focus,
  .block-bundle-summary .action.towishlist:focus, .block-event .slider-panel .slider .item .action.event:focus {
    text-decoration: none; }

.abs-product-options-list dt, .cart.table-wrapper .item-options dt, .multicheckout .item-options dt {
  clear: left;
  float: left;
  margin: 0 10px 5px 0; }
  .abs-product-options-list dt:after, .cart.table-wrapper .item-options dt:after, .multicheckout .item-options dt:after {
    content: ': '; }

.abs-product-options-list dd, .cart.table-wrapper .item-options dd, .multicheckout .item-options dd {
  float: left;
  margin: 0 0 5px; }

@media only screen and (min-width: 768px) {
  .abs-product-options-list-desktop dt {
    clear: left;
    float: left;
    margin: 0 10px 5px 0; }
    .abs-product-options-list-desktop dt:after {
      content: ': '; }
  .abs-product-options-list-desktop dd {
    float: left;
    margin: 0 0 5px; } }

.abs-button-responsive, .actions-toolbar > .primary .action,
.actions-toolbar > .secondary .action {
  width: 100%; }

@media only screen and (min-width: 768px) {
  .abs-button-desktop {
    width: auto; } }

@media only screen and (max-width: 768px) {
  .abs-button-responsive-smaller, .product-info-main .box-tocart .actions .action.tocart {
    width: 100%; } }

@media only screen and (min-width: 768px) {
  .abs-blocks-2columns, .column .block-addbysku .block-content .box, .login-container .block, .account .column.main .block:not(.widget) .block-content .box, .block-addresses-list .items.addresses > .item, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box,
  .sales-guest-view .column.main .block:not(.widget) .block-content .box {
    width: 48.8%; }
    .abs-blocks-2columns:nth-child(odd), .column .block-addbysku .block-content .box:nth-child(odd), .login-container .block:nth-child(odd), .account .column.main .block:not(.widget) .block-content .box:nth-child(odd), .block-addresses-list .items.addresses > .item:nth-child(odd), [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box:nth-child(odd),
    .sales-guest-view .column.main .block:not(.widget) .block-content .box:nth-child(odd) {
      clear: left;
      float: left; }
    .abs-blocks-2columns:nth-child(even), .column .block-addbysku .block-content .box:nth-child(even), .login-container .block:nth-child(even), .account .column.main .block:not(.widget) .block-content .box:nth-child(even), .block-addresses-list .items.addresses > .item:nth-child(even), [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box:nth-child(even),
    .sales-guest-view .column.main .block:not(.widget) .block-content .box:nth-child(even) {
      float: right; } }

@media only screen and (min-width: 640px) {
  .abs-blocks-2columns-s {
    width: 48.8%; }
    .abs-blocks-2columns-s:nth-child(odd) {
      clear: left;
      float: left; }
    .abs-blocks-2columns-s:nth-child(even) {
      float: right; } }

.abs-reset-image-wrapper, .products-list .product-image-wrapper, .cart.table-wrapper .product-image-wrapper, .minicart-items .product-image-wrapper {
  height: auto;
  padding: 0 !important; }
  .abs-reset-image-wrapper .product-image-photo, .products-list .product-image-wrapper .product-image-photo, .cart.table-wrapper .product-image-wrapper .product-image-photo, .minicart-items .product-image-wrapper .product-image-photo {
    position: static; }

.abs-adaptive-images {
  display: block;
  height: auto;
  max-width: 100%; }

.abs-adaptive-images-centered, .product.media .product.photo .photo.image {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 100%; }

.abs-login-block-title, .login-container .block .block-title {
  font-size: 18px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 15px;
  padding-bottom: 12px; }
  .abs-login-block-title strong, .login-container .block .block-title strong {
    font-weight: 500; }

.abs-block-title, .abs-account-blocks .block-title, .account .column.main .block:not(.widget) .block-title, .multicheckout .block-title, .paypal-review .block .block-title, [class^='sales-guest-'] .column.main .block:not(.widget) .block-title, .sales-guest-view .column.main .block:not(.widget) .block-title, .block-compare .block-title, .block-wishlist-search-results .block-title, .block-reorder .block-title, .widget .block-title, .block-wishlist .block-title {
  margin-bottom: 15px; }
  .abs-block-title > strong, .abs-account-blocks .block-title > strong, .account .column.main .block:not(.widget) .block-title > strong, .multicheckout .block-title > strong, .paypal-review .block .block-title > strong, [class^='sales-guest-'] .column.main .block:not(.widget) .block-title > strong, .sales-guest-view .column.main .block:not(.widget) .block-title > strong, .block-compare .block-title > strong, .block-wishlist-search-results .block-title > strong, .block-reorder .block-title > strong, .widget .block-title > strong, .block-wishlist .block-title > strong {
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: 300;
    line-height: 1.1;
    font-size: 18px;
    margin-top: 7px;
    margin-bottom: 7px; }

.abs-account-blocks .block-title > .action, .account .column.main .block:not(.widget) .block-title > .action, .multicheckout .block-title > .action, .paypal-review .block .block-title > .action, [class^='sales-guest-'] .column.main .block:not(.widget) .block-title > .action, .sales-guest-view .column.main .block:not(.widget) .block-title > .action {
  margin-left: 15px; }

.abs-account-blocks .box-title, .account .column.main .block:not(.widget) .box-title, .multicheckout .box-title, .paypal-review .block .box-title, [class^='sales-guest-'] .column.main .block:not(.widget) .box-title, .sales-guest-view .column.main .block:not(.widget) .box-title {
  display: inline-block;
  margin: 0 0 5px; }
  .abs-account-blocks .box-title > span, .account .column.main .block:not(.widget) .box-title > span, .multicheckout .box-title > span, .paypal-review .block .box-title > span, [class^='sales-guest-'] .column.main .block:not(.widget) .box-title > span, .sales-guest-view .column.main .block:not(.widget) .box-title > span {
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: 700;
    line-height: 1.1;
    font-size: 14px;
    margin-top: 7px;
    margin-bottom: 7px; }
  .abs-account-blocks .box-title > .action, .account .column.main .block:not(.widget) .box-title > .action, .multicheckout .box-title > .action, .paypal-review .block .box-title > .action, [class^='sales-guest-'] .column.main .block:not(.widget) .box-title > .action, .sales-guest-view .column.main .block:not(.widget) .box-title > .action {
    font-weight: 400;
    margin-left: 10px; }

.abs-account-blocks .block-content p:last-child, .account .column.main .block:not(.widget) .block-content p:last-child, .multicheckout .block-content p:last-child, .paypal-review .block .block-content p:last-child, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content p:last-child, .sales-guest-view .column.main .block:not(.widget) .block-content p:last-child {
  margin-bottom: 0; }

.abs-account-blocks .block-content .box, .account .column.main .block:not(.widget) .block-content .box, .multicheckout .block-content .box, .paypal-review .block .block-content .box, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box, .sales-guest-view .column.main .block:not(.widget) .block-content .box {
  margin-bottom: 20px; }

.abs-dropdown-simple, .wishlist-dropdown {
  position: relative; }
  .abs-dropdown-simple:before, .wishlist-dropdown:before, .abs-dropdown-simple:after, .wishlist-dropdown:after {
    content: '';
    display: table; }
  .abs-dropdown-simple:after, .wishlist-dropdown:after {
    clear: both; }
  .abs-dropdown-simple .action.toggle, .wishlist-dropdown .action.toggle {
    padding: inherit;
    cursor: pointer;
    display: inline-block;
    text-decoration: none; }
    .abs-dropdown-simple .action.toggle:before, .wishlist-dropdown .action.toggle:before {
      -webkit-font-smoothing: antialiased;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0 0 0 5px;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: middle; }
    .abs-dropdown-simple .action.toggle:hover:before, .wishlist-dropdown .action.toggle:hover:before {
      color: inherit; }
    .abs-dropdown-simple .action.toggle:active:before, .wishlist-dropdown .action.toggle:active:before {
      color: inherit; }
    .abs-dropdown-simple .action.toggle.active, .wishlist-dropdown .action.toggle.active {
      display: inline-block;
      text-decoration: none; }
      .abs-dropdown-simple .action.toggle.active:before, .wishlist-dropdown .action.toggle.active:before {
        -webkit-font-smoothing: antialiased;
        font-size: 22px;
        line-height: 22px;
        color: inherit;
        content: "";
        font-family: "icons-blank-theme";
        margin: 0 0 0 5px;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
        vertical-align: middle; }
      .abs-dropdown-simple .action.toggle.active:hover:before, .wishlist-dropdown .action.toggle.active:hover:before {
        color: inherit; }
      .abs-dropdown-simple .action.toggle.active:active:before, .wishlist-dropdown .action.toggle.active:active:before {
        color: inherit; }
  .abs-dropdown-simple ul.dropdown, .wishlist-dropdown ul.dropdown {
    background: #fff;
    border: 1px solid #bbb;
    z-index: 100;
    margin: 0;
    padding: 0;
    list-style: none none;
    box-sizing: border-box;
    position: absolute;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    height: inherit;
    margin-top: 4px;
    min-width: 200px;
    width: inherit;
    display: none; }
    .abs-dropdown-simple ul.dropdown li, .wishlist-dropdown ul.dropdown li {
      margin: 0;
      padding: 5px 5px 5px 23px; }
      .abs-dropdown-simple ul.dropdown li:hover, .wishlist-dropdown ul.dropdown li:hover {
        background: #e8e8e8;
        cursor: pointer; }
    .abs-dropdown-simple ul.dropdown:before, .wishlist-dropdown ul.dropdown:before, .abs-dropdown-simple ul.dropdown:after, .wishlist-dropdown ul.dropdown:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .abs-dropdown-simple ul.dropdown:before, .wishlist-dropdown ul.dropdown:before {
      border: 6px solid;
      border-color: transparent transparent #fff transparent;
      z-index: 99; }
    .abs-dropdown-simple ul.dropdown:after, .wishlist-dropdown ul.dropdown:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .abs-dropdown-simple ul.dropdown:before, .wishlist-dropdown ul.dropdown:before {
      left: 10px;
      top: -12px; }
    .abs-dropdown-simple ul.dropdown:after, .wishlist-dropdown ul.dropdown:after {
      left: 9px;
      top: -14px; }
  .abs-dropdown-simple.active, .active.wishlist-dropdown {
    overflow: visible; }
    .abs-dropdown-simple.active ul.dropdown, .active.wishlist-dropdown ul.dropdown {
      display: block; }

.abs-input-qty, .column .block-addbysku .qty .qty, .bundle-options-container .input-text.qty, .cart.table-wrapper .col.qty .input-text, .account .table-return-items .qty .input-text, .table.grouped .control .qty, .block-wishlist-info-items .input-text.qty, .multicheckout .table-wrapper .col .qty .input-text, .account .table-wrapper .data.table.wishlist .box-tocart .qty, .products-grid.wishlist .product-item .box-tocart input.qty, .sidebar .block-addbysku .fieldset .fields .field.qty .qty {
  text-align: center;
  width: 47px; }

.abs-margin-for-blocks-and-widgets, .block, .sidebar .widget.block:not(:last-child),
.sidebar .widget:not(:last-child), .block-banners,
.block-banners-inline {
  margin-bottom: 40px; }

.abs-remove-button-for-blocks, .sidebar .action.delete, .table-comparison .cell.remove .action.delete, .block-compare .action.delete, .filtered .item .action.remove {
  display: inline-block;
  text-decoration: none; }
  .abs-remove-button-for-blocks > span, .sidebar .action.delete > span, .table-comparison .cell.remove .action.delete > span, .block-compare .action.delete > span, .filtered .item .action.remove > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .abs-remove-button-for-blocks:before, .sidebar .action.delete:before, .table-comparison .cell.remove .action.delete:before, .block-compare .action.delete:before, .filtered .item .action.remove:before {
    -webkit-font-smoothing: antialiased;
    font-size: 26px;
    line-height: 15px;
    color: #303030;
    content: "";
    font-family: "icons-blank-theme";
    margin: inherit;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: middle; }
  .abs-remove-button-for-blocks:hover:before, .sidebar .action.delete:hover:before, .table-comparison .cell.remove .action.delete:hover:before, .block-compare .action.delete:hover:before, .filtered .item .action.remove:hover:before {
    color: #303030; }
  .abs-remove-button-for-blocks:active:before, .sidebar .action.delete:active:before, .table-comparison .cell.remove .action.delete:active:before, .block-compare .action.delete:active:before, .filtered .item .action.remove:active:before {
    color: #303030; }

.abs-product-link, .product-item-name, .product.name a {
  font-weight: 400; }
  .abs-product-link > a, .product-item-name > a, .product.name a > a {
    color: #1979c3;
    text-decoration: none; }
    .abs-product-link > a:visited, .product-item-name > a:visited, .product.name a > a:visited {
      color: #1979c3;
      text-decoration: none; }
    .abs-product-link > a:hover, .product-item-name > a:hover, .product.name a > a:hover {
      color: #006bb4;
      text-decoration: underline; }
    .abs-product-link > a:active, .product-item-name > a:active, .product.name a > a:active {
      color: #ff5501;
      text-decoration: underline; }

.abs-reset-left-margin, .form-new-agreement .actions-toolbar, .fieldset .review-legend.legend, .form.wishlist.items .actions-toolbar {
  margin-left: 0; }

@media only screen and (min-width: 768px) {
  .abs-reset-left-margin-desktop, .bundle-options-container .legend.title, .column.main .cart-summary .actions-toolbar, .form-new-agreement .fieldset .legend, .form-new-agreement .actions-toolbar, .column.main .paypal-review .actions-toolbar, .wishlist-index-index .main .form-wishlist-items .actions-toolbar {
    margin-left: 0; } }

@media only screen and (min-width: 640px) {
  .abs-reset-left-margin-desktop-s, .column:not(.sidebar-main) .multicheckout .actions-toolbar, .multicheckout .block-shipping .box-shipping-method .fieldset .legend {
    margin-left: 0; } }

.abs-action-remove, .abs-add-fields .action.remove, .form.send.friend .action.remove {
  left: 10px;
  margin-left: 70%;
  position: absolute;
  top: 31px;
  width: auto; }

@media only screen and (min-width: 768px) {
  .abs-action-remove-desktop, .abs-add-fields-desktop .fieldset .additional .action.remove, .form.send.friend .fieldset .additional .action.remove {
    margin-left: 75.8%;
    top: 6px; } }

.abs-add-fields .fieldset, .form.send.friend .fieldset {
  margin-bottom: 50px; }
  .abs-add-fields .fieldset .field:not(.choice) .control, .form.send.friend .fieldset .field:not(.choice) .control {
    width: 70%; }
  .abs-add-fields .fieldset .actions-toolbar:not(:first-child) > .secondary, .form.send.friend .fieldset .actions-toolbar:not(:first-child) > .secondary {
    float: left; }
    .abs-add-fields .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form.send.friend .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add {
      margin-top: 30px; }
  .abs-add-fields .fieldset .fields .actions-toolbar, .form.send.friend .fieldset .fields .actions-toolbar {
    margin: 0; }

.abs-add-fields .message.notice, .form.send.friend .message.notice {
  margin: 30px 0 0; }

.abs-add-fields .additional, .form.send.friend .additional {
  margin-top: 55px;
  position: relative; }

@media only screen and (min-width: 768px) {
  .abs-add-fields-desktop .fieldset .field:not(.choice) .control, .form.send.friend .fieldset .field:not(.choice) .control {
    width: 50%; } }

@media only screen and (min-width: 768px) {
  .abs-margin-for-forms-desktop, .fieldset .legend, .column:not(.sidebar-additional) form .actions-toolbar,
  .column:not(.sidebar-main) form .actions-toolbar, .login-container .fieldset:after {
    margin-left: 25.8%; } }

@media only screen and (min-width: 640px) {
  .abs-margin-for-forms-desktop-s {
    margin-left: 25.8%; } }

.abs-hidden {
  height: 0;
  visibility: hidden; }

.abs-visually-hidden, .fieldset > .field.no-label > .label,
.fieldset > .fields > .field.no-label > .label, .product-item-actions .actions-secondary > .action span, .table-comparison .cell.label.remove span,
.table-comparison .cell.label.product span, .cart.table-wrapper .col.qty .label, .minicart-wrapper .action.showcart .text, .minicart-wrapper .action.showcart .counter-label, .checkout-index-index .page-title-wrapper,
.checkout-onepage-success .page-title-wrapper, .checkout-payment-method .field-select-billing > .label, .checkout-payment-method .payments .legend, .checkout-payment-method .ccard .legend, .account .column.main .limiter > .label, .field.street .field.additional .label, .account-nav .title, .page-product-downloadable .product-options-wrapper .fieldset .legend.links-title, .filter-options-content .filter-count-label, .block-wishlist-search-form .form-wishlist-search .fieldset > .legend, .page-multiple-wishlist .page-title-wrapper .page-title, .multicheckout .table-wrapper .col .label, .multicheckout .block .methods-shipping .item-content .fieldset > .legend, .block-reorder .product-item .label, .opc-wrapper .form-discount .field .label, .action.skip:not(:focus), .footer.content .block.newsletter .label, .page-header .switcher .label,
.page-footer .switcher .label, .products-grid.wishlist .product-item .comment-box .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

@media only screen and (max-width: 640px) {
  .abs-visually-hidden-mobile {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

@media only screen and (max-width: 768px) {
  .abs-visually-hidden-mobile-m {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

@media only screen and (min-width: 640px) {
  .abs-visually-hidden-desktop-s {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

@media only screen and (min-width: 768px) {
  .abs-visually-hidden-desktop, .modes-label, .dashboard-welcome-toggler {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

.abs-visually-hidden-reset, .shipping-policy-block.field-tooltip .field-tooltip-action span {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.abs-add-clearfix:before, .abs-add-fields .fieldset .actions-toolbar:not(:first-child):before, .form.send.friend .fieldset .actions-toolbar:not(:first-child):before, .payment-method-braintree .hosted-date-wrap:before, .toolbar:before, .toolbar-products:before, .sidebar .product-items-images:before, .cart.table-wrapper .actions-toolbar:before, .cart.table-wrapper .item-options:before, .minicart-items .product-item > .product:before, .checkout-container:before, .opc-estimated-wrapper:before, .opc-wrapper .field.addresses:before, .page-product-downloadable .product-add-form:before, .map-popup .map-info-price:before, .multicheckout .item-options:before, .multicheckout .block:before, .multicheckout .block-content:before, .customer-review .product-details:before, .header.content:before, .abs-add-clearfix:after, .abs-add-fields .fieldset .actions-toolbar:not(:first-child):after, .form.send.friend .fieldset .actions-toolbar:not(:first-child):after, .payment-method-braintree .hosted-date-wrap:after, .toolbar:after, .toolbar-products:after, .sidebar .product-items-images:after, .cart.table-wrapper .actions-toolbar:after, .cart.table-wrapper .item-options:after, .minicart-items .product-item > .product:after, .checkout-container:after, .opc-estimated-wrapper:after, .opc-wrapper .field.addresses:after, .page-product-downloadable .product-add-form:after, .map-popup .map-info-price:after, .multicheckout .item-options:after, .multicheckout .block:after, .multicheckout .block-content:after, .customer-review .product-details:after, .header.content:after {
  content: '';
  display: table; }

.abs-add-clearfix:after, .abs-add-fields .fieldset .actions-toolbar:not(:first-child):after, .form.send.friend .fieldset .actions-toolbar:not(:first-child):after, .payment-method-braintree .hosted-date-wrap:after, .toolbar:after, .toolbar-products:after, .sidebar .product-items-images:after, .cart.table-wrapper .actions-toolbar:after, .cart.table-wrapper .item-options:after, .minicart-items .product-item > .product:after, .checkout-container:after, .opc-estimated-wrapper:after, .opc-wrapper .field.addresses:after, .page-product-downloadable .product-add-form:after, .map-popup .map-info-price:after, .multicheckout .item-options:after, .multicheckout .block:after, .multicheckout .block-content:after, .customer-review .product-details:after, .header.content:after {
  clear: both; }

@media only screen and (min-width: 768px) {
  .abs-add-clearfix-desktop:before, .abs-pager-toolbar:before, .account .toolbar:before, .toolbar-wishlist-results:before, .column .block-addbysku .block-content:before, .block-cart-failed .block-content:before, .cart-container:before, .login-container:before, .account .column.main .block:not(.widget) .block-content:before, .block-addresses-list .items.addresses:before, .block-wishlist-management:before, .paypal-review .block-content:before, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content:before,
  .sales-guest-view .column.main .block:not(.widget) .block-content:before, .header.content:before, .page-header .header.panel:before, .abs-add-clearfix-desktop:after, .abs-pager-toolbar:after, .account .toolbar:after, .toolbar-wishlist-results:after, .column .block-addbysku .block-content:after, .block-cart-failed .block-content:after, .cart-container:after, .login-container:after, .account .column.main .block:not(.widget) .block-content:after, .block-addresses-list .items.addresses:after, .block-wishlist-management:after, .paypal-review .block-content:after, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content:after,
  .sales-guest-view .column.main .block:not(.widget) .block-content:after, .header.content:after, .page-header .header.panel:after {
    content: '';
    display: table; }
  .abs-add-clearfix-desktop:after, .abs-pager-toolbar:after, .account .toolbar:after, .toolbar-wishlist-results:after, .column .block-addbysku .block-content:after, .block-cart-failed .block-content:after, .cart-container:after, .login-container:after, .account .column.main .block:not(.widget) .block-content:after, .block-addresses-list .items.addresses:after, .block-wishlist-management:after, .paypal-review .block-content:after, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content:after,
  .sales-guest-view .column.main .block:not(.widget) .block-content:after, .header.content:after, .page-header .header.panel:after {
    clear: both; } }

@media only screen and (min-width: 640px) {
  .abs-add-clearfix-desktop-s:before, .multicheckout .block-billing:before, .abs-add-clearfix-desktop-s:after, .multicheckout .block-billing:after {
    content: '';
    display: table; }
  .abs-add-clearfix-desktop-s:after, .multicheckout .block-billing:after {
    clear: both; } }

@media only screen and (max-width: 640px) {
  .abs-add-clearfix-mobile:before, .abs-checkout-order-review tbody tr:before, .multicheckout.order-review .data.table tbody tr:before, .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:before, .account .toolbar:before, .abs-add-clearfix-mobile:after, .abs-checkout-order-review tbody tr:after, .multicheckout.order-review .data.table tbody tr:after, .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:after, .account .toolbar:after {
    content: '';
    display: table; }
  .abs-add-clearfix-mobile:after, .abs-checkout-order-review tbody tr:after, .multicheckout.order-review .data.table tbody tr:after, .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:after, .account .toolbar:after {
    clear: both; } }

@media only screen and (max-width: 768px) {
  .abs-add-clearfix-mobile-m:before, .abs-add-clearfix-mobile-m:after {
    content: '';
    display: table; }
  .abs-add-clearfix-mobile-m:after {
    clear: both; } }

.abs-add-box-sizing, .abs-field-date .control, .field.date .control, .abs-field-tooltip, .field .control._with-tooltip, .column .block-addbysku .sku, .bundle-options-container .block-bundle-summary, .product-item, .sidebar .product-items-images .product-item, .search-autocomplete, .cart-summary, .opc-wrapper .shipping-address-item, .shipping-policy-block.field-tooltip .field-tooltip-content, .opc-block-summary, .sidebar .block-addbysku .fieldset .fields .field.sku, .block-event .slider-panel .slider .item .action.show {
  box-sizing: border-box; }

@media only screen and (min-width: 768px) {
  .abs-add-box-sizing-desktop {
    box-sizing: border-box; } }

@media only screen and (min-width: 640px) {
  .abs-add-box-sizing-desktop-s, .multicheckout .block-shipping .box, .multicheckout .block-billing .box-billing-address, .multicheckout .block-billing .box-billing-method {
    box-sizing: border-box; } }

@media only screen and (min-width: 768px) {
  .abs-add-box-sizing-desktop-m, .opc-wrapper {
    box-sizing: border-box; } }

@media only screen and (min-width: 768px) {
  .abs-revert-field-type-desktop .fieldset > .field, .product-add-form .fieldset > .field,
  .abs-revert-field-type-desktop .fieldset .fields > .field,
  .product-add-form .fieldset .fields > .field {
    margin: 0; }
    .abs-revert-field-type-desktop .fieldset > .field:not(.choice) > .label, .product-add-form .fieldset > .field:not(.choice) > .label,
    .abs-revert-field-type-desktop .fieldset .fields > .field:not(.choice) > .label,
    .product-add-form .fieldset .fields > .field:not(.choice) > .label {
      box-sizing: content-box;
      float: none;
      width: auto;
      text-align: left;
      padding: 0; }
    .abs-revert-field-type-desktop .fieldset > .field:not(.choice) > .control, .product-add-form .fieldset > .field:not(.choice) > .control,
    .abs-revert-field-type-desktop .fieldset .fields > .field:not(.choice) > .control,
    .product-add-form .fieldset .fields > .field:not(.choice) > .control {
      float: none;
      width: auto; }
    .abs-revert-field-type-desktop .fieldset > .field > .label, .product-add-form .fieldset > .field > .label,
    .abs-revert-field-type-desktop .fieldset .fields > .field > .label,
    .product-add-form .fieldset .fields > .field > .label {
      margin: 0 0 5px;
      padding: inherit;
      text-align: inherit;
      display: inline-block; }
    .abs-revert-field-type-desktop .fieldset > .field.choice:before, .product-add-form .fieldset > .field.choice:before, .abs-revert-field-type-desktop .fieldset > .field.no-label:before, .product-add-form .fieldset > .field.no-label:before,
    .abs-revert-field-type-desktop .fieldset .fields > .field.choice:before,
    .product-add-form .fieldset .fields > .field.choice:before,
    .abs-revert-field-type-desktop .fieldset .fields > .field.no-label:before,
    .product-add-form .fieldset .fields > .field.no-label:before {
      display: none; }
    .abs-revert-field-type-desktop .fieldset > .field:not(:first-child):last-of-type, .product-add-form .fieldset > .field:not(:first-child):last-of-type,
    .abs-revert-field-type-desktop .fieldset .fields > .field:not(:first-child):last-of-type,
    .product-add-form .fieldset .fields > .field:not(:first-child):last-of-type {
      margin-bottom: 0; } }

.abs-navigation-icon {
  display: block;
  text-decoration: none; }
  .abs-navigation-icon:after {
    -webkit-font-smoothing: antialiased;
    font-size: 34px;
    line-height: 1.2;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: inherit;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: middle; }
  .abs-navigation-icon:hover:after {
    color: inherit; }
  .abs-navigation-icon:active:after {
    color: inherit; }
  .abs-navigation-icon:after {
    position: absolute;
    right: 5px;
    top: 0; }

.abs-split-button {
  display: inline-block;
  position: relative;
  vertical-align: middle; }
  .abs-split-button:before, .abs-split-button:after {
    content: '';
    display: table; }
  .abs-split-button:after {
    clear: both; }
  .abs-split-button .action.split {
    float: left;
    margin: 0; }
  .abs-split-button .action.toggle {
    float: right;
    margin: 0; }
  .abs-split-button button.action.split, .abs-split-button .cart.table-wrapper .actions-toolbar > .action.split, .cart.table-wrapper .abs-split-button .actions-toolbar > .action.split {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .abs-split-button button + .action.toggle, .abs-split-button .cart.table-wrapper .actions-toolbar > .action + .action.toggle, .cart.table-wrapper .abs-split-button .actions-toolbar > .action + .action.toggle {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0; }
  .abs-split-button .action.split {
    padding: inherit; }
  .abs-split-button .action.toggle {
    padding: inherit;
    display: inline-block;
    text-decoration: none; }
    .abs-split-button .action.toggle > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .abs-split-button .action.toggle:after {
      -webkit-font-smoothing: antialiased;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: inherit;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: top; }
    .abs-split-button .action.toggle:hover:after {
      color: inherit; }
    .abs-split-button .action.toggle:active:after {
      color: inherit; }
    .abs-split-button .action.toggle.active {
      display: inline-block;
      text-decoration: none; }
      .abs-split-button .action.toggle.active > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .abs-split-button .action.toggle.active:after {
        -webkit-font-smoothing: antialiased;
        font-size: 22px;
        line-height: 22px;
        color: inherit;
        content: "";
        font-family: "icons-blank-theme";
        margin: inherit;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
        vertical-align: top; }
      .abs-split-button .action.toggle.active:hover:after {
        color: inherit; }
      .abs-split-button .action.toggle.active:active:after {
        color: inherit; }
  .abs-split-button .items {
    background: #fff;
    border: 1px solid #bbb;
    z-index: 100;
    margin: 0;
    padding: 0;
    list-style: none none;
    box-sizing: border-box;
    position: absolute;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    height: inherit;
    margin-top: 4px;
    min-width: 100%;
    width: inherit;
    display: none; }
    .abs-split-button .items li {
      margin: 0;
      padding: 3px 5px; }
      .abs-split-button .items li:hover {
        background: #e8e8e8;
        cursor: pointer; }
    .abs-split-button .items:before, .abs-split-button .items:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .abs-split-button .items:before {
      border: 6px solid;
      border-color: transparent transparent #fff transparent;
      z-index: 99; }
    .abs-split-button .items:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .abs-split-button .items:before {
      left: 10px;
      top: -12px; }
    .abs-split-button .items:after {
      left: 9px;
      top: -14px; }
  .abs-split-button.active {
    overflow: visible; }
    .abs-split-button.active .items {
      display: block; }

.abs-action-addto-product, .bundle-options-container .block-bundle-summary .product-addto-links > .action, .product-info-main .action.tocompare,
.product-options-bottom .action.tocompare, .product-info-main .action.towishlist,
.product-options-bottom .action.towishlist,
.block-bundle-summary .action.towishlist {
  line-height: 12px;
  padding: 5px 8px;
  font-size: 11px; }

.abs-actions-addto-gridlist, .product-item-actions .actions-secondary > .action, .table-comparison .wishlist.split.button > .action.split,
.product-items .wishlist.split.button > .action.split, .products.list.items .action.towishlist,
.table-comparison .action.towishlist {
  display: inline-block;
  text-decoration: none; }
  .abs-actions-addto-gridlist > span, .product-item-actions .actions-secondary > .action > span, .table-comparison .wishlist.split.button > .action.split > span, .product-items .wishlist.split.button > .action.split > span, .products.list.items .action.towishlist > span, .table-comparison .action.towishlist > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .abs-actions-addto-gridlist:before, .product-item-actions .actions-secondary > .action:before, .table-comparison .wishlist.split.button > .action.split:before,
  .product-items .wishlist.split.button > .action.split:before, .products.list.items .action.towishlist:before,
  .table-comparison .action.towishlist:before {
    -webkit-font-smoothing: antialiased;
    font-size: 29px;
    line-height: 24px;
    color: #20272B;
    content: inherit;
    font-family: "icons-blank-theme";
    margin: inherit;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: middle; }
  .abs-actions-addto-gridlist:hover:before, .product-item-actions .actions-secondary > .action:hover:before, .table-comparison .wishlist.split.button > .action.split:hover:before,
  .product-items .wishlist.split.button > .action.split:hover:before, .products.list.items .action.towishlist:hover:before,
  .table-comparison .action.towishlist:hover:before {
    color: #006bb4; }
  .abs-actions-addto-gridlist:active:before, .product-item-actions .actions-secondary > .action:active:before, .table-comparison .wishlist.split.button > .action.split:active:before,
  .product-items .wishlist.split.button > .action.split:active:before, .products.list.items .action.towishlist:active:before,
  .table-comparison .action.towishlist:active:before {
    color: inherit; }

.abs-button-l, .bundle-actions .action.primary, .bundle-options-container .block-bundle-summary .box-tocart .action.primary, .product-info-main .box-tocart .action.tocart,
.product-options-bottom .box-tocart .action.tocart, .cart-container .checkout-methods-items .action.primary, .block-minicart .block-content > .actions > .primary .action.primary, .methods-shipping .actions-toolbar .action.primary, .block-authentication .action.action-register,
.block-authentication .action.action-login, .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary, .form-address-edit .actions-toolbar .action.primary, .multicheckout .action.primary {
  line-height: 22px;
  padding: 14px 17px;
  font-size: 18px;
  font-weight: 600 !important; }

.abs-action-button-as-link, .abs-action-remove, .abs-add-fields .action.remove, .form.send.friend .action.remove, .cart.table-wrapper .action.help.map, .opc-wrapper .edit-address-link, .opc-block-shipping-information .shipping-information-title .action-edit, .action-auth-toggle, .checkout-payment-method .payment-method-billing-address .action-cancel, .checkout-payment-method .payment-method-billing-address .billing-address-details .action-edit-address, .checkout-agreements-block .action-show, .table-comparison .wishlist.split.button > .action,
.product-items .wishlist.split.button > .action, .paypal-button-widget .paypal-button {
  display: inline;
  line-height: 1.42857;
  padding: 0;
  background: none;
  border: 0;
  color: #1979c3;
  text-decoration: none;
  border-radius: 0;
  font-size: inherit;
  font-weight: 400; }
  .abs-action-button-as-link:visited, .abs-action-remove:visited, .abs-add-fields .action.remove:visited, .form.send.friend .action.remove:visited, .cart.table-wrapper .action.help.map:visited, .opc-wrapper .edit-address-link:visited, .opc-block-shipping-information .shipping-information-title .action-edit:visited, .action-auth-toggle:visited, .checkout-payment-method .payment-method-billing-address .action-cancel:visited, .checkout-payment-method .payment-method-billing-address .billing-address-details .action-edit-address:visited, .checkout-agreements-block .action-show:visited, .table-comparison .wishlist.split.button > .action:visited,
  .product-items .wishlist.split.button > .action:visited, .paypal-button-widget .paypal-button:visited {
    color: #1979c3;
    text-decoration: none; }
  .abs-action-button-as-link:hover, .abs-action-remove:hover, .abs-add-fields .action.remove:hover, .form.send.friend .action.remove:hover, .cart.table-wrapper .action.help.map:hover, .opc-wrapper .edit-address-link:hover, .opc-block-shipping-information .shipping-information-title .action-edit:hover, .action-auth-toggle:hover, .checkout-payment-method .payment-method-billing-address .action-cancel:hover, .checkout-payment-method .payment-method-billing-address .billing-address-details .action-edit-address:hover, .checkout-agreements-block .action-show:hover, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:hover, .paypal-button-widget .paypal-button:hover {
    color: #006bb4;
    text-decoration: underline; }
  .abs-action-button-as-link:active, .abs-action-remove:active, .abs-add-fields .action.remove:active, .form.send.friend .action.remove:active, .cart.table-wrapper .action.help.map:active, .opc-wrapper .edit-address-link:active, .opc-block-shipping-information .shipping-information-title .action-edit:active, .action-auth-toggle:active, .checkout-payment-method .payment-method-billing-address .action-cancel:active, .checkout-payment-method .payment-method-billing-address .billing-address-details .action-edit-address:active, .checkout-agreements-block .action-show:active, .table-comparison .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:active, .paypal-button-widget .paypal-button:active {
    color: #ff5501;
    text-decoration: underline; }
  .abs-action-button-as-link:hover, .abs-action-remove:hover, .abs-add-fields .action.remove:hover, .form.send.friend .action.remove:hover, .cart.table-wrapper .action.help.map:hover, .opc-wrapper .edit-address-link:hover, .opc-block-shipping-information .shipping-information-title .action-edit:hover, .action-auth-toggle:hover, .checkout-payment-method .payment-method-billing-address .action-cancel:hover, .checkout-payment-method .payment-method-billing-address .billing-address-details .action-edit-address:hover, .checkout-agreements-block .action-show:hover, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:hover, .paypal-button-widget .paypal-button:hover {
    color: #006bb4; }
  .abs-action-button-as-link:hover, .abs-action-remove:hover, .abs-add-fields .action.remove:hover, .form.send.friend .action.remove:hover, .cart.table-wrapper .action.help.map:hover, .opc-wrapper .edit-address-link:hover, .opc-block-shipping-information .shipping-information-title .action-edit:hover, .action-auth-toggle:hover, .checkout-payment-method .payment-method-billing-address .action-cancel:hover, .checkout-payment-method .payment-method-billing-address .billing-address-details .action-edit-address:hover, .checkout-agreements-block .action-show:hover, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:hover, .paypal-button-widget .paypal-button:hover, .abs-action-button-as-link:active, .abs-action-remove:active, .abs-add-fields .action.remove:active, .form.send.friend .action.remove:active, .cart.table-wrapper .action.help.map:active, .opc-wrapper .edit-address-link:active, .opc-block-shipping-information .shipping-information-title .action-edit:active, .action-auth-toggle:active, .checkout-payment-method .payment-method-billing-address .action-cancel:active, .checkout-payment-method .payment-method-billing-address .billing-address-details .action-edit-address:active, .checkout-agreements-block .action-show:active, .table-comparison .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:active, .paypal-button-widget .paypal-button:active, .abs-action-button-as-link:focus, .abs-action-remove:focus, .abs-add-fields .action.remove:focus, .form.send.friend .action.remove:focus, .cart.table-wrapper .action.help.map:focus, .opc-wrapper .edit-address-link:focus, .opc-block-shipping-information .shipping-information-title .action-edit:focus, .action-auth-toggle:focus, .checkout-payment-method .payment-method-billing-address .action-cancel:focus, .checkout-payment-method .payment-method-billing-address .billing-address-details .action-edit-address:focus, .checkout-agreements-block .action-show:focus, .table-comparison .wishlist.split.button > .action:focus,
  .product-items .wishlist.split.button > .action:focus, .paypal-button-widget .paypal-button:focus {
    background: none;
    border: 0; }
  .abs-action-button-as-link.disabled, .disabled.abs-action-remove, .abs-add-fields .disabled.action.remove, .form.send.friend .disabled.action.remove, .cart.table-wrapper .disabled.action.help.map, .opc-wrapper .disabled.edit-address-link, .opc-block-shipping-information .shipping-information-title .disabled.action-edit, .disabled.action-auth-toggle, .checkout-payment-method .payment-method-billing-address .disabled.action-cancel, .checkout-payment-method .payment-method-billing-address .billing-address-details .disabled.action-edit-address, .checkout-agreements-block .disabled.action-show, .table-comparison .wishlist.split.button > .disabled.action,
  .product-items .wishlist.split.button > .disabled.action, .paypal-button-widget .disabled.paypal-button, .abs-action-button-as-link[disabled], .abs-action-remove[disabled], .abs-add-fields .action.remove[disabled], .form.send.friend .action.remove[disabled], .cart.table-wrapper .action.help.map[disabled], .opc-wrapper .edit-address-link[disabled], .opc-block-shipping-information .shipping-information-title .action-edit[disabled], .action-auth-toggle[disabled], .checkout-payment-method .payment-method-billing-address .action-cancel[disabled], .checkout-payment-method .payment-method-billing-address .billing-address-details .action-edit-address[disabled], .checkout-agreements-block .action-show[disabled], .table-comparison .wishlist.split.button > .action[disabled],
  .product-items .wishlist.split.button > .action[disabled], .paypal-button-widget .paypal-button[disabled],
  fieldset[disabled] .abs-action-button-as-link,
  fieldset[disabled] .abs-action-remove,
  fieldset[disabled] .abs-add-fields .action.remove,
  .abs-add-fields fieldset[disabled] .action.remove,
  fieldset[disabled] .form.send.friend .action.remove,
  .form.send.friend fieldset[disabled] .action.remove,
  fieldset[disabled] .cart.table-wrapper .action.help.map,
  .cart.table-wrapper fieldset[disabled] .action.help.map,
  fieldset[disabled] .opc-wrapper .edit-address-link,
  .opc-wrapper fieldset[disabled] .edit-address-link,
  fieldset[disabled] .opc-block-shipping-information .shipping-information-title .action-edit,
  .opc-block-shipping-information .shipping-information-title fieldset[disabled] .action-edit,
  fieldset[disabled] .action-auth-toggle,
  fieldset[disabled] .checkout-payment-method .payment-method-billing-address .action-cancel,
  .checkout-payment-method .payment-method-billing-address fieldset[disabled] .action-cancel,
  fieldset[disabled] .checkout-payment-method .payment-method-billing-address .billing-address-details .action-edit-address,
  .checkout-payment-method .payment-method-billing-address .billing-address-details fieldset[disabled] .action-edit-address,
  fieldset[disabled] .checkout-agreements-block .action-show,
  .checkout-agreements-block fieldset[disabled] .action-show,
  fieldset[disabled] .table-comparison .wishlist.split.button > .action,
  .table-comparison fieldset[disabled] .wishlist.split.button > .action,
  fieldset[disabled] .product-items .wishlist.split.button > .action,
  .product-items fieldset[disabled] .wishlist.split.button > .action,
  fieldset[disabled] .paypal-button-widget .paypal-button,
  .paypal-button-widget fieldset[disabled] .paypal-button {
    color: #1979c3;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }
  .abs-action-button-as-link:active, .abs-action-remove:active, .abs-add-fields .action.remove:active, .form.send.friend .action.remove:active, .cart.table-wrapper .action.help.map:active, .opc-wrapper .edit-address-link:active, .opc-block-shipping-information .shipping-information-title .action-edit:active, .action-auth-toggle:active, .checkout-payment-method .payment-method-billing-address .action-cancel:active, .checkout-payment-method .payment-method-billing-address .billing-address-details .action-edit-address:active, .checkout-agreements-block .action-show:active, .table-comparison .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:active, .paypal-button-widget .paypal-button:active, .abs-action-button-as-link:not(:focus), .abs-action-remove:not(:focus), .abs-add-fields .action.remove:not(:focus), .form.send.friend .action.remove:not(:focus), .cart.table-wrapper .action.help.map:not(:focus), .opc-wrapper .edit-address-link:not(:focus), .opc-block-shipping-information .shipping-information-title .action-edit:not(:focus), .action-auth-toggle:not(:focus), .checkout-payment-method .payment-method-billing-address .action-cancel:not(:focus), .checkout-payment-method .payment-method-billing-address .billing-address-details .action-edit-address:not(:focus), .checkout-agreements-block .action-show:not(:focus), .table-comparison .wishlist.split.button > .action:not(:focus),
  .product-items .wishlist.split.button > .action:not(:focus), .paypal-button-widget .paypal-button:not(:focus) {
    box-shadow: none; }

.abs-revert-secondary-color, .block-compare .action.compare, .cart-summary .actions-toolbar > .primary button, .cart-summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .cart-summary .actions-toolbar > .primary .actions-toolbar > .action, .paypal-review .block .actions-toolbar .action.primary {
  background: #f2f2f2;
  border: 1px solid #cdcdcd;
  color: #2FDF66; }
  .abs-revert-secondary-color:focus, .block-compare .action.compare:focus, .cart-summary .actions-toolbar > .primary button:focus, .cart-summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action:focus, .cart.table-wrapper .cart-summary .actions-toolbar > .primary .actions-toolbar > .action:focus, .paypal-review .block .actions-toolbar .action.primary:focus, .abs-revert-secondary-color:active, .block-compare .action.compare:active, .cart-summary .actions-toolbar > .primary button:active, .cart-summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action:active, .cart.table-wrapper .cart-summary .actions-toolbar > .primary .actions-toolbar > .action:active, .paypal-review .block .actions-toolbar .action.primary:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #2FDF66; }
  .abs-revert-secondary-color:hover, .block-compare .action.compare:hover, .cart-summary .actions-toolbar > .primary button:hover, .cart-summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .cart-summary .actions-toolbar > .primary .actions-toolbar > .action:hover, .paypal-review .block .actions-toolbar .action.primary:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #555; }

.abs-revert-secondary-size {
  font-size: 14px;
  line-height: 1.42857;
  padding: 7px 15px; }

.abs-box-tocart, .bundle-actions {
  margin: 10px 0; }

.abs-adjustment-incl-excl-tax .price-including-tax, .bundle-options-container .price-notice .price-including-tax, .product-options-wrapper .field .price-notice .price-including-tax, .cart-summary .price-including-tax,
.abs-adjustment-incl-excl-tax .price-excluding-tax,
.bundle-options-container .price-notice .price-excluding-tax,
.product-options-wrapper .field .price-notice .price-excluding-tax,
.cart-summary .price-excluding-tax,
.abs-adjustment-incl-excl-tax .weee,
.bundle-options-container .price-notice .weee,
.product-options-wrapper .field .price-notice .weee,
.cart-summary .weee {
  font-size: 14px;
  display: inline-block;
  white-space: nowrap; }

.abs-adjustment-incl-excl-tax .price-including-tax + .price-excluding-tax, .bundle-options-container .price-notice .price-including-tax + .price-excluding-tax, .product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax, .cart-summary .price-including-tax + .price-excluding-tax {
  display: inline-block;
  font-size: 11px; }
  .abs-adjustment-incl-excl-tax .price-including-tax + .price-excluding-tax:before, .bundle-options-container .price-notice .price-including-tax + .price-excluding-tax:before, .product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax:before, .cart-summary .price-including-tax + .price-excluding-tax:before {
    content: "(" attr(data-label) ": "; }
  .abs-adjustment-incl-excl-tax .price-including-tax + .price-excluding-tax:after, .bundle-options-container .price-notice .price-including-tax + .price-excluding-tax:after, .product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax:after, .cart-summary .price-including-tax + .price-excluding-tax:after {
    content: ')'; }

.abs-tax-total, .col.price .price-including-tax .cart-tax-total,
.col.price .price-excluding-tax .cart-tax-total {
  cursor: pointer;
  padding-right: 12px;
  position: relative;
  display: inline-block;
  text-decoration: none; }
  .abs-tax-total:after, .col.price .price-including-tax .cart-tax-total:after,
  .col.price .price-excluding-tax .cart-tax-total:after {
    -webkit-font-smoothing: antialiased;
    font-size: 26px;
    line-height: 10px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 3px 0 0 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: middle; }
  .abs-tax-total:hover:after, .col.price .price-including-tax .cart-tax-total:hover:after,
  .col.price .price-excluding-tax .cart-tax-total:hover:after {
    color: inherit; }
  .abs-tax-total:active:after, .col.price .price-including-tax .cart-tax-total:active:after,
  .col.price .price-excluding-tax .cart-tax-total:active:after {
    color: inherit; }
  .abs-tax-total:after, .col.price .price-including-tax .cart-tax-total:after,
  .col.price .price-excluding-tax .cart-tax-total:after {
    position: absolute;
    right: -10px;
    top: 3px; }
  .abs-tax-total-expanded:after, .col.price .price-including-tax .cart-tax-total-expanded:after,
  .col.price .price-excluding-tax .cart-tax-total-expanded:after {
    content: ""; }

.abs-tax-total-expanded:after, .col.price .price-including-tax .cart-tax-total-expanded:after,
.col.price .price-excluding-tax .cart-tax-total-expanded:after {
  content: ""; }

.abs-methods-shipping-title {
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 15px; }

.abs-checkout-cart-price {
  display: auto; }

.abs-checkout-product-name {
  display: auto; }

@media only screen and (max-width: 640px) {
  .abs-checkout-order-review tbody tr:not(:last-child), .multicheckout.order-review .data.table tbody tr:not(:last-child) {
    border-bottom: 1px solid #d1d1d1; }
  .abs-checkout-order-review tbody tr .col.item:before, .multicheckout.order-review .data.table tbody tr .col.item:before {
    display: none; }
  .abs-checkout-order-review tbody tr .col.qty, .multicheckout.order-review .data.table tbody tr .col.qty, .abs-checkout-order-review tbody tr .col.price, .multicheckout.order-review .data.table tbody tr .col.price, .abs-checkout-order-review tbody tr .col.subtotal, .multicheckout.order-review .data.table tbody tr .col.subtotal {
    box-sizing: border-box;
    float: left;
    text-align: center;
    white-space: nowrap;
    width: 33%; }
    .abs-checkout-order-review tbody tr .col.qty:before, .multicheckout.order-review .data.table tbody tr .col.qty:before, .abs-checkout-order-review tbody tr .col.price:before, .multicheckout.order-review .data.table tbody tr .col.price:before, .abs-checkout-order-review tbody tr .col.subtotal:before, .multicheckout.order-review .data.table tbody tr .col.subtotal:before {
      content: attr(data-th) ":";
      display: block;
      font-weight: 700;
      padding-bottom: 10px; }
  .abs-checkout-order-review tbody tr .product-item-name, .multicheckout.order-review .data.table tbody tr .product-item-name {
    margin: 0; } }

.abs-colon:after, .block-minicart .subtotal .label:after, .minicart-items .details-qty .label:after,
.minicart-items .price-minicart .label:after, .block-wishlist-management .wishlist-select .wishlist-name-label:after {
  content: ': '; }

.abs-icon-add, .abs-dropdown-items-new .action.new, .wishlist.split.button .action.new, .wishlist-dropdown .action.new {
  display: inline-block;
  text-decoration: none; }
  .abs-icon-add:before, .abs-dropdown-items-new .action.new:before, .wishlist.split.button .action.new:before, .wishlist-dropdown .action.new:before {
    -webkit-font-smoothing: antialiased;
    font-size: 10px;
    line-height: 10px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: inherit;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: middle; }
  .abs-icon-add:hover:before, .abs-dropdown-items-new .action.new:hover:before, .wishlist.split.button .action.new:hover:before, .wishlist-dropdown .action.new:hover:before {
    color: inherit; }
  .abs-icon-add:active:before, .abs-dropdown-items-new .action.new:active:before, .wishlist.split.button .action.new:active:before, .wishlist-dropdown .action.new:active:before {
    color: inherit; }

@media only screen and (max-width: 768px) {
  .abs-icon-add-mobile, .block-wishlist-management .wishlist-add.item .add {
    display: block;
    text-decoration: none; }
    .abs-icon-add-mobile:before, .block-wishlist-management .wishlist-add.item .add:before {
      -webkit-font-smoothing: antialiased;
      font-size: 10px;
      line-height: 10px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0 5px 0 0;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: middle; }
    .abs-icon-add-mobile:hover:before, .block-wishlist-management .wishlist-add.item .add:hover:before {
      color: inherit; }
    .abs-icon-add-mobile:active:before, .block-wishlist-management .wishlist-add.item .add:active:before {
      color: inherit; } }

.abs-dropdown-items-new .items .item:last-child:hover, .wishlist.split.button .items .item:last-child:hover, .wishlist-dropdown .items .item:last-child:hover {
  background: #e8e8e8; }

.abs-dropdown-items-new .action.new:before, .wishlist.split.button .action.new:before, .wishlist-dropdown .action.new:before {
  margin-left: -17px;
  margin-right: 5px; }

@media only screen and (max-width: 768px) {
  .abs-toggling-title-mobile, .block-wishlist-management .wishlist-select .wishlist-name {
    border-bottom: 1px solid #d1d1d1;
    border-top: 1px solid #d1d1d1;
    cursor: pointer;
    margin-bottom: 0;
    padding: 10px 40px 10px 15px;
    position: relative;
    display: block;
    text-decoration: none; }
    .abs-toggling-title-mobile:after, .block-wishlist-management .wishlist-select .wishlist-name:after {
      -webkit-font-smoothing: antialiased;
      font-size: 28px;
      line-height: inherit;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: inherit;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: middle; }
    .abs-toggling-title-mobile:hover:after, .block-wishlist-management .wishlist-select .wishlist-name:hover:after {
      color: inherit; }
    .abs-toggling-title-mobile:active:after, .block-wishlist-management .wishlist-select .wishlist-name:active:after {
      color: inherit; }
    .abs-toggling-title-mobile:after, .block-wishlist-management .wishlist-select .wishlist-name:after {
      position: absolute;
      right: 10px;
      top: 0; }
    .abs-toggling-title-mobile.active:after, .block-wishlist-management .wishlist-select .active.wishlist-name:after {
      content: ""; } }

.abs-no-display, .abs-sidebar-totals .table-caption, .cart-totals .table-caption, .opc-block-summary .table-totals .table-caption, .sidebar .subtitle, .cart-summary .block .fieldset.estimate > .legend,
.cart-summary .block .fieldset.estimate > .legend + br, .minicart-items .product-item-details .weee[data-label] .label, .multicheckout .block .methods-shipping .item-content .fieldset > .legend + br, .multicheckout .hidden, [class^='multishipping-'] .nav-sections,
[class^='multishipping-'] .nav-toggle, .sidebar-main .block-reorder .subtitle,
.sidebar-additional .block-reorder .subtitle, .no-display {
  display: none; }

@media only screen and (max-width: 640px) {
  .abs-no-display-s, .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison).additional-attributes tbody th, .opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
    display: none; } }

@media only screen and (min-width: 768px) {
  .abs-no-display-desktop, .opc-estimated-wrapper, .sidebar .block.widget .pager .item:not(.pages-item-next):not(.pages-item-previous) {
    display: none; } }

.abs-status, .order-status {
  display: inline-block;
  margin-bottom: 20px; }

@media only screen and (max-width: 768px) {
  .abs-pager-toolbar-mobile .toolbar-amount, .account .toolbar .toolbar-amount, .toolbar-wishlist-results .toolbar-amount,
  .abs-pager-toolbar-mobile .limiter,
  .account .toolbar .limiter,
  .toolbar-wishlist-results .limiter,
  .abs-pager-toolbar-mobile .pages,
  .account .toolbar .pages,
  .toolbar-wishlist-results .pages {
    float: none;
    margin-bottom: 25px; } }

@media only screen and (max-width: 640px) {
  .abs-pager-toolbar-mobile-s .toolbar-amount,
  .abs-pager-toolbar-mobile-s .limiter,
  .abs-pager-toolbar-mobile-s .pages {
    margin-bottom: 25px; } }

@media only screen and (min-width: 768px) {
  .abs-pager-toolbar, .account .toolbar, .toolbar-wishlist-results {
    margin-bottom: 20px;
    position: relative; }
    .abs-pager-toolbar .limiter, .account .toolbar .limiter, .toolbar-wishlist-results .limiter {
      float: right;
      position: relative;
      z-index: 1; }
    .abs-pager-toolbar .toolbar-amount, .account .toolbar .toolbar-amount, .toolbar-wishlist-results .toolbar-amount {
      float: left;
      line-height: normal;
      padding: 7px 0 0;
      position: relative;
      z-index: 1; }
    .abs-pager-toolbar .pages, .account .toolbar .pages, .toolbar-wishlist-results .pages {
      position: absolute;
      width: 100%;
      z-index: 0; } }

.abs-block-items-counter, .block-compare .counter, .block-wishlist .counter {
  color: #7d7d7d;
  font-size: 12px;
  white-space: nowrap; }

.abs-shopping-cart-items .action.continue, .block-cart-failed .action.continue, .cart-container .form-cart .action.continue {
  border-radius: 3px;
  font-weight: 700;
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: #f2f2f2;
  border: 1px solid #cdcdcd;
  color: #2FDF66;
  cursor: pointer;
  display: inline-block;
  font-family: "Lexend", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 7px 15px 7px 0;
  width: auto;
  font-size: 14px;
  line-height: 16px;
  box-sizing: border-box;
  vertical-align: middle;
  display: inline-block;
  text-decoration: none; }
  .abs-shopping-cart-items .action.continue:hover, .block-cart-failed .action.continue:hover, .cart-container .form-cart .action.continue:hover, .abs-shopping-cart-items .action.continue:active, .block-cart-failed .action.continue:active, .cart-container .form-cart .action.continue:active, .abs-shopping-cart-items .action.continue:focus, .block-cart-failed .action.continue:focus, .cart-container .form-cart .action.continue:focus {
    text-decoration: none; }
  .abs-shopping-cart-items .action.continue:before, .block-cart-failed .action.continue:before, .cart-container .form-cart .action.continue:before {
    -webkit-font-smoothing: antialiased;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: top; }
  .abs-shopping-cart-items .action.continue:hover:before, .block-cart-failed .action.continue:hover:before, .cart-container .form-cart .action.continue:hover:before {
    color: inherit; }
  .abs-shopping-cart-items .action.continue:active:before, .block-cart-failed .action.continue:active:before, .cart-container .form-cart .action.continue:active:before {
    color: inherit; }
  .abs-shopping-cart-items .action.continue:focus, .block-cart-failed .action.continue:focus, .cart-container .form-cart .action.continue:focus, .abs-shopping-cart-items .action.continue:active, .block-cart-failed .action.continue:active, .cart-container .form-cart .action.continue:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #2FDF66; }
  .abs-shopping-cart-items .action.continue:hover, .block-cart-failed .action.continue:hover, .cart-container .form-cart .action.continue:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #555; }
  .abs-shopping-cart-items .action.continue.disabled, .block-cart-failed .action.continue.disabled, .cart-container .form-cart .action.continue.disabled, .abs-shopping-cart-items .action.continue[disabled], .block-cart-failed .action.continue[disabled], .cart-container .form-cart .action.continue[disabled],
  fieldset[disabled] .abs-shopping-cart-items .action.continue,
  fieldset[disabled] .block-cart-failed .action.continue,
  fieldset[disabled] .cart-container .form-cart .action.continue,
  .cart-container fieldset[disabled] .form-cart .action.continue {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
  .abs-shopping-cart-items .action.continue:active, .block-cart-failed .action.continue:active, .cart-container .form-cart .action.continue:active {
    box-shadow: none; }

.abs-shopping-cart-items .action.update, .block-cart-failed .action.update, .cart-container .form-cart .action.update {
  display: inline-block;
  text-decoration: none;
  padding-left: 5px; }
  .abs-shopping-cart-items .action.update:before, .block-cart-failed .action.update:before, .cart-container .form-cart .action.update:before {
    -webkit-font-smoothing: antialiased;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: top; }
  .abs-shopping-cart-items .action.update:hover:before, .block-cart-failed .action.update:hover:before, .cart-container .form-cart .action.update:hover:before {
    color: inherit; }
  .abs-shopping-cart-items .action.update:active:before, .block-cart-failed .action.update:active:before, .cart-container .form-cart .action.update:active:before {
    color: inherit; }

@media only screen and (max-width: 768px) {
  .abs-shopping-cart-items-mobile .actions, .cart-container .form-cart .actions {
    text-align: center; }
  .abs-shopping-cart-items-mobile .action.update, .cart-container .form-cart .action.update, .abs-shopping-cart-items-mobile .action.continue, .cart-container .form-cart .action.continue, .abs-shopping-cart-items-mobile .action.clear, .cart-container .form-cart .action.clear {
    margin: 0 auto 10px; }
  .abs-shopping-cart-items-mobile .action.update, .cart-container .form-cart .action.update, .abs-shopping-cart-items-mobile .action.clear, .cart-container .form-cart .action.clear {
    display: block; } }

@media only screen and (min-width: 768px) {
  .abs-shopping-cart-items-desktop, .block-cart-failed, .cart-container .form-cart {
    float: left;
    position: relative;
    width: 73%; }
    .abs-shopping-cart-items-desktop .actions, .block-cart-failed .actions, .cart-container .form-cart .actions {
      text-align: right; }
    .abs-shopping-cart-items-desktop .action.continue, .block-cart-failed .action.continue, .cart-container .form-cart .action.continue {
      float: left; }
    .abs-shopping-cart-items-desktop .action.clear, .block-cart-failed .action.clear, .cart-container .form-cart .action.clear {
      margin-right: 10px; } }

.abs-field-date .control, .field.date .control {
  position: relative; }

.abs-field-date-input, .abs-field-date input, .field.date input {
  margin-right: 10px;
  width: calc(100% - (40px + 10px)); }

.abs-field-tooltip, .field .control._with-tooltip {
  position: relative; }
  .abs-field-tooltip input, .field .control._with-tooltip input {
    margin-right: 10px;
    width: calc(100% - (21px + 10px + 5px)); }

.abs-checkout-tooltip-content-position-top, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content, .shipping-policy-block.field-tooltip .field-tooltip-content {
  right: -10px;
  top: 40px;
  left: auto; }
  .abs-checkout-tooltip-content-position-top:before, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before, .shipping-policy-block.field-tooltip .field-tooltip-content:before, .abs-checkout-tooltip-content-position-top:after, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after, .shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border: 10px solid transparent;
    height: 0;
    width: 0;
    margin-top: -21px;
    right: 10px;
    left: auto;
    top: 0; }
  .abs-checkout-tooltip-content-position-top:before, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before, .shipping-policy-block.field-tooltip .field-tooltip-content:before {
    border-bottom-color: #999; }
  .abs-checkout-tooltip-content-position-top:after, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after, .shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border-bottom-color: #f4f4f4;
    top: 1px; }

@media only screen and (max-width: 769px) {
  .abs-checkout-tooltip-content-position-top-mobile, .field-tooltip .field-tooltip-content {
    right: -10px;
    top: 40px;
    left: auto; }
    .abs-checkout-tooltip-content-position-top-mobile:before, .field-tooltip .field-tooltip-content:before, .abs-checkout-tooltip-content-position-top-mobile:after, .field-tooltip .field-tooltip-content:after {
      border: 10px solid transparent;
      height: 0;
      width: 0;
      margin-top: -21px;
      right: 10px;
      left: auto;
      top: 0; }
    .abs-checkout-tooltip-content-position-top-mobile:before, .field-tooltip .field-tooltip-content:before {
      border-bottom-color: #999; }
    .abs-checkout-tooltip-content-position-top-mobile:after, .field-tooltip .field-tooltip-content:after {
      border-bottom-color: #f4f4f4;
      top: 1px; } }

.abs-checkout-title, .opc-wrapper .step-title, .opc-block-shipping-information .shipping-information-title, .opc-block-summary > .title, .checkout-agreements-items .checkout-agreements-item-title {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  color: #333;
  font-family: "Lexend", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: false;
  font-weight: 300;
  line-height: false;
  font-size: 26px; }

.abs-sidebar-totals .mark, .cart-totals .mark, .opc-block-summary .table-totals .mark {
  font-weight: 400;
  padding-left: 4px; }

.abs-sidebar-totals .amount, .cart-totals .amount, .opc-block-summary .table-totals .amount {
  padding-right: 4px;
  text-align: right;
  white-space: nowrap; }
  .abs-sidebar-totals .amount strong, .cart-totals .amount strong, .opc-block-summary .table-totals .amount strong {
    font-weight: 400; }

.abs-sidebar-totals .grand .amount, .cart-totals .grand .amount, .opc-block-summary .table-totals .grand .amount {
  padding-right: 4px;
  text-align: right; }
  .abs-sidebar-totals .grand .amount strong, .cart-totals .grand .amount strong, .opc-block-summary .table-totals .grand .amount strong {
    font-weight: 700; }

.abs-sidebar-totals .msrp, .cart-totals .msrp, .opc-block-summary .table-totals .msrp {
  margin-bottom: 10px; }

.abs-sidebar-totals .totals-tax-summary .mark, .cart-totals .totals-tax-summary .mark, .opc-block-summary .table-totals .totals-tax-summary .mark,
.abs-sidebar-totals .totals-tax-summary .amount,
.cart-totals .totals-tax-summary .amount,
.opc-block-summary .table-totals .totals-tax-summary .amount {
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  cursor: pointer; }

.abs-sidebar-totals .totals-tax-summary .amount .price, .cart-totals .totals-tax-summary .amount .price, .opc-block-summary .table-totals .totals-tax-summary .amount .price {
  display: block;
  text-decoration: none;
  padding-right: 25px;
  position: relative; }
  .abs-sidebar-totals .totals-tax-summary .amount .price > span, .cart-totals .totals-tax-summary .amount .price > span, .opc-block-summary .table-totals .totals-tax-summary .amount .price > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .abs-sidebar-totals .totals-tax-summary .amount .price:after, .cart-totals .totals-tax-summary .amount .price:after, .opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
    -webkit-font-smoothing: antialiased;
    font-size: 30px;
    line-height: inherit;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: inherit;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: middle; }
  .abs-sidebar-totals .totals-tax-summary .amount .price:hover:after, .cart-totals .totals-tax-summary .amount .price:hover:after, .opc-block-summary .table-totals .totals-tax-summary .amount .price:hover:after {
    color: inherit; }
  .abs-sidebar-totals .totals-tax-summary .amount .price:active:after, .cart-totals .totals-tax-summary .amount .price:active:after, .opc-block-summary .table-totals .totals-tax-summary .amount .price:active:after {
    color: inherit; }
  .abs-sidebar-totals .totals-tax-summary .amount .price:after, .cart-totals .totals-tax-summary .amount .price:after, .opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
    position: absolute;
    right: -5px;
    top: -12px; }

.abs-sidebar-totals .totals-tax-summary.expanded .mark, .cart-totals .totals-tax-summary.expanded .mark, .opc-block-summary .table-totals .totals-tax-summary.expanded .mark,
.abs-sidebar-totals .totals-tax-summary.expanded .amount,
.cart-totals .totals-tax-summary.expanded .amount,
.opc-block-summary .table-totals .totals-tax-summary.expanded .amount {
  border-bottom: 0; }

.abs-sidebar-totals .totals-tax-summary.expanded .amount .price:after, .cart-totals .totals-tax-summary.expanded .amount .price:after, .opc-block-summary .table-totals .totals-tax-summary.expanded .amount .price:after {
  content: ""; }

.abs-sidebar-totals .totals-tax-details, .cart-totals .totals-tax-details, .opc-block-summary .table-totals .totals-tax-details {
  display: none;
  border-bottom: 1px solid #d1d1d1; }
  .abs-sidebar-totals .totals-tax-details.shown, .cart-totals .totals-tax-details.shown, .opc-block-summary .table-totals .totals-tax-details.shown {
    display: table-row; }

.abs-discount-block > .title, .cart-summary .block > .title, .paypal-review-discount .block > .title {
  border-top: 1px solid #d1d1d1;
  cursor: pointer;
  font-weight: 600;
  display: block;
  text-decoration: none;
  margin-bottom: 0;
  overflow: hidden;
  padding: 7px 30px 7px 5px;
  position: relative; }
  .abs-discount-block > .title:after, .cart-summary .block > .title:after, .paypal-review-discount .block > .title:after {
    -webkit-font-smoothing: antialiased;
    font-size: 30px;
    line-height: inherit;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: inherit;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: middle; }
  .abs-discount-block > .title:hover:after, .cart-summary .block > .title:hover:after, .paypal-review-discount .block > .title:hover:after {
    color: inherit; }
  .abs-discount-block > .title:active:after, .cart-summary .block > .title:active:after, .paypal-review-discount .block > .title:active:after {
    color: inherit; }
  .abs-discount-block > .title:after, .cart-summary .block > .title:after, .paypal-review-discount .block > .title:after {
    position: absolute;
    right: -5px;
    top: -5px; }
  .column.main .abs-discount-block > .title strong, .column.main .cart-summary .block > .title strong, .cart-summary .column.main .block > .title strong, .column.main .paypal-review-discount .block > .title strong, .paypal-review-discount .column.main .block > .title strong {
    font-size: 18px;
    font-weight: 400; }

.abs-discount-block > .content, .cart-summary .block > .content, .paypal-review-discount .block > .content {
  display: none; }

.abs-discount-block.active > .title:after, .cart-summary .active.block > .title:after, .paypal-review-discount .active.block > .title:after {
  content: ""; }

.abs-discount-block.active > .content, .cart-summary .active.block > .content, .paypal-review-discount .active.block > .content {
  display: block; }

@font-face {
  font-family: "icomoon";
  src: url("../fonts/Icomoon-Theme-Icons/icomoon.eot");
  src: url("../fonts/Icomoon-Theme-Icons/icomoon.eot?#iefix") format("embedded-opentype"), url("../fonts/Icomoon-Theme-Icons/icomoon.woff2") format("woff2"), url("../fonts/Icomoon-Theme-Icons/icomoon.woff") format("woff"), url("../fonts/Icomoon-Theme-Icons/icomoon.ttf") format("truetype"), url("../fonts/Icomoon-Theme-Icons/icomoon.svg#icomoon") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham/light/gotham-light.eot");
  src: url("../fonts/gotham/light/gotham-light.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/light/gotham-light.woff2") format("woff2"), url("../fonts/gotham/light/gotham-light.woff") format("woff"), url("../fonts/gotham/light/gotham-light.ttf") format("truetype"), url("../fonts/gotham/light/gotham-light.svg#Gotham") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham/regular/gotham-regular.eot");
  src: url("../fonts/gotham/regular/gotham-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/regular/gotham-regular.woff2") format("woff2"), url("../fonts/gotham/regular/gotham-regular.woff") format("woff"), url("../fonts/gotham/regular/gotham-regular.ttf") format("truetype"), url("../fonts/gotham/regular/gotham-regular.svg#Gotham") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham/medium/gotham-medium.eot");
  src: url("../fonts/gotham/medium/gotham-medium.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/medium/gotham-medium.woff2") format("woff2"), url("../fonts/gotham/medium/gotham-medium.woff") format("woff"), url("../fonts/gotham/medium/gotham-medium.ttf") format("truetype"), url("../fonts/gotham/medium/gotham-medium.svg#Gotham") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham/medium-italic/gotham-medium-italic.eot");
  src: url("../fonts/gotham/medium-italic/gotham-medium-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/medium-italic/gotham-medium-italic.woff2") format("woff2"), url("../fonts/gotham/medium-italic/gotham-medium-italic.woff") format("woff"), url("../fonts/gotham/medium-italic/gotham-medium-italic.ttf") format("truetype"), url("../fonts/gotham/medium-italic/gotham-medium-italic.svg#Gotham") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham/bold/gotham-bold.eot");
  src: url("../fonts/gotham/bold/gotham-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/bold/gotham-bold.woff2") format("woff2"), url("../fonts/gotham/bold/gotham-bold.woff") format("woff"), url("../fonts/gotham/bold/gotham-bold.ttf") format("truetype"), url("../fonts/gotham/bold/gotham-bold.svg#Gotham") format("svg");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham/black/gotham-black.eot");
  src: url("../fonts/gotham/black/gotham-black.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/black/gotham-black.woff2") format("woff2"), url("../fonts/gotham/black/gotham-black.woff") format("woff"), url("../fonts/gotham/black/gotham-black.ttf") format("truetype"), url("../fonts/gotham/black/gotham-black.svg#Gotham") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Lexend";
  src: url("../fonts/lexend/Lexend-Light.eot");
  src: url("../fonts/lexend/Lexend-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/lexend/Lexend-Light.woff2") format("woff2"), url("../fonts/lexend/Lexend-Light.woff") format("woff"), url("../fonts/lexend/Lexend-Light.ttf") format("truetype"), url("../fonts/lexend/Lexend-Light.svg#Lexend") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Lexend";
  src: url("../fonts/lexend/Lexend-Regular.eot");
  src: url("../fonts/lexend/Lexend-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/lexend/Lexend-Regular.woff2") format("woff2"), url("../fonts/lexend/Lexend-Regular.woff") format("woff"), url("../fonts/lexend/Lexend-Regular.ttf") format("truetype"), url("../fonts/lexend/Lexend-Regular.svg#Lexend") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Lexend";
  src: url("../fonts/lexend/Lexend-Medium.eot");
  src: url("../fonts/lexend/Lexend-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/lexend/Lexend-Medium.woff2") format("woff2"), url("../fonts/lexend/Lexend-Medium.woff") format("woff"), url("../fonts/lexend/Lexend-Medium.ttf") format("truetype"), url("../fonts/lexend/Lexend-Medium.svg#Lexend") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Lexend";
  src: url("../fonts/lexend/Lexend-Semibold.eot");
  src: url("../fonts/lexend/Lexend-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/lexend/Lexend-Semibold.woff2") format("woff2"), url("../fonts/lexend/Lexend-Semibold.woff") format("woff"), url("../fonts/lexend/Lexend-Semibold.ttf") format("truetype"), url("../fonts/lexend/Lexend-Semibold.svg#Lexend") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

.items {
  margin: 0;
  padding: 0;
  list-style: none none; }

p {
  margin-top: 7px;
  margin-bottom: 7px;
  font-weight: 100; }

.category-full-description ul,
.category-full-description li {
  font-weight: 100; }

h2 {
  font-weight: 100;
  font-weight: lighter; }

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 40px;
    margin-bottom: 40px;
    margin-top: 40px; } }

button:active, .cart.table-wrapper .actions-toolbar > .action:active {
  box-shadow: none; }

a.action.primary {
  display: inline-block;
  text-decoration: none; }
  a.action.primary:hover, a.action.primary:active, a.action.primary:focus {
    text-decoration: none; }

.action.primary {
  background-image: none;
  background: #1979c3;
  border: 1px solid #1979c3;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Lexend", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 7px 15px;
  width: auto;
  font-size: 14px;
  line-height: 16px;
  box-sizing: border-box;
  vertical-align: middle; }
  .action.primary:focus, .action.primary:active {
    background: #006bb4;
    border: 1px solid #1979c3;
    color: #fff; }
  .action.primary:hover {
    background: #006bb4;
    border: 1px solid #1979c3;
    color: #fff; }
  .action.primary.disabled, .action.primary[disabled],
  fieldset[disabled] .action.primary {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
  .action.primary.out-of-stock-notify {
    width: 100%;
    line-height: 46px;
    background: #014A5B;
    border: 2px solid #e8e8e8;
    padding: 0 33px; }
    .action.primary.out-of-stock-notify:hover {
      background: transparent;
      color: #e8e8e8; }

#btn-minicart-close {
  color: #20272B; }

/**/
.checkout-index-index.page-layout-checkout .action.action-apply {
  background-color: #2fdf66 !important;
  height: 50px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  color: white !important;
  border: 0px;
  margin-right: 0px !important; }

.action.primary.checkout {
  background-image: none;
  background: #ED8400;
  border: 0px solid #ED8400;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 7px 15px;
  width: auto;
  font-size: 14px;
  line-height: 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px; }
  .action.primary.checkout:hover {
    background: transparent;
    color: white;
    background: #2FDF66; }

.action.primary {
  line-height: 46px;
  padding: 0 33px;
  border: 0px solid;
  margin-bottom: 30px !important;
  border: 0px solid #014A5B;
  background: #2FDF66;
  color: white; }
  .action.primary:hover {
    border: 0px solid #2FDF66;
    background: #014A5B;
    color: white; }

.cart-container .form-cart .action.continue {
  display: inline-block;
  text-decoration: none;
  background: #014A5B;
  border: 0px;
  margin-bottom: 20px;
  border-radius: 0px;
  color: white;
  cursor: pointer;
  font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin: 0;
  padding: 16px 24px 0px 0;
  width: auto;
  font-size: 14px;
  line-height: 16px;
  vertical-align: middle;
  height: 48px;
  margin-bottom: 20px; }
  .cart-container .form-cart .action.continue:hover {
    background: #2FDF66;
    color: white;
    border: 0px; }

#empty_cart_button {
  padding-left: 20px; }

.action.clear,
.action.update {
  border: 0px;
  border-radius: 0px;
  color: black;
  cursor: pointer;
  font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin: 0;
  padding: 0px 24px 0px 0;
  width: auto;
  font-size: 14px;
  line-height: 16px;
  vertical-align: middle;
  height: 48px; }
  .action.clear:hover,
  .action.update:hover {
    color: black;
    border: 0px; }

.action.remove {
  vertical-align: middle; }

.page-bottom {
  width: 100%;
  max-width: 100%; }
  .page-bottom li {
    display: inline;
    text-decoration: none; }

.footercms {
  text-align: center;
  background: #014A5B;
  padding-top: 25px;
  padding-bottom: 25px;
  color: white; }
  .footercms a {
    color: white; }
  .footercms a:hover {
    color: #2FDF66; }
  .footercms ul, .footercms ol {
    color: inherit;
    font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857;
    font-size: inherit;
    list-style: none;
    display: inline-flex; }
    .footercms ul li, .footercms ol li {
      margin-right: 10px; }
  .footercms img {
    width: 125px;
    height: auto; }

@media only screen and (max-width: 768px) {
  .account .page-main,
  .cms-privacy-policy .page-main {
    padding-top: 41px;
    position: relative; } }

@media only screen and (min-width: 768px) {
  .navigation,
  .breadcrumbs,
  .page-header .header.panel,
  .header.content,
  .page-wrapper > .widget,
  .page-wrapper >
.block.category.event,
  .top-container,
  .page-main {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1445px;
    width: auto; }
  .page-main {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto; }
    .ie9 .page-main {
      width: auto; }
  .columns {
    display: block; }
  .page-layout-2columns-left .sidebar-additional {
    clear: left;
    float: left;
    padding-left: 0; }
  .panel.header {
    padding: 10px 0px; } }

@media only screen and (max-width: 767px) {
  .footer.content li {
    font-size: 13px; }
  .footer.content h2 {
    font-size: 17px;
    font-weight: bold; } }

@media only screen and (max-width: 560px) {
  .footer.content {
    padding: 30px 20px; }
    .footer.content li {
      font-size: 17px; }
    .footer.content h2 {
      font-size: 20px;
      font-weight: bold; } }

.table-wrapper {
  margin-bottom: 20px; }

table > caption {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.table:not(.cart):not(.totals) {
  border: none; }
  .table:not(.cart):not(.totals) > thead > tr > th,
  .table:not(.cart):not(.totals) > thead > tr > td,
  .table:not(.cart):not(.totals) > tbody > tr > th,
  .table:not(.cart):not(.totals) > tbody > tr > td,
  .table:not(.cart):not(.totals) > tfoot > tr > th,
  .table:not(.cart):not(.totals) > tfoot > tr > td {
    border: none; }
  .table:not(.cart):not(.totals) > thead > tr > th,
  .table:not(.cart):not(.totals) > thead > tr > td {
    border-bottom: 1px solid #d1d1d1; }
  .table:not(.cart):not(.totals) tfoot > tr:first-child th,
  .table:not(.cart):not(.totals) tfoot > tr:first-child td {
    border-top: 1px solid #d1d1d1;
    padding-top: 20px; }
  .table:not(.cart):not(.totals) tfoot .mark {
    font-weight: 400;
    text-align: right; }

@media only screen and (max-width: 640px) {
  .table-wrapper {
    margin-bottom: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    position: relative; }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) {
      background: inherit;
      border: none;
      display: block; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > thead > tr > th {
        display: none; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody {
        display: block; }
        .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr {
          display: block; }
          .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td,
          .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th {
            border-bottom: none;
            display: block;
            padding: 5px 0; }
            .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td:before,
            .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th:before {
              padding-right: 10px;
              content: attr(data-th) ": ";
              display: inline-block;
              color: #111111;
              font-family: inherit;
              font-style: inherit;
              font-weight: 700;
              line-height: inherit;
              font-size: inherit; }
          .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td {
            background: inherit; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr > th {
        background-color: #f0f0f0; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr > td:first-child {
        padding-top: 20px; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr > td:last-child {
        padding-bottom: 20px; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison).additional-attributes tbody td:last-child {
        border: none;
        padding: 0 0 5px; }
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot {
      display: block; }
      .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr {
        display: block; }
        .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:first-child th,
        .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:first-child td {
          padding-top: 20px; }
      .table-wrapper .table:not(.totals):not(.table-comparison) tfoot th {
        box-sizing: border-box;
        float: left;
        padding-left: 0;
        padding-right: 0;
        text-align: left;
        width: 70%; }
      .table-wrapper .table:not(.totals):not(.table-comparison) tfoot td {
        box-sizing: border-box;
        float: left;
        padding-left: 0;
        padding-right: 0;
        text-align: right;
        width: 30%; }
  .data-table-definition-list thead {
    display: none; }
  .data-table-definition-list tbody th {
    padding-bottom: 0; }
  .data-table-definition-list tbody th,
  .data-table-definition-list tbody td {
    display: block;
    padding-left: 0;
    padding-right: 0; } }

.message.info {
  border-radius: inherit;
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: 1.2;
  font-size: 13px;
  background: #fdf0d5;
  border-color: inherit;
  color: #6f4400;
  padding-left: 40px;
  position: relative; }
  .message.info a {
    color: #1979c3; }
    .message.info a:hover {
      color: #006bb4; }
    .message.info a:active {
      color: #006bb4; }
  .message.info > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    font-size: 28px;
    line-height: 28px;
    color: #c07600;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    bottom: inherit;
    left: 0;
    right: inherit;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.error {
  border-radius: inherit;
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: 1.2;
  font-size: 13px;
  background: #fae5e5;
  border-color: inherit;
  color: #e02b27;
  padding-left: 40px;
  position: relative; }
  .message.error a {
    color: #1979c3; }
    .message.error a:hover {
      color: #006bb4; }
    .message.error a:active {
      color: #006bb4; }
  .message.error > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    font-size: 28px;
    line-height: 28px;
    color: #b30000;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    bottom: inherit;
    left: 0;
    right: inherit;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.warning {
  border-radius: inherit;
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: 1.2;
  font-size: 13px;
  background: #fdf0d5;
  border-color: inherit;
  color: #6f4400;
  padding-left: 40px;
  position: relative; }
  .message.warning a {
    color: #1979c3; }
    .message.warning a:hover {
      color: #006bb4; }
    .message.warning a:active {
      color: #006bb4; }
  .message.warning > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    font-size: 28px;
    line-height: 28px;
    color: #c07600;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    bottom: inherit;
    left: 0;
    right: inherit;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.notice {
  border-radius: inherit;
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: 1.2;
  font-size: 13px;
  background: #fdf0d5;
  border-color: inherit;
  color: #6f4400;
  padding-left: 40px;
  position: relative; }
  .message.notice a {
    color: #1979c3; }
    .message.notice a:hover {
      color: #006bb4; }
    .message.notice a:active {
      color: #006bb4; }
  .message.notice > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    font-size: 28px;
    line-height: 28px;
    color: #c07600;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    bottom: inherit;
    left: 0;
    right: inherit;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.success {
  border-radius: inherit;
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: 1.2;
  font-size: 13px;
  background: #e5efe5;
  border-color: inherit;
  color: #006400;
  padding-left: 40px;
  position: relative; }
  .message.success a {
    color: #1979c3; }
    .message.success a:hover {
      color: #006bb4; }
    .message.success a:active {
      color: #006bb4; }
  .message.success > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    font-size: 28px;
    line-height: 28px;
    color: #006400;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    bottom: inherit;
    left: 0;
    right: inherit;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.nav-open #trustbadge-container-98e3dadd90eb493088abdc5597a70810 {
  z-index: 1 !important; }

.navigation .ui-menu .megamenu.level-top-sectioned .submenu .submenu-child {
  width: 100% !important; }

.navigation .ui-menu .megamenu.level-top-sectioned .submenu .level1 .submenu li > a {
  margin-top: 0;
  padding: 0 10px; }

.navigation .ui-menu .megamenu.level-top-sectioned .submenu .level1 .submenu li > a:first-child {
  margin-top: 0;
  padding: 0 10px; }

.navigation .ui-menu .megamenu.level-top-sectioned .submenu .columns-group li > a span {
  font-size: 14px; }

.navigation .ui-menu .megamenu a {
  text-transform: none; }

.navigation .level0 .submenu a {
  line-height: inherit;
  color: #014A5B !important;
  font-size: 14px; }
  .navigation .level0 .submenu a:hover {
    color: #3f3f3f !important; }

.panel.header .links,
.panel.header .switcher {
  display: none; }

.nav-sections {
  background: #014A5B; }

.nav-toggle {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  display: block;
  font-size: 0;
  left: 15px;
  position: absolute;
  top: 15px;
  z-index: 14; }
  .nav-toggle:before {
    -webkit-font-smoothing: antialiased;
    font-size: 28px;
    line-height: inherit;
    color: #8f8f8f;
    content: "";
    font-family: "icons-blank-theme";
    margin: inherit;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: middle; }
  .nav-toggle:hover:before {
    color: #333; }
  .nav-toggle:active:before {
    color: inherit; }
  .nav-toggle > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  @media only screen and (max-width: 480px) {
    .nav-toggle {
      top: 0; } }

@media only screen and (max-width: 1040px) {
  .navigation .nav-1 .columns-group.starter {
    display: none !important; }
  .has-menu-block .columns-group-block.top-group {
    display: inline-block !important; }
  .megamenu-custom-group {
    margin-bottom: 20px !important; }
  .megamenu-custom-group {
    padding-left: 10px !important; }
  .megamenu-custom-group {
    display: block !important; }
  .action.nav-toggle:before {
    color: #005266;
    font-size: 48px; }
  .mobile-nav .nav-sections .section-item-content .navigation .parent .level-top {
    display: block;
    text-decoration: none; }
    .mobile-nav .nav-sections .section-item-content .navigation .parent .level-top:after {
      -webkit-font-smoothing: antialiased;
      font-size: 42px;
      line-height: inherit;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: inherit;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: middle; }
    .mobile-nav .nav-sections .section-item-content .navigation .parent .level-top:hover:after {
      color: inherit; }
    .mobile-nav .nav-sections .section-item-content .navigation .parent .level-top:active:after {
      color: inherit; }
  .mobile-nav .nav-sections .section-item-content .navigation .level0 {
    font-size: 16px; }
  .navigation {
    padding: 0 0 25px 0; }
    .navigation .parent .level-top {
      position: relative;
      padding-right: 20px;
      display: block;
      text-decoration: none;
      font-size: 16px;
      font-weight: 600; }
      .navigation .parent .level-top:after {
        -webkit-font-smoothing: antialiased;
        font-size: 42px;
        line-height: inherit;
        color: inherit;
        content: "";
        font-family: "icons-blank-theme";
        margin: inherit;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
        vertical-align: middle; }
      .navigation .parent .level-top:hover:after {
        color: inherit; }
      .navigation .parent .level-top:active:after {
        color: inherit; }
      .navigation .parent .level-top:after {
        position: absolute;
        right: 0; }
      .navigation .parent .level-top.ui-state-active:after {
        content: ""; }
    .navigation .submenu:not(:first-child) .level1.active > a {
      border-color: #005266; }
  .nav-sections {
    -webkit-overflow-scrolling: touch;
    transition: left 0.3s;
    height: 100%;
    left: -80%;
    left: calc(-1 * (100% - 54px));
    overflow: auto;
    position: fixed;
    top: 0;
    width: 80%;
    background: #fff;
    width: calc(100% - 54px); }
    .nav-sections .switcher {
      border-top: 1px solid white;
      font-size: 1.6rem;
      font-weight: 600;
      margin: 0;
      padding: 0.8rem 1.5rem 0.8rem 1.5rem; }
      .nav-sections .switcher .switcher-options {
        border: 1px solid #111111; }
      .nav-sections .switcher .label {
        display: none; }
    .nav-sections .switcher-trigger {
      border-bottom: 0; }
      .nav-sections .switcher-trigger strong {
        position: relative;
        line-height: 60px;
        padding-left: 20px;
        color: #111111;
        font-weight: 300;
        display: block;
        text-decoration: none; }
        .nav-sections .switcher-trigger strong:before {
          content: "";
          width: 30px;
          height: 20px;
          position: relative;
          top: 2px;
          margin-right: 10px;
          display: inline-block; }
        .nav-sections .switcher-trigger strong:after {
          -webkit-font-smoothing: antialiased;
          font-size: 60px;
          line-height: inherit;
          color: inherit;
          content: "";
          font-family: "icons-blank-theme";
          margin: inherit;
          display: inline-block;
          font-weight: normal;
          overflow: hidden;
          speak: none;
          text-align: center;
          vertical-align: middle; }
        .nav-sections .switcher-trigger strong:hover:after {
          color: inherit; }
        .nav-sections .switcher-trigger strong:active:after {
          color: inherit; }
        .nav-sections .switcher-trigger strong:after {
          position: absolute;
          right: 0;
          top: 0;
          height: 60px;
          width: 60px;
          line-height: 60px;
          background: none; }
      .nav-sections .switcher-trigger.active strong:after {
        content: ""; }
    .nav-sections .switcher-dropdown {
      margin: 0;
      padding: 0;
      list-style: none none;
      padding: 0; }
      .nav-sections .switcher-dropdown li {
        margin: 0;
        line-height: 60px;
        border-bottom: 1px solid white;
        font-weight: 300;
        padding: 0; }
        .nav-sections .switcher-dropdown li a {
          display: block;
          padding-left: 20px;
          box-sizing: border-box; }
          .nav-sections .switcher-dropdown li a:before {
            content: "";
            width: 30px;
            height: 20px;
            position: relative;
            top: 4px;
            margin-right: 10px;
            display: inline-block; }
          .nav-sections .switcher-dropdown li a:visited {
            color: #e8e8e8; }
          .nav-sections .switcher-dropdown li a:hover {
            text-decoration: none;
            background: #a6a6a6;
            color: #e8e8e8; }
    .nav-sections .header.links {
      margin: 0;
      padding: 0;
      list-style: none none;
      border-bottom: 1px solid #111111; }
      .nav-sections .header.links li {
        font-size: 1.6rem;
        margin: 0;
        padding-right: 0;
        display: block !important; }
        .nav-sections .header.links li.for-questions, .nav-sections .header.links li.whatsapp {
          display: none; }
        .nav-sections .header.links li.greet.welcome {
          border-top: 1px solid #d1d1d1;
          font-weight: 700;
          padding: 0.8rem 20px; }
        .nav-sections .header.links li > a {
          border-top: 1px solid #111111;
          display: block;
          width: 100%;
          box-sizing: border-box;
          color: #111111;
          font-size: 16px;
          font-weight: 600; }
        .nav-sections .header.links li.authorization-link:before {
          display: none; }
      .nav-sections .header.links a,
      .nav-sections .header.links a:hover {
        color: #005266;
        /*$navigation-level0-item__color*/
        text-decoration: none;
        display: block;
        font-weight: 700;
        padding: 0.8rem 20px; }
      .nav-sections .header.links .header.links {
        border: 0; }
  .nav-before-open {
    height: 100%;
    overflow-x: hidden;
    width: 100%; }
    .nav-before-open .page-wrapper {
      transition: left 0.3s;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: relative; }
    .nav-before-open body {
      height: 100%;
      overflow: hidden;
      position: relative;
      width: 100%; }
  .nav-open .page-wrapper {
    left: 80%;
    left: calc(100% - 54px); }
  .nav-open .nav-sections {
    box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
    left: 0;
    z-index: 99; }
    .nav-open .nav-sections .navigation {
      background: transparent; }
  .nav-open .nav-toggle:after {
    background: rgba(0, 0, 0, 0.7);
    content: '';
    display: block;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1; }
  .nav-sections-items {
    position: relative;
    z-index: 1; }
    .nav-sections-items:before, .nav-sections-items:after {
      content: '';
      display: table; }
    .nav-sections-items:after {
      clear: both; }
  .nav-sections-item-title {
    background: #013542;
    border: solid #002129;
    border-width: 0 0 1px 0;
    box-sizing: border-box;
    float: left;
    height: 71px;
    font-weight: 600;
    color: #fff;
    padding-top: 24px;
    text-align: center;
    width: 33.33%; }
    .nav-sections-item-title > a {
      color: #fff; }
    .nav-sections-item-title.active {
      background: transparent;
      border-bottom: 0; }
      .nav-sections-item-title.active > a {
        color: #005266; }
    .nav-sections-item-title .nav-sections-item-switch:hover {
      text-decoration: none; }
  .nav-sections-item-content {
    box-sizing: border-box;
    float: right;
    margin-left: -100%;
    margin-top: 71px;
    width: 100%;
    padding: 25px 0 0; }
    .nav-sections-item-content:before, .nav-sections-item-content:after {
      content: '';
      display: table; }
    .nav-sections-item-content:after {
      clear: both; }
    .nav-sections-item-content.active {
      display: block; }
  .navigation {
    background: whitesmoke;
    border: none;
    box-sizing: border-box; }
    .navigation ul {
      margin: 0;
      padding: 0; }
    .navigation li {
      margin: 0; }
    .navigation a {
      display: block;
      padding: 10px 0 10px 15px; }
    .navigation a,
    .navigation a:hover {
      color: #575757;
      text-decoration: none; }
    .navigation .level0 {
      border-top: 1px solid #d1d1d1;
      font-size: 16px; }
      .navigation .level0 > .level-top {
        background: inherit;
        font-weight: 700;
        line-height: inherit;
        padding: 8px 40px 8px 15px;
        text-transform: uppercase;
        word-wrap: break-word; }
      .navigation .level0.active .all-category .ui-state-focus {
        background: inherit;
        border-color: #ff5501;
        border-style: solid;
        border-width: 0 0 0 8px;
        color: inherit;
        padding-left: 7px;
        text-decoration: inherit;
        display: inline-block; }
      .navigation .level0 > .level1 {
        font-weight: 600; }
      .navigation .level0.active > a:not(.ui-state-active), .navigation .level0.has-active > a:not(.ui-state-active) {
        background: inherit;
        border-color: #ff5501;
        border-style: solid;
        border-width: 0 0 0 8px;
        color: inherit;
        text-decoration: inherit; }
        .navigation .level0.active > a:not(.ui-state-active) span:not(.ui-menu-icon), .navigation .level0.has-active > a:not(.ui-state-active) span:not(.ui-menu-icon) {
          margin-left: -8px; }
    .navigation li.level0:last-child {
      border-bottom: 1px solid #d1d1d1; }
    .navigation .submenu > li {
      word-wrap: break-word; }
    .navigation .submenu:not(:first-child) {
      background: inherit;
      border: none;
      font-size: inherit;
      font-weight: 400;
      line-height: 1.3;
      left: auto;
      overflow-x: hidden;
      padding: 0;
      position: relative;
      top: auto !important;
      transition: left 0.3s ease-out; }
      .navigation .submenu:not(:first-child) > li > a {
        padding-left: 15px; }
      .navigation .submenu:not(:first-child) > li:last-child {
        margin-bottom: 0; }
      .navigation .submenu:not(:first-child) ul {
        display: block;
        padding-left: 15px; }
        .navigation .submenu:not(:first-child) ul > li {
          margin: 0; }
          .navigation .submenu:not(:first-child) ul > li a {
            background: inherit;
            border: none;
            color: #575757;
            text-decoration: inherit;
            display: block;
            line-height: normal; }
      .navigation .submenu:not(:first-child).expanded {
        display: block !important;
        padding-right: 0;
        top: 0 !important; }
      .navigation .submenu:not(:first-child) .active > a {
        background: inherit;
        border-color: #ff5501;
        border-style: solid;
        border-width: 0 0 0 8px;
        color: inherit;
        padding-left: 7px;
        text-decoration: inherit; }
      .navigation .submenu:not(:first-child) .level1.active > a {
        padding-left: 7px; } }

@media only screen and (min-width: 1041px) {
  .navigation .nav-1 .columns-group.starter {
    display: none !important; }
  .navigation .ui-menu {
    position: relative !important; }
    .navigation .ui-menu .megamenu.level-top-sectioned {
      position: static; }
    .navigation .ui-menu .megamenu.level-top-default {
      position: relative; }
    .navigation .ui-menu .megamenu .submenu {
      position: absolute !important;
      top: 60px !important;
      width: auto;
      z-index: 2; }
  .menu-block.top-block {
    display: flex !important;
    flex-wrap: wrap; }
  .megamenu-custom-group {
    flex: 1 1 25%;
    padding-right: 15px !important; }
  .nav-toggle {
    display: none; }
  .nav-sections {
    flex-shrink: 0;
    flex-basis: auto; }
  .nav-sections-item-title {
    display: none; }
  .nav-sections-item-content {
    display: block !important; }
  .nav-sections-item-content > * {
    display: none; }
  .nav-sections-item-content > .navigation {
    display: block; }
  .navigation {
    background: #014A5B;
    border: none;
    font-size: 17px;
    font-weight: 400;
    height: inherit;
    left: auto;
    overflow: inherit;
    padding: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 3; }
    .navigation:empty {
      display: none; }
    .navigation ul {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
      position: relative;
      font-weight: 400; }
    .navigation li.level0 {
      border-top: none; }
    .navigation .level0 {
      margin: 0;
      display: inline-block;
      position: relative; }
      .navigation .level0:last-child {
        margin-right: 0;
        padding-right: 0; }
      .navigation .level0 > .level-top {
        background: inherit;
        border: none;
        color: #fff;
        line-height: 60px;
        padding: 0 12px;
        text-decoration: none;
        box-sizing: border-box;
        position: relative; }
        .navigation .level0 > .level-top:hover, .navigation .level0 > .level-top.ui-state-focus {
          background: inherit;
          border: none;
          color: #2FDF66;
          text-decoration: none; }
      .navigation .level0.active > .level-top, .navigation .level0.has-active > .level-top {
        background: inherit;
        border-color: #ff5501;
        border-style: solid;
        border-width: 0px;
        color: #2FDF66;
        text-decoration: none;
        display: inline-block; }
      .navigation .level0.parent:hover > .submenu {
        overflow: visible !important; }
      .navigation .level0 .submenu {
        background: #fff;
        border: 1px solid #d1d1d1;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.19);
        font-size: inherit;
        font-weight: 700;
        min-width: 230px;
        padding: 15px 0;
        display: none;
        left: 0;
        margin: 0 !important;
        padding: 0;
        position: absolute;
        z-index: 1;
        margin-top: 11px; }
        .navigation .level0 .submenu > ul {
          margin-top: 11px; }
          .navigation .level0 .submenu > ul:before, .navigation .level0 .submenu > ul:after {
            content: '';
            display: block;
            overflow: hidden;
            position: absolute; }
          .navigation .level0 .submenu > ul:before {
            color: #fff;
            left: 20px;
            top: -20px;
            border: 10px solid transparent;
            height: 0;
            width: 0;
            border-bottom-color: #fff;
            z-index: 4; }
          .navigation .level0 .submenu > ul:after {
            border: 11px solid transparent;
            height: 0;
            width: 0;
            border-bottom-color: #d1d1d1;
            color: #d1d1d1;
            left: 19px;
            top: -22px;
            z-index: 3; }
        .navigation .level0 .submenu a {
          display: block;
          line-height: inherit;
          background: inherit;
          border: none;
          color: #2FDF66;
          padding: 8px 20px;
          text-decoration: inherit; }
          .navigation .level0 .submenu a:hover, .navigation .level0 .submenu a.ui-state-focus {
            background: #e8e8e8;
            border: none;
            color: #2FDF66;
            text-decoration: none; }
        .navigation .level0 .submenu .active > a {
          background: inherit;
          border-color: #ff5501;
          border-style: solid;
          border-width: 0 0 0 3px;
          color: inherit;
          text-decoration: inherit; }
        .navigation .level0 .submenu .submenu {
          top: 0 !important;
          left: 100% !important; }
        .navigation .level0 .submenu .submenu-reverse {
          left: auto !important;
          right: 100%; }
      .navigation .level0.more {
        position: relative;
        display: inline-block;
        text-decoration: none; }
        .navigation .level0.more:after {
          -webkit-font-smoothing: antialiased;
          font-size: 26px;
          line-height: inherit;
          color: inherit;
          content: "";
          font-family: "icons-blank-theme";
          margin: inherit;
          display: inline-block;
          font-weight: normal;
          overflow: hidden;
          speak: none;
          text-align: center;
          vertical-align: middle; }
        .navigation .level0.more:hover:after {
          color: inherit; }
        .navigation .level0.more:active:after {
          color: inherit; }
        .navigation .level0.more:before {
          display: none; }
        .navigation .level0.more:after {
          cursor: pointer;
          padding: 8px 12px;
          position: relative;
          z-index: 1; }
        .navigation .level0.more:hover > .submenu {
          overflow: visible !important; }
        .navigation .level0.more li {
          display: block; }
  .navigation .submenu a {
    border-color: #2FDF66 !important;
    line-height: inherit;
    color: #014A5B !important;
    font-size: 14px; }
    .navigation .submenu a:hover {
      color: #3f3f3f !important; }
  .navigation ul {
    font-weight: 600; }
  .navigation .level0 .level-top {
    padding-left: 0;
    margin-left: -5px;
    margin-right: 20px;
    padding: 0 9px; }
  .panel.header .links,
  .panel.header .switcher {
    display: inline-flex; }
  .panel.header .switcher {
    float: right;
    margin-right: 0; }
    .panel.header .switcher .view-default span {
      display: none; }
    .panel.header .switcher .view-storeview_nl span {
      display: none; }
    .panel.header .switcher .view-storeview_nl:before {
      display: inline-block;
      width: 30px;
      height: 20px; }
    .panel.header .switcher .view-storeview_at span {
      display: none; }
    .panel.header .switcher .view-storeview_at:before {
      display: inline-block;
      width: 30px;
      height: 20px; }
    .panel.header .switcher .view-storeview_ch span {
      display: none; }
    .panel.header .switcher .view-storeview_ch:before {
      display: inline-block;
      width: 30px;
      height: 20px; }
    .panel.header .switcher ul.dropdown .switcher-option a {
      display: inline-block; }
    .panel.header .switcher ul.dropdown .switcher-option:before {
      position: relative;
      top: 6px;
      margin-left: 5px;
      left: 3px; } }

@media only screen and (min-width: 1400px) {
  .navigation .level0 .level-top {
    font-size: 16px; } }

@media only screen and (max-width: 1400px) and (min-width: 1310px) {
  .navigation .level0 .level-top {
    font-size: 15px;
    margin-right: 12px; } }

@media only screen and (max-width: 1440px) {
  .section-item-content .switcher-language {
    display: none; }
  .section-item-content > .switcher-language {
    display: block; }
  .navigation {
    font-size: 14px; }
    .navigation li.level0 {
      border-top-color: #005266; }
      .navigation li.level0 > a {
        color: #111111; }
      .navigation li.level0:last-child {
        border-bottom-color: #005266; }
      .navigation li.level0 .all-category {
        font-weight: 600; }
      .navigation li.level0.active > a:not(.ui-state-active),
      .navigation li.level0.active .all-category .ui-state-focus, .navigation li.level0.has-active > a:not(.ui-state-active),
      .navigation li.level0.has-active .all-category .ui-state-focus {
        border-color: #005266; } }

@media only screen and (max-width: 1260px) {
  .navigation {
    font-size: 13px; }
    .navigation .level0 > .level-top {
      margin-right: 0; } }

@media only screen and (max-width: 1040px) {
  .navigation > .level-top {
    font-weight: 600;
    color: #111111;
    padding: 0.8rem 20px;
    margin-right: 10px; } }

@media only screen and (max-width: 480px) {
  .nav-sections-item-title {
    font-size: 13px; } }

.nav-sections .account-section {
  padding: 0 15px; }
  .nav-sections .account-section .account-links-wrapper {
    list-style: none;
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    padding: 0;
    margin-bottom: 0; }
    .nav-sections .account-section .account-links-wrapper li {
      border-bottom: 1px solid #f7f7f7;
      font-size: 16px;
      font-weight: 700;
      line-height: 40px;
      padding: 8px 40px 8px 10px;
      margin: 0; }
    .nav-sections .account-section .account-links-wrapper a {
      color: #000; }
    .nav-sections .account-section .account-links-wrapper .authorization-link:before {
      display: none; }

.nav-sections .quote-section {
  padding: 0 15px; }
  .nav-sections .quote-section .my-quote-link-wrapper {
    list-style: none;
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    padding: 0;
    margin: 0; }
    .nav-sections .quote-section .my-quote-link-wrapper li {
      font-size: 16px;
      font-weight: 700;
      line-height: 40px;
      padding: 8px 40px 8px 10px;
      margin-top: 0; }
    .nav-sections .quote-section .my-quote-link-wrapper a {
      color: #000; }

.nav-sections .nav-sections-items .section-item-content .store-switch-section {
  background: #f7f7f7; }
  .nav-sections .nav-sections-items .section-item-content .store-switch-section .switcher .view-current-store-switch:before,
  .nav-sections .nav-sections-items .section-item-content .store-switch-section .switcher .switcher-option:before {
    content: "";
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 30px;
    height: 20px;
    vertical-align: middle; }
  .nav-sections .nav-sections-items .section-item-content .store-switch-section .switcher .view-default:before {
    background-image: url("../images/flags/de.svg"); }
  .nav-sections .nav-sections-items .section-item-content .store-switch-section .switcher .view-storeview_at:before {
    background-image: url("../images/flags/at.svg"); }
  .nav-sections .nav-sections-items .section-item-content .store-switch-section .switcher .view-storeview_be:before {
    background-image: url("../images/flags/be.svg"); }
  .nav-sections .nav-sections-items .section-item-content .store-switch-section .switcher .view-storeview_ch:before {
    background-image: url("../images/flags/ch.svg"); }
  .nav-sections .nav-sections-items .section-item-content .store-switch-section .switcher .view-storeview_dk:before {
    background-image: url("../images/flags/dk.svg"); }
  .nav-sections .nav-sections-items .section-item-content .store-switch-section .switcher .view-storeview_fr:before {
    background-image: url("../images/flags/fr.svg"); }
  .nav-sections .nav-sections-items .section-item-content .store-switch-section .switcher .view-storeview_gb:before {
    background-image: url("../images/flags/gb.svg"); }
  .nav-sections .nav-sections-items .section-item-content .store-switch-section .switcher .view-storeview_nl:before {
    background-image: url("../images/flags/nl.svg"); }
  .nav-sections .nav-sections-items .section-item-content .store-switch-section .switcher .view-storeview_se:before {
    background-image: url("../images/flags/se.svg"); }
  .nav-sections .nav-sections-items .section-item-content .store-switch-section .language-switch-mobile {
    padding: 0 15px; }
    .nav-sections .nav-sections-items .section-item-content .store-switch-section .language-switch-mobile .mobile-hint {
      font-weight: 700;
      padding: 8px 40px 8px 10px; }
    .nav-sections .nav-sections-items .section-item-content .store-switch-section .language-switch-mobile .switcher-language {
      display: block;
      border-top: 1px solid #f7f7f7;
      padding: 0; }
      .nav-sections .nav-sections-items .section-item-content .store-switch-section .language-switch-mobile .switcher-language .switcher-options {
        border: none;
        padding: 0; }
        .nav-sections .nav-sections-items .section-item-content .store-switch-section .language-switch-mobile .switcher-language .switcher-options .switcher-trigger {
          padding: 8px 40px 8px 10px;
          position: relative; }
          .nav-sections .nav-sections-items .section-item-content .store-switch-section .language-switch-mobile .switcher-language .switcher-options .switcher-trigger .view-current-store-switch {
            color: #000;
            font-size: 16px;
            font-weight: 700;
            line-height: 40px;
            padding: 0;
            position: static; }
          .nav-sections .nav-sections-items .section-item-content .store-switch-section .language-switch-mobile .switcher-language .switcher-options .switcher-trigger strong {
            display: block;
            text-decoration: none; }
            .nav-sections .nav-sections-items .section-item-content .store-switch-section .language-switch-mobile .switcher-language .switcher-options .switcher-trigger strong:after {
              -webkit-font-smoothing: antialiased;
              font-size: 42px;
              line-height: inherit;
              color: inherit;
              content: "";
              font-family: "icons-blank-theme";
              margin: inherit;
              display: inline-block;
              font-weight: normal;
              overflow: hidden;
              speak: none;
              text-align: center;
              vertical-align: middle; }
            .nav-sections .nav-sections-items .section-item-content .store-switch-section .language-switch-mobile .switcher-language .switcher-options .switcher-trigger strong:hover:after {
              color: inherit; }
            .nav-sections .nav-sections-items .section-item-content .store-switch-section .language-switch-mobile .switcher-language .switcher-options .switcher-trigger strong:active:after {
              color: inherit; }
            .nav-sections .nav-sections-items .section-item-content .store-switch-section .language-switch-mobile .switcher-language .switcher-options .switcher-trigger strong:after {
              position: absolute;
              right: 0;
              width: 40px;
              height: 40px;
              line-height: 40px;
              top: 8px; }
          .nav-sections .nav-sections-items .section-item-content .store-switch-section .language-switch-mobile .switcher-language .switcher-options .switcher-trigger.active strong:after {
            content: ""; }
        .nav-sections .nav-sections-items .section-item-content .store-switch-section .language-switch-mobile .switcher-language .switcher-options .switcher-option {
          border: none;
          padding: 0 10px; }
        .nav-sections .nav-sections-items .section-item-content .store-switch-section .language-switch-mobile .switcher-language .switcher-options a {
          display: inline-block;
          font-size: 16px;
          padding-left: 10px;
          line-height: 40px;
          vertical-align: middle; }
          .nav-sections .nav-sections-items .section-item-content .store-switch-section .language-switch-mobile .switcher-language .switcher-options a:before {
            display: none; }

@media (min-width: 1040px) {
  .account-section,
  .quote-section,
  .store-switch-section {
    display: none; } }

.tooltip.wrapper {
  position: relative; }
  .tooltip.wrapper .tooltip.content {
    background: #fff;
    border-radius: inherit;
    margin: inherit;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: #2FDF66;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: 1.4;
    font-size: inherit;
    border: 1px solid #bbb;
    margin-top: 5px;
    left: 0;
    top: 100%; }
    .tooltip.wrapper .tooltip.content:after, .tooltip.wrapper .tooltip.content:before {
      border: solid transparent;
      content: '';
      height: 0;
      position: absolute;
      width: 0; }
    .tooltip.wrapper .tooltip.content:after {
      border-width: 5px;
      border-color: transparent; }
    .tooltip.wrapper .tooltip.content:before {
      border-width: 6px;
      border-color: transparent; }
    .tooltip.wrapper .tooltip.content:after, .tooltip.wrapper .tooltip.content:before {
      bottom: 100%; }
    .tooltip.wrapper .tooltip.content:after {
      border-bottom-color: #fff;
      left: 15px;
      margin-left: -5px; }
    .tooltip.wrapper .tooltip.content:before {
      border-bottom-color: #bbb;
      left: 15px;
      margin-left: -6px; }
  .tooltip.wrapper .tooltip.toggle {
    cursor: help; }
  .tooltip.wrapper .tooltip.toggle:hover + .tooltip.content,
  .tooltip.wrapper .tooltip.toggle:focus + .tooltip.content,
  .tooltip.wrapper:hover .tooltip.content {
    display: block; }
  .tooltip.wrapper .tooltip.content dl {
    margin-bottom: 0; }
  .tooltip.wrapper .tooltip.content dd {
    white-space: normal; }
  .tooltip.wrapper .tooltip.content .subtitle {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px; }
  .tooltip.wrapper .tooltip.content .label {
    margin-top: 10px; }
    .tooltip.wrapper .tooltip.content .label:first-child {
      margin-top: 0; }
  .tooltip.wrapper .tooltip.content .values {
    margin: 0; }

.ui-tooltip {
  position: absolute;
  z-index: 9999; }

@media only screen and (max-width: 480px) {
  .tooltip.wrapper .tooltip.content {
    min-width: 10rem; } }

.load.indicator {
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  position: absolute; }
  .load.indicator:before {
    background: transparent url("../images/loader-2.gif") no-repeat 50% 50%;
    border-radius: 5px;
    height: 160px;
    padding: inherit;
    width: 160px;
    bottom: 0;
    box-sizing: border-box;
    content: '';
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0; }
  .load.indicator > span {
    display: none; }

.loading-mask {
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5); }
  .loading-mask .loader > img {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100; }
  .loading-mask .loader > p {
    display: none; }

body > .loading-mask {
  z-index: 9999; }

._block-content-loading {
  position: relative; }

input[type="text"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="url"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="datetime"]::-moz-placeholder,
input[type="email"]::-moz-placeholder {
  color: #fff;
  opacity: 1; }

div.mage-error[generated] {
  color: #e02b27;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-size: 12px;
  display: block;
  text-decoration: none; }

.fieldset {
  border: 0;
  margin: 0 0 40px;
  padding: 0;
  letter-spacing: -0.31em; }
  .fieldset > * {
    letter-spacing: normal; }
  .fieldset > .legend {
    margin: 0 0 25px;
    padding: 0;
    box-sizing: border-box;
    float: left;
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: 1.2;
    font-size: 20px; }
    .fieldset > .legend + br {
      clear: both;
      display: block;
      height: 0;
      overflow: hidden;
      visibility: hidden; }
  .fieldset:last-child {
    margin-bottom: 0; }
  .fieldset > .field,
  .fieldset > .fields > .field {
    border: none;
    margin: 0 0 20px; }
    .fieldset > .field > .label,
    .fieldset > .fields > .field > .label {
      margin: 0 0 5px;
      padding: inherit;
      text-align: inherit;
      display: inline-block; }
    .fieldset > .field:last-child,
    .fieldset > .fields > .field:last-child {
      margin-bottom: 0; }
    .fieldset > .field > .label,
    .fieldset > .fields > .field > .label {
      color: inherit;
      font-family: inherit;
      font-style: inherit;
      font-weight: 700;
      line-height: inherit;
      font-size: inherit; }
      .fieldset > .field > .label + br,
      .fieldset > .fields > .field > .label + br {
        display: none; }
    .fieldset > .field .choice input,
    .fieldset > .fields > .field .choice input {
      vertical-align: top; }
    .fieldset > .field .fields.group:before, .fieldset > .field .fields.group:after,
    .fieldset > .fields > .field .fields.group:before,
    .fieldset > .fields > .field .fields.group:after {
      content: '';
      display: table; }
    .fieldset > .field .fields.group:after,
    .fieldset > .fields > .field .fields.group:after {
      clear: both; }
    .fieldset > .field .fields.group .field,
    .fieldset > .fields > .field .fields.group .field {
      box-sizing: border-box;
      float: left; }
    .fieldset > .field .fields.group.group-2 .field,
    .fieldset > .fields > .field .fields.group.group-2 .field {
      width: 50% !important; }
    .fieldset > .field .fields.group.group-3 .field,
    .fieldset > .fields > .field .fields.group.group-3 .field {
      width: 33.3% !important; }
    .fieldset > .field .fields.group.group-4 .field,
    .fieldset > .fields > .field .fields.group.group-4 .field {
      width: 25% !important; }
    .fieldset > .field .fields.group.group-5 .field,
    .fieldset > .fields > .field .fields.group.group-5 .field {
      width: 20% !important; }
    .fieldset > .field .addon,
    .fieldset > .fields > .field .addon {
      display: inline-flex;
      flex-wrap: nowrap;
      padding: 0;
      width: 100%; }
      .fieldset > .field .addon textarea,
      .fieldset > .field .addon select,
      .fieldset > .field .addon input,
      .fieldset > .fields > .field .addon textarea,
      .fieldset > .fields > .field .addon select,
      .fieldset > .fields > .field .addon input {
        order: 2;
        flex-basis: 100%;
        display: inline-block;
        margin: 0;
        width: auto; }
      .fieldset > .field .addon .addbefore,
      .fieldset > .field .addon .addafter,
      .fieldset > .fields > .field .addon .addbefore,
      .fieldset > .fields > .field .addon .addafter {
        background: #fff;
        background-clip: padding-box;
        border: 1px solid #c2c2c2;
        border-radius: 1px;
        color: inherit;
        font-family: "Lexend", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-style: inherit;
        font-weight: inherit;
        height: 32px;
        line-height: 1.42857;
        margin: 0;
        padding: 0 9px;
        vertical-align: baseline;
        width: 100%;
        box-sizing: border-box;
        order: 3;
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;
        width: auto; }
        .fieldset > .field .addon .addbefore:disabled,
        .fieldset > .field .addon .addafter:disabled,
        .fieldset > .fields > .field .addon .addbefore:disabled,
        .fieldset > .fields > .field .addon .addafter:disabled {
          opacity: 0.5; }
        .fieldset > .field .addon .addbefore::-moz-placeholder,
        .fieldset > .field .addon .addafter::-moz-placeholder,
        .fieldset > .fields > .field .addon .addbefore::-moz-placeholder,
        .fieldset > .fields > .field .addon .addafter::-moz-placeholder {
          color: #c2c2c2; }
        .fieldset > .field .addon .addbefore::-webkit-input-placeholder,
        .fieldset > .field .addon .addafter::-webkit-input-placeholder,
        .fieldset > .fields > .field .addon .addbefore::-webkit-input-placeholder,
        .fieldset > .fields > .field .addon .addafter::-webkit-input-placeholder {
          color: #c2c2c2; }
        .fieldset > .field .addon .addbefore:-ms-input-placeholder,
        .fieldset > .field .addon .addafter:-ms-input-placeholder,
        .fieldset > .fields > .field .addon .addbefore:-ms-input-placeholder,
        .fieldset > .fields > .field .addon .addafter:-ms-input-placeholder {
          color: #c2c2c2; }
      .fieldset > .field .addon .addbefore,
      .fieldset > .fields > .field .addon .addbefore {
        float: left;
        order: 1; }
    .fieldset > .field .additional,
    .fieldset > .fields > .field .additional {
      margin-top: 10px; }
    .fieldset > .field.required > .label:after,
    .fieldset > .fields > .field.required > .label:after {
      content: '*';
      color: #e02b27;
      font-family: inherit;
      font-style: inherit;
      font-weight: inherit;
      line-height: inherit;
      font-size: 12px;
      margin: 0 0 0 5px; }
    .fieldset > .field .note,
    .fieldset > .fields > .field .note {
      color: inherit;
      font-family: inherit;
      font-style: inherit;
      font-weight: inherit;
      line-height: inherit;
      font-size: 12px;
      margin: 3px 0 0;
      padding: 0;
      display: inline-block;
      text-decoration: none; }
      .fieldset > .field .note:before,
      .fieldset > .fields > .field .note:before {
        -webkit-font-smoothing: antialiased;
        font-size: 24px;
        line-height: 12px;
        color: inherit;
        content: "";
        font-family: "icons-blank-theme";
        margin: inherit;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
        vertical-align: middle; }
      .fieldset > .field .note:hover:before,
      .fieldset > .fields > .field .note:hover:before {
        color: inherit; }
      .fieldset > .field .note:active:before,
      .fieldset > .fields > .field .note:active:before {
        color: inherit; }
    .fieldset > .field.choice .label,
    .fieldset > .fields > .field.choice .label {
      display: inline;
      font-weight: normal; }
    .column:not(.main) .fieldset > .field .label, .column:not(.main)
    .fieldset > .fields > .field .label {
      font-weight: normal; }
    .fieldset > .field .field.choice,
    .fieldset > .fields > .field .field.choice {
      margin-bottom: 10px; }
      .fieldset > .field .field.choice:last-child,
      .fieldset > .fields > .field .field.choice:last-child {
        margin-bottom: 0; }
    .fieldset > .field input[type=file],
    .fieldset > .fields > .field input[type=file] {
      margin: 5px 0; }

.legend + .fieldset,
.legend + div {
  clear: both; }

.legend strong {
  margin-left: 5px; }

fieldset.field {
  border: 0;
  padding: 0; }

.field.date .time-picker {
  display: inline-block;
  margin-top: 10px;
  white-space: nowrap; }

.field .message.warning {
  margin-top: 10px; }

div.mage-error[generated] {
  margin-top: 7px; }

.field .tooltip {
  position: relative; }
  .field .tooltip .tooltip-content {
    background: #fff;
    border-radius: inherit;
    margin: inherit;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: #2FDF66;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: 1.4;
    font-size: inherit;
    border: 1px solid #bbb;
    margin-left: 5px;
    left: 100%;
    top: 0; }
    .field .tooltip .tooltip-content:after, .field .tooltip .tooltip-content:before {
      border: solid transparent;
      content: '';
      height: 0;
      position: absolute;
      width: 0; }
    .field .tooltip .tooltip-content:after {
      border-width: 5px;
      border-color: transparent; }
    .field .tooltip .tooltip-content:before {
      border-width: 6px;
      border-color: transparent; }
    .field .tooltip .tooltip-content:after, .field .tooltip .tooltip-content:before {
      right: 100%; }
    .field .tooltip .tooltip-content:after {
      border-right-color: #fff;
      margin-top: -5px;
      top: 15px; }
    .field .tooltip .tooltip-content:before {
      border-right-color: #bbb;
      margin-top: -6px;
      top: 15px; }
  .field .tooltip .tooltip-toggle {
    cursor: help; }
  .field .tooltip .tooltip-toggle:hover + .tooltip-content,
  .field .tooltip .tooltip-toggle:focus + .tooltip-content,
  .field .tooltip:hover .tooltip-content {
    display: block; }
  .field .tooltip .tooltip-content {
    min-width: 200px;
    white-space: normal; }

input:focus ~ .tooltip .tooltip-content,
select:focus ~ .tooltip .tooltip-content {
  display: block; }

._has-datepicker ~ .ui-datepicker-trigger {
  background-image: none;
  background: none;
  box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  display: inline-block;
  text-decoration: none;
  vertical-align: middle; }
  ._has-datepicker ~ .ui-datepicker-trigger:focus, ._has-datepicker ~ .ui-datepicker-trigger:active {
    background: none;
    border: none; }
  ._has-datepicker ~ .ui-datepicker-trigger:hover {
    background: none;
    border: none; }
  ._has-datepicker ~ .ui-datepicker-trigger.disabled, ._has-datepicker ~ .ui-datepicker-trigger[disabled],
  fieldset[disabled] ._has-datepicker ~ .ui-datepicker-trigger {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  ._has-datepicker ~ .ui-datepicker-trigger > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  ._has-datepicker ~ .ui-datepicker-trigger:before {
    -webkit-font-smoothing: antialiased;
    font-size: 40px;
    line-height: 40px;
    color: #7d7d7d;
    content: "";
    font-family: "icons-blank-theme";
    margin: inherit;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: middle; }
  ._has-datepicker ~ .ui-datepicker-trigger:hover:before {
    color: inherit; }
  ._has-datepicker ~ .ui-datepicker-trigger:active:before {
    color: inherit; }
  ._has-datepicker ~ .ui-datepicker-trigger:focus {
    box-shadow: none;
    outline: 0; }

.sidebar .fieldset {
  margin: 0; }
  .sidebar .fieldset > .field:not(.choice):not(:last-child),
  .sidebar .fieldset .fields > .field:not(:last-child) {
    margin: 0 0 20px; }
  .sidebar .fieldset > .field:not(.choice) .label,
  .sidebar .fieldset .fields > .field .label {
    margin: 0 0 4px;
    padding: 0 0 5px;
    text-align: left;
    width: 100%; }
  .sidebar .fieldset > .field:not(.choice) .control,
  .sidebar .fieldset .fields > .field .control {
    width: 100%; }

@media only screen and (min-width: 768px) {
  .fieldset > .field {
    margin: 0 0 20px;
    box-sizing: border-box;
    margin: 0 0 29px; }
    .fieldset > .field > .label {
      margin: 0; }
    .fieldset > .field:before, .fieldset > .field:after {
      content: '';
      display: table; }
    .fieldset > .field:after {
      clear: both; }
    .fieldset > .field.choice:before, .fieldset > .field.no-label:before {
      padding: 6px 15px 0 0;
      width: 25.8%;
      box-sizing: border-box;
      content: ' ';
      float: left;
      height: 1px; }
    .fieldset > .field .description {
      padding: 6px 15px 0 0;
      text-align: right;
      width: 25.8%;
      box-sizing: border-box;
      float: left; }
    .fieldset > .field:not(.choice) > .label {
      padding: 6px 15px 0 0;
      text-align: right;
      width: 25.8%;
      box-sizing: border-box;
      float: left; }
    .fieldset > .field:not(.choice) > .control {
      width: 74.2%;
      float: left; } }

.fieldset > .field:not(.choice) > .label {
  text-align: left; }

@font-face {
  font-family: "icons-blank-theme";
  src: url("../fonts/Blank-Theme-Icons/Blank-Theme-Icons.eot");
  src: url("../fonts/Blank-Theme-Icons/Blank-Theme-Icons.eot?#iefix") format("embedded-opentype"), url("../fonts/Blank-Theme-Icons/Blank-Theme-Icons.woff2") format("woff2"), url("../fonts/Blank-Theme-Icons/Blank-Theme-Icons.woff") format("woff"), url("../fonts/Blank-Theme-Icons/Blank-Theme-Icons.ttf") format("truetype"), url("../fonts/Blank-Theme-Icons/Blank-Theme-Icons.svg#icons-blank-theme") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

button:active, .cart.table-wrapper .actions-toolbar > .action:active {
  box-shadow: none; }

a.action.primary {
  display: inline-block;
  text-decoration: none; }
  a.action.primary:hover, a.action.primary:active, a.action.primary:focus {
    text-decoration: none; }

.action.primary {
  background-image: none;
  background: #1979c3;
  border: 1px solid #1979c3;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Lexend", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 7px 15px;
  width: auto;
  font-size: 14px;
  line-height: 16px;
  box-sizing: border-box;
  vertical-align: middle; }
  .action.primary:focus, .action.primary:active {
    background: #006bb4;
    border: 1px solid #1979c3;
    color: #fff; }
  .action.primary:hover {
    background: #006bb4;
    border: 1px solid #1979c3;
    color: #fff; }
  .action.primary.disabled, .action.primary[disabled],
  fieldset[disabled] .action.primary {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
  .action.primary.out-of-stock-notify {
    width: 100%;
    line-height: 46px;
    background: #014A5B;
    border: 2px solid #e8e8e8;
    padding: 0 33px; }
    .action.primary.out-of-stock-notify:hover {
      background: transparent;
      color: #e8e8e8; }

#btn-minicart-close {
  color: #20272B; }

/**/
.checkout-index-index.page-layout-checkout .action.action-apply {
  background-color: #2fdf66 !important;
  height: 50px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  color: white !important;
  border: 0px;
  margin-right: 0px !important; }

.action.primary.checkout {
  background-image: none;
  background: #ED8400;
  border: 0px solid #ED8400;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 7px 15px;
  width: auto;
  font-size: 14px;
  line-height: 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px; }
  .action.primary.checkout:hover {
    background: transparent;
    color: white;
    background: #2FDF66; }

.action.primary {
  line-height: 46px;
  padding: 0 33px;
  border: 0px solid;
  margin-bottom: 30px !important;
  border: 0px solid #014A5B;
  background: #2FDF66;
  color: white; }
  .action.primary:hover {
    border: 0px solid #2FDF66;
    background: #014A5B;
    color: white; }

.cart-container .form-cart .action.continue {
  display: inline-block;
  text-decoration: none;
  background: #014A5B;
  border: 0px;
  margin-bottom: 20px;
  border-radius: 0px;
  color: white;
  cursor: pointer;
  font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin: 0;
  padding: 16px 24px 0px 0;
  width: auto;
  font-size: 14px;
  line-height: 16px;
  vertical-align: middle;
  height: 48px;
  margin-bottom: 20px; }
  .cart-container .form-cart .action.continue:hover {
    background: #2FDF66;
    color: white;
    border: 0px; }

#empty_cart_button {
  padding-left: 20px; }

.action.clear,
.action.update {
  border: 0px;
  border-radius: 0px;
  color: black;
  cursor: pointer;
  font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin: 0;
  padding: 0px 24px 0px 0;
  width: auto;
  font-size: 14px;
  line-height: 16px;
  vertical-align: middle;
  height: 48px; }
  .action.clear:hover,
  .action.update:hover {
    color: black;
    border: 0px; }

.action.remove {
  vertical-align: middle; }

.product.data.items {
  margin: 0;
  padding: 0;
  margin-bottom: 20px; }
  .product.data.items > .item.title {
    box-sizing: border-box;
    float: none;
    width: 100%; }
    .product.data.items > .item.title > .switch {
      display: block; }
  .product.data.items > .item.content {
    box-sizing: border-box;
    display: block;
    float: none;
    margin: 0; }
    .product.data.items > .item.content:before, .product.data.items > .item.content:after {
      content: '';
      display: table; }
    .product.data.items > .item.content:after {
      clear: both; }
    .product.data.items > .item.content.active {
      display: block; }
  .product.data.items > .item.title > .switch {
    background: #f0f0f0;
    border-bottom: 1px solid #d1d1d1;
    border-left: 1px solid #d1d1d1;
    border-right: 1px solid #d1d1d1;
    border-top: 1px solid #d1d1d1;
    height: 40px;
    padding: 5px 20px 5px 20px;
    color: #7d7d7d;
    font-family: inherit;
    font-style: inherit;
    font-weight: 600;
    line-height: 40px;
    font-size: 18px;
    color: #7d7d7d;
    text-decoration: none; }
    .product.data.items > .item.title > .switch:visited {
      color: #7d7d7d;
      text-decoration: none; }
    .product.data.items > .item.title > .switch:hover {
      color: #7d7d7d;
      text-decoration: none; }
    .product.data.items > .item.title > .switch:active {
      color: #333;
      text-decoration: none; }
  .product.data.items > .item.title:not(.disabled) > .switch:focus,
  .product.data.items > .item.title:not(.disabled) > .switch:hover {
    background: #fcfcfc; }
  .product.data.items > .item.title:not(.disabled) > .switch:active,
  .product.data.items > .item.title.active > .switch,
  .product.data.items > .item.title.active > .switch:focus,
  .product.data.items > .item.title.active > .switch:hover {
    background: white;
    padding-bottom: 5px; }
  .product.data.items > .item.content {
    background: white;
    border: 1px solid #d1d1d1;
    margin: 0 0 5px;
    padding: 20px 20px 20px 20px; }

@media only screen and (min-width: 768px) {
  .product.data.items {
    position: relative;
    z-index: 1; }
    .product.data.items:before, .product.data.items:after {
      content: '';
      display: table; }
    .product.data.items:after {
      clear: both; }
    .product.data.items > .item.title {
      float: left;
      width: auto; }
      .product.data.items > .item.title > .switch {
        height: 20px;
        display: block;
        position: relative;
        z-index: 2; }
    .product.data.items > .item.content {
      margin-top: 20px;
      box-sizing: border-box;
      float: right;
      margin-left: -100%;
      width: 100%; }
      .product.data.items > .item.content:before, .product.data.items > .item.content:after {
        content: '';
        display: table; }
      .product.data.items > .item.content:after {
        clear: both; }
      .product.data.items > .item.content.active {
        display: block; }
    .product.data.items > .item.title {
      margin: 0 5px 0 0; }
      .product.data.items > .item.title > .switch {
        color: #7d7d7d;
        font-family: inherit;
        font-style: inherit;
        font-weight: 600;
        line-height: 20px;
        font-size: 14px;
        color: #7d7d7d;
        text-decoration: none;
        background: #f0f0f0;
        border: 1px solid #d1d1d1;
        border-bottom: none;
        height: 20px;
        padding: 5px 20px 5px 20px; }
        .product.data.items > .item.title > .switch:visited {
          color: #7d7d7d;
          text-decoration: none; }
        .product.data.items > .item.title > .switch:hover {
          color: #7d7d7d;
          text-decoration: none; }
        .product.data.items > .item.title > .switch:active {
          color: #333;
          text-decoration: none; }
      .product.data.items > .item.title:not(.disabled) > .switch:focus,
      .product.data.items > .item.title:not(.disabled) > .switch:hover {
        background: #fcfcfc; }
      .product.data.items > .item.title:not(.disabled) > .switch:active,
      .product.data.items > .item.title.active > .switch,
      .product.data.items > .item.title.active > .switch:focus,
      .product.data.items > .item.title.active > .switch:hover {
        background: white;
        color: #333; }
      .product.data.items > .item.title.active > .switch,
      .product.data.items > .item.title.active > .switch:focus,
      .product.data.items > .item.title.active > .switch:hover {
        padding-bottom: 6px; }
    .product.data.items > .item.content {
      background: white;
      margin-top: 31px;
      padding: 20px 20px 20px 20px; } }

@media only screen and (max-width: 768px) {
  .product.data.items {
    margin: 0;
    padding: 0; }
    .product.data.items > .item.title {
      box-sizing: border-box;
      float: none;
      width: 100%; }
      .product.data.items > .item.title > .switch {
        display: block; }
    .product.data.items > .item.content {
      box-sizing: border-box;
      display: block;
      float: none;
      margin: 0; }
      .product.data.items > .item.content:before, .product.data.items > .item.content:after {
        content: '';
        display: table; }
      .product.data.items > .item.content:after {
        clear: both; }
      .product.data.items > .item.content.active {
        display: block; }
    .product.data.items > .item.title > .switch {
      background: #f0f0f0;
      border-bottom: 1px solid #d1d1d1;
      border-left: 1px solid #d1d1d1;
      border-right: 1px solid #d1d1d1;
      border-top: 1px solid #d1d1d1;
      height: 40px;
      padding: 5px 20px 5px 20px;
      color: #7d7d7d;
      font-family: inherit;
      font-style: inherit;
      font-weight: 600;
      line-height: 40px;
      font-size: 18px;
      color: #7d7d7d;
      text-decoration: none; }
      .product.data.items > .item.title > .switch:visited {
        color: #7d7d7d;
        text-decoration: none; }
      .product.data.items > .item.title > .switch:hover {
        color: #7d7d7d;
        text-decoration: none; }
      .product.data.items > .item.title > .switch:active {
        color: #333;
        text-decoration: none; }
    .product.data.items > .item.title:not(.disabled) > .switch:focus,
    .product.data.items > .item.title:not(.disabled) > .switch:hover {
      background: #fcfcfc; }
    .product.data.items > .item.title:not(.disabled) > .switch:active,
    .product.data.items > .item.title.active > .switch,
    .product.data.items > .item.title.active > .switch:focus,
    .product.data.items > .item.title.active > .switch:hover {
      background: white;
      padding-bottom: 5px; }
    .product.data.items > .item.content {
      background: white;
      border: 1px solid #d1d1d1;
      margin: 0 0 5px;
      padding: 20px 20px 20px 20px; }
    .product.data.items .data.item {
      display: block; } }

.pages > .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.pages .items {
  font-size: 0;
  letter-spacing: -1px;
  line-height: 0;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  list-style: none none;
  display: inline-block;
  font-weight: 700; }

.pages .item {
  font-size: 12px;
  font-size: 12px;
  letter-spacing: normal;
  line-height: 32px;
  margin: 0 2px 0 0;
  display: inline-block; }
  .pages .item .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }

.pages a.page {
  background: inherit;
  border: none;
  color: #1979c3;
  display: inline-block;
  padding: 0 4px;
  text-decoration: none; }
  .pages a.page:visited {
    background: inherit;
    border: none;
    color: #1979c3; }
  .pages a.page:hover {
    background: inherit;
    border: none;
    color: #006bb4;
    text-decoration: none; }
  .pages a.page:active {
    background: inherit;
    border: none;
    color: #ff5501; }

.pages strong.page {
  background: inherit;
  border: none;
  font-size: 12px;
  font-size: 12px;
  letter-spacing: normal;
  line-height: 32px;
  color: #2FDF66;
  display: inline-block;
  font-weight: 700;
  padding: 0 4px; }

.pages .action {
  background: inherit;
  border: 1px solid #d1d1d1;
  color: #7d7d7d;
  display: inline-block;
  padding: 0;
  text-decoration: none; }
  .pages .action:visited {
    background: inherit;
    border: none;
    color: #7d7d7d; }
  .pages .action:hover {
    background: inherit;
    border: 1px solid #d1d1d1;
    color: #7d7d7d;
    text-decoration: none; }
  .pages .action:active {
    background: inherit;
    border: none;
    color: #7d7d7d; }
  .pages .action.next {
    display: inline-block;
    text-decoration: none; }
    .pages .action.next:visited:before {
      color: #7d7d7d; }
    .pages .action.next:active:before {
      color: #7d7d7d; }
    .pages .action.next > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .pages .action.next:before {
      -webkit-font-smoothing: antialiased;
      font-size: 46px;
      line-height: inherit;
      color: #7d7d7d;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0 0 0 -6px;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: top; }
    .pages .action.next:hover:before {
      color: #7d7d7d; }
    .pages .action.next:active:before {
      color: #7d7d7d; }
  .pages .action.previous {
    display: inline-block;
    text-decoration: none; }
    .pages .action.previous:visited:before {
      color: #7d7d7d; }
    .pages .action.previous:active:before {
      color: #7d7d7d; }
    .pages .action.previous > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .pages .action.previous:before {
      -webkit-font-smoothing: antialiased;
      font-size: 46px;
      line-height: inherit;
      color: #7d7d7d;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0 0 0 -6px;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: top; }
    .pages .action.previous:hover:before {
      color: #7d7d7d; }
    .pages .action.previous:active:before {
      color: #7d7d7d; }

.pages .action {
  width: 34px; }
  .pages .action.previous {
    margin-right: 12px; }
  .pages .action.next {
    margin-left: 12px; }

.actions-toolbar > .primary,
.actions-toolbar > .secondary {
  text-align: center;
  font-weight: bold !important;
  margin-bottom: 10px; }
  .actions-toolbar > .primary .action,
  .actions-toolbar > .secondary .action {
    margin-bottom: 10px; }
    .actions-toolbar > .primary .action:last-child,
    .actions-toolbar > .secondary .action:last-child {
      margin-bottom: 0; }
  .actions-toolbar > .primary:last-child,
  .actions-toolbar > .secondary:last-child {
    margin-bottom: 0; }

@media only screen and (min-width: 768px) {
  .actions-toolbar {
    margin: inherit;
    padding: inherit;
    clear: both; }
    .actions-toolbar:before, .actions-toolbar:after {
      content: '';
      display: table; }
    .actions-toolbar:after {
      clear: both; }
    .actions-toolbar .primary {
      float: right; }
    .actions-toolbar .secondary {
      float: left; }
    .actions-toolbar .primary,
    .actions-toolbar .secondary {
      display: inline-block; }
      .actions-toolbar .primary .action,
      .actions-toolbar .secondary .action {
        margin: inherit; }
      .actions-toolbar .primary a.action,
      .actions-toolbar .secondary a.action {
        display: inline-block;
        margin-top: inherit; }
    .actions-toolbar .primary .action {
      margin: 0 5px 0 0; }
    .actions-toolbar .primary a.action {
      margin-top: inherit; }
    .actions-toolbar .secondary .action {
      margin: inherit; }
    .actions-toolbar .secondary a.action {
      margin-top: 6px; }
    .actions-toolbar > .secondary,
    .actions-toolbar > .primary {
      margin-bottom: 0; }
      .actions-toolbar > .secondary .action,
      .actions-toolbar > .primary .action {
        margin-bottom: 0;
        width: auto; } }

.breadcrumbs {
  margin: auto auto 20px; }
  .breadcrumbs .items {
    font-size: 12px;
    color: #a3a3a3;
    margin: 0;
    padding: 0;
    list-style: none none; }
    .breadcrumbs .items > li {
      display: inline-block;
      vertical-align: top; }
  .breadcrumbs .item {
    margin: 0; }
  .breadcrumbs a {
    background: inherit;
    border: none;
    color: #2FDF66;
    display: inherit;
    padding: inherit;
    text-decoration: none; }
    .breadcrumbs a:visited {
      background: inherit;
      border: none;
      color: #2FDF66;
      text-decoration: none; }
    .breadcrumbs a:hover {
      background: inherit;
      border: none;
      color: #2FDF66;
      text-decoration: underline; }
    .breadcrumbs a:active {
      background: inherit;
      border: none;
      color: #2FDF66;
      text-decoration: none; }
  .breadcrumbs strong {
    background: inherit;
    border: none;
    display: inherit;
    font-weight: 400;
    padding: inherit; }
  .breadcrumbs .item:not(:last-child):after {
    color: #a3a3a3;
    content: inherit; }
  .breadcrumbs .item:not(:last-child) {
    display: inline-block;
    text-decoration: none; }
    .breadcrumbs .item:not(:last-child):after {
      -webkit-font-smoothing: antialiased;
      font-size: 24px;
      line-height: 18px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: top; }
    .breadcrumbs .item:not(:last-child):hover:after {
      color: inherit; }
    .breadcrumbs .item:not(:last-child):active:after {
      color: inherit; }
  @media only screen and (min-width: 769px) {
    .breadcrumbs {
      min-height: 85px; } }
  .breadcrumbs .items {
    padding: 10px 0 10px 20px;
    margin-top: 15px;
    color: #20272B;
    font-weight: 100;
    background-color: #FBFBFB; }
  .breadcrumbs strong {
    background: inherit;
    border: none;
    display: inherit;
    font-weight: lighter;
    padding: inherit; }
  .breadcrumbs a:visited {
    background: inherit;
    border: none;
    color: #014A5B;
    font-weight: lighter;
    text-decoration: none; }

@media only screen and (max-width: 768px) {
  .breadcrumbs {
    display: none; } }

.ui-dialog.popup .action.close {
  bottom: inherit;
  left: inherit;
  position: absolute;
  height: 40px;
  right: 0;
  top: 0;
  width: 40px;
  z-index: 1001;
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: none;
  box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400; }
  .ui-dialog.popup .action.close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .ui-dialog.popup .action.close:before {
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 16px;
    color: #8c8c8c;
    content: "";
    font-family: "icons-blank-theme";
    margin: inherit;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: top; }
  .ui-dialog.popup .action.close:hover:before {
    color: #737373; }
  .ui-dialog.popup .action.close:active:before {
    color: #737373; }
  .ui-dialog.popup .action.close:focus, .ui-dialog.popup .action.close:active {
    background: none;
    border: none; }
  .ui-dialog.popup .action.close:hover {
    background: none;
    border: none; }
  .ui-dialog.popup .action.close.disabled, .ui-dialog.popup .action.close[disabled],
  fieldset[disabled] .ui-dialog.popup .action.close {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }

.popup-pointer {
  margin-left: -14px;
  position: absolute;
  top: -14px; }
  .popup-pointer:before, .popup-pointer:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-bottom-style: solid; }
  .popup-pointer:before {
    border: solid 6px;
    border-color: transparent transparent #fff transparent;
    z-index: 99; }
  .popup-pointer:after {
    border: solid 7px;
    border-color: transparent transparent #aeaeae transparent;
    z-index: 98; }

@media only screen and (min-width: 640px) {
  .ui-dialog.popup {
    width: 420px; } }

.price-tier_price .price-excluding-tax,
.price-tier_price .price-including-tax {
  display: inline; }

.col.price .price-including-tax,
.col.price .price-excluding-tax {
  display: block;
  font-size: 18px;
  line-height: 0;
  margin-right: 11px;
  margin-top: 50px; }
  .col.price .price-including-tax .price,
  .col.price .price-excluding-tax .price {
    font-weight: 700; }

.price-including-tax + .price-excluding-tax,
.weee[data-label] {
  display: block;
  font-size: 18px; }
  .price-including-tax + .price-excluding-tax .price,
  .weee[data-label] .price {
    font-size: 11px; }

@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.footer .container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

@media (min-width: 576px) {
  .footer .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .footer .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .footer .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .footer .container {
    max-width: 1445px; } }

.footer .container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.footer .row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.footer .no-gutters {
  margin-right: 0;
  margin-left: 0; }

.footer .no-gutters > .col,
.footer .no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.footer .col-1, .footer .col-2, .footer .col-3, .footer .col-4, .footer .col-5, .footer .col-6, .footer .col-7, .footer .col-8, .footer .col-9, .footer .col-10, .footer .col-11, .footer .col-12,
.footer .col-auto, .footer .col-sm-1, .footer .col-sm-2, .footer .col-sm-3, .footer .col-sm-4, .footer .col-sm-5, .footer .col-sm-6, .footer .col-sm-7, .footer .col-sm-8, .footer .col-sm-9, .footer .col-sm-10, .footer .col-sm-11, .footer .col-sm-12, .footer .col-sm,
.footer .col-sm-auto, .footer .col-md-1, .footer .col-md-2, .footer .col-md-3, .footer .col-md-4, .footer .col-md-5, .footer .col-md-6, .footer .col-md-7, .footer .col-md-8, .footer .col-md-9, .footer .col-md-10, .footer .col-md-11, .footer .col-md-12, .footer .col-md,
.footer .col-md-auto, .footer .col-lg-1, .footer .col-lg-2, .footer .col-lg-3, .footer .col-lg-4, .footer .col-lg-5, .footer .col-lg-6, .footer .col-lg-7, .footer .col-lg-8, .footer .col-lg-9, .footer .col-lg-10, .footer .col-lg-11, .footer .col-lg-12, .footer .col-lg,
.footer .col-lg-auto, .footer .col-xl-1, .footer .col-xl-2, .footer .col-xl-3, .footer .col-xl-4, .footer .col-xl-5, .footer .col-xl-6, .footer .col-xl-7, .footer .col-xl-8, .footer .col-xl-9, .footer .col-xl-10, .footer .col-xl-11, .footer .col-xl-12, .footer .col-xl,
.footer .col-xl-auto, .footer .col-20 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.footer .col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.footer .col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.footer .col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.footer .col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.footer .col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.footer .col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.footer .col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.footer .col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.footer .col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.footer .col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.footer .col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.footer .col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.footer .col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 576px) {
  .footer .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .footer .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .footer .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .footer .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .footer .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .footer .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .footer .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .footer .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .footer .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .footer .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .footer .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .footer .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .footer .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .footer .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .footer .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .footer .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .footer .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .footer .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .footer .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .footer .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .footer .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .footer .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .footer .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .footer .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .footer .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .footer .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .footer .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .footer .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .footer .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .footer .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .footer .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .footer .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .footer .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .footer .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .footer .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .footer .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .footer .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .footer .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .footer .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .footer .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .footer .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .footer .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1200px) {
  .footer .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .footer .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .footer .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .footer .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .footer .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .footer .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .footer .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .footer .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .footer .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .footer .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .footer .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .footer .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .footer .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .footer .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .footer .col-xl-20 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../images/loader-1.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button, .slick-dots li .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .slick-dots li .actions-toolbar > .action {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:hover, .slick-dots li button:focus, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:focus, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:hover:before, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:hover:before, .slick-dots li button:focus:before, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:focus:before, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:focus:before {
          opacity: 1; }
      .slick-dots li button:before, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:before, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before, .slick-dots li.slick-active .cart.table-wrapper .actions-toolbar > .action:before, .cart.table-wrapper .slick-dots li.slick-active .actions-toolbar > .action:before {
      color: black;
      opacity: 0.75; }

.minicart-custom .mage-dropdown-dialog {
  display: block !important; }

.minicart-custom.minicart-wrapper .block-minicart {
  z-index: 1001; }

.box-sized {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.header .quickcart-wrapper .block-quickcart {
  border: none;
  height: 100%;
  width: 400px;
  min-width: inherit;
  overflow: hidden;
  padding: 0;
  position: fixed !important;
  right: -500px !important;
  top: -4px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.header .quickcart-content-wrapper {
  width: 100%; }

.header .block-content-quickcart {
  width: 100%; }
  .header .block-content-quickcart .quickcart-top {
    background: #333333;
    color: #ffffff;
    line-height: 50px;
    text-align: center; }
  .header .block-content-quickcart .quickcart-items-wrapper {
    border: 0 none;
    margin: 0;
    z-index: -1;
    overflow-y: auto;
    width: 100%; }
    .header .block-content-quickcart .quickcart-items-wrapper::-webkit-scrollbar-track {
      background-color: #F5F5F5; }
    .header .block-content-quickcart .quickcart-items-wrapper::-webkit-scrollbar, .header .block-content-quickcart .quickcart-items-wrapper::-webkit-scrollbar-thumb {
      background-color: #c2c2c2; }
  .header .block-content-quickcart .quickcart-bottom-container {
    width: 100%;
    margin-top: 20px; }
    .header .block-content-quickcart .quickcart-bottom-container .items-total {
      display: none; }
    .header .block-content-quickcart .quickcart-bottom-container .subtotal {
      margin: 0;
      padding: 20px;
      width: 100%;
      background-color: #F6F6F6; }
      .header .block-content-quickcart .quickcart-bottom-container .subtotal span.label {
        float: left; }
        .header .block-content-quickcart .quickcart-bottom-container .subtotal span.label span {
          font-size: 16px; }
      .header .block-content-quickcart .quickcart-bottom-container .subtotal .price-container span.price {
        font-size: 16px;
        font-weight: 400;
        margin-left: 10px; }
    .header .block-content-quickcart .quickcart-bottom-container .actions.action-viewcart {
      background: transparent;
      margin: 0;
      padding: 0;
      text-align: center;
      width: 100%; }
      .header .block-content-quickcart .quickcart-bottom-container .actions.action-viewcart .secondary {
        padding: 15px; }
    .header .block-content-quickcart .quickcart-bottom-container .actions.action-checkout {
      margin: 0;
      padding: 0 10px;
      width: 100%; }
      .header .block-content-quickcart .quickcart-bottom-container .actions.action-checkout button.checkout, .header .block-content-quickcart .quickcart-bottom-container .actions.action-checkout .cart.table-wrapper .actions-toolbar > .checkout.action, .cart.table-wrapper .header .block-content-quickcart .quickcart-bottom-container .actions.action-checkout .actions-toolbar > .checkout.action {
        display: block;
        margin: 0 auto 10px;
        min-width: 200px;
        font-size: 1.2em;
        padding: 15px 0;
        width: 100%; }
    .header .block-content-quickcart .quickcart-bottom-container .minicart-widgets {
      padding: 0;
      margin: 0; }

.quickcart-wrapper.active .block-quickcart {
  right: 0 !important; }

.quickcart-wrapper .quickcart-items .quickcart-product-item .product-item-name {
  max-width: 90%; }

.quickcart-wrapper .quickcart-items .quickcart-product-item .actions {
  float: right;
  margin: -28px 0 0; }

.quickcart-wrapper .quickcart-items .quickcart-product-item .details-qty {
  float: left;
  margin-top: 5px; }
  .quickcart-wrapper .quickcart-items .quickcart-product-item .details-qty .spinner {
    display: none;
    width: 10px;
    height: 10px;
    background-color: transparent;
    margin: 4px 12px 4px 13px;
    float: left;
    border: 1px solid #000;
    border-radius: 50%;
    border-bottom: 0 none;
    border-top: 0 none;
    top: -2px;
    position: relative;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out; }

.quickcart-wrapper .quickcart-items .quickcart-product-item .price-container {
  float: right;
  margin-top: 6px; }
  .quickcart-wrapper .quickcart-items .quickcart-product-item .price-container span {
    margin: 0; }
    .quickcart-wrapper .quickcart-items .quickcart-product-item .price-container span span.price-including-tax {
      width: 100%;
      display: table;
      text-align: right;
      line-height: .5; }
    .quickcart-wrapper .quickcart-items .quickcart-product-item .price-container span span.price-excluding-tax {
      width: 100%; }
      .quickcart-wrapper .quickcart-items .quickcart-product-item .price-container span span.price-excluding-tax span.minicart-price span.price {
        font-size: 12px; }
  .quickcart-wrapper .quickcart-items .quickcart-product-item .price-container span.price {
    font-size: 14px;
    margin: 0 0 10px 0;
    float: right; }

.quickcart-wrapper .quickcart-items .quickcart-product-item .options {
  margin: 0;
  min-height: 18px; }
  .quickcart-wrapper .quickcart-items .quickcart-product-item .options .option-wrapper {
    float: left;
    margin-bottom: 5px;
    width: 100%; }
    .quickcart-wrapper .quickcart-items .quickcart-product-item .options .option-wrapper dt.label,
    .quickcart-wrapper .quickcart-items .quickcart-product-item .options .option-wrapper dd.values {
      float: left;
      margin: 0 5px 0 0; }
    .quickcart-wrapper .quickcart-items .quickcart-product-item .options .option-wrapper dd.values {
      margin: 0; }

.quickcart-wrapper .quickcart-items .quickcart-product-item .product-item-pricing .label {
  line-height: 12px;
  float: left;
  padding: 0 20px 0 0;
  width: auto; }

.quickcart-wrapper .quickcart-items .quickcart-product-item span.qty-update {
  color: #000;
  font-size: 13px;
  float: left;
  padding: 0 5px;
  line-height: 10px; }
  .quickcart-wrapper .quickcart-items .quickcart-product-item span.qty-update.item-minus {
    font-family: 'luma-icons'; }
    .quickcart-wrapper .quickcart-items .quickcart-product-item span.qty-update.item-minus:after {
      content: '-'; }
  .quickcart-wrapper .quickcart-items .quickcart-product-item span.qty-update.item-plus {
    font-family: 'luma-icons'; }
    .quickcart-wrapper .quickcart-items .quickcart-product-item span.qty-update.item-plus:after {
      content: '+'; }

.quickcart-wrapper .quickcart-items .quickcart-product-item .cart-item-qty {
  color: #000;
  border: 0 none;
  float: left;
  height: auto;
  line-height: 14px;
  margin: 0;
  opacity: 1;
  padding: 0;
  text-align: center;
  font-size: 13px;
  width: auto;
  max-width: 35px; }

.quickcart-wrapper .quickcart-items .quickcart-product-item button.update-cart-item, .quickcart-wrapper .quickcart-items .quickcart-product-item .cart.table-wrapper .actions-toolbar > .update-cart-item.action, .cart.table-wrapper .quickcart-wrapper .quickcart-items .quickcart-product-item .actions-toolbar > .update-cart-item.action {
  display: none; }

.quickcart-wrapper .quickcart-items .quickcart-product-item:last-child {
  border-bottom: 1px solid #ccc; }

@media only screen and (max-width: 400px) {
  .header .quickcart-wrapper .block-quickcart {
    width: 90%; }
    .header .quickcart-wrapper .block-quickcart .block-content-quickcart .quickcart-items-wrapper {
      position: absolute; }
    .header .quickcart-wrapper .block-quickcart .block-content-quickcart .quickcart-bottom-container {
      bottom: 0;
      position: absolute; }
  .quickcart-wrapper .quickcart-items .quickcart-product-item .product-item-pricing .label {
    padding: 0 5px 0 0; }
  .quickcart-wrapper .quickcart-items .quickcart-product-item .cart-item-qty {
    max-width: 20px; }
  .quickcart-wrapper .quickcart-items .quickcart-product-item .details-qty .spinner {
    margin: 4px 6px 4px 4px; } }

.icon-email:before {
  font-family: "icomoon";
  content: "\e94f";
  color: white;
  padding-right: 8px; }

.icon-headphones:before {
  font-family: "icomoon";
  content: "\e956";
  color: white;
  padding-right: 8px;
  font-weight: 600; }

.block.newsletter .field .control::before {
  -webkit-font-smoothing: antialiased;
  font-size: 35px;
  line-height: 33px;
  color: #c2c2c2;
  content: "";
  font-family: "icons-blank-theme";
  margin: inherit;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center;
  vertical-align: middle;
  display: none !important; }

.action.subscribe.primary {
  font-size: 0px;
  border: 0px;
  background: none; }
  .action.subscribe.primary:before {
    font-family: "icomoon";
    font-size: 20px;
    display: inline-block;
    color: white;
    line-height: 12px;
    text-align: center;
    cursor: pointer;
    content: "\e94f";
    font-weight: 100; }

.authorization-link {
  display: flex !important;
  padding-right: 14px; }
  .authorization-link:before {
    font-family: "icomoon";
    font-size: 12px;
    display: inline-block;
    color: #4A4A49;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
    content: "\e951";
    padding-right: 5px; }

.icon-icon-planten-11:before {
  font-family: "icomoon";
  font-size: 70px;
  display: inline-block;
  color: white;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  content: "\e961"; }

.icon-icon-info:before {
  font-family: "icomoon";
  font-size: 70px;
  display: inline-block;
  color: white;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  content: "\e958"; }

.icon-icon-info:hover:before {
  color: none; }

.icon-icon-headphones:before {
  font-family: "icomoon";
  font-size: 70px;
  display: inline-block;
  color: white;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  content: "\e956"; }

.icon-icon-headphones:hover:before {
  color: none; }

.icon-icon-veilig:before {
  font-family: "icomoon";
  font-size: 70px;
  display: inline-block;
  color: white;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  content: "\e964"; }

.icon-icon-veilig:hover:before {
  color: none; }

.icon-icon-Bezorgen22:before {
  font-family: "icomoon";
  font-size: 70px;
  display: inline-block;
  color: white;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  content: "\e94e";
  float: left;
  padding-bottom: 1px; }

.icon-icon-Bezorgen22:hover:before {
  color: none; }

.icon-icon-Bezorgen2:before {
  font-family: "icomoon";
  font-size: 90px;
  display: inline-block;
  color: #606260;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
  content: "\e94e";
  padding-bottom: 15px; }

.icon-icon-Bezorgen2:hover:before {
  color: #014A5B; }

.icon-icon-happy2:before {
  font-family: "icomoon";
  font-size: 90px;
  display: inline-block;
  color: #606260;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
  content: "\e954";
  padding-bottom: 15px; }

.icon-icon-happy2:hover:before {
  color: #014A5B; }

.icon-icon-happy3:before {
  font-family: "icomoon";
  font-size: 90px;
  display: inline-block;
  color: #606260;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
  content: "\e955";
  padding-bottom: 15px; }

.icon-icon-happy3:hover:before {
  color: #014A5B; }

.icon-icon-think:before {
  font-family: "icomoon";
  font-size: 90px;
  display: inline-block;
  color: #606260;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
  content: "\e962";
  padding-bottom: 15px; }

.icon-icon-think:hover:before {
  color: #014A5B; }

.icon-icon-planten-1:before {
  font-family: "icomoon";
  font-size: 90px;
  display: inline-block;
  color: #606260;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
  content: "\e960";
  padding-bottom: 15px; }

.icon-icon-planten-1:hover:before {
  color: #014A5B; }

ul .icon-icon-Bezorgen2usp:before {
  font-family: "icomoon";
  font-size: 80px;
  display: inline-block;
  color: #606260;
  line-height: 180px;
  text-align: center;
  cursor: pointer;
  content: "\e94e"; }

.icon-icon-Bezorgen3:before {
  font-family: "icomoon";
  font-size: 30px !important;
  display: inline-block;
  color: #014A5B;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  float: left;
  margin-top: 15px;
  content: "\e94e"; }

ul .icon-icon-think:before {
  font-family: "icomoon";
  font-size: 80px;
  display: inline-block;
  color: #606260;
  line-height: 180px;
  text-align: center;
  cursor: pointer;
  content: "\e962"; }

ul .icon-icon-planten-1:before {
  font-family: "icomoon";
  font-size: 80px;
  display: inline-block;
  color: #606260;
  line-height: 180px;
  text-align: center;
  cursor: pointer;
  content: "\e960"; }

ul .icon-icon-happy2:before {
  font-family: "icomoon";
  font-size: 80px;
  display: inline-block;
  color: #606260;
  line-height: 180px;
  text-align: center;
  cursor: pointer;
  content: "\e954"; }

ul .icon-icon-gezond:before {
  font-family: "icomoon";
  font-size: 80px;
  display: inline-block;
  color: #606260;
  line-height: 180px;
  text-align: center;
  cursor: pointer;
  content: "\e952"; }

ul .icon-icon-gezond2:before {
  font-family: "icomoon";
  font-size: 80px;
  display: inline-block;
  color: #606260;
  line-height: 180px;
  text-align: center;
  cursor: pointer;
  content: "\e953"; }

ul .icon-icon-headphones:before {
  font-family: "icomoon";
  font-size: 80px;
  display: inline-block;
  color: #606260;
  line-height: 180px;
  text-align: center;
  cursor: pointer;
  content: "\e956"; }

ul .icon-icon-height_plant:before {
  font-family: "icomoon";
  font-size: 80px;
  display: inline-block;
  color: #606260;
  line-height: 180px;
  text-align: center;
  cursor: pointer;
  content: "\e957"; }

ul .icon-icon-info:before {
  font-family: "icomoon";
  font-size: 80px;
  display: inline-block;
  color: #606260;
  line-height: 180px;
  text-align: center;
  cursor: pointer;
  content: "\e958"; }

ul .icon-icon-instagram:before {
  font-family: "icomoon";
  font-size: 80px;
  display: inline-block;
  color: #606260;
  line-height: 180px;
  text-align: center;
  cursor: pointer;
  content: "\e959"; }

ul .icon-icon-ligging:before {
  font-family: "icomoon";
  font-size: 80px;
  display: inline-block;
  color: #606260;
  line-height: 180px;
  text-align: center;
  cursor: pointer;
  content: "\e95d"; }

ul .icon-icon-medewerkers:before {
  font-family: "icomoon";
  font-size: 80px;
  display: inline-block;
  color: #606260;
  line-height: 180px;
  text-align: center;
  cursor: pointer;
  content: "\e95e"; }

ul .icon-icon-twitter:before {
  font-family: "icomoon";
  font-size: 80px;
  display: inline-block;
  color: #606260;
  line-height: 180px;
  text-align: center;
  cursor: pointer;
  content: "\e963"; }

ul .icon-icon-veilig:before {
  font-family: "icomoon";
  font-size: 80px;
  display: inline-block;
  color: #606260;
  line-height: 180px;
  text-align: center;
  cursor: pointer;
  content: "\e964"; }

ul .icon-icon-veilig2:before {
  font-family: "icomoon";
  font-size: 80px;
  display: inline-block;
  color: #606260;
  line-height: 180px;
  text-align: center;
  cursor: pointer;
  content: "\e965"; }

ul .icon-icon-Whatsapp:before {
  font-family: "icomoon";
  font-size: 80px;
  display: inline-block;
  color: #606260;
  line-height: 180px;
  text-align: center;
  cursor: pointer;
  content: "\e966"; }

ul .icon-icon-winkelwagen:before {
  font-family: "icomoon";
  font-size: 80px;
  display: inline-block;
  color: #606260;
  line-height: 180px;
  text-align: center;
  cursor: pointer;
  content: "\e967"; }

/*Artibute.phtml op grouped product een lees meer naar beneden*/
.read-more a {
  color: #20272B;
  font-weight: 600;
  opacity: 1.0; }
  .read-more a:hover {
    opacity: 0.8; }

.btn-overflow {
  display: block;
  text-decoration: none;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.9em;
  line-height: 2;
  border: 1px solid #dddddd;
  border-radius: 0.25em;
  cursor: pointer;
  width: 140px;
  text-align: center;
  margin-top: 20px;
  color: #666666 !important;
  padding-left: 5px;
  padding-right: 5px;
  position: relative; }
  .btn-overflow:hover {
    text-decoration: none;
    opacity: 0.8;
    border-color: #2FDF66;
    border-radius: 0px; }

.text-overflow {
  width: 70%;
  height: 170px;
  margin-left: 15%;
  display: block;
  overflow: hidden;
  word-break: break-word;
  word-wrap: break-word;
  text-align: center;
  transition: 0.4s height, 0.4s padding, 0s visibility 0.4s; }
  @media only screen and (max-width: 1024px) {
    .text-overflow {
      width: 80%;
      margin-left: 10%; } }
  @media only screen and (max-width: 768px) {
    .text-overflow {
      height: 230px; } }
  @media only screen and (max-width: 610px) {
    .text-overflow {
      height: 135px; } }
  @media only screen and (max-width: 503px) {
    .text-overflow {
      height: 185px; } }
  .text-overflow h2 {
    margin-top: 0; }
  .text-overflow h3 {
    margin-top: 35px;
    font-size: 25px; }
  .text-overflow b,
  .text-overflow strong {
    font-weight: 600;
    color: #005266; }

.content-heading {
  display: none; }

.alink {
  color: #014A5B;
  text-decoration: none;
  font-weight: 600; }

.alink:hover {
  text-decoration: none !important;
  opacity: 0.7; }

.actie-label {
  background: #ED8400;
  width: 84px;
  text-align: center;
  color: white;
  height: 80px;
  line-height: 80px;
  font-size: 20px;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  left: 0; }

.paymentmethod {
  margin-top: 35px;
  justify-content: space-between;
  display: flex; }
  .paymentmethod img {
    height: 29px;
    margin-right: 20px;
    margin-bottom: 10px; }
    @media only screen and (max-width: 980px) and (min-width: 769px) {
      .paymentmethod img {
        height: 20px; } }
    @media only screen and (max-width: 500px) {
      .paymentmethod img {
        height: 20px; } }
    @media only screen and (max-width: 400px) {
      .paymentmethod img {
        height: 15px; } }
  @media only screen and (max-width: 768px) {
    .paymentmethod {
      padding: 0 20px;
      box-sizing: border-box; } }

.paymentmethod ul {
  list-style-type: none;
  overflow: hidden;
  float: right;
  width: 1350px; }
  .paymentmethod ul li {
    float: right;
    margin-left: 7%; }
  .paymentmethod ul img {
    height: 30px !important;
    padding-top: 5px;
    text-align: center; }

.block.newsletter .form.subscribe {
  display: table;
  width: 100%;
  margin-top: 45px; }
  @media only screen and (max-width: 560px) {
    .block.newsletter .form.subscribe {
      margin-top: 0; } }

.todo {
  position: relative;
  background-color: #f1f8f1;
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 30px; }
  .todo ul {
    display: flex; }
  .todo img {
    position: relative;
    left: 10%;
    bottom: 0;
    z-index: 1;
    display: none; }
    @media only screen and (max-width: 1280px) {
      .todo img {
        left: 2%;
        margin-top: 5%;
        max-width: 350px; } }
    @media only screen and (max-width: 767px) {
      .todo img {
        display: none; } }
  .todo .centre-container {
    z-index: 2;
    width: 800px;
    margin: 0 auto;
    text-align: center; }
    .todo .centre-container > div {
      position: relative; }
    .todo .centre-container .expanded {
      margin-top: 100px;
      position: absolute;
      color: #606260; }
    .todo .centre-container .icons {
      float: left;
      padding-left: 40px;
      margin-top: -70px;
      padding-bottom: 250px; }
      @media only screen and (max-width: 640px) {
        .todo .centre-container .icons {
          padding-left: 20px; } }
      @media only screen and (max-width: 684px) and (min-width: 641px) {
        .todo .centre-container .icons {
          padding-left: 30px; } }
      .todo .centre-container .icons b {
        margin-top: -60px;
        font-size: 17px; }
    .todo .centre-container h1 {
      margin-bottom: 100px; }
    @media only screen and (max-width: 640px) {
      .todo .centre-container div[data-role="collapsible"] .icons b {
        font-size: 13px !important; } }
    @media only screen and (max-width: 680px) {
      .todo .centre-container {
        width: 100%;
        padding-left: 25px;
        padding-right: 25px; } }
    @media only screen and (max-width: 684px) {
      .todo .centre-container .icons > span:before {
        font-size: 40px !important; } }
    @media only screen and (max-width: 800px) {
      .todo .centre-container {
        width: 645px; }
        .todo .centre-container div[data-role="collapsible"] .icons > span {
          display: block; }
          .todo .centre-container div[data-role="collapsible"] .icons > span:before {
            font-size: 60px; }
        .todo .centre-container div[data-role="collapsible"] .icons > br {
          display: none; }
        .todo .centre-container div[data-role="collapsible"] .icons b {
          font-size: 14px;
          font-weight: 600; } }
    @media only screen and (max-width: 580px) {
      .todo .centre-container {
        width: 100%;
        box-sizing: border-box;
        padding: 0 15px;
        left: 0;
        transform: none; } }
    @media only screen and (max-width: 550px) {
      .todo .centre-container #element {
        border-bottom: 1px solid #005266; }
      .todo .centre-container .expanded {
        margin-top: 10px;
        position: relative;
        padding-bottom: 10px; }
        .todo .centre-container .expanded b, .todo .centre-container .expanded br {
          display: none; }
      .todo .centre-container div[data-role="collapsible"] {
        border-top: 1px solid #005266; }
        .todo .centre-container div[data-role="collapsible"] .icons {
          padding-right: 0;
          width: 100%;
          margin-top: 0;
          cursor: pointer;
          float: none;
          padding-bottom: 7px;
          text-align: center; }
          .todo .centre-container div[data-role="collapsible"] .icons > span {
            display: none; }
      .todo .centre-container h1 {
        margin-bottom: 20px; } }
  @media only screen and (max-width: 550px) {
    .todo {
      padding-bottom: 40px; } }

@media only screen and (max-width: 610px) {
  .homepage-slider {
    display: none; } }

.cms-over-ons .column.main {
  padding-left: 8px;
  padding-right: 8px; }

.img-green-border1 img {
  position: relative;
  margin-left: 180px;
  z-index: -5; }

.img-green-border2 img {
  margin-top: -220px;
  z-index: 10; }

.green-border {
  border: 10px solid #2FDF66;
  min-height: 366px;
  min-width: 268px;
  position: absolute;
  z-index: -8;
  margin-left: 49px;
  margin-top: 124px; }

.kader {
  float: left;
  margin-right: 50px;
  margin-bottom: 100px; }

.text-kader {
  padding-top: 40px; }

.div-center {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 115px; }
  .div-center ul {
    margin-top: 5px; }

.medewerkers {
  padding: 0 8px;
  box-sizing: border-box;
  max-width: 1445px;
  margin: 0 auto; }
  .medewerkers ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .medewerkers ul li {
      display: block;
      margin: 1% 0 0 0;
      color: #2FDF66;
      width: 24.25%;
      max-width: 257px; }
      .medewerkers ul li:nth-child(2) {
        margin-left: 1%; }
      .medewerkers ul li:nth-child(3) {
        margin-right: 1%;
        margin-left: 1%; }
      @media only screen and (max-width: 580px) {
        .medewerkers ul li {
          width: 48.5%; }
          .medewerkers ul li:nth-child(3) {
            margin-right: 0;
            margin-left: 0; }
          .medewerkers ul li:nth-child(2) {
            margin-left: 0; }
          .medewerkers ul li:nth-child(2n) {
            margin-right: 0;
            margin-left: 1%; }
          .medewerkers ul li:nth-child(2n + 1) {
            margin-right: 1%;
            margin-left: 1%; } }
      @media only screen and (max-width: 400px) {
        .medewerkers ul li {
          width: 100%; }
          .medewerkers ul li:nth-child(3), .medewerkers ul li:nth-child(2), .medewerkers ul li:nth-child(2n), .medewerkers ul li:nth-child(2n + 1) {
            margin-right: auto;
            margin-left: auto; } }
      .medewerkers ul li a {
        width: 100%;
        display: block;
        background-color: #F1F8F1;
        border-radius: 50%;
        overflow: hidden;
        height: 0;
        padding-bottom: 100%; }
        .medewerkers ul li a img {
          display: block;
          filter: brightness(100%);
          transition: filter 0.2s ease; }
          .medewerkers ul li a img :hover {
            opacity: 0.5;
            filter: brightness(0%); }
      .medewerkers ul li p {
        font-weight: 100;
        font-size: 20px;
        margin-bottom: 0; }
      .medewerkers ul li span {
        color: #95989A;
        font-weight: bold;
        font-size: 20px; }

.center-div {
  background-color: #f1f8f1; }

.center-div-white {
  background-color: white;
  height: 400px;
  position: inherit;
  margin-top: 50px;
  margin-bottom: 180px; }

/*header image van reviews*/
.review-img {
  margin: 0 auto;
  text-align: center; }
  .review-img p {
    font-size: 33px;
    color: white;
    padding-top: 150px;
    font-weight: bolder;
    margin: 0px; }
  .review-img span {
    font-size: 14px;
    color: white;
    font-weight: bold; }

.center-div-bg {
  background: no-repeat Url("../images/Over-ons/header.png") center;
  background-size: cover;
  height: 390px; }

/*USPs op rij in groene kader */
.USP-bar {
  text-align: center;
  color: white;
  height: 100%;
  max-width: 1445px;
  margin: 0 auto; }
  .USP-bar ul {
    display: flex;
    padding: 0;
    margin: 0; }
    @media only screen and (max-width: 940px) {
      .USP-bar ul {
        flex-wrap: wrap; } }
    .USP-bar ul li {
      list-style-type: none;
      display: block;
      width: 25%;
      box-sizing: border-box;
      background-color: #F1F8F1;
      margin-bottom: 50px;
      margin-top: 0;
      color: #20272B;
      font-weight: normal;
      padding: 10px; }
      @media only screen and (max-width: 940px) {
        .USP-bar ul li {
          width: 50%;
          margin-bottom: 20px;
          margin-top: 0; } }
      @media only screen and (max-width: 420px) {
        .USP-bar ul li {
          width: 100%; } }
      .USP-bar ul li span[class*='icon-'] {
        display: inline-block; }
      .USP-bar ul li b {
        margin-left: 0;
        display: block; }

@media (max-width: 640px) {
  .text-kader {
    text-align: center; }
  .kader {
    margin-bottom: 50px; }
  .green-border {
    border: 6px solid #2FDF66;
    min-height: 218px;
    min-width: 176px;
    z-index: -8;
    margin-left: 24px;
    margin-top: 33px; }
  .img-green-border1 img {
    width: 180px;
    margin-left: 125px; }
  .img-green-border2 img {
    width: 180px;
    margin-left: 15px; } }

.contact-index-index.page-layout-1column .page-bottom {
  background-color: #F1F8F1; }

.questions-container {
  display: inline-block;
  width: 100%;
  min-height: 540px; }
  .questions-container .expand {
    width: 100%; }

.fold {
  display: inline-block; }
  .fold .icon-icon-Bezorgen22::before {
    float: none;
    padding-bottom: 0px; }

.faq-icons {
  color: white;
  width: 100%;
  background: #005266;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
  box-sizing: border-box;
  border-left: 1px solid #003F4E;
  border-top: 1px solid #003F4E;
  height: 155px; }
  .faq-icons:hover {
    background: #003F4E; }
  .faq-icons:focus {
    background: #003F4E; }
  .faq-icons b {
    font-weight: 100; }

.cms-faq .faq-container {
  width: 100%;
  padding: 0 8px;
  max-width: 1445px;
  margin: 0 auto;
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  margin-top: 100px; }
  @media only screen and (max-width: 768px) {
    .cms-faq .faq-container {
      margin-top: 0px; } }
  .cms-faq .faq-container .faq-container-left {
    width: 40%; }
    @media only screen and (max-width: 1200px) {
      .cms-faq .faq-container .faq-container-left {
        width: 50%; } }
    @media only screen and (max-width: 900px) {
      .cms-faq .faq-container .faq-container-left {
        display: none; } }
    .cms-faq .faq-container .faq-container-left .content {
      height: 100%; }
    .cms-faq .faq-container .faq-container-left .fullscreen-background-green {
      margin-top: 20px;
      margin-right: 20px;
      position: relative;
      height: calc(100% - 20px);
      width: calc(100% - 20px); }
    .cms-faq .faq-container .faq-container-left img {
      display: block;
      position: absolute;
      bottom: 0; }
  .cms-faq .faq-container .faq-container-right {
    width: 60%; }
    @media only screen and (max-width: 1200px) {
      .cms-faq .faq-container .faq-container-right {
        width: 50%; } }
    @media only screen and (max-width: 900px) {
      .cms-faq .faq-container .faq-container-right {
        width: 100%; } }
    .cms-faq .faq-container .faq-container-right > .faq-container {
      padding: 0;
      margin: 0;
      width: 100%;
      max-width: none;
      display: block; }
      .cms-faq .faq-container .faq-container-right > .faq-container > .block {
        width: 100%; }
        .cms-faq .faq-container .faq-container-right > .faq-container > .block #faq-element {
          width: 100%; }
          .cms-faq .faq-container .faq-container-right > .faq-container > .block #faq-element .faq-container {
            padding: 0;
            margin: 0;
            width: 100%;
            max-width: none;
            display: flex; }
            .cms-faq .faq-container .faq-container-right > .faq-container > .block #faq-element .faq-container .fold {
              width: 25%;
              display: block;
              cursor: pointer; }
              @media only screen and (max-width: 500px) {
                .cms-faq .faq-container .faq-container-right > .faq-container > .block #faq-element .faq-container .fold {
                  width: 50%; } }

.cms-faq dd {
  display: none; }

.cms-faq dt {
  color: #20272B;
  font-weight: 600; }

.cms-faq dl {
  margin-bottom: 20px;
  margin-top: 0;
  border-top: #D0E5D0 solid 1px;
  padding-top: 20px; }
  .cms-faq dl a {
    color: #20272B;
    text-decoration: none; }

.cms-faq .faq-expanded h2 {
  margin-top: 80px;
  font-size: 36px;
  margin-bottom: 33px;
  color: #014A5B;
  margin-left: 5px; }

.cms-faq .page-bottom {
  background-color: #F1F8F1; }

.change-toggle {
  max-width: 770px; }
  .change-toggle:before {
    font-family: "icomoon";
    font-size: 30px;
    display: none;
    color: #2FDF66 !important;
    -webkit-text-stroke: 1px #2FDF66;
    line-height: 12px;
    content: "\e900";
    font-weight: bolder;
    text-decoration: none;
    background-color: #F1F8F1;
    -ms-transform: rotate(180deg);
    /* IE 9 */
    -webkit-transform: rotate(180deg);
    /* Safari 3-8 */
    transform: rotate(180deg); }

.cms-faq .page-bottom {
  background-color: #F1F8F1;
  background-image: url("/media/wysiwyg/faq.png");
  background-repeat: no-repeat;
  background-position: calc(12% - -34px) calc(100% - 0px);
  background-size: auto 720px; }
  @media only screen and (min-width: 2000px) {
    .cms-faq .page-bottom {
      background-position: calc(24% - -34px) calc(100% - 0px); } }
  @media only screen and (max-width: 1080px) {
    .cms-faq .page-bottom {
      background-position: calc(0% - -0px) calc(100% - 0px); } }
  @media only screen and (max-width: 900px) {
    .cms-faq .page-bottom {
      background-size: auto 0px; } }

.view-default {
  padding-left: 11px; }

.logo img {
  height: 60px;
  width: auto;
  margin-left: 0px; }

.header.content {
  padding: 30px 8px 0 !important; }

._keyfocus :focus,
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
  -webkit-box-shadow: 0 0 3px 1px #2FDF66;
  box-shadow: 0 0 3px 1px #2FDF66; }

.page-header .switcher .view-current-store-switch:before,
.page-header .switcher .switcher-option:before {
  content: "";
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 20px; }

.page-header .switcher .view-default:before {
  background-image: url("../images/flags/de.svg"); }

.page-header .switcher .view-storeview_at:before {
  background-image: url("../images/flags/at.svg"); }

.page-header .switcher .view-storeview_be:before {
  background-image: url("../images/flags/be.svg"); }

.page-header .switcher .view-storeview_ch:before {
  background-image: url("../images/flags/ch.svg"); }

.page-header .switcher .view-storeview_dk:before {
  background-image: url("../images/flags/dk.svg"); }

.page-header .switcher .view-storeview_fr:before {
  background-image: url("../images/flags/fr.svg"); }

.page-header .switcher .view-storeview_gb:before {
  background-image: url("../images/flags/gb.svg"); }

.page-header .switcher .view-storeview_nl:before {
  background-image: url("../images/flags/nl.svg"); }

.page-header .switcher .view-storeview_se:before {
  background-image: url("../images/flags/se.svg"); }

.page-header .switcher .options ul.dropdown.switcher-dropdown {
  right: 0; }
  .page-header .switcher .options ul.dropdown.switcher-dropdown:before {
    right: 10px;
    left: auto; }
  .page-header .switcher .options ul.dropdown.switcher-dropdown:after {
    right: 9px;
    left: auto; }

.form-container img {
  float: left; }

.form-container .field.name.required {
  display: inline-grid;
  width: 50%;
  padding-right: 15px; }

.form-container .field.email.required {
  display: inline-grid;
  width: 50%; }

.form-container .field.comment.required {
  display: inline-grid;
  width: 100%; }

.form-container .fieldset > .field:not(.choice) > .control {
  width: 100%;
  float: left; }

.form-container .fieldset > .field:not(.choice) > .label {
  padding: 6px 15px 0 0;
  text-align: left; }

.form-container input[type="text"],
.form-container input[type="email"] {
  background: #fff;
  border: 1px solid #D0E5D0;
  border-radius: 1px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 0.6;
  height: 40px;
  margin-top: 15px; }

.form-container textarea {
  background: #fff;
  border: 1px solid #D0E5D0;
  border-radius: 1px;
  font-size: 14px;
  height: 170px;
  line-height: 1.42857;
  margin: 0;
  padding: 10px;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 0.6;
  margin-top: 15px; }

.form-container .contact-img {
  background: #F1F8F1; }

.form-container .send-btn {
  background: white;
  border: solid #2FDF66 3px;
  width: 230px;
  height: 56px;
  float: right; }
  .form-container .send-btn:hover {
    border: solid #014A5B 3px;
    background: #014A5B;
    color: white; }

.form-container .contact-text {
  margin-bottom: 60px; }

.form-bg {
  height: 800px;
  width: 100%;
  margin-top: -790px;
  position: absolute;
  background: #F1F8F1;
  z-index: -999; }

@media only screen and (max-width: 360px) {
  .contact-img {
    max-width: 0px;
    display: none; }
  .form-container .field.name.required {
    width: 95%;
    padding-left: 5px;
    padding-right: 5px; }
  .form-container .field.email.required {
    width: 95%;
    padding-left: 5px;
    padding-right: 5px; }
  .form-container .field.comment.required {
    padding-left: 5px;
    padding-right: 5px;
    width: 95%; }
  .form-container .contact-text {
    text-align: center;
    padding: 5px;
    margin-bottom: 20px; }
  .form-container .send-btn {
    margin-bottom: 20px;
    margin-right: 10px; } }

.fieldset > .field > .label,
.fieldset > .fields > .field > .label {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: 400;
  line-height: inherit;
  font-size: inherit; }

.actions-toolbar > .primary,
.actions-toolbar > .secondary {
  text-align: center;
  font-weight: bold !important;
  margin-bottom: 10px; }
  .actions-toolbar > .primary .action,
  .actions-toolbar > .secondary .action {
    margin-bottom: 10px; }
    .actions-toolbar > .primary .action:last-child,
    .actions-toolbar > .secondary .action:last-child {
      margin-bottom: 0; }
  .actions-toolbar > .primary:last-child,
  .actions-toolbar > .secondary:last-child {
    margin-bottom: 0; }

@media only screen and (min-width: 768px) {
  .actions-toolbar {
    margin: inherit;
    padding: inherit;
    clear: both; }
    .actions-toolbar:before, .actions-toolbar:after {
      content: '';
      display: table; }
    .actions-toolbar:after {
      clear: both; }
    .actions-toolbar .primary {
      float: right; }
    .actions-toolbar .secondary {
      float: left; }
    .actions-toolbar .primary,
    .actions-toolbar .secondary {
      display: inline-block; }
      .actions-toolbar .primary .action,
      .actions-toolbar .secondary .action {
        margin: inherit; }
      .actions-toolbar .primary a.action,
      .actions-toolbar .secondary a.action {
        display: inline-block;
        margin-top: inherit; }
    .actions-toolbar .primary .action {
      margin: 0 5px 0 0; }
    .actions-toolbar .primary a.action {
      margin-top: inherit; }
    .actions-toolbar .secondary .action {
      margin: inherit; }
    .actions-toolbar .secondary a.action {
      margin-top: 6px; }
    .actions-toolbar > .secondary,
    .actions-toolbar > .primary {
      margin-bottom: 0; }
      .actions-toolbar > .secondary .action,
      .actions-toolbar > .primary .action {
        margin-bottom: 0;
        width: auto; } }

.callout-block {
  background-color: #014a5b;
  height: 107px;
  max-width: 320px;
  width: 100%;
  color: white;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 30px;
  box-sizing: border-box; }
  .callout-block p {
    line-height: 0;
    font-size: 15px;
    font-style: italic; }

.callout-text {
  font-size: 33px;
  color: white;
  text-transform: uppercase;
  padding-top: 17px; }

li b {
  margin-left: 10px;
  color: black; }

.UPS-text ul li {
  list-style-type: none;
  margin-left: -40px; }

.UPS-text span {
  color: #2FDF66; }

.UPS-text p {
  color: #95989A;
  font-size: 14px; }

.UPS-text H3 {
  margin-bottom: 30px;
  margin-top: 30px;
  margin-top: 85px;
  font-size: 16px; }

.cms-no-route .page-main,
.cms-page-view .page-main {
  padding: 0 0 50px 8px;
  box-sizing: border-box; }

.contact-index-index .page-main {
  max-width: none; }
  .contact-index-index .page-main .form-container {
    background: #f1f8f1; }
    @media only screen and (max-width: 768px) {
      .contact-index-index .page-main .form-container .contact-text {
        text-align: center; } }
    .contact-index-index .page-main .form-container .inner-form-center-container {
      max-width: 1445px;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 0 8px;
      display: flex;
      position: relative; }
      .contact-index-index .page-main .form-container .inner-form-center-container .contact-img {
        width: 40%;
        position: relative; }
        @media only screen and (max-width: 1200px) {
          .contact-index-index .page-main .form-container .inner-form-center-container .contact-img {
            width: 50%; } }
        @media only screen and (max-width: 768px) {
          .contact-index-index .page-main .form-container .inner-form-center-container .contact-img {
            display: none; } }
        .contact-index-index .page-main .form-container .inner-form-center-container .contact-img img {
          float: none;
          margin-top: 20px;
          margin-right: 20px;
          display: block;
          position: absolute;
          bottom: 0;
          width: calc(100% - 20px); }
      .contact-index-index .page-main .form-container .inner-form-center-container .formcontact {
        width: 60%;
        padding-bottom: 80px; }
        .contact-index-index .page-main .form-container .inner-form-center-container .formcontact .fieldset {
          padding-top: 30px; }
          @media only screen and (max-width: 768px) {
            .contact-index-index .page-main .form-container .inner-form-center-container .formcontact .fieldset {
              text-align: center; } }
          @media only screen and (max-width: 768px) {
            .contact-index-index .page-main .form-container .inner-form-center-container .formcontact .fieldset > .field:not(.choice) {
              padding: 0;
              width: 100%; } }
          .contact-index-index .page-main .form-container .inner-form-center-container .formcontact .fieldset > .field:not(.choice) > .label {
            width: 100%;
            box-sizing: border-box; }
          @media only screen and (max-width: 768px) {
            .contact-index-index .page-main .form-container .inner-form-center-container .formcontact .fieldset .send-btn {
              float: none;
              display: inline-block; } }
        @media only screen and (max-width: 1200px) {
          .contact-index-index .page-main .form-container .inner-form-center-container .formcontact {
            width: 50%; } }
        @media only screen and (max-width: 768px) {
          .contact-index-index .page-main .form-container .inner-form-center-container .formcontact {
            width: 100%; } }

body._has-modal {
  height: 100%;
  overflow: hidden;
  width: 100%; }

.modals-overlay {
  z-index: 899; }

.modal-popup,
.modal-slide {
  bottom: 0;
  left: 0;
  min-width: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden; }
  .modal-popup._show,
  .modal-slide._show {
    visibility: visible; }
    .modal-popup._show .modal-inner-wrap,
    .modal-slide._show .modal-inner-wrap {
      transform: translate(0, 0); }
  .modal-popup .modal-inner-wrap,
  .modal-slide .modal-inner-wrap {
    background-color: #fff;
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
    opacity: 1;
    pointer-events: auto; }

.modal-slide {
  left: 236px;
  z-index: 900; }
  .modal-slide._show .modal-inner-wrap {
    transform: translateX(0); }
  .modal-slide .modal-inner-wrap {
    height: 100%;
    overflow-y: auto;
    position: static;
    transform: translateX(100%);
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    width: auto; }
  .modal-slide._inner-scroll .modal-inner-wrap {
    overflow-y: visible;
    display: flex;
    flex-direction: column; }
  .modal-slide._inner-scroll .modal-footer,
  .modal-slide._inner-scroll .modal-header {
    flex-grow: 0;
    flex-shrink: 0; }
  .modal-slide._inner-scroll .modal-content {
    overflow-y: auto; }
  .modal-slide._inner-scroll .modal-footer {
    margin-top: auto; }
  .modal-slide .modal-content,
  .modal-slide .modal-footer,
  .modal-slide .modal-header {
    padding: 0 41px 41px; }
  .modal-slide .modal-header {
    padding-bottom: 33px;
    padding-top: 33px; }

.modal-popup {
  z-index: 900;
  left: 0;
  overflow-y: auto; }
  .modal-popup._show .modal-inner-wrap {
    transform: translateY(0); }
  .modal-popup .modal-inner-wrap {
    margin: 80px auto;
    width: 75%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    transform: translateY(-200%);
    transition-duration: 0.2s;
    transition-timing-function: ease; }
  .modal-popup._inner-scroll {
    overflow-y: visible; }
    .ie10 .modal-popup._inner-scroll,
    .ie9 .modal-popup._inner-scroll {
      overflow-y: auto; }
    .modal-popup._inner-scroll .modal-inner-wrap {
      max-height: 90%; }
      .ie10 .modal-popup._inner-scroll .modal-inner-wrap,
      .ie9 .modal-popup._inner-scroll .modal-inner-wrap {
        max-height: none; }
    .modal-popup._inner-scroll .modal-content {
      overflow-y: auto; }
  .modal-popup .modal-content,
  .modal-popup .modal-footer,
  .modal-popup .modal-header {
    padding-left: 48px;
    padding-right: 48px; }
  .modal-popup .modal-footer,
  .modal-popup .modal-header {
    flex-grow: 0;
    flex-shrink: 0; }
  .modal-popup .modal-header {
    padding-bottom: 19.2px;
    padding-top: 48px; }
  .modal-popup .modal-footer {
    margin-top: auto;
    padding-bottom: 48px;
    padding-top: 48px; }
  .modal-popup .modal-footer-actions {
    text-align: right; }

@media only screen and (max-width: 769px) {
  .modal-popup.modal-slide {
    left: 236px;
    z-index: 900; }
    .modal-popup.modal-slide._show .modal-inner-wrap {
      transform: translateX(0); }
    .modal-popup.modal-slide .modal-inner-wrap {
      height: 100%;
      overflow-y: auto;
      position: static;
      transform: translateX(100%);
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
      width: auto; }
    .modal-popup.modal-slide .modal-inner-wrap {
      margin: 0;
      max-height: none; } }

.modal-custom .action-close,
.modal-popup .action-close,
.modal-slide .action-close {
  background-image: none;
  background: none;
  box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  display: inline-block;
  text-decoration: none;
  position: absolute;
  right: 0;
  top: 0; }
  .modal-custom .action-close:focus, .modal-custom .action-close:active,
  .modal-popup .action-close:focus,
  .modal-popup .action-close:active,
  .modal-slide .action-close:focus,
  .modal-slide .action-close:active {
    background: none;
    border: none; }
  .modal-custom .action-close:hover,
  .modal-popup .action-close:hover,
  .modal-slide .action-close:hover {
    background: none;
    border: none; }
  .modal-custom .action-close.disabled, .modal-custom .action-close[disabled],
  fieldset[disabled] .modal-custom .action-close,
  .modal-popup .action-close.disabled,
  .modal-popup .action-close[disabled],
  fieldset[disabled]
  .modal-popup .action-close,
  .modal-slide .action-close.disabled,
  .modal-slide .action-close[disabled],
  fieldset[disabled]
  .modal-slide .action-close {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .modal-custom .action-close > span,
  .modal-popup .action-close > span,
  .modal-slide .action-close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .modal-custom .action-close:before,
  .modal-popup .action-close:before,
  .modal-slide .action-close:before {
    -webkit-font-smoothing: antialiased;
    font-size: 32px;
    line-height: 32px;
    color: #8f8f8f;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: top; }
  .modal-custom .action-close:hover:before,
  .modal-popup .action-close:hover:before,
  .modal-slide .action-close:hover:before {
    color: inherit; }
  .modal-custom .action-close:active:before,
  .modal-popup .action-close:active:before,
  .modal-slide .action-close:active:before {
    color: inherit; }
  .modal-custom .action-close:hover:before,
  .modal-popup .action-close:hover:before,
  .modal-slide .action-close:hover:before {
    color: #1dbe4f; }

.modal-custom .action-close {
  margin: 25px; }

.modal-popup .modal-title {
  border-bottom: 1px solid #c1c1c1;
  font-weight: 300;
  padding-bottom: 10px;
  font-size: 26px;
  margin-bottom: 0;
  min-height: 1em;
  word-wrap: break-word; }

.modal-popup .action-close {
  padding: 10px; }

.modal-slide .action-close {
  padding: 17px 32px; }

.modal-slide .page-main-actions {
  margin-bottom: 18px;
  margin-top: 33px; }

.modals-overlay {
  background-color: fade(#333, 55%);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0; }

body._has-modal-custom .modal-custom-overlay {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 899; }

@media only screen and (max-width: 768px) {
  .custom-slide {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    left: 236px;
    z-index: 900; }
    .custom-slide._show {
      visibility: visible; }
      .custom-slide._show .modal-inner-wrap {
        transform: translate(0, 0); }
    .custom-slide .modal-inner-wrap {
      background-color: #fff;
      box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
      opacity: 1;
      pointer-events: auto; }
    .custom-slide._show .modal-inner-wrap {
      transform: translateX(0); }
    .custom-slide .modal-inner-wrap {
      height: 100%;
      overflow-y: auto;
      position: static;
      transform: translateX(100%);
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
      width: auto; }
    .custom-slide._show {
      -webkit-overflow-scrolling: touch;
      overflow-x: hidden;
      overflow-y: auto; }
    .custom-slide .modal-inner-wrap {
      background-color: #f4f4f4;
      box-sizing: border-box;
      height: auto;
      min-height: 100%; }
  body._has-modal-custom {
    height: 100vh;
    overflow: hidden;
    width: 100vw; }
    body._has-modal-custom .modal-custom-overlay {
      background-color: fade(#333, 55%); } }

@media only screen and (max-width: 769px) {
  .modal-popup.modal-slide .modal-inner-wrap[class] {
    background-color: #f4f4f4; }
  .modal-popup.modal-slide._inner-scroll._show {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto; }
  .modal-popup.modal-slide._inner-scroll .modal-inner-wrap {
    height: auto;
    min-height: 100%; }
  .modal-popup .modal-title {
    font-size: 14px;
    font-weight: 700; } }

@media only screen and (min-width: 769px) {
  .modal-popup.modal-slide .modal-footer {
    border-top: 1px solid #c1c1c1;
    text-align: right; } }

.column .block-addbysku .fieldset {
  margin: 0; }
  .column .block-addbysku .fieldset .fields {
    position: relative; }
    .column .block-addbysku .fieldset .fields .field {
      display: inline-block;
      margin-bottom: 10px;
      vertical-align: top; }
    .column .block-addbysku .fieldset .fields .actions-toolbar {
      position: absolute;
      right: 0;
      top: 4px; }

.column .block-addbysku .sku {
  margin-right: -155px;
  padding-right: 160px;
  width: 100%; }

.column .block-addbysku .skus > .label {
  margin-top: 10px; }

.column .block-addbysku .qty .qty + .mage-error {
  width: 80px; }

.column .block-addbysku .block-content .actions-toolbar {
  clear: both;
  margin: 0 0 20px;
  padding: inherit;
  clear: both;
  text-align: left; }
  .column .block-addbysku .block-content .actions-toolbar:before, .column .block-addbysku .block-content .actions-toolbar:after {
    content: '';
    display: table; }
  .column .block-addbysku .block-content .actions-toolbar:after {
    clear: both; }
  .column .block-addbysku .block-content .actions-toolbar .secondary {
    float: left; }
  .column .block-addbysku .block-content .actions-toolbar .primary,
  .column .block-addbysku .block-content .actions-toolbar .secondary {
    display: inline-block; }
    .column .block-addbysku .block-content .actions-toolbar .primary .action,
    .column .block-addbysku .block-content .actions-toolbar .secondary .action {
      margin: inherit; }
    .column .block-addbysku .block-content .actions-toolbar .primary a.action,
    .column .block-addbysku .block-content .actions-toolbar .secondary a.action {
      display: inline-block;
      margin-top: inherit; }
  .column .block-addbysku .block-content .actions-toolbar .primary .action {
    margin: 0 5px 0 0; }
  .column .block-addbysku .block-content .actions-toolbar .primary a.action {
    margin-top: inherit; }
  .column .block-addbysku .block-content .actions-toolbar .secondary .action {
    margin: inherit; }
  .column .block-addbysku .block-content .actions-toolbar .secondary a.action {
    margin-top: 6px; }
  .column .block-addbysku .block-content .actions-toolbar .primary,
  .column .block-addbysku .block-content .actions-toolbar .secondary {
    display: block;
    float: none; }
  .column .block-addbysku .block-content .actions-toolbar > .secondary {
    text-align: left; }

.column .block-addbysku .reset {
  margin: 10px 0; }

.column .block-addbysku .note {
  display: block;
  margin: 0 0 20px; }

.block-cart-failed {
  margin-top: 40px; }
  .block-cart-failed .block-title {
    font-size: 26px; }
    .block-cart-failed .block-title strong {
      font-weight: 300; }
  .block-cart-failed .actions {
    text-align: center; }
    .block-cart-failed .actions .action {
      margin-bottom: 10px; }

@media only screen and (max-width: 640px) {
  .column .block-addbysku .sku {
    margin-right: -85px;
    padding-right: 90px; }
  .column .block-addbysku .action.remove {
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400; }
    .column .block-addbysku .action.remove > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .column .block-addbysku .action.remove:before {
      -webkit-font-smoothing: antialiased;
      font-size: 28px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 1px 0 0;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: top; }
    .column .block-addbysku .action.remove:hover:before {
      color: inherit; }
    .column .block-addbysku .action.remove:active:before {
      color: inherit; }
    .column .block-addbysku .action.remove:focus, .column .block-addbysku .action.remove:active {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove:hover {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove.disabled, .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; } }

@media only screen and (min-width: 640px) {
  .column .block-addbysku .action.remove {
    background-image: none;
    background: none;
    box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    display: inline;
    line-height: 1.42857;
    padding: 0;
    background: none;
    border: 0;
    color: #1979c3;
    text-decoration: none; }
    .column .block-addbysku .action.remove:focus, .column .block-addbysku .action.remove:active {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove:hover {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove.disabled, .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
    .column .block-addbysku .action.remove:visited {
      color: #1979c3;
      text-decoration: none; }
    .column .block-addbysku .action.remove:hover {
      color: #006bb4;
      text-decoration: underline; }
    .column .block-addbysku .action.remove:active {
      color: #ff5501;
      text-decoration: underline; }
    .column .block-addbysku .action.remove:hover {
      color: #006bb4; }
    .column .block-addbysku .action.remove:hover, .column .block-addbysku .action.remove:active, .column .block-addbysku .action.remove:focus {
      background: none;
      border: 0; }
    .column .block-addbysku .action.remove.disabled, .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
      color: #1979c3;
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
      text-decoration: underline; } }

@media only screen and (min-width: 768px) {
  .block-cart-failed .actions {
    text-align: left; }
  .block-cart-failed .actions.primary {
    float: right; } }

.catalogsearch-advanced-result .message.error {
  margin-top: -60px; }

@media only screen and (min-width: 640px) {
  .catalogsearch-advanced-result .message.error {
    margin-top: 0; } }

.payment-method-braintree .hosted-control {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  color: inherit;
  font-family: "Lexend", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: inherit;
  font-weight: inherit;
  height: 32px;
  line-height: 1.42857;
  margin: 0;
  padding: 0 9px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  width: 225px; }
  .payment-method-braintree .hosted-control:disabled {
    opacity: 0.5; }
  .payment-method-braintree .hosted-control::-moz-placeholder {
    color: #c2c2c2; }
  .payment-method-braintree .hosted-control::-webkit-input-placeholder {
    color: #c2c2c2; }
  .payment-method-braintree .hosted-control:-ms-input-placeholder {
    color: #c2c2c2; }
  .payment-method-braintree .hosted-control.braintree-hosted-fields-focused {
    border-color: #006bb4; }
  .payment-method-braintree .hosted-control.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) {
    border-color: #e02b27; }
    .payment-method-braintree .hosted-control.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) ~ .hosted-error {
      height: auto;
      opacity: 1; }
  .payment-method-braintree .hosted-control.braintree-hosted-fields-valid {
    border-color: #006400; }
  .payment-method-braintree .hosted-control.hosted-cid {
    width: 85px; }
  .payment-method-braintree .hosted-control.hosted-date {
    float: left;
    margin-bottom: 7px;
    width: 100px; }
    .payment-method-braintree .hosted-control.hosted-date:first-of-type {
      margin-right: 50px; }
      .payment-method-braintree .hosted-control.hosted-date:first-of-type:after {
        content: '/';
        display: inline;
        padding-bottom: inherit;
        padding-top: inherit;
        position: absolute;
        right: -25px;
        top: 5px; }

.payment-method-braintree .field-tooltip {
  right: 0; }

.payment-method-braintree .hosted-error {
  clear: both;
  color: #e02b27;
  font-size: 20px;
  height: 0;
  margin-top: 7px;
  opacity: 0; }

@media only screen and (min-width: 768px) {
  .payment-method-braintree .cvv .field-tooltip {
    left: 6rem; } }

.braintree-paypal-logo {
  margin-top: 10px;
  text-align: center; }

.action-braintree-paypal-logo {
  background-image: none;
  background: none;
  box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400; }
  .action-braintree-paypal-logo:focus, .action-braintree-paypal-logo:active {
    background: none;
    border: none; }
  .action-braintree-paypal-logo:hover {
    background: none;
    border: none; }
  .action-braintree-paypal-logo.disabled, .action-braintree-paypal-logo[disabled],
  fieldset[disabled] .action-braintree-paypal-logo {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .action-braintree-paypal-logo img {
    margin: 0;
    width: 114px; }

.bundle-options-container {
  clear: both;
  margin-bottom: 40px; }
  .bundle-options-container .legend.title {
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: 300;
    line-height: 1.1;
    font-size: 26px;
    margin-top: 7px;
    margin-bottom: 7px; }
  .bundle-options-container .product-add-form {
    display: none; }
  .bundle-options-container .product-options-wrapper {
    margin-bottom: 30px; }
  .bundle-options-container .action.back {
    margin-bottom: 30px; }
  .bundle-options-container .price-box .price {
    font-size: 18px;
    font-weight: 700; }
  .bundle-options-container .block-bundle-summary {
    background: #e8e8e8;
    padding: 10px 20px; }
    .bundle-options-container .block-bundle-summary > .title > strong {
      color: inherit;
      font-family: inherit;
      font-style: inherit;
      font-weight: 300;
      line-height: 1.1;
      font-size: 26px;
      margin-top: 7px;
      margin-bottom: 7px; }
    .bundle-options-container .block-bundle-summary .bundle-summary {
      margin-top: 30px; }
      .bundle-options-container .block-bundle-summary .bundle-summary.empty {
        display: none; }
      .bundle-options-container .block-bundle-summary .bundle-summary > .subtitle {
        color: inherit;
        font-family: inherit;
        font-style: inherit;
        font-weight: 300;
        line-height: 1.1;
        font-size: 18px;
        margin-top: 7px;
        margin-bottom: 7px;
        display: block; }
    .bundle-options-container .block-bundle-summary .bundle.items > li {
      margin-bottom: 10px; }
    .bundle-options-container .block-bundle-summary .box-tocart .actions {
      display: inline-block;
      margin-bottom: 10px; }
    .bundle-options-container .block-bundle-summary .product-addto-links > .action {
      vertical-align: top; }
  .bundle-options-container .nested .field.qty {
    margin-top: 20px; }
    .bundle-options-container .nested .field.qty .label {
      font-weight: 600;
      margin: 0 0 5px;
      display: inline-block; }
  .bundle-options-container p.required {
    color: #e02b27; }
  .bundle-options-container .product-options-bottom {
    clear: left; }

@media only screen and (min-width: 768px) {
  .bundle-options-container .bundle-options-wrapper,
  .bundle-options-container .product-options-wrapper {
    float: left;
    width: 57%; }
  .bundle-options-container .block-bundle-summary {
    float: right;
    position: relative;
    width: 40%; }
  .page-layout-2columns-left .bundle-options-container .bundle-options-wrapper,
  .page-layout-2columns-right .bundle-options-container .bundle-options-wrapper,
  .page-layout-3columns .bundle-options-container .bundle-options-wrapper, .page-layout-2columns-left
  .bundle-options-container .block-bundle-summary,
  .page-layout-2columns-right
  .bundle-options-container .block-bundle-summary,
  .page-layout-3columns
  .bundle-options-container .block-bundle-summary {
    width: 48%; } }

.catalog-product-view .block.related .block-title {
  color: #000000;
  font-family: inherit;
  font-style: inherit;
  line-height: 1.1;
  font-size: 18px;
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 20px; }

.catalog-product-view .block.related .products.products-related {
  margin: 30px 0 0 20px; }

.catalog-product-view .block.related .product-item {
  display: block;
  width: 100%;
  padding-left: 0; }
  .catalog-product-view .block.related .product-item .product-item-info {
    display: grid;
    grid-template-columns: minmax(0, 55px) minmax(0, 1fr) minmax(0, 1fr); }
    @media only screen and (min-width: 425px) {
      .catalog-product-view .block.related .product-item .product-item-info {
        grid-template-columns: 65px minmax(0, 3fr) minmax(0, 65px); } }
    @media only screen and (min-width: 1024px) {
      .catalog-product-view .block.related .product-item .product-item-info {
        grid-template-columns: 65px 1fr 65px; } }
    .catalog-product-view .block.related .product-item .product-item-info.out-of-stock {
      grid-template-columns: minmax(0, 55px) minmax(0, 1fr) minmax(0, 120px); }
      @media only screen and (min-width: 425px) {
        .catalog-product-view .block.related .product-item .product-item-info.out-of-stock {
          grid-template-columns: 65px minmax(0, 3fr) minmax(0, 80px); } }
      @media only screen and (min-width: 1024px) {
        .catalog-product-view .block.related .product-item .product-item-info.out-of-stock {
          grid-template-columns: 65px 1fr 120px; } }
    .catalog-product-view .block.related .product-item .product-item-info .stock {
      float: none; }
  .catalog-product-view .block.related .product-item .product-item-photo {
    all: unset;
    background: none;
    display: inline-block; }
  .catalog-product-view .block.related .product-item .product-item-name {
    all: unset;
    color: #000;
    display: block;
    height: auto;
    text-decoration: underline; }
  .catalog-product-view .block.related .product-item .related-price {
    display: block; }
    .catalog-product-view .block.related .product-item .related-price .price-box {
      text-align: left; }
      .catalog-product-view .block.related .product-item .related-price .price-box .price {
        color: #000;
        font-size: 16px; }
  .catalog-product-view .block.related .product-item .product-item-details {
    display: inline-block;
    padding-left: 0; }
  .catalog-product-view .block.related .product-item .product-item-link .label-icon span {
    display: none; }
  .catalog-product-view .block.related .product-item .product-item-actions {
    display: block;
    text-align: right; }
    .catalog-product-view .block.related .product-item .product-item-actions .actions-primary {
      display: block !important; }
      .catalog-product-view .block.related .product-item .product-item-actions .actions-primary .action.tocart.primary {
        background-color: #ED8400;
        width: 65px;
        height: 50px;
        padding: 5px;
        margin-bottom: 0 !important;
        line-height: 1; }
        .catalog-product-view .block.related .product-item .product-item-actions .actions-primary .action.tocart.primary:before {
          color: #fff;
          content: "+";
          display: inline-block;
          font-size: 30px;
          font-weight: 100; }
        .catalog-product-view .block.related .product-item .product-item-actions .actions-primary .action.tocart.primary:after {
          font-family: "icomoon";
          font-size: 30px;
          display: inline-block;
          color: white;
          line-height: 30px;
          text-align: center;
          cursor: pointer;
          content: "\e967"; }
        .catalog-product-view .block.related .product-item .product-item-actions .actions-primary .action.tocart.primary > span {
          display: none; }

.catalog-product-view .block.related .checkbox {
  display: none; }

/*gemixte haag nl de*/
.page-with-filter.page-products.categorypath-gemengde-hagen.category-gemengde-hagen.catalog-category-view.page-layout-2columns-left .item.product.product-item .product-item-photo .height-reset img,
.page-with-filter.page-products.categorypath-gemischte-hecken.category-gemischte-hecken.catalog-category-view.page-layout-2columns-left .item.product.product-item .product-item-photo .height-reset img {
  width: 100%; }

/* end gemixte haag nl de*/
.product-item-catalog {
  border: 1px #e2e2e2 solid; }
  .product-item-catalog:hover {
    border: 1px #c7c7c7 solid; }
    .product-item-catalog:hover .product-item-details {
      background-color: #e8e8e8; }
  .product-item-catalog .product-item-details .price-box {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 10px; }
    .product-item-catalog .product-item-details .price-box .special-price .price-label {
      display: none; }
    .product-item-catalog .product-item-details .price-box .special-price .price-container span.price {
      color: #2FDF66; }
    .product-item-catalog .product-item-details .price-box .old-price .price-label {
      display: none; }
    .product-item-catalog .product-item-details .price-box .old-price .price {
      text-decoration: line-through;
      font-size: 20px; }

.product-item-details {
  color: #000000; }

/* related products image left*/
/*.wrapper.products-related {
    &.products-grid {
        .product-item {
            width: 100%;
        }
    }

    .product-item {
        width: 100%;

        .price-box {
            margin: 0;
        }
    }

    .product-item-info {
        width: 60%;
        padding-top: 20px;
        padding-bottom: 20px;
        align-items: center;

        .product-item-photo {
            background: #fcfcfc;
            padding-top: 55px;
            padding-bottom: 45px;
            text-align: left;
            display: block;
        }

        .product.photo.product-item-photo2 {
            max-height: 100px;
            float: left;
            margin-top: 0;
            padding: 0;

            .product-image-container {
                max-height: 100px;
            }

            img {
                max-height: 100px;
                margin: 0;
            }
        }

        .product.name.product-item-name {
            height: 40px;
            width: 60%;
            float: left;
            margin: 0;

            .product-item-link2 {
                width: 100%;
                margin: 0;
                padding: 5px 10px 0;
                position: relative;
            }
        }

        .product.details.product-item-details {
            min-width: 100px;
            height: 60px;
            float: left;
            margin-right: 40%;

            .price-box.price-label {
                float: left;
            }
        }

        .field.choice.related {
            width: 50px;
            float: right;
            margin-top: 25px;

            .product-item-link2 {
                width: auto;
                margin: 0;
                position: relative;
            }
        }

        &.related-available {
            .product-item-photo2 {
                .product-image-container {
                    background-color: #fff;

                    .product-image-wrapper {
                        .product-image-photo {
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}*/
/*Hover grey home product widget*/
li.product-item:hover img {
  -webkit-filter: brightness(1.5);
  filter: brightness(1.5);
  transition: 1.5s;
  transition-timing-function: ease; }

.products2.wrapper.grid.products-grid.products-upsell .product-item-photo {
  background: #fcfcfc;
  padding-top: 55px;
  padding-bottom: 45px;
  text-align: left !important;
  display: block; }

.block.upsell .price {
  position: absolute !important;
  margin-top: -60px !important;
  margin-left: 70px !important; }

.product-item-link2 {
  margin-top: 3px;
  margin-left: 10px;
  display: block;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  font-size: 20px;
  margin-bottom: 20px;
  position: absolute;
  width: 225px;
  font-weight: 600 !important; }

.product-item-link2:hover {
  text-decoration: none !important; }

.block-title.title2 {
  color: #014A5B;
  font-size: 33px;
  font-weight: 600;
  margin-top: 90px; }

.products2 {
  margin: 0px 0;
  margin-left: 0px; }

.label-icon:before {
  font-family: "icomoon";
  font-size: 30px;
  display: inline-block;
  color: white;
  line-height: 30px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  content: "\e967";
  background-color: #ED8400; }

.products {
  margin: 30px 0 0 35px; }
  @media only screen and (max-width: 768px) {
    .products {
      margin-left: 0; } }

.product-item-smaller {
  font-size: 0.7em; }
  @media only screen and (max-width: 640px) {
    .product-item-smaller {
      display: block;
      margin-top: 4px; } }

.product-item {
  vertical-align: top;
  /*remove underline on hover*/ }
  .products-grid .product-item {
    display: inline-block;
    width: 50%; }
    @media only screen and (max-width: 639px) {
      .products-grid .product-item {
        width: 48%; } }
  .product-item-name {
    display: block;
    margin: 5px 0;
    overflow: hidden;
    font-size: 18px;
    height: 50px; }
    .product-item-name-latin {
      font-weight: 600;
      display: block;
      height: 45px;
      overflow: hidden; }
    .product-item-name a {
      color: #20272B !important;
      font-weight: 600; }
  .product-item-details {
    overflow: hidden;
    padding-left: 15px; }
  .product-item .product-item-name a:hover {
    text-decoration: none;
    opacity: 0.7; }
  .product-item-info {
    position: relative; }
    .product-item-info .product-labels {
      position: absolute;
      z-index: 9999;
      top: 0;
      right: 0;
      width: 65%;
      display: block;
      text-align: center; }
      @media only screen and (max-width: 768px) {
        .product-item-info .product-labels {
          width: 90%; } }
      .product-item-info .product-labels .buxusvervanger {
        background-color: #EB8500;
        color: #fff;
        display: block;
        font-weight: 600;
        padding: 10px; }
      .product-item-info .product-labels .uitverkocht {
        background-color: #ffb3b3;
        color: #fff;
        display: block;
        font-weight: 600;
        padding: 10px; }
  .product-item-actions {
    display: none; }
    .product-item-actions .actions-primary {
      display: none !important; }
    .product-item-actions .actions-secondary > .action {
      display: none; }
      .product-item-actions .actions-secondary > .action:before {
        margin: 0; }
  .product-item-description {
    margin: 25px 0; }
  .product-item .product-reviews-summary .rating-summary {
    margin: 0 4px 0 0; }
  .product-item .product-reviews-summary .reviews-actions {
    font-size: 12px;
    margin-top: 5px;
    text-transform: lowercase; }
  .product-item .price-box {
    margin: 10px 0 25px; }
    .product-item .price-box .price {
      text-decoration: none;
      margin-left: 0px;
      font-size: 24px;
      color: #6D6D6D;
      display: block;
      font-weight: bold; }
    .product-item .price-box .price-label {
      font-size: 16px;
      font-weight: 100; }
  .product-item .special-price {
    display: block; }
  .product-item .old-price .price {
    font-weight: 400; }
  .product-item .minimal-price .price-container {
    display: block; }
  .product-item .minimal-price-link {
    color: #6D6D6D;
    font-size: 24px;
    font-weight: 800;
    display: block;
    float: left;
    padding-right: 25px; }
    .product-item .minimal-price-link .price {
      font-weight: 400; }
  .product-item .minimal-price-link,
  .product-item .price-excluding-tax,
  .product-item .price-including-tax {
    display: block;
    white-space: nowrap;
    text-decoration: none; }
  .product-item .price-from,
  .product-item .price-to {
    margin: 0; }
  .product-item .action.tocompare:before {
    content: ""; }
  .product-item .tocart {
    white-space: nowrap; }

.column.main .product-items {
  margin-left: -20px; }
  @media only screen and (max-width: 768px) {
    .column.main .product-items {
      margin-left: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; } }

.column.main .product-item {
  padding-left: 20px; }
  @media only screen and (max-width: 768px) {
    .column.main .product-item {
      padding-left: 0; } }

.price-container .price {
  font-size: 14px; }

.price-container .price2 {
  font-size: 14px; }

.price-container .price-including-tax + .price-excluding-tax,
.price-container .weee {
  margin-top: 5px; }

.price-container .price-including-tax + .price-excluding-tax,
.price-container .weee,
.price-container .price-including-tax + .price-excluding-tax .price,
.price-container .weee .price,
.price-container .weee + .price-excluding-tax:before,
.price-container .weee + .price-excluding-tax .price {
  font-size: 11px; }

.price-container .weee:before {
  content: "(" attr(data-label) ": "; }

.price-container .weee:after {
  content: ')'; }

.price-container .weee + .price-excluding-tax:before {
  content: attr(data-label) ": "; }

.products-list .product-item {
  display: table;
  width: 100%; }
  .products-list .product-item-info {
    display: table-row; }
  .products-list .product-item-photo {
    display: table-cell;
    padding: 0 30px 30px 0;
    vertical-align: top;
    width: 1%; }
  .products-list .product-item-details {
    display: table-cell;
    vertical-align: top;
    margin-left: 18px; }

@media only screen and (max-width: 640px) {
  .products-grid .product-item {
    margin-bottom: 25px; }
  .products-list .product-item {
    table-layout: fixed; }
    .products-list .product-item-photo {
      padding: 0 10px 10px 0;
      width: 30%; } }

@media only screen and (min-width: 640px) {
  .products-grid .product-item {
    margin-bottom: 30px; }
  .product-item-actions {
    display: block; }
    .products-grid .product-item-actions {
      margin: 10px 0; }
    .product-item-actions .actions-primary + .actions-secondary {
      display: table-cell;
      padding-left: 5px;
      white-space: nowrap;
      width: 50%; }
      .product-item-actions .actions-primary + .actions-secondary > * {
        white-space: normal; }
    .product-item-actions .actions-primary {
      display: table-cell; }
  .products-grid .product-item {
    width: 33.3333%; }
  .page-products .products-grid .product-item,
  .page-layout-1column .products-grid .product-item,
  .page-layout-3columns .products-grid .product-item,
  .page-products.page-layout-1column .products-grid .product-item,
  .page-products.page-layout-3columns .products-grid .product-item {
    width: 33.3333%; } }
  @media only screen and (min-width: 640px) and (max-width: 768px) {
    .page-products .products-grid .product-item,
    .page-layout-1column .products-grid .product-item,
    .page-layout-3columns .products-grid .product-item,
    .page-products.page-layout-1column .products-grid .product-item,
    .page-products.page-layout-3columns .products-grid .product-item {
      width: 32%; } }

@media only screen and (min-width: 768px) {
  .page-products .products-grid .product-item {
    width: 33.3333%; }
  .page-products.page-layout-1column .products-grid .product-item {
    width: 25%; }
  .page-products.page-layout-3columns .products-grid .product-item {
    width: 50%; } }

@media only screen and (min-width: 1024px) {
  .products-grid .product-item {
    /*width: 20%;*/
    width: 25%; }
  .page-layout-3columns .products-grid .product-item {
    width: 25%; }
  .page-products .products-grid .product-items {
    margin: 0; }
  .page-products .products-grid .product-item {
    margin-left: 1.77%;
    padding: 0;
    width: 23.233%; }
    .page-products .products-grid .product-item:nth-child(4n + 1) {
      margin-left: 0; }
  .page-products.page-layout-1column .products-grid .product-item {
    margin-left: 0;
    width: 20%; }
  .page-products.page-layout-3columns .products-grid .product-item {
    margin-left: 1%;
    width: 32.667%; }
    .page-products.page-layout-3columns .products-grid .product-item:nth-child(3n) {
      margin-left: 1%; }
    .page-products.page-layout-3columns .products-grid .product-item:nth-child(3n + 1) {
      margin-left: 0; } }

.page-products .columns {
  position: relative;
  z-index: 1; }

.toolbar-amount {
  display: block;
  line-height: 28px;
  margin: 0;
  padding: 8px 0 0; }
  .products.wrapper ~ .toolbar .toolbar-amount {
    display: none; }

.toolbar-products {
  margin-bottom: 40px;
  padding: 0 10px;
  text-align: center; }
  .toolbar-products .pages {
    display: none; }
    .products.wrapper ~ .toolbar-products .pages {
      display: block; }
  .toolbar-products .limiter {
    display: none; }
    .toolbar-products .limiter .control {
      display: inline-block; }

.sorter {
  padding: 4px 0 0; }
  .products.wrapper ~ .toolbar .sorter {
    display: none; }

.sorter-options {
  margin: 0 0 0 7px;
  width: auto; }

.sorter-action {
  vertical-align: top;
  display: inline-block;
  text-decoration: none; }
  .sorter-action > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .sorter-action:before {
    -webkit-font-smoothing: antialiased;
    font-size: 28px;
    line-height: 32px;
    color: #8f8f8f;
    content: "";
    font-family: "icons-blank-theme";
    margin: inherit;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: middle; }
  .sorter-action:hover:before {
    color: #333; }
  .sorter-action:active:before {
    color: inherit; }

.sorter.sort-desc:before {
  content: ""; }

.modes {
  display: none; }

.limiter-options {
  margin: 0 5px 0 7px;
  width: auto; }

.limiter-label {
  font-weight: 400; }

.page-products .toolbar .limiter {
  display: none; }

@media only screen and (min-width: 768px) {
  .page-products .columns {
    padding-top: 0;
    position: relative;
    z-index: 1; }
  .products.wrapper ~ .toolbar .pages {
    float: left; }
  .toolbar-amount {
    float: left; }
  .sorter {
    float: right; }
  .modes {
    display: block;
    float: left;
    margin-right: 20px; }
    .products.wrapper ~ .toolbar .modes {
      display: none; }
  .modes-mode {
    color: #7d7d7d;
    border: 1px solid #d1d1d1;
    border-right: 0;
    float: left;
    font-weight: 400;
    line-height: 1;
    padding: 7px 10px;
    text-align: center;
    display: block;
    text-decoration: none; }
    .modes-mode > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .modes-mode:before {
      -webkit-font-smoothing: antialiased;
      font-size: 26px;
      line-height: inherit;
      color: #7d7d7d;
      content: "";
      font-family: "icons-blank-theme";
      margin: inherit;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: middle; }
    .modes-mode:hover:before {
      color: #7d7d7d; }
    .modes-mode:active:before {
      color: inherit; }
    .modes-mode:not(.active):hover {
      color: #7d7d7d;
      background: #dedede; }
    .modes-mode:last-child {
      border-right: 1px solid #d1d1d1; }
    .modes-mode.active {
      color: #a6a6a6; }
  .mode-list:before {
    content: ""; }
  .limiter {
    float: right; }
    .products.wrapper ~ .toolbar .limiter {
      display: none; } }

.fotorama-stretch, .fotorama-focus-overlay:after, .fotorama__nav__frame:focus:after, .fotorama__html, .fotorama__stage__frame, .fotorama__stage__shaft, .fotorama__video iframe {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }

.fotorama-grab-cursor, .fotorama__wrap .fotorama__grab {
  cursor: move;
  cursor: grab; }

.fotorama-grabbing-cursor, .fotorama__grabbing * {
  cursor: move;
  cursor: grabbing; }

.fotorama-gpu, .fotorama__caption, .fotorama--fullscreen, .fotorama__wrap--css3 .fotorama__html, .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full, .fotorama__wrap--css3 .fotorama__nav, .fotorama__nav:before, .fotorama__nav:after, .fotorama__wrap--css3 .fotorama__spinner, .fotorama__wrap--css3 .fotorama__stage, .fotorama__stage:before, .fotorama__stage:after, .fotorama__wrap--css3 .fotorama__stage__frame {
  transform: translateZ(0); }

.fotorama-focus, .fotorama__fullscreen-icon:focus, .fotorama__nav__frame, .fotorama__zoom-in:focus,
.fotorama__zoom-out:focus {
  outline: 0; }

.fotorama-focus-overlay:after, .fotorama__nav__frame:focus:after {
  background-color: #006bb4;
  border-radius: inherit;
  content: ''; }

.fotorama-transform-disabled, .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html, .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img, .fotorama__wrap--video .fotorama__stage, .fotorama__wrap--video .fotorama__stage__frame--video, .fotorama__wrap--video .fotorama__stage__shaft {
  transform: none !important; }

.fotorama-transition-for-slide, .fotorama__wrap--css3 .fotorama__nav__shaft, .fotorama__wrap--css3 .fotorama__stage__shaft, .fotorama__wrap--css3 .fotorama__thumb-border {
  transition-duration: unset;
  transition-property: transform, width;
  transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1); }

.fotorama-no-select, .fotorama__arr, .fotorama__fullscreen-icon, .fotorama__no-select, .fotorama__video-close, .fotorama__wrap {
  user-select: none; }

.fotorama-select, .fotorama__select {
  user-select: text; }

.fotorama-empty-bg {
  background: url(data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=); }

.fotorama-auto-margin, .fotorama__nav, .fotorama__nav__frame {
  margin: auto;
  padding: 0; }

.fotorama-inline-block, .fotorama__caption__wrap, .fotorama__nav__frame, .fotorama__nav__shaft {
  display: inline;
  display: inline-block;
  vertical-align: middle;
  zoom: 1; }

.fotorama-hidden, .fotorama--hidden, .fotorama__load {
  position: absolute;
  top: -99999px;
  left: -99999px;
  z-index: -1000; }

.fotorama-visible {
  position: relative;
  left: auto;
  top: auto;
  opacity: 1;
  z-index: auto; }

.fotorama-no-tap, .fotorama__arr, .fotorama__fullscreen-icon, .fotorama__nav, .fotorama__nav__frame, .fotorama__nav__shaft, .fotorama__stage__frame, .fotorama__stage__shaft, .fotorama__video-close {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.transitionDuration {
  transition-duration: 333ms; }

.transitionDurationZero {
  transition-duration: unset; }

.fotorama-sprite, .fotorama__arr .fotorama__arr__arr, .fotorama__fullscreen-icon, .fotorama__thumb--icon, .fotorama__video-close, .fotorama__video-play, .fotorama__fullscreen .fotorama__zoom-in, .fotorama__fullscreen .fotorama__zoom-out {
  background-image: url("../images/gallery.png");
  background-repeat: no-repeat; }

@media print {
  .fotorama-print-background, .fotorama-sprite, .fotorama__arr .fotorama__arr__arr, .fotorama__fullscreen-icon, .fotorama__thumb--icon, .fotorama__video-close, .fotorama__video-play, .fotorama__fullscreen .fotorama__zoom-in, .fotorama__fullscreen .fotorama__zoom-out, .fotorama__thumb-border {
    background: none !important; } }

.fotorama__active {
  box-shadow: 0 0 0 transparent; }

.fotorama__stage__frame.fotorama__active {
  z-index: 8; }

.fotorama__wrap--fade .fotorama__stage__frame.fotorama__active {
  display: block;
  left: 0;
  top: 0; }

.fotorama__wrap--fade .fotorama__fade-rear.fotorama__active {
  z-index: 9; }

.fotorama__wrap--only-active .fotorama__stage__frame.fotorama__active {
  visibility: visible; }

.fotorama__arr {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 80px;
  box-sizing: border-box;
  z-index: 900;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.3);
  transition: background-color 0.3s ease-in-out; }
  .fotorama__arr:hover {
    background-color: rgba(255, 255, 255, 0.5); }
  .fotorama__arr:active {
    background-color: rgba(213, 213, 213, 0.5); }
  .fotorama__arr:focus {
    box-shadow: none; }
    .fotorama__arr:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama__arr--prev {
    left: 0; }
    .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--prev:not(:focus) {
      transform: translate3d(-120px, 0, 0); }
    .fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--prev {
      transform: translate3d(-120px, 0, 0); }
  .fotorama__arr--next {
    right: 0; }
    .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--next:not(:focus) {
      transform: translate3d(120px, 0, 0); }
    .fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--next {
      transform: translate3d(120px, 0, 0); }
  .fotorama__arr--disabled {
    display: none;
    opacity: 0.1;
    pointer-events: none;
    cursor: default; }
  .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr {
    opacity: 0; }
    .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus {
      opacity: 1; }
  .fotorama__wrap--video .fotorama__arr {
    opacity: 0 !important; }
  .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr {
    display: none; }
    .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus {
      display: block; }
  .fotorama__wrap--css2.fotorama__wrap--video .fotorama__arr {
    display: none !important; }
  .fotorama__wrap--css3 .fotorama__arr:not(:focus) {
    transition-duration: 0.3s;
    transition-property: transform, opacity, background-color; }
  .fotorama__stage .fotorama__arr--shown {
    display: block !important; }
  .fotorama__stage .fotorama__arr--hidden {
    display: none !important; }

.fotorama__arr .fotorama__arr__arr {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0); }
  .ie9 .fotorama__arr .fotorama__arr__arr {
    margin: -40px 0 0 -40px; }

.fotorama__arr--prev .fotorama__arr__arr {
  background-position: -80px -80px; }

.fotorama__arr--next .fotorama__arr__arr {
  background-position: -160px -80px; }

.fotorama__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #000;
  font-size: 14px;
  line-height: 1.5;
  z-index: 12; }
  .fotorama__caption a {
    color: #000;
    text-decoration: none;
    border-bottom: 1px solid;
    border-color: fade(#000, 0.5); }
    .fotorama__caption a:hover {
      color: #000;
      border-color: fade(#000, 0.5); }
  .fotorama__wrap--rtl .fotorama__caption {
    left: auto;
    right: 0; }
  .fotorama__wrap--video .fotorama__caption,
  .fotorama__wrap--no-captions .fotorama__caption {
    display: none; }

.fotorama__caption__wrap {
  padding: 5px 10px;
  box-sizing: border-box;
  background-color: #fff; }

.fotorama__dot {
  display: block;
  position: relative;
  top: 12px;
  left: 6px;
  width: 25px;
  height: 5px;
  border-radius: 0px;
  background-color: white;
  opacity: 0.8; }
  .fotorama__nav__frame--dot:focus .fotorama__dot {
    box-shadow: none; }
    .fotorama__nav__frame--dot:focus .fotorama__dot:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama__nav__frame--thumb:focus .fotorama__dot:after,
  .fotorama__nav__frame--dot:focus .fotorama__dot:after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0; }
  .fotorama__active .fotorama__dot {
    opacity: 1; }

@media only screen and (min-width: 769px) {
  .fotorama__nav__frame {
    position: relative;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    padding-right: 15px;
    padding-left: 15px; }
  .fotorama__nav.fotorama__nav--dots {
    margin-top: -110px;
    margin-bottom: 20px; } }

.fotorama__wrap--fade .fotorama__fade-front,
.fotorama__wrap--fade .fotorama__fade-rear {
  display: block;
  left: 0;
  top: 0; }

.fotorama__wrap--fade .fotorama__fade-front {
  z-index: 8; }

.fotorama__wrap--fade .fotorama__fade-rear {
  z-index: 7; }

.fotorama {
  min-width: 1px;
  overflow: hidden; }
  .fotorama--fullscreen {
    position: fixed !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    margin: 0 !important;
    background: #fff;
    z-index: 1000 !important; }

.fotorama__fullscreen {
  overflow: hidden;
  position: relative; }

.fotorama__fullscreen-icon {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  background-position: 0 0;
  cursor: pointer;
  z-index: 1000; }
  .fotorama__fullscreen-icon:focus {
    box-shadow: none; }
    .fotorama__fullscreen-icon:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama--fullscreen-icons .fotorama__fullscreen-icon {
    display: none; }
  .fotorama--fullscreen .fotorama__fullscreen-icon {
    display: inline-block;
    background-position: -80px 0; }
  .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
    opacity: 0; }
    .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
      opacity: 1; }
  .fotorama__wrap--video .fotorama__fullscreen-icon {
    opacity: 0 !important; }
  .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
    display: none; }
    .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
      display: block; }
  .fotorama__wrap--css2.fotorama__wrap--video .fotorama__fullscreen-icon {
    display: none !important; }
  .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:not(:focus) {
    transform: translate3d(80px, -80px, 0); }
  .fotorama__wrap--css3.fotorama__wrap--video .fotorama__fullscreen-icon {
    transform: translate3d(80px, -80px, 0) !important; }
  .fotorama__wrap--css3 .fotorama__fullscreen-icon:not(:focus) {
    transition-duration: 0.3s;
    transition-property: transform, opacity, background-color; }
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon {
    background-color: rgba(255, 255, 255, 0.3);
    transition: background-color 0.3s ease-in-out; }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon:hover {
      background-color: rgba(255, 255, 255, 0.5); }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon:active {
      background-color: rgba(213, 213, 213, 0.5); }

.gallery-placeholder {
  height: 0;
  padding-bottom: calc(100% + 94px);
  line-height: 0; }
  .gallery-placeholder .loading-mask {
    position: static;
    padding: 0 0 50%; }
  .gallery-placeholder .loader img {
    position: absolute; }

.fotorama__wrap--css3 .fotorama__html {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: linear; }

.fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html {
  opacity: 0; }

.fotorama__img {
  max-width: inherit;
  opacity: 0;
  border: none !important; }
  .fotorama__loaded .fotorama__img,
  .fotorama__error .fotorama__img {
    opacity: 1; }
  .fotorama__img--full {
    display: none; }
  .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img {
    opacity: 0; }
  .fotorama__nav-wrap .fotorama_vertical_ratio .fotorama__img {
    position: absolute;
    top: 50%;
    width: 100%;
    height: auto;
    transform: translateY(-50%); }
  .fotorama__nav-wrap .fotorama_horizontal_ratio .fotorama__img {
    position: absolute;
    left: 50%;
    width: auto;
    height: 100%;
    transform: translateX(-50%); }
  .fotorama__stage__frame .fotorama__img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    max-height: 100%;
    transform: translate3d(-50%, -50%, 0);
    transition-duration: 0.3s;
    transition-property: width, height, top, left; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img {
    display: none; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img,
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: inherit;
    max-height: inherit; }
  .fotorama--fullscreen .fotorama__loaded--full .fotorama__img {
    display: none; }
    .fotorama--fullscreen .fotorama__loaded--full .fotorama__img--full {
      display: block; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full {
    display: block;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    cursor: default;
    transition: 0.3s linear; }
    .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full.fotorama__img--zoommable {
      max-width: none;
      max-height: none;
      cursor: pointer;
      transition-property: width, height, bottom, right, top, left; }
    .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full.fotorama__img--draggable {
      cursor: move;
      transition-property: none; }
  .fotorama__product-video--loaded .fotorama__img,
  .fotorama__product-video--loaded .fotorama__img--full {
    display: none !important; }

.magnifier-thumb-wrapper {
  position: relative;
  display: block;
  top: 0;
  left: 0; }

.magnifier-lens {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  border: solid 1px #bbb;
  z-index: 800; }

.magnifier-loader-text {
  margin-top: 10px; }

.magnifier-large {
  position: absolute;
  width: 32%;
  z-index: 100; }

.magnifier-preview {
  position: absolute;
  top: 215px;
  bottom: 0;
  left: 58%;
  overflow: hidden;
  padding: 0; }
  .magnifier-preview:not(.hidden) {
    background-color: #fff; }
  .magnifier-preview img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: inherit; }

.magnify-lens {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
  border: solid 1px #bbb;
  z-index: 1000; }

.magnify-opaque {
  opacity: 0.5; }

.magnify-hidden {
  display: none; }

.magnify-fullimage {
  display: none; }
  .fotorama__fullscreen .magnify-fullimage {
    display: inline-block; }

.fotorama__nav {
  position: relative;
  display: none;
  max-width: 100%;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  white-space: nowrap;
  z-index: 5; }
  .fotorama__nav--dots {
    display: block;
    text-align: center; }
  .fotorama__nav--thumbs {
    display: block; }
  .fotorama__nav:before, .fotorama__nav:after {
    position: absolute;
    display: block;
    content: '';
    text-decoration: none;
    background-repeat: no-repeat;
    z-index: 10;
    pointer-events: none; }
  .fotorama__nav:before {
    left: -10px;
    top: -10px; }
  .fotorama__nav:after {
    right: -10px;
    bottom: -10px; }
  .fotorama--fullscreen .fotorama__nav:before, .fotorama--fullscreen .fotorama__nav:after,
  .fotorama__wrap--no-shadows .fotorama__nav:before,
  .fotorama__wrap--no-shadows .fotorama__nav:after {
    display: none; }
  .fotorama__wrap--only-active .fotorama__nav {
    max-width: 99999px !important; }

.fotorama__nav-wrap--vertical.fotorama__nav-wrap {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0; }

.fotorama__nav__frame {
  position: relative;
  box-sizing: content-box; }
  .fotorama__nav--dots .fotorama__nav__frame {
    width: 18px;
    height: 30px; }
    .fotorama__nav--dots .fotorama__nav__frame--thumb {
      display: none; }
  .fotorama__nav--thumbs .fotorama__nav__frame {
    padding-left: 0 !important; }
    .fotorama__nav--thumbs .fotorama__nav__frame:last-child {
      padding-right: 0 !important; }
    .fotorama__nav--thumbs .fotorama__nav__frame--dot {
      display: none; }
  .fotorama__nav__frame.fotorama__active .fotorama__nav__frame {
    width: 0;
    height: 0;
    border-width: 3px; }
    .fotorama__nav__frame.fotorama__active .fotorama__nav__frame:after {
      top: -3px;
      left: -3px;
      padding: 3px; }
  .fotorama__nav__frame:focus {
    box-shadow: none; }
    .fotorama__nav__frame:focus :after {
      top: -1px;
      left: -1px;
      padding: 1px; }
  .fotorama__nav-wrap--vertical .fotorama__nav__shaft .fotorama__nav__frame--thumb {
    display: block;
    padding-bottom: inherit !important; }

.fotorama__nav__shaft {
  position: relative;
  top: 0;
  left: 0;
  text-align: left; }
  .fotorama__nav-wrap--vertical .fotorama__nav__shaft {
    width: 100%;
    background-color: white; }

.fotorama__wrap .fotorama__pointer {
  cursor: pointer; }

.fotorama__select {
  cursor: auto; }

.fotorama__wrap--fade .fotorama__stage .fotorama__shadow {
  display: none; }

.fotorama__nav.fotorama__shadows--left:before, .fotorama__nav.fotorama__shadows--right:after,
.fotorama__stage.fotorama__shadows--left:before,
.fotorama__stage.fotorama__shadows--right:after {
  top: 0;
  bottom: 0;
  width: 10px;
  height: auto;
  background-size: 1px 100%, 5px 100%; }

.fotorama__nav.fotorama__shadows--top:before, .fotorama__nav.fotorama__shadows--bottom:after,
.fotorama__stage.fotorama__shadows--top:before,
.fotorama__stage.fotorama__shadows--bottom:after {
  left: 0;
  right: 0;
  width: auto;
  height: 10px;
  background-size: 100% 1px, 100% 5px; }

.fotorama__nav.fotorama__shadows--left:before,
.fotorama__stage.fotorama__shadows--left:before {
  left: 0;
  background-position: 0 0, 0 0;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.4), transparent); }

.fotorama__nav.fotorama__shadows--right:after,
.fotorama__stage.fotorama__shadows--right:after {
  right: 0;
  background-position: 100% 0, 100% 0;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.4), transparent); }

.fotorama__nav.fotorama__shadows--top:before,
.fotorama__stage.fotorama__shadows--top:before {
  top: 0;
  background-position: 0 0, 0 0;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.4), transparent); }

.fotorama__nav.fotorama__shadows--bottom:after,
.fotorama__stage.fotorama__shadows--bottom:after {
  bottom: 0;
  background-position: 0 100%, 0 100%;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.4), transparent); }

.fotorama__video-close.fotorama-show-control {
  opacity: 1;
  transform: translate3d(0, -10px, 0); }

.fotorama__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  display: none;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  height: 64px;
  width: 64px;
  margin: auto;
  background-image: url("../images/loader-1.gif");
  z-index: 100; }
  .fotorama__spinner--show {
    display: block; }
  .fotorama__wrap--css3 .fotorama__spinner {
    animation: spinner 24s infinite linear; }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fotorama__stage {
  position: relative;
  max-width: 100%;
  overflow: hidden; }
  .fotorama__stage:before, .fotorama__stage:after {
    position: absolute;
    display: block;
    content: '';
    background-repeat: no-repeat;
    pointer-events: none;
    text-decoration: none;
    z-index: 10; }
  .fotorama__stage:before {
    left: -10px;
    top: -10px; }
  .fotorama__stage:after {
    right: -10px;
    bottom: -10px; }
  .fotorama--fullscreen .fotorama__stage:before, .fotorama--fullscreen .fotorama__stage:after,
  .fotorama__wrap--no-shadows .fotorama__stage:before,
  .fotorama__wrap--no-shadows .fotorama__stage:after,
  .fotorama__wrap--fade .fotorama__stage:before,
  .fotorama__wrap--fade .fotorama__stage:after {
    display: none; }
  .fotorama__wrap--only-active .fotorama__stage {
    max-width: 99999px !important; }

.fotorama__stage__frame {
  overflow: hidden;
  text-align: center; }
  .fotorama__wrap--rtl .fotorama__stage__frame {
    direction: rtl; }
  .fotorama__wrap--slide .fotorama__stage__frame {
    opacity: 1 !important; }
  .fotorama__wrap--fade .fotorama__stage__frame {
    display: none; }
  .fotorama--fullscreen .fotorama__stage__frame iframe {
    left: 80px;
    width: calc(100% - 80px * 2); }
  .fotorama__wrap--only-active .fotorama__stage__frame {
    visibility: hidden; }

.fotorama__stage__shaft {
  position: relative; }
  .fotorama__stage__shaft:focus {
    box-shadow: none; }
    .fotorama__stage__shaft:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }

.fotorama__thumb {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #ebebeb; }
  .fotorama__nav__frame:focus .fotorama__thumb {
    box-shadow: none;
    z-index: 2; }
    .fotorama__nav__frame:focus .fotorama__thumb:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 0 3px 1px #68a8e0;
      border-radius: inherit;
      content: '';
      z-index: 1000; }
  .fotorama__nav__frame--thumb:focus .fotorama__thumb,
  .fotorama__nav__frame--dot:focus .fotorama__thumb {
    overflow: inherit; }
  .fotorama__nav__frame:nth-child(2):focus .fotorama__thumb:after {
    left: 1px; }
  .fotorama__nav__frame:last-child:focus .fotorama__thumb:after {
    right: 1px; }
  .fotorama__thumb--icon {
    padding-bottom: 30px;
    font-size: 0.001px; }
    .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left .fotorama__thumb--icon,
    .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right .fotorama__thumb--icon {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0); }
      .ie9 .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left .fotorama__thumb--icon, .ie9
      .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right .fotorama__thumb--icon {
        margin: -15px 0 0 -15px; }
    .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left .fotorama__thumb--icon,
    .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right .fotorama__thumb--icon {
      margin: auto;
      width: 30px;
      transform: rotate(90deg); }
    .fotorama__thumb__arr--left .fotorama__thumb--icon {
      background-position: -25px -265px; }
    .fotorama__thumb__arr--right .fotorama__thumb--icon {
      background-position: -25px -350px; }

.fotorama__thumb-border {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border: 1px solid #2FDF66;
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.25), rgba(64, 64, 64, 0.1));
  backface-visibility: hidden;
  z-index: 9; }
  .fotorama__nav--dots .fotorama__thumb-border {
    display: none; }

.fotorama__thumb__arr {
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.3);
  transition: background-color 0.3s ease-in-out; }
  .fotorama__thumb__arr:hover {
    background-color: rgba(255, 255, 255, 0.5); }
  .fotorama__thumb__arr:active {
    background-color: rgba(213, 213, 213, 0.5); }
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left,
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30px;
    z-index: 1000;
    cursor: pointer; }
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left {
    left: 0; }
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right {
    right: 0; }
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left,
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right {
    position: absolute;
    left: 0;
    right: 0;
    height: 30px;
    cursor: pointer;
    z-index: 1000; }
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left {
    top: 0; }
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right {
    bottom: 0; }

.fotorama__nav__frame--thumb:focus .fotorama__thumb.fotorama_vertical_ratio:after,
.fotorama__nav__frame--dot:focus .fotorama__thumb.fotorama_vertical_ratio:after {
  left: 2px;
  right: 2px; }

.fotorama__nav__frame:nth-child(2):focus .fotorama__thumb.fotorama_vertical_ratio:after {
  top: 1px; }

.fotorama__nav__frame:last-child:focus .fotorama__thumb.fotorama_vertical_ratio:after {
  bottom: 1px; }

.fotorama__video {
  position: absolute;
  top: 32px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10; }

.fotorama__video-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  opacity: 0;
  z-index: 1000;
  cursor: pointer;
  background-position: -80px 0;
  transform: translate3d(80px, -80px, 0);
  transition: opacity 0.3s ease-in-out; }
  .fotorama__fullscreen .fotorama__video-close {
    display: none; }
  .fotorama__wrap--toggle-arrows:not(.fotorama__wrap--video) .fotorama__video-close {
    display: none; }
  .fotorama__wrap--toggle-arrows.fotorama__wrap--video .fotorama__video-close {
    top: 97px;
    right: 93px;
    opacity: 1; }
  .fotorama__wrap--css3 .fotorama__video-close:not(:focus) {
    transition-duration: 0.3s;
    transition-property: transform, opacity, background-color; }
  @media all and (max-width: 768px) {
    .fotorama__video-close {
      top: 10px;
      width: 40px;
      height: 40px;
      background-position: -100px -20px; } }

.fotorama__video-play {
  width: 160px;
  height: 160px;
  background-position: -80px -160px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0); }
  .fotorama__wrap--css2 .fotorama__video-play,
  .fotorama__wrap--video .fotorama__stage .fotorama__video-play {
    display: none; }
  .fotorama__loaded .fotorama__video-play,
  .fotorama__error .fotorama__video-play,
  .fotorama__nav__frame .fotorama__video-play {
    display: block;
    opacity: 1; }
  .fotorama__nav__frame .fotorama__video-play {
    width: 80px;
    height: 80px;
    background-position: 0 -240px; }
  .fotorama__wrap--css3 .fotorama__video-play:not(:focus) {
    transition-duration: 0.3s;
    transition-property: transform, opacity, background-color; }

.fotorama__wrap {
  position: relative;
  direction: ltr;
  text-size-adjust: 100%;
  z-index: 0; }
  .fotorama--fullscreen .fotorama__wrap {
    max-width: 100% !important; }
  .fotorama__wrap--pan-y {
    touch-action: pan-y; }

.fotorama__zoom-in,
.fotorama__zoom-out {
  display: none;
  z-index: 1000; }
  .fotorama__zoom-in:focus,
  .fotorama__zoom-out:focus {
    box-shadow: none; }
    .fotorama__zoom-in:focus:after,
    .fotorama__zoom-out:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in, .fotorama__wrap:not(.fotorama__wrap--toggle-arrows)
  .fotorama__zoom-out {
    background-color: rgba(255, 255, 255, 0.3);
    transition: background-color 0.3s ease-in-out; }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in:hover, .fotorama__wrap:not(.fotorama__wrap--toggle-arrows)
    .fotorama__zoom-out:hover {
      background-color: rgba(255, 255, 255, 0.5); }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in:active, .fotorama__wrap:not(.fotorama__wrap--toggle-arrows)
    .fotorama__zoom-out:active {
      background-color: rgba(213, 213, 213, 0.5); }
  .fotorama__fullscreen .fotorama__zoom-in, .fotorama__fullscreen
  .fotorama__zoom-out {
    position: absolute;
    display: block;
    width: 80px;
    height: 80px;
    overflow: hidden;
    cursor: pointer; }
    .fotorama__fullscreen .fotorama__zoom-in--disabled, .fotorama__fullscreen
    .fotorama__zoom-out--disabled {
      display: none; }

.fotorama__fullscreen .fotorama__zoom-out {
  top: 80px;
  background-position: 0 -80px !important; }

.fotorama__fullscreen .fotorama__zoom-in {
  background-position: 0 0 !important;
  top: 0; }

.block.filter.filter-no-options {
  display: none; }

.block.filter .filter-current .block-subtitle {
  font-weight: 400; }

.catalog-product-view .availability.only {
  color: #CE2D1B;
  display: block;
  font-weight: 700;
  padding: 10px 0; }

.catalog-product-view.page-product-grouped .availability.only {
  clear: both;
  float: right;
  padding: 0 10px; }

.catalog-product-view .action-label {
  clear: both;
  color: #ED8400;
  display: inline-block;
  float: right;
  font-weight: 600;
  line-height: 20px;
  padding: 10px;
  user-select: none; }

.filter-options .filter-options-item .filter-options-title {
  font-size: 16px; }

.filter-options .filter-options-item .items .item input[type="checkbox"] {
  display: none; }

.filter-options .filter-options-item .items .item a {
  text-decoration: none; }
  .filter-options .filter-options-item .items .item a label {
    color: #333;
    cursor: pointer; }

.product.attribute.overview h2 {
  display: none; }

.grouped-item-container .actionbar .control.qty .action.primary.toquote {
  padding: 0 !important; }

.catalog-product-view .product-info-main .item .btn-wrapper .action.primary.toquote {
  width: 100%; }

.action.primary.toquote {
  background: #EB8500;
  float: none;
  top: 0;
  right: 0;
  font-size: 16px;
  line-height: 70px;
  height: 100%; }

@media only screen and (max-width: 768px) {
  #mobile-click-open {
    text-align: center;
    cursor: pointer; }
    #mobile-click-open:after {
      content: "\e900";
      font-family: 'icomoon';
      display: block;
      float: right;
      margin-right: 10px; }
    #mobile-click-open.open:after {
      transform: rotate(180deg); } }

.mobile-height-container {
  overflow: hidden; }
  @media only screen and (max-width: 768px) {
    .mobile-height-container {
      height: 0;
      transition: height 0.3s; } }
  @media only screen and (min-width: 769px) {
    .mobile-height-container {
      height: auto !important; } }

.product-info-main .price-box {
  margin-bottom: 10px; }

@media only screen and (max-width: 768px) {
  .UPS-text,
  .trusted {
    text-align: center; }
  .callout-block {
    width: 320px;
    position: relative;
    left: 50%;
    margin-left: -160px; }
  .catalog-product-view.page-product-grouped .availability.only {
    clear: both;
    float: left;
    padding: 0; }
  .catalog-product-view.page-product-grouped .delivery-available {
    clear: both; }
  .catalog-product-view.page-product-grouped .action-label {
    clear: both;
    float: left;
    padding: 0 0 20px 0;
    margin: 0; } }

@media only screen and (max-width: 340px) {
  .callout-block {
    width: 100%;
    left: 0;
    margin-left: 0; } }

/*op homepage*/
.cms-index-index .column.main .product-item .product-item-details .price-box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px; }
  .cms-index-index .column.main .product-item .product-item-details .price-box .special-price .price-container span.price {
    color: #2FDF66; }
  .cms-index-index .column.main .product-item .product-item-details .price-box .old-price .price-label {
    display: none; }
  .cms-index-index .column.main .product-item .product-item-details .price-box .old-price .price {
    text-decoration: line-through;
    font-size: 20px; }

.product-item-photo2 {
  background: #fcfcfc;
  padding-top: 55px;
  padding-bottom: 45px;
  text-align: left;
  display: block; }

.product-item-photo {
  text-align: center;
  display: block;
  height: auto; }

.item.product.product-item .product-item-photo {
  background: #fcfcfc;
  text-align: center;
  display: block;
  height: auto;
  width: 100%;
  height: 0;
  position: relative;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 100%; }
  .item.product.product-item .product-item-photo .height-reset {
    position: absolute;
    width: 100%;
    height: 100%; }
    .item.product.product-item .product-item-photo .height-reset img {
      width: 100%;
      max-width: 100%;
      height: auto;
      left: 50%;
      top: 50%;
      position: relative;
      display: block;
      transform: translate(-50%, -50%); }
  .item.product.product-item .product-item-photo img {
    width: auto;
    height: auto; }

.value h2 {
  text-align: left !important;
  margin-top: 0px !important; }
  @media only screen and (max-width: 768px) {
    .value h2 {
      text-align: center !important; } }

.tooltip {
  display: inline;
  position: relative; }

.tooltip:hover:after {
  background: #333;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  bottom: 30px;
  color: #fff;
  content: attr(title);
  left: 3%;
  padding: 5px 15px;
  position: absolute;
  z-index: 98;
  width: 220px; }

.tooltip:hover:before {
  border: solid;
  border-color: #333 transparent;
  border-width: 6px 6px 0 6px;
  bottom: 26px;
  content: "";
  left: 5%;
  position: absolute;
  z-index: 99; }

a.tooltip {
  color: #014A5B;
  text-decoration: none; }

.star {
  font-size: 21px; }

.versandkosten {
  font-size: 13px;
  line-height: 24px;
  margin-left: 15px;
  margin-bottom: 5px; }

.input-checkbox.input-zigzag:focus {
  -webkit-box-shadow: none;
  box-shadow: none; }

.prices-tier .price-container .price-including-tax + .price-excluding-tax:before {
  content: "(" attr(data-label) ": "; }

.prices-tier .price-container .price-including-tax + .price-excluding-tax:last-child:after {
  content: ')'; }

.prices-tier .price-container .weee[data-label] {
  display: inline; }
  .prices-tier .price-container .weee[data-label] .price {
    font-size: 11px; }
  .prices-tier .price-container .weee[data-label]:before {
    content: " +" attr(data-label) ": "; }

.actual-price {
  font-weight: 700; }

.category-view {
  overflow: hidden;
  display: flex;
  position: relative; }
  @media only screen and (max-width: 890px) {
    .category-view {
      display: block; } }

.category-image, .category-description-container {
  background: #005266;
  color: white;
  width: 50%;
  padding: 0;
  box-sizing: border-box; }
  @media only screen and (max-width: 890px) {
    .category-image, .category-description-container {
      width: 100%; } }
  .category-image img, .category-description-container img {
    width: 100%; }
  .category-image a, .category-image .alink, .category-description-container a, .category-description-container .alink {
    color: white !important;
    text-decoration: underline;
    display: inline; }
    .category-image a:hover, .category-image .alink:hover, .category-description-container a:hover, .category-description-container .alink:hover {
      color: #2FDF66 !important; }
  .category-image h2, .category-description-container h2 {
    text-align: left;
    font-size: 25px !important;
    margin-bottom: 33px;
    font-weight: 600; }
  .category-image h3, .category-description-container h3 {
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: 600;
    line-height: 1.1;
    font-size: 18px;
    margin-top: 59px;
    margin-bottom: 7px; }

.category-image .image {
  display: block;
  height: auto;
  max-width: 100%;
  max-height: 259px;
  overflow: hidden; }

@media only screen and (max-width: 1250px) {
  .category-image {
    overflow: hidden; }
    .category-image .image {
      max-width: none;
      width: 150%;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); } }

@media only screen and (max-width: 890px) {
  .category-image {
    overflow: hidden; }
    .category-image .image {
      max-width: 100%;
      width: 100%;
      position: relative;
      top: 0;
      left: 0;
      transform: none; } }

.category-description-container {
  padding: 20px; }
  .category-description-container h2 {
    margin-top: 15px !important; }
  @media only screen and (max-width: 890px) {
    .category-description-container {
      box-sizing: border-box; }
      .category-description-container .category-short-description * {
        text-align: center; } }

.product-image-container {
  display: inline-block;
  max-width: 100%;
  background-color: #F4F4F4; }

.product-image-wrapper {
  display: block;
  height: 0;
  overflow: hidden;
  position: relative;
  z-index: 1; }

.product-image-photo {
  bottom: 0;
  display: inline-block;
  height: auto;
  left: 0;
  margin: auto;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0; }

.product.media .placeholder .photo.container {
  max-width: 100%; }

.product.media .notice {
  color: #7d7d7d;
  font-size: 12px;
  margin: 10px 0; }

.product.media .product.thumbs {
  margin: 20px 0 30px; }

.product.media .items.thumbs {
  margin: 0;
  padding: 0;
  list-style: none none; }
  .product.media .items.thumbs > li {
    display: inline-block;
    vertical-align: top; }
  .product.media .items.thumbs .active {
    display: block;
    line-height: 1; }

.product.info.detailed {
  clear: both;
  margin-bottom: 30px; }
  .product.info.detailed .additional-attributes {
    width: auto; }
    .product.info.detailed .additional-attributes > thead > tr > td,
    .product.info.detailed .additional-attributes > tbody > tr > td,
    .product.info.detailed .additional-attributes > tfoot > tr > td {
      padding: 5.5px 5px 10px 5px; }
    .product.info.detailed .additional-attributes > thead > tr > th,
    .product.info.detailed .additional-attributes > tbody > tr > th,
    .product.info.detailed .additional-attributes > tfoot > tr > th {
      padding: 5.5px 30px 10px 0; }

.product-info-main .product.pricing {
  display: block;
  clear: both;
  margin-top: 90px; }

.product-info-main .page-title-wrapper .page-title {
  line-height: 1.42857;
  margin-bottom: 10px; }
  @media only screen and (min-width: 769px) {
    .product-info-main .page-title-wrapper .page-title {
      margin-top: 50px !important; } }

.product-info-main .stock.available {
  display: inline-block;
  font-size: 15px;
  vertical-align: top;
  float: right; }
  @media only screen and (max-width: 768px) {
    .product-info-main .stock.available {
      float: none;
      position: relative;
      left: 50%;
      transform: translateX(-50%); } }

.product-info-main .stock.unavailable {
  display: inline-block;
  font-size: 15px;
  vertical-align: top;
  color: #CE2D1B;
  float: right; }

.product-info-main .stock.available::before {
  font-size: 30px;
  display: inline-block;
  color: #2FDF66;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  content: "•";
  float: left;
  padding-right: 5px; }

.product-info-main .product.attribute.sku {
  display: inline-block;
  vertical-align: top;
  color: #7d7d7d; }
  .product-info-main .product.attribute.sku > .value {
    display: inline-block;
    vertical-align: top;
    word-break: break-all; }
  .product-info-main .product.attribute.sku .type {
    margin-right: 5px; }

.product-info-main .product.attribute.overview {
  margin: 20px 0; }
  @media only screen and (max-width: 768px) {
    .product-info-main .product.attribute.overview {
      text-align: center; } }

.product-info-main .product.alert {
  margin: 10px 0; }

.product-info-main .price-box {
  margin-top: 10px; }
  .product-info-main .price-box .special-price .price-label,
  .product-info-main .price-box .old-price .price-label {
    display: none; }

.product-info-main .product-reviews-summary .reviews-actions {
  font-size: 14px; }

.product-options-wrapper .fieldset-product-options-inner .legend {
  font-weight: 700;
  margin: 0 0 5px;
  font-size: 14px;
  border: none;
  display: inline-block;
  padding: 0; }

.product-options-wrapper .fieldset-product-options-inner.required .legend:after, .product-options-wrapper .fieldset-product-options-inner._required .legend:after {
  content: '*';
  color: #e02b27;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-size: 12px;
  margin: 0 0 0 5px; }

.product-options-wrapper .field .note {
  display: block; }

.product-info-main .price-box .price-including-tax + .price-excluding-tax,
.product-info-main .price-box .weee + .price-excluding-tax,
.product-info-main .price-box .weee,
.product-options-bottom .price-box .price-including-tax + .price-excluding-tax,
.product-options-bottom .price-box .weee + .price-excluding-tax,
.product-options-bottom .price-box .weee {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 5px; }
  .product-info-main .price-box .price-including-tax + .price-excluding-tax .price,
  .product-info-main .price-box .weee + .price-excluding-tax .price,
  .product-info-main .price-box .weee .price,
  .product-options-bottom .price-box .price-including-tax + .price-excluding-tax .price,
  .product-options-bottom .price-box .weee + .price-excluding-tax .price,
  .product-options-bottom .price-box .weee .price {
    font-size: 12px;
    font-weight: 700; }

.product-info-main .price-box .price-wrapper .price,
.product-options-bottom .price-box .price-wrapper .price {
  font-size: 18px;
  font-weight: 700; }

.product-info-main .price-box .price,
.product-options-bottom .price-box .price {
  white-space: nowrap; }

.product-info-main .special-price,
.product-options-bottom .special-price {
  display: block;
  margin: 10px 0; }
  .product-info-main .special-price .price-container,
  .product-options-bottom .special-price .price-container {
    font-size: 14px; }
  .product-info-main .special-price .price-label + .price-wrapper,
  .product-options-bottom .special-price .price-label + .price-wrapper {
    display: inline-block; }

.product-info-main .box-tocart,
.product-options-bottom .box-tocart {
  margin: 20px 0; }
  .product-info-main .box-tocart .field.qty,
  .product-options-bottom .box-tocart .field.qty {
    padding-right: 15px; }
  .product-info-main .box-tocart .input-text.qty,
  .product-options-bottom .box-tocart .input-text.qty {
    height: 52px;
    text-align: center;
    width: 52px; }
  .product-info-main .box-tocart .actions,
  .product-options-bottom .box-tocart .actions {
    text-align: center; }

.product-info-main .product-addto-links,
.product-options-bottom .product-addto-links {
  margin: 20px 0; }

.product-info-main .action.tocompare,
.product-options-bottom .action.tocompare {
  vertical-align: top; }

.prices-tier {
  background: #f5f5f5;
  margin: 10px 0;
  padding: 10px 15px; }
  .prices-tier .price-container {
    display: inline-block; }
  .prices-tier .price-including-tax,
  .prices-tier .price-excluding-tax,
  .prices-tier .weee {
    display: inline-block; }
    .prices-tier .price-including-tax .price,
    .prices-tier .price-excluding-tax .price,
    .prices-tier .weee .price {
      font-size: 14px;
      font-weight: 700; }

.ui-dialog-titlebar-close {
  display: inline;
  line-height: 1.42857;
  padding: 0;
  background: none;
  border: 0;
  color: #1979c3;
  text-decoration: none; }
  .ui-dialog-titlebar-close:visited {
    color: #1979c3;
    text-decoration: none; }
  .ui-dialog-titlebar-close:hover {
    color: #006bb4;
    text-decoration: underline; }
  .ui-dialog-titlebar-close:active {
    color: #ff5501;
    text-decoration: underline; }
  .ui-dialog-titlebar-close:hover {
    color: #006bb4; }
  .ui-dialog-titlebar-close:hover, .ui-dialog-titlebar-close:active, .ui-dialog-titlebar-close:focus {
    background: none;
    border: 0; }
  .ui-dialog-titlebar-close.disabled, .ui-dialog-titlebar-close[disabled],
  fieldset[disabled] .ui-dialog-titlebar-close {
    color: #1979c3;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

.page-product-grouped.page-layout-1column .columns {
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box; }
  @media only screen and (min-width: 769px) {
    .page-product-grouped.page-layout-1column .columns {
      display: flex;
      justify-content: center; } }

.page-product-grouped.page-layout-1column .table-wrapper.grouped .stock.unavailable {
  color: #CE2D1B;
  font-weight: bold; }
  @media only screen and (max-width: 768px) {
    .page-product-grouped.page-layout-1column .table-wrapper.grouped .stock.unavailable {
      text-align: center;
      padding-bottom: 10px; } }
  @media only screen and (min-width: 769px) {
    .page-product-grouped.page-layout-1column .table-wrapper.grouped .stock.unavailable {
      float: right;
      padding-right: 10px; } }

.page-product-grouped.page-layout-1column .UPS-text,
.page-product-grouped.page-layout-1column .trusted,
.page-product-grouped.page-layout-1column .callout-block {
  width: 100%; }

.sidebar .product-items .product-item {
  margin-bottom: 20px;
  position: relative; }
  .sidebar .product-items .product-item-info {
    position: relative;
    width: auto; }
    .sidebar .product-items .product-item-info .product-item-photo {
      left: 0;
      position: absolute;
      top: 0; }
  .sidebar .product-items .product-item-name {
    margin-top: 0; }
  .sidebar .product-items .product-item-details {
    margin: 0 0 0 85px; }
  .sidebar .product-items .product-item-actions {
    display: block;
    margin-top: 10px; }

.sidebar .product-items .price-box {
  display: block;
  margin: 7px 0; }

.sidebar .product-items .text {
  margin-right: 8px; }

.sidebar .product-items .counter {
  color: #7d7d7d;
  font-size: 12px;
  white-space: nowrap; }

.sidebar .product-items .minilist .price {
  display: inline;
  padding: 0; }

.sidebar .product-items .minilist .weee:before {
  display: inline-block; }

.sidebar .action.delete {
  position: absolute;
  right: 0;
  top: 0; }

.sidebar .product-items-images {
  margin-left: -5px; }
  .sidebar .product-items-images .product-item {
    float: left;
    padding-left: 5px; }

.sidebar .product-items-names .product-item {
  margin-bottom: 10px; }

.sidebar .product-items-names .product-item-name {
  margin: 0; }

@media (max-width: 640px) {
  .product-item-name {
    font-size: 16px;
    height: 46px; } }

@media (max-width: 768px) {
  .product-item-name {
    font-size: 16px !important; } }

.product-item-name h3 {
  font-size: 25px; }

@media only screen and (max-width: 1300px) {
  #product-attribute-specs-table {
    font-size: 13px; } }

@media only screen and (max-width: 768px) {
  .tier_prices {
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%); }
  .catalog-product-view .column.main {
    display: flex;
    flex-direction: column; }
  .catalog-product-view .product.media {
    order: -1; }
  .compare,
  .product-addto-links .action.tocompare,
  .product-item-actions .actions-secondary > .action.tocompare,
  [class*='block-compare'] {
    display: none; } }

@media only screen and (min-width: 768px) {
  .product-info-main .box-tocart,
  .product-options-bottom .box-tocart {
    display: table; }
    .product-info-main .box-tocart .field.qty,
    .product-options-bottom .box-tocart .field.qty {
      display: table-cell; }
    .product-info-main .box-tocart .actions,
    .product-options-bottom .box-tocart .actions {
      display: table-cell;
      text-align: center;
      vertical-align: bottom; }
  .product-info-main .page-title-wrapper .page-title {
    margin-top: -13px; }
  .sidebar .product-items .product-item-info .product-item-photo {
    float: left;
    left: auto;
    margin: 0 10px 10px 0;
    position: relative;
    top: auto; }
  .sidebar .product-items .product-item-details {
    margin: 0; }
  .sidebar .product-items .product-item-actions {
    clear: left; } }

@media only screen and (min-width: 1440px) {
  .sidebar .product-items .product-item-info .product-item-photo {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0; }
  .sidebar .product-items .product-item-details {
    margin-left: 85px; } }

@media only screen and (min-width: 769px) {
  .product-info-main {
    float: right; }
  .product.media {
    float: left;
    margin-bottom: 25px; }
  .page-layout-1column .product-info-main {
    width: calc(100% - 400px);
    padding-left: 30px; } }

@media only screen and (min-width: 769px) and (min-width: 769px) {
  .page-layout-1column .product.media {
    width: 400px; } }

@media only screen and (min-width: 769px) and (max-width: 768px) {
  .page-layout-1column .product.media {
    width: 100%; } }

@media only screen and (min-width: 769px) {
  .page-layout-2columns-left .product-info-main,
  .page-layout-2columns-right .product-info-main,
  .page-layout-3columns .product-info-main {
    width: 47%; }
  .page-layout-2columns-left .product.media,
  .page-layout-2columns-right .product.media,
  .page-layout-3columns .product.media {
    width: 50%; }
  .page-product-grouped.page-layout-1column .product-info-main .page-title-wrapper .page-title {
    margin-top: -13px !important; }
  .page-product-grouped.page-layout-1column .product-info-main .product.attribute.overview {
    margin: 0; }
  .page-product-grouped.page-layout-1column .table-wrapper.grouped {
    width: calc(100% - 400px);
    padding-left: 30px;
    float: right;
    min-height: 1500px; }
  .page-product-grouped.page-layout-1column .store-information {
    float: left;
    width: 400px; }
    .page-product-grouped.page-layout-1column .store-information.fixed {
      position: fixed; } }

body.catalog-product-compare-index .action.print {
  float: right;
  margin: 15px 0; }

.table-wrapper.comparison {
  clear: both;
  max-width: 100%;
  overflow-x: auto; }

.table-comparison {
  table-layout: fixed; }
  .table-comparison .cell.label,
  .table-comparison td:last-child {
    border-right: 1px solid #d1d1d1; }
  .table-comparison .cell {
    padding: 15px;
    width: 140px; }
    .table-comparison .cell .attribute.value {
      overflow: hidden;
      width: 100%; }
    .table-comparison .cell.product.info, .table-comparison .cell.product.label {
      border-bottom: 1px solid #d1d1d1; }
    .table-comparison .cell.label .attribute.label {
      display: block;
      width: 100%;
      word-wrap: break-word; }
    .table-comparison .cell.attribute {
      font-size: 13px; }
      .table-comparison .cell.attribute img {
        height: auto;
        max-width: 100%; }
  .table-comparison .product-item-photo {
    display: block;
    margin: 0 auto 15px; }
  .table-comparison .product-image-photo {
    margin-left: 0; }
  .table-comparison .product-item-actions,
  .table-comparison .price-box,
  .table-comparison .product.rating,
  .table-comparison .product-item-name {
    display: block;
    margin: 15px 0; }
  .table-comparison .product-addto-links {
    margin-top: 15px; }
    .table-comparison .product-addto-links .action.split,
    .table-comparison .product-addto-links .action.toggle {
      line-height: 12px;
      padding: 5px 8px;
      font-size: 11px; }
    .table-comparison .product-addto-links .action.toggle {
      padding: 0; }
  .table-comparison .cell.remove {
    padding-bottom: 0;
    padding-top: 0;
    text-align: right; }
  .table-comparison .product-item-actions > .actions-primary + .actions-secondary {
    margin-top: 10px; }
  .table-comparison .action.tocart {
    white-space: nowrap; }

.comparison.headings {
  background: #fff;
  left: 0;
  position: absolute;
  top: 0;
  width: auto;
  z-index: 2; }

.block-compare .product-item .product-item-name {
  margin-left: 22px; }

.block-compare .action.delete {
  left: -6px;
  position: absolute;
  top: 0; }

.block-compare .actions-toolbar {
  margin: 17px 0 0; }

.page-layout-2columns-left:not(.account) .columns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box; }
  .page-layout-2columns-left:not(.account) .columns .column.main {
    order: 2;
    width: 75%;
    margin: 0 auto; }
  .page-layout-2columns-left:not(.account) .columns .sidebar.sidebar-additional,
  .page-layout-2columns-left:not(.account) .columns .sidebar.sidebar-main {
    order: 1;
    width: 25%;
    margin-top: 59px; }
  @media only screen and (max-width: 1250px) {
    .page-layout-2columns-left:not(.account) .columns {
      flex-wrap: wrap; }
      .page-layout-2columns-left:not(.account) .columns .category-images {
        padding-left: 0; }
      .page-layout-2columns-left:not(.account) .columns .column.main {
        width: 100%; } }

.page-layout-2columns-left.account .page-main {
  box-sizing: border-box;
  padding: 0; }

.page-layout-2columns-left.account .columns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box;
  flex-wrap: wrap; }
  .page-layout-2columns-left.account .columns .column.main {
    order: 2;
    width: 74%; }
    @media only screen and (max-width: 768px) {
      .page-layout-2columns-left.account .columns .column.main {
        width: 100%; } }
  .page-layout-2columns-left.account .columns .sidebar.sidebar-additional {
    display: none; }
  .page-layout-2columns-left.account .columns .sidebar.sidebar-main {
    order: 1;
    width: 24%; }
    @media only screen and (max-width: 768px) {
      .page-layout-2columns-left.account .columns .sidebar.sidebar-main {
        width: 100%; } }
    .page-layout-2columns-left.account .columns .sidebar.sidebar-main .account-nav {
      margin-top: 40px; }

.category-images {
  width: 100%;
  overflow: hidden;
  list-style: none;
  padding: 0;
  margin-top: 30px; }
  .category-images .sub-category-image {
    width: 31.5%;
    padding-bottom: 20.7%;
    position: relative;
    margin-bottom: 2.75%;
    float: left;
    overflow: hidden;
    margin-top: 0; }
    .category-images .sub-category-image:nth-child(3n+2) {
      margin-left: 2.75%;
      margin-right: 2.75%; }
    @media only screen and (max-width: 768px) {
      .category-images .sub-category-image {
        width: 48%;
        margin-bottom: 2%;
        padding-bottom: 32%;
        margin-right: 1%;
        margin-left: 1%; }
        .category-images .sub-category-image:nth-child(3n+2) {
          margin-left: 1%;
          margin-right: 1%; }
        .category-images .sub-category-image:nth-child(2n+2) {
          margin-left: 2%;
          margin-right: 1%; }
        .category-images .sub-category-image:nth-child(2n+1) {
          margin-left: 1%;
          margin-right: 0; } }
    @media only screen and (max-width: 420px) {
      .category-images .sub-category-image {
        width: 100%;
        margin-bottom: 2%;
        padding-bottom: 66%; }
        .category-images .sub-category-image:nth-child(3n+2) {
          margin-left: 0;
          margin-right: 0; }
        .category-images .sub-category-image:nth-child(2n+2) {
          margin-left: 0;
          margin-right: 0; }
        .category-images .sub-category-image:nth-child(2n+1) {
          margin-left: 0;
          margin-right: 0; } }
    .category-images .sub-category-image > a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      .category-images .sub-category-image > a img {
        width: 100%; }
    .category-images .sub-category-image.empty {
      background: #e6e6e6;
      min-height: 4px; }
      .category-images .sub-category-image.empty .content-container {
        color: #333; }
    .category-images .sub-category-image img {
      display: block; }
    .category-images .sub-category-image:hover img {
      filter: brightness(1.5);
      transition: filter 1s; }
    .category-images .sub-category-image .content-container {
      position: absolute;
      left: 0;
      top: 50%;
      width: 90%;
      margin-left: 5%;
      color: #fff;
      transform: translateY(-50%); }
      .category-images .sub-category-image .content-container .category-name {
        font-weight: 600;
        position: relative;
        display: block;
        text-align: center;
        font-size: 22px;
        text-shadow: -1px -1px 8px black; }
      .category-images .sub-category-image .content-container .category-name-sub {
        font-weight: 600;
        position: relative !important;
        display: block;
        text-align: center;
        font-size: 15px;
        text-shadow: -1px -1px 8px black;
        font-style: italic;
        color: #fffc; }
      .category-images .sub-category-image .content-container .category-fromprice {
        font-weight: 600;
        position: relative;
        display: block;
        text-align: center;
        font-size: 16px;
        text-shadow: -1px -1px 8px black; }
        .category-images .sub-category-image .content-container .category-fromprice .price-box {
          display: inline-block; }
          .category-images .sub-category-image .content-container .category-fromprice .price-box .minimal-price .price-label {
            display: none; }
          .category-images .sub-category-image .content-container .category-fromprice .price-box .special-price .price-label {
            display: none; }
          .category-images .sub-category-image .content-container .category-fromprice .price-box .special-price .price-container span.price {
            color: #2FDF66; }
          .category-images .sub-category-image .content-container .category-fromprice .price-box .old-price .price-label {
            display: none; }
          .category-images .sub-category-image .content-container .category-fromprice .price-box .old-price .price {
            text-decoration: line-through; }

.price-box.starting-at {
  float: right;
  padding: 15px 30px;
  width: calc(100% - 400px); }
  @media only screen and (max-width: 768px) {
    .price-box.starting-at {
      float: none;
      margin: 0 auto;
      text-align: center;
      width: 100%; } }
  .price-box.starting-at .price-label {
    font-size: 25px; }
  .price-box.starting-at .price {
    font-size: 25px;
    font-weight: 600; }

.categorie-title {
  color: #014A5B !important;
  font-size: 28px;
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 40px; }
  @media only screen and (max-width: 1400px) {
    .categorie-title {
      font-size: 20px; } }

.vertnav-mobile ul {
  list-style-type: none;
  font-size: 16px;
  padding-left: 0; }
  .vertnav-mobile ul.active-sub {
    padding-left: 10px; }
  .vertnav-mobile ul li {
    line-height: 30px; }

.vertnav-mobile a {
  color: #20272B; }
  .vertnav-mobile a:hover {
    text-decoration: none; }

.vertnav-menu {
  margin-bottom: 100px; }
  .vertnav-menu ul {
    list-style-type: none;
    padding-left: 0; }
    .vertnav-menu ul li {
      line-height: 42px; }

.active-sub {
  padding-left: 15px; }

.vertnav-mobile li.level-top.active a {
  border-bottom: 2px solid #2FDF66;
  padding-bottom: 5px;
  padding-right: 15px; }

@media only screen and (max-width: 1400px) {
  .catalog-product-view .vertnav-menu .categorie-title {
    margin-top: 0;
    margin-bottom: 20px; }
  .catalog-product-view .vertnav-menu ul {
    font-size: 14px; }
    .catalog-product-view .vertnav-menu ul li {
      line-height: 25px; } }

@media only screen and (max-width: 768px) {
  .category-image {
    min-height: 140px; } }

.category-image .image {
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center; }

@media only screen and (max-width: 768px) {
  .category-description-container {
    min-height: 300px; } }

.category-full-description {
  margin-bottom: 100px; }
  .category-full-description h3 {
    font-size: 22px;
    margin: 30px 0 10px; }

@media only screen and (min-width: 769px) {
  .page-product-grouped.page-layout-1column .product.attribute.description {
    clear: both; } }

.product.attribute.description {
  margin: 40px 0; }
  .product.attribute.description h3 {
    font-size: 22px;
    margin: 30px 0 10px; }

.additional-attributes-wrapper.table-wrapper table {
  border-collapse: collapse;
  width: 100%;
  background: #FCFCFC; }

.additional-attributes-wrapper.table-wrapper td,
.additional-attributes-wrapper.table-wrapper th {
  text-align: left; }

.additional-attributes-wrapper.table-wrapper tr:nth-child(even) {
  background-color: #F8F8F8; }

.additional-attributes-wrapper.table-wrapper .col.label {
  font-weight: 400; }

.additional-attributes-wrapper h3 {
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 20px; }

.color_thumb {
  height: 15px;
  width: 15px;
  margin-top: 5px;
  border: 1px #efefef solid; }

.multicolor {
  height: 15px;
  width: 15px;
  margin-top: 5px;
  background: red;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, red 0%, #ff3fac 33%, #2989d8 64%, yellow 92%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, red 0%, #ff3fac 33%, #2989d8 64%, yellow 92%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, red 0%, #ff3fac 33%, #2989d8 64%, yellow 92%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#ffff00',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.catalog-product-view .product-info-main .table-wrapper.simple .actionbar .control.qty .total.price-on-request {
  color: #20272B;
  font-size: 16px;
  font-weight: 500; }

.btn-wrapper {
  display: block; }

.request-quote-dialog {
  position: fixed;
  inset: 0;
  max-width: 750px; }
  .request-quote-dialog .head {
    display: flex;
    justify-content: space-between; }
  .request-quote-dialog .webforms .actions-toolbar {
    padding-left: 0;
    padding-right: 0; }
  .request-quote-dialog .webforms-fields-customer_street {
    width: 66%;
    margin: 0 1% 29px 0; }
  .request-quote-dialog .webforms-fields-customer_house_number {
    width: 32%;
    margin: 0 0 29px 1%; }
  .request-quote-dialog .webforms-fields-customer_zipcode {
    width: 49%;
    margin: 0 1% 29px 0; }
  .request-quote-dialog .webforms-fields-customer_city {
    width: 49%;
    margin: 0 0 29px 1%; }
  @media only screen and (max-width: 512px) {
    .request-quote-dialog .webforms-fields-customer_zipcode,
    .request-quote-dialog .webforms-fields-customer_city,
    .request-quote-dialog .webforms-fields-customer_street,
    .request-quote-dialog .webforms-fields-customer_house_number {
      width: 100%; } }
  .request-quote-dialog input,
  .request-quote-dialog textarea {
    background: #fff;
    color: #95989A;
    font-size: 20px;
    font-weight: 100;
    padding: 0 9px; }

.btn-request-quote {
  cursor: pointer;
  font-size: 20px;
  text-decoration: none;
  color: #014A5B;
  padding-left: 10px; }
  .btn-request-quote:hover {
    text-decoration: none;
    color: #20272B; }

#rfq-confirm h1 {
  background: #014a5b !important; }

#rfq-confirm a {
  color: #014a5b; }

#rfq-confirm #confirmButtons .action.primary {
  -webkit-appearance: unset;
  color: #fff; }

#rfq-confirm #confirmButtons button, #rfq-confirm #confirmButtons .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper #rfq-confirm #confirmButtons .actions-toolbar > .action {
  background: #014a5b;
  color: #fff; }

.quotation-quote-index .form-cart {
  padding-right: 30px; }

.quotation-quote-index .cart.table-wrapper .col.price {
  display: none; }

.quotation-quote-index .cart.table-wrapper .cart.item {
  position: revert;
  display: table-row-group; }
  .quotation-quote-index .cart.table-wrapper .cart.item:last-child {
    border-bottom: solid #D8D8D8 1px !important; }
  .quotation-quote-index .cart.table-wrapper .cart.item .control.qty {
    position: revert; }

@media only screen and (max-width: 1040px) {
  .header.content .miniquote-wrapper {
    display: none; } }

.webforms .product-price-quote .fieldset {
  margin-top: 10px;
  padding-left: 0;
  padding-right: 0; }
  .webforms .product-price-quote .fieldset .field {
    padding: 0; }
    .webforms .product-price-quote .fieldset .field .label {
      float: none;
      width: 100%;
      margin-bottom: 5px;
      padding: 0; }
    .webforms .product-price-quote .fieldset .field .control {
      float: unset;
      width: 100%; }
      .webforms .product-price-quote .fieldset .field .control input {
        text-align: left !important;
        width: 100% !important;
        margin: 0; }
      .webforms .product-price-quote .fieldset .field .control .input-text {
        border: 1px solid #c2c2c2 !important; }

.webforms .product-price-quote .actions-toolbar.submit-right {
  margin-left: auto !important; }
  .webforms .product-price-quote .actions-toolbar.submit-right .primary .action.submit.primary {
    margin-right: 0 !important;
    margin-bottom: 0 !important; }

.block-category-event {
  background: #f0f0f0;
  color: #2FDF66;
  padding: 10px 0 15px;
  text-align: center; }
  .block-category-event.block:last-child {
    margin-bottom: 10px; }
  .block-category-event .block-title {
    margin: 0 0 10px; }
    .block-category-event .block-title strong {
      font-size: 15px;
      font-weight: 300; }
  .block-category-event .ticker {
    margin: 0;
    padding: 0;
    list-style: none none; }
    .block-category-event .ticker li {
      display: none;
      margin: 0 15px; }
    .block-category-event .ticker .value {
      font-size: 18px;
      font-weight: 300; }
    .block-category-event .ticker .label {
      font-size: 10px;
      display: block;
      text-transform: lowercase; }
  .block-category-event .dates {
    display: table;
    margin: 0 auto; }
    .block-category-event .dates .start,
    .block-category-event .dates .end {
      display: table-cell;
      vertical-align: top; }
    .block-category-event .dates .date {
      font-size: 18px; }
    .block-category-event .dates .time {
      display: block; }
    .block-category-event .dates .start {
      padding-right: 40px;
      position: relative; }
      .block-category-event .dates .start:after {
        font-size: 18px;
        content: '\2013';
        display: block;
        position: absolute;
        right: 15px;
        top: 0; }

@media only screen and (min-width: 768px) {
  .block-category-event.block:last-child {
    margin-bottom: 30px;
    padding: 10px 0 30px; }
  .block-category-event .block-title {
    margin: 0; }
    .block-category-event .block-title strong {
      font-size: 24px; }
  .block-category-event .ticker li {
    display: none;
    margin: 0 50px; }
  .block-category-event .ticker .value {
    font-size: 60px; }
  .block-category-event .ticker .label {
    font-size: 14px;
    text-transform: none; }
  .block-category-event .dates .date {
    font-size: 50px; }
  .block-category-event .dates .start {
    padding-right: 50px; }
    .block-category-event .dates .start:after {
      font-size: 50px;
      right: 10px; } }

.block-search {
  margin-bottom: 0; }
  .block-search .block-title {
    display: none; }
  .block-search .block-content {
    margin-bottom: 0; }
  .block-search .label {
    display: block;
    text-decoration: none;
    float: right; }
    .block-search .label > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .block-search .label:before {
      -webkit-font-smoothing: antialiased;
      font-size: 35px;
      line-height: 33px;
      color: #8f8f8f;
      content: "";
      font-family: "icons-blank-theme";
      margin: inherit;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: middle; }
    .block-search .label:hover:before {
      color: #333; }
    .block-search .label:active:before {
      color: #333; }
    .block-search .label.active + .control input {
      position: static; }
  .block-search .action.search {
    display: none; }
  .block-search .control {
    clear: both;
    margin: 0;
    padding: 0; }
  .block-search input {
    left: -300%;
    margin: 15px 0;
    position: absolute; }
  .block-search .nested {
    display: none; }

.search-autocomplete {
  background: #fff;
  display: none;
  margin-top: -15px;
  overflow: hidden;
  position: absolute;
  z-index: 3; }
  .search-autocomplete dl, .search-autocomplete ul {
    margin: 0;
    padding: 0;
    list-style: none none; }
    .search-autocomplete dl dt, .search-autocomplete ul dt {
      pointer-events: none; }
      .search-autocomplete dl dt:hover, .search-autocomplete ul dt:hover {
        background: transparent; }
    .search-autocomplete dl .title-product, .search-autocomplete ul .title-product {
      display: none; }
    .search-autocomplete dl dt,
    .search-autocomplete dl dd,
    .search-autocomplete dl li, .search-autocomplete ul dt,
    .search-autocomplete ul dd,
    .search-autocomplete ul li {
      border-top: 1px solid #e5e5e5;
      cursor: pointer;
      margin: 0;
      padding: 5px 40px 5px 10px;
      position: relative;
      text-align: left;
      white-space: normal; }
      .search-autocomplete dl dt:not(:empty),
      .search-autocomplete dl dd:not(:empty),
      .search-autocomplete dl li:not(:empty), .search-autocomplete ul dt:not(:empty),
      .search-autocomplete ul dd:not(:empty),
      .search-autocomplete ul li:not(:empty) {
        border-top: 0;
        border: 1px solid #c2c2c2;
        background: #fff; }
      .search-autocomplete dl dt:first-child,
      .search-autocomplete dl dd:first-child,
      .search-autocomplete dl li:first-child, .search-autocomplete ul dt:first-child,
      .search-autocomplete ul dd:first-child,
      .search-autocomplete ul li:first-child {
        border-top: none; }
      .search-autocomplete dl dt:not(:last-child),
      .search-autocomplete dl dd:not(:last-child),
      .search-autocomplete dl li:not(:last-child), .search-autocomplete ul dt:not(:last-child),
      .search-autocomplete ul dd:not(:last-child),
      .search-autocomplete ul li:not(:last-child) {
        border-bottom: 0; }
      .search-autocomplete dl dt:hover, .search-autocomplete dl dt.selected,
      .search-autocomplete dl dd:hover,
      .search-autocomplete dl dd.selected,
      .search-autocomplete dl li:hover,
      .search-autocomplete dl li.selected, .search-autocomplete ul dt:hover, .search-autocomplete ul dt.selected,
      .search-autocomplete ul dd:hover,
      .search-autocomplete ul dd.selected,
      .search-autocomplete ul li:hover,
      .search-autocomplete ul li.selected {
        background: #e8e8e8; }
      .search-autocomplete dl dt .amount,
      .search-autocomplete dl dd .amount,
      .search-autocomplete dl li .amount, .search-autocomplete ul dt .amount,
      .search-autocomplete ul dd .amount,
      .search-autocomplete ul li .amount {
        color: #2FDF66;
        position: absolute;
        right: 7px;
        top: 5px; }
      .search-autocomplete dl dt .product-image-box,
      .search-autocomplete dl dd .product-image-box,
      .search-autocomplete dl li .product-image-box, .search-autocomplete ul dt .product-image-box,
      .search-autocomplete ul dd .product-image-box,
      .search-autocomplete ul li .product-image-box {
        display: inline-block; }
      .search-autocomplete dl dt .product-item,
      .search-autocomplete dl dd .product-item,
      .search-autocomplete dl li .product-item, .search-autocomplete ul dt .product-item,
      .search-autocomplete ul dd .product-item,
      .search-autocomplete ul li .product-item {
        display: inline-block; }
        .search-autocomplete dl dt .product-item .product-primary,
        .search-autocomplete dl dd .product-item .product-primary,
        .search-autocomplete dl li .product-item .product-primary, .search-autocomplete ul dt .product-item .product-primary,
        .search-autocomplete ul dd .product-item .product-primary,
        .search-autocomplete ul li .product-item .product-primary {
          display: inline-block; }
        .search-autocomplete dl dt .product-item .price-label,
        .search-autocomplete dl dt .product-item .price-wrapper,
        .search-autocomplete dl dd .product-item .price-label,
        .search-autocomplete dl dd .product-item .price-wrapper,
        .search-autocomplete dl li .product-item .price-label,
        .search-autocomplete dl li .product-item .price-wrapper, .search-autocomplete ul dt .product-item .price-label,
        .search-autocomplete ul dt .product-item .price-wrapper,
        .search-autocomplete ul dd .product-item .price-label,
        .search-autocomplete ul dd .product-item .price-wrapper,
        .search-autocomplete ul li .product-item .price-label,
        .search-autocomplete ul li .product-item .price-wrapper {
          display: inline-block;
          font-size: 18px; }
        .search-autocomplete dl dt .product-item .minimal-price-link,
        .search-autocomplete dl dd .product-item .minimal-price-link,
        .search-autocomplete dl li .product-item .minimal-price-link, .search-autocomplete ul dt .product-item .minimal-price-link,
        .search-autocomplete ul dd .product-item .minimal-price-link,
        .search-autocomplete ul li .product-item .minimal-price-link {
          display: inline-block;
          float: none; }

.form.search.advanced .fields.range .field:first-child {
  position: relative; }
  .form.search.advanced .fields.range .field:first-child .control {
    padding-right: 25px; }
    .form.search.advanced .fields.range .field:first-child .control:after {
      content: ' \2013 ';
      display: inline-block;
      position: absolute;
      right: 0;
      text-align: center;
      top: 6px;
      width: 25px; }

.form.search.advanced .fields.range .field:last-child {
  position: relative; }
  .form.search.advanced .fields.range .field:last-child div.mage-error[generated] {
    left: 0;
    position: absolute;
    top: 32px; }

.form.search.advanced .fields.range .field.with-addon .control {
  padding-right: 45px; }

.form.search.advanced .group.price .addon .addafter {
  background: none;
  border: 0;
  padding-top: 6px;
  position: absolute;
  right: 0;
  top: 0; }

.search.summary {
  margin-bottom: 10px; }

@media only screen and (max-width: 1040px) {
  .block-search .block-content {
    margin-bottom: 0; } }

@media only screen and (min-width: 1041px) {
  .block-search {
    float: right;
    padding-left: 15px;
    position: relative;
    width: 250px;
    z-index: 4; }
    .block-search .label {
      display: none; }
    .block-search .control {
      border-top: 0;
      margin: 0;
      padding: 0 0 25px; }
    .block-search .nested {
      display: block;
      padding-top: 5px;
      position: absolute; }
    .block-search input {
      margin: 0;
      padding-right: 35px;
      position: static; }
      .block-search input::-webkit-input-placeholder {
        color: #c2c2c2; }
      .block-search input:-moz-placeholder {
        color: #c2c2c2; }
      .block-search input::-moz-placeholder {
        color: #c2c2c2; }
      .block-search input:-ms-input-placeholder {
        color: #c2c2c2; }
    .block-search .action.search {
      display: inline-block;
      display: inline-block;
      text-decoration: none;
      background-image: none;
      background: none;
      box-sizing: content-box;
      border: 0;
      box-shadow: none;
      line-height: inherit;
      padding: 0;
      text-decoration: none;
      text-shadow: none;
      font-weight: 400;
      padding: 5px 0;
      position: absolute;
      right: 10px;
      top: 0;
      z-index: 1; }
      .block-search .action.search > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .block-search .action.search:before {
        -webkit-font-smoothing: antialiased;
        font-size: 22px;
        line-height: 22px;
        color: #8f8f8f;
        content: "";
        font-family: "icons-blank-theme";
        margin: 0;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
        vertical-align: top; }
      .block-search .action.search:hover:before {
        color: inherit; }
      .block-search .action.search:active:before {
        color: inherit; }
      .block-search .action.search:focus, .block-search .action.search:active {
        background: none;
        border: none; }
      .block-search .action.search:hover {
        background: none;
        border: none; }
      .block-search .action.search.disabled, .block-search .action.search[disabled],
      fieldset[disabled] .block-search .action.search {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5; }
      .block-search .action.search:focus:before {
        color: #333; }
  .search-autocomplete {
    margin-top: 0; } }

.block-search {
  float: right;
  padding-left: 15px;
  position: relative;
  z-index: 4;
  margin-right: 40px;
  width: 560px; }
  @media only screen and (max-width: 1040px) {
    .block-search {
      padding-left: 0;
      width: 100%;
      margin-right: 0; }
      .block-search .label {
        margin-top: -50px; }
        .block-search .label:before {
          font-family: 'icomoon';
          content: "\e968";
          color: #005266;
          display: inline; } }
  .block-search input {
    border: 1px solid #CFCFCF;
    border-radius: 1px;
    color: #6D6D6D;
    font-family: "gotham", sans-serif;
    font-size: 16px;
    font-style: inherit;
    margin: 0;
    height: 51px;
    line-height: 51px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
    font-weight: 100 !important; }
  .block-search .action.search {
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    line-height: inherit;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    padding: 8px 10px 0 8px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1; }
  .block-search .action.search::before {
    -webkit-font-smoothing: antialiased;
    font-size: 28px !important;
    line-height: 50px !important;
    color: #8f8f8f;
    content: "\e968";
    font-family: 'icomoon';
    margin: 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: top;
    background-color: #F8F8F8;
    width: 49px;
    height: 49px;
    margin-top: -7px !important;
    margin-right: -9px !important;
    border-left: solid 1px #CFCFCF; }

@media only screen and (max-width: 1040px) {
  .block-search .label {
    margin-top: -50px;
    right: 80px;
    position: relative; }
  .block-search .control {
    top: 15px;
    position: relative; }
  .block-search .action.search:before {
    content: none; }
  .block-search .action.search span {
    display: none; } }

@media only screen and (min-width: 768px) {
  .search.results dl.block {
    margin: 30px 0 0 35px; } }

@media only screen and (max-width: 550px) {
  .block-search .label {
    right: 60px; } }

.quotation-quote-index .cart.table-wrapper .product-item-photo {
  height: auto !important;
  background-color: white;
  float: left;
  width: 30%;
  position: initial;
  display: inline-block; }

.quotation-quote-index .cart.table-wrapper .product-item-details {
  display: block;
  margin-top: 20px; }
  .quotation-quote-index .cart.table-wrapper .product-item-details .product-item-name {
    height: auto;
    display: block;
    float: left;
    width: 100%; }
  .quotation-quote-index .cart.table-wrapper .product-item-details .field.qty {
    height: auto;
    overflow: auto;
    margin-top: 5px; }
    .quotation-quote-index .cart.table-wrapper .product-item-details .field.qty label {
      float: left;
      line-height: 30px; }
    .quotation-quote-index .cart.table-wrapper .product-item-details .field.qty input {
      max-width: 100px;
      position: initial;
      display: inline-block;
      float: left;
      margin-left: 10px;
      margin-right: 0; }

.delivery-available {
  font-weight: bold;
  white-space: normal; }
  .delivery-available.directly-available {
    color: #2FDF66; }
  .delivery-available.available-from {
    color: #FFA500; }

.checkout-cart-index .column.main .cart-summary .block > .title strong {
  font-size: 13px; }

/*reset on checkout cart*/
.cart.table-wrapper td {
  padding: 0 !important; }

.cart.item {
  border: solid #D8D8D8 1px;
  height: 240px; }
  .cart.item .message.error {
    height: auto !important;
    margin: 15px 15px 10px;
    white-space: normal;
    width: auto; }

.cart.item .control.qty {
  right: 10px;
  bottom: 90px;
  position: absolute; }

tbody.cart.item {
  display: list-item;
  margin-bottom: 30px; }

button.action.apply.primary, .cart.table-wrapper .actions-toolbar > .action.apply.primary {
  background: #2FDF66;
  border: 0px;
  color: white; }

button.action.apply.primary:hover, .cart.table-wrapper .actions-toolbar > .action.apply.primary:hover {
  border: 0px;
  background: #014A5B;
  color: white; }

.update-cart-hidden {
  display: none; }

#increaseQty,
#decreaseQty {
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  color: #20272B;
  height: 35px; }

.opc-block-summary .data.table.table-totals {
  background: none; }

.cart-summary {
  margin-bottom: 25px;
  padding: 20px 15px 25px;
  border: 1px solid #D8D8D8;
  font-size: 13px; }
  .cart-summary table {
    background-color: white; }
  .cart-summary > .title {
    display: block;
    font-weight: 600 !important;
    margin-left: 5px;
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: 300;
    line-height: 1.1;
    font-size: 18px;
    margin-top: 7px;
    margin-bottom: 7px; }
  .cart-summary .block {
    margin-bottom: 0; }
    .cart-summary .block .item-options {
      margin-left: 0; }
    .cart-summary .block .fieldset {
      margin: 15px 0 25px 5px; }
      .cart-summary .block .fieldset .field {
        margin: 0 0 10px; }
      .cart-summary .block .fieldset .methods .field > .label {
        display: inline; }
  .cart-summary #co-shipping-method-form {
    display: none; }

.cart-totals {
  border-top: 1px solid #d1d1d1;
  padding-top: 10px; }
  .cart-totals .totals .mark strong {
    font-weight: bold !important; }
  .cart-totals .totals.excl.both strong {
    font-weight: normal !important; }
  .cart-totals .table-wrapper {
    margin-bottom: 0;
    overflow: inherit; }

.cart-items .item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border: solid #D8D8D8 1px;
  margin-bottom: 35px;
  padding: 10px; }
  .cart-items .item .product-item-photo {
    width: 18%;
    display: inline; }
    @media only screen and (max-width: 1050px) {
      .cart-items .item .product-item-photo {
        width: 25%; } }
  .cart-items .item .product-image-container {
    background: none; }
  .cart-items .item .product-item-info {
    width: 40%;
    padding: 20px 0; }
    @media only screen and (max-width: 1050px) {
      .cart-items .item .product-item-info {
        padding: 0 10px;
        width: 75%; } }
    .cart-items .item .product-item-info .product-item-name {
      height: unset;
      margin: 0; }
    .cart-items .item .product-item-info .product-item-price {
      margin-top: 15px; }
      .cart-items .item .product-item-info .product-item-price .price {
        font-weight: 600; }
  .cart-items .item .product-item-actions {
    display: block !important;
    width: 50px;
    text-align: right; }
    @media only screen and (max-width: 450px) {
      .cart-items .item .product-item-actions {
        width: 25px;
        margin-top: 15px; } }
    .cart-items .item .product-item-actions .remove-item {
      color: #20272B;
      font-size: 14px; }
      .cart-items .item .product-item-actions .remove-item span {
        display: none; }
      .cart-items .item .product-item-actions .remove-item:hover {
        text-decoration: none; }
      .cart-items .item .product-item-actions .remove-item:before {
        content: "\e902";
        font-family: 'icomoon';
        font-size: 15px;
        line-height: 50px;
        color: #014A5B; }
  .cart-items .item .product-item-qty {
    width: 19%; }
    @media only screen and (max-width: 1050px) {
      .cart-items .item .product-item-qty {
        width: 35%;
        text-align: right;
        margin-left: auto; } }
    @media only screen and (max-width: 450px) {
      .cart-items .item .product-item-qty {
        width: 45%;
        text-align: left;
        margin-left: unset;
        margin-top: 15px; } }
    @media only screen and (max-width: 320px) {
      .cart-items .item .product-item-qty {
        width: 54%; } }
    .cart-items .item .product-item-qty input {
      background: #f2f2f2;
      width: 50px;
      text-align: center; }
  .cart-items .item .product-item-subtotal {
    width: 16%;
    text-align: center; }
    @media only screen and (max-width: 1050px) {
      .cart-items .item .product-item-subtotal {
        width: 25%; } }
    @media only screen and (max-width: 450px) {
      .cart-items .item .product-item-subtotal {
        width: 45%;
        margin-top: 15px; } }
    @media only screen and (max-width: 320px) {
      .cart-items .item .product-item-subtotal {
        width: 36%; } }
    .cart-items .item .product-item-subtotal .price {
      font-size: 20px;
      font-weight: 600; }

.cart.table-wrapper .items thead + .item {
  /*    border-top: $border-width__base solid $border-color__base;*/ }

.cart.table-wrapper .items > .item {
  /*border-bottom: $border-width__base solid $border-color__base;*/
  position: relative; }

.cart.table-wrapper .col {
  padding-top: 20px; }
  .cart.table-wrapper .col.qty .input-text {
    margin-top: -5px; }

.cart.table-wrapper .item-actions td {
  padding-bottom: 10px;
  text-align: center;
  white-space: normal; }

.cart.table-wrapper .actions-toolbar > .action {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  margin-right: 10px; }
  .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .actions-toolbar > .action:active, .cart.table-wrapper .actions-toolbar > .action:focus {
    text-decoration: none; }
  .cart.table-wrapper .actions-toolbar > .action:last-child {
    margin-right: 0; }

.cart.table-wrapper .action.help.map {
  font-weight: 400; }

.cart.table-wrapper .gift-registry-name-label:after {
  content: ':'; }

.cart.table-wrapper .item-options {
  font-size: 12px;
  margin-bottom: 10px; }

.cart.table-wrapper .product-item-name + .item-options {
  margin-top: 10px; }

.cart.table-wrapper .action.configure {
  display: inline-block;
  margin: 0 0 20px; }

.cart-container .form-cart {
  width: 70%; }

.cart-container .checkout-methods-items {
  margin-top: 20px;
  text-align: center; }
  .cart-container .checkout-methods-items .action.primary {
    width: 100%; }
  .cart-container .checkout-methods-items .item + .item {
    margin-top: 20px; }
  .cart-container .checkout-methods-items .shipping-cart-error {
    margin-bottom: 5px; }
    .cart-container .checkout-methods-items .shipping-cart-error span {
      color: red; }
  .cart-container .checkout-methods-items .cart-summary-trustedshops {
    margin: 0 auto;
    width: 175px; }

.block.crosssell {
  margin-top: 70px; }

.cart-tax-info + .cart-tax-total,
.cart .cart-tax-info + .cart-tax-total {
  display: block; }

.cart.table-wrapper .col.price,
.cart.table-wrapper .col.qty,
.cart.table-wrapper .col.subtotal,
.cart.table-wrapper .col.msrp,
.order-items.table-wrapper .col.price,
.order-items.table-wrapper .col.qty,
.order-items.table-wrapper .col.subtotal,
.order-items.table-wrapper .col.msrp {
  text-align: right; }

@media only screen and (min-width: 371px) and (max-width: 768px) {
  .cart .col.qty .field.qty {
    margin-left: 15px; } }

@media only screen and (max-width: 768px) {
  .cart.table-wrapper {
    overflow: inherit; }
    .cart.table-wrapper thead .col:not(.item) {
      display: none; }
    .cart.table-wrapper .col.qty, .cart.table-wrapper .col.price, .cart.table-wrapper .col.subtotal, .cart.table-wrapper .col.msrp {
      box-sizing: border-box;
      display: block;
      float: left;
      text-align: center;
      white-space: nowrap;
      width: 33%; }
      .cart.table-wrapper .col.qty:before, .cart.table-wrapper .col.price:before, .cart.table-wrapper .col.subtotal:before, .cart.table-wrapper .col.msrp:before {
        content: attr(data-th) ":";
        display: block;
        font-weight: 700;
        padding-bottom: 10px; }
    .cart.table-wrapper .col.msrp {
      white-space: normal; }
    .cart.table-wrapper .item .col.item {
      padding-bottom: 0; } }

@media only screen and (min-width: 768px) {
  .cart-container .widget {
    float: left; }
  .cart-summary {
    float: right;
    position: relative;
    width: 29%;
    background: white; }
    .column.main .cart-summary .actions-toolbar > .secondary {
      float: none; }
    .cart-summary .block .fieldset .field {
      margin: 0;
      margin: 0 0 10px; }
      .cart-summary .block .fieldset .field:not(.choice) > .label {
        box-sizing: content-box;
        float: none;
        width: auto;
        text-align: left;
        padding: 0; }
      .cart-summary .block .fieldset .field:not(.choice) > .control {
        float: none;
        width: auto; }
      .cart-summary .block .fieldset .field > .label {
        margin: 0 0 5px;
        padding: inherit;
        text-align: inherit;
        display: inline-block; }
      .cart-summary .block .fieldset .field.choice:before, .cart-summary .block .fieldset .field.no-label:before {
        display: none; }
    .cart-summary .action.primary.checkout {
      margin-bottom: 0 !important; } }

.steps-container {
  margin-top: 0;
  margin-bottom: 0; }
  .steps-container .steps-container-centered {
    position: relative;
    margin-left: -10px; }
  .steps-container .steps-bar {
    margin: 0;
    list-style: none;
    padding: 0;
    overflow: hidden;
    width: 80%;
    margin-left: 10%;
    margin-top: 30px;
    margin-bottom: 30px; }
    .steps-container .steps-bar.step1step .step1 .step-number {
      background: #014A5B; }
    .steps-container .steps-bar.step2step .step1 .step-number,
    .steps-container .steps-bar.step2step .step2 .step-number {
      background: #037793; }
    .steps-container .steps-bar.step3step .step1 .step-number,
    .steps-container .steps-bar.step3step .step2 .step-number,
    .steps-container .steps-bar.step3step .step3 .step-number {
      background: #014A5B; }
    .steps-container .steps-bar li {
      margin: 0; }
    .steps-container .steps-bar .step {
      float: left;
      width: 33.333%;
      text-align: center; }
      .steps-container .steps-bar .step .step-number {
        display: inline-block;
        height: 33px;
        width: 33px;
        background: #F8F8F8;
        color: #c9d2d9;
        text-align: center;
        line-height: 33px;
        font-size: 20px;
        margin-top: 25px;
        font-weight: 600;
        position: relative;
        z-index: 1; }
      .steps-container .steps-bar .step .step-text {
        line-height: 50px;
        font-size: 18px;
        font-weight: 400;
        color: #014A5B; }
        @media only screen and (max-width: 480px) {
          .steps-container .steps-bar .step .step-text {
            font-size: 12px; } }
    .steps-container .steps-bar .step-bar {
      position: absolute;
      width: 100%;
      height: 4px;
      margin-left: calc(-9.6% - 5px);
      top: 39px;
      background: #C9D2D9; }
      .steps-container .steps-bar .step-bar .step-line {
        height: 4px;
        display: inline-block;
        background: #014A5B;
        position: absolute;
        left: 0;
        top: 0; }
        .steps-container .steps-bar .step-bar .step-line.step-size1 {
          width: calc(33.333% - 10%); }
        .steps-container .steps-bar .step-bar .step-line.step-size2 {
          width: calc(50%); }
        .steps-container .steps-bar .step-bar .step-line.step-size3 {
          width: 100%; }

body.step2 .steps-container .steps-bar .step1 .step-number,
body.step2 .steps-container .steps-bar .step2 .step-number,
body.step2 .steps-container .steps-bar .step3 .step-number {
  background: #2FDF66; }

body.step2 .steps-container .steps-bar .step-bar .step-line.step-size2 {
  width: 100%; }

@media only screen and (max-width: 768px) {
  .cart.table-wrapper .col.qty:before, .cart.table-wrapper .col.price:before, .cart.table-wrapper .col.subtotal:before, .cart.table-wrapper .col.msrp:before {
    content: none; }
  .cart-container {
    display: flex;
    flex-wrap: wrap; }
    .cart-container .cart-summary {
      width: 100%;
      order: 2; }
    .cart-container .form.form-cart {
      order: 1;
      width: 100%; }
      .cart-container .form.form-cart .table-wrapper {
        display: block; }
        .cart-container .form.form-cart .table-wrapper .data.table {
          display: block; } }

.quickcart-wrapper .block-quickcart .block-content-quickcart .quickcart-items-wrapper {
  max-height: calc(100vh - 300px) !important; }

a.action.viewcart-link {
  color: white;
  padding-bottom: 15px;
  padding-top: 15px;
  text-decoration: none; }
  a.action.viewcart-link :hover {
    text-decoration: none; }

.block-minicart .subtotal {
  margin: 0 10px;
  text-align: left !important; }

a.zzgl {
  float: left;
  margin-right: 20px; }

span.zzgl {
  font-size: 13px;
  line-height: 40px;
  margin-right: 5px; }

.block-minicart .items-total {
  float: none;
  margin: 0 10px;
  margin-bottom: 30px; }

.block-minicart .subtotal {
  margin: 0 10px;
  text-align: right; }

.block-minicart .amount .price-wrapper:first-child .price {
  font-size: 23px;
  font-weight: 700; }

.block-minicart .subtitle {
  display: none; }
  .block-minicart .subtitle.empty {
    display: block;
    font-size: 14px;
    padding: 30px 0 20px;
    text-align: center; }

.block-minicart .text.empty {
  text-align: center; }

.block-minicart .block-content > .actions {
  margin-top: 15px;
  text-align: center; }
  .block-minicart .block-content > .actions > .primary {
    margin: 30px 10px 15px; }
    .block-minicart .block-content > .actions > .primary .action.primary {
      display: block;
      margin-bottom: 15px;
      width: 100%; }
      .block-minicart .block-content > .actions > .primary .action.primary:last-child {
        margin-bottom: 0; }

.block-minicart .block-category-link,
.block-minicart .block-product-link,
.block-minicart .block-cms-link,
.block-minicart .block-banners {
  margin: 15px 0 0;
  text-align: center; }

.minicart-wrapper {
  position: relative;
  float: right;
  margin-right: 26px;
  margin-left: 0px !important; }
  .minicart-wrapper:before, .minicart-wrapper:after {
    content: '';
    display: table; }
  .minicart-wrapper:after {
    clear: both; }
  .minicart-wrapper .action.showcart {
    padding: inherit;
    cursor: pointer;
    display: inline-block;
    text-decoration: none; }
    .minicart-wrapper .action.showcart:before {
      -webkit-font-smoothing: antialiased;
      font-size: 35px;
      line-height: 33px;
      color: #8f8f8f;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: top; }
    .minicart-wrapper .action.showcart:hover:before {
      color: #333; }
    .minicart-wrapper .action.showcart:active:before {
      color: #8f8f8f; }
    .minicart-wrapper .action.showcart.active {
      display: inline-block;
      text-decoration: none; }
      .minicart-wrapper .action.showcart.active:before {
        -webkit-font-smoothing: antialiased;
        font-size: 35px;
        line-height: 33px;
        color: #8f8f8f;
        content: "";
        font-family: "icons-blank-theme";
        margin: 0;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
        vertical-align: top; }
      .minicart-wrapper .action.showcart.active:hover:before {
        color: #333; }
      .minicart-wrapper .action.showcart.active:active:before {
        color: #8f8f8f; }
  .minicart-wrapper .block-minicart {
    background: #fff;
    border: 1px solid #bbb;
    z-index: 100;
    margin: 0;
    padding: 0;
    list-style: none none;
    box-sizing: border-box;
    position: absolute;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    height: inherit;
    margin-top: 4px;
    min-width: 100%;
    width: inherit;
    display: none; }
    .minicart-wrapper .block-minicart li {
      margin: 0;
      padding: false; }
      .minicart-wrapper .block-minicart li:hover {
        background: false;
        cursor: pointer; }
    .minicart-wrapper .block-minicart:before, .minicart-wrapper .block-minicart:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .minicart-wrapper .block-minicart:before {
      border: 6px solid;
      border-color: transparent transparent #fff transparent;
      z-index: 99; }
    .minicart-wrapper .block-minicart:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .minicart-wrapper .block-minicart:before {
      left: 10px;
      top: -12px; }
    .minicart-wrapper .block-minicart:after {
      left: 9px;
      top: -14px; }
  .minicart-wrapper.active {
    overflow: visible; }
    .minicart-wrapper.active .block-minicart {
      display: block; }
  @media only screen and (max-width: 550px) {
    .minicart-wrapper {
      margin-right: 6px; } }
  .minicart-wrapper .block-minicart {
    padding: 25px 20px;
    right: 0;
    width: 320px; }
    .minicart-wrapper .block-minicart .block-title {
      display: none; }
    .minicart-wrapper .block-minicart:after {
      left: auto;
      right: 25px; }
    .minicart-wrapper .block-minicart:before {
      left: auto;
      right: 26px; }
  .minicart-wrapper .product .actions {
    float: right;
    margin: -24px 0 0;
    text-align: right; }
    .minicart-wrapper .product .actions > .primary,
    .minicart-wrapper .product .actions > .secondary {
      display: inline; }
  .minicart-wrapper .action.showcart {
    display: block;
    height: 50px; }
    .minicart-wrapper .action.showcart:before {
      content: "\e967" !important;
      font-size: 45px !important;
      display: flex;
      font-weight: 200;
      font-family: "icomoon" !important;
      color: #3f3f3f;
      line-height: 44px !important; }
    @media only screen and (max-width: 1040px) {
      .minicart-wrapper .action.showcart:before {
        color: #005266; } }
  .minicart-wrapper .action.showcart.active::before {
    display: flex; }
  .minicart-wrapper .action.close {
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px; }
    .minicart-wrapper .action.close > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .minicart-wrapper .action.close:before {
      -webkit-font-smoothing: antialiased;
      font-size: 38px;
      line-height: 50px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: top; }
    .minicart-wrapper .action.close:hover:before {
      color: inherit; }
    .minicart-wrapper .action.close:active:before {
      color: inherit; }
    .minicart-wrapper .action.close:focus, .minicart-wrapper .action.close:active {
      background: none;
      border: none; }
    .minicart-wrapper .action.close:hover {
      background: none;
      border: none; }
    .minicart-wrapper .action.close.disabled, .minicart-wrapper .action.close[disabled],
    fieldset[disabled] .minicart-wrapper .action.close {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
    .minicart-wrapper .action.close:before {
      color: #fff;
      vertical-align: middle; }
    .minicart-wrapper .action.close:hover:before {
      color: #fff;
      opacity: .8; }
  .minicart-wrapper .action.showcart {
    white-space: nowrap; }
    .minicart-wrapper .action.showcart .counter.qty {
      background: #ED8400;
      color: #fff;
      height: 18px;
      line-height: 18px;
      display: -moz-inline-grid;
      min-width: 10px;
      overflow: hidden;
      text-align: center;
      font-weight: normal;
      font-size: 12px;
      position: absolute;
      margin-left: 44px;
      margin-top: -49px;
      width: 18px; }
      .minicart-wrapper .action.showcart .counter.qty.empty {
        display: none; }
      .minicart-wrapper .action.showcart .counter.qty .loader > img {
        max-width: 24px; }
  .minicart-wrapper .minicart-widgets {
    margin-top: 15px; }

.minicart-items-wrapper {
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  border-top: 0px solid #ccc;
  border-left: 0;
  border-right: 0;
  overflow-x: auto;
  padding: 15px; }

.title-minicart {
  font-size: 25px;
  padding: 5px;
  margin-top: -14px; }

.minicart-items-wrapper .item.product.product-item .product-item-photo {
  background: none !important;
  text-align: center;
  display: block;
  padding-top: 0;
  padding-bottom: 26px !important;
  height: 50px !important;
  width: 50px !important; }
  .minicart-items-wrapper .item.product.product-item .product-item-photo img {
    width: auto;
    height: 100%; }

/**/
td.col.item .product-item-photo {
  height: auto !important;
  background-color: white; }

.minicart-wrapper .product-item-photo {
  background: #ffffff;
  padding-top: 0px;
  padding-bottom: 45px;
  text-align: center;
  display: block;
  height: 50px !important; }

/*CSS simple products ipv grouped*/
.minicart-items {
  margin: 0;
  padding: 0;
  list-style: none none; }
  .minicart-items .product-item {
    padding: 20px 0; }
    .minicart-items .product-item:not(:first-child) {
      border-top: 1px solid #ccc; }
    .minicart-items .product-item:first-child {
      padding-top: 0; }
  .minicart-items .product-item-pricing .label {
    display: inline-block;
    width: 4.5rem; }
  .minicart-items .price-minicart {
    margin-bottom: 5px; }
  .minicart-items .product > .product-item-photo,
  .minicart-items .product > .product-image-container {
    float: left; }
  .minicart-items .product .toggle {
    display: block;
    text-decoration: none;
    cursor: pointer;
    position: relative; }
    .minicart-items .product .toggle:after {
      -webkit-font-smoothing: antialiased;
      font-size: 28px;
      line-height: 16px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: inherit;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: middle; }
    .minicart-items .product .toggle:hover:after {
      color: inherit; }
    .minicart-items .product .toggle:active:after {
      color: inherit; }
    .minicart-items .product .toggle:after {
      position: static; }
  .minicart-items .product.active > .toggle:after {
    content: ""; }
  .minicart-items .product-item-name {
    margin: 0 0 10px;
    font-size: 16px;
    height: auto; }
    .minicart-items .product-item-name a {
      color: #1979c3; }
  .minicart-items .product-item-details {
    /*padding-left: 88px;*/ }
    .minicart-items .product-item-details .price {
      font-weight: 700; }
    .minicart-items .product-item-details .price-including-tax,
    .minicart-items .product-item-details .price-excluding-tax {
      margin: 5px 0 0; }
    .minicart-items .product-item-details .weee[data-label] {
      font-size: 11px; }
    .minicart-items .product-item-details .details-qty {
      margin-top: 10px; }
  .minicart-items .product.options .tooltip.toggle {
    display: inline-block;
    text-decoration: none; }
    .minicart-items .product.options .tooltip.toggle > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .minicart-items .product.options .tooltip.toggle:after {
      -webkit-font-smoothing: antialiased;
      font-size: 28px;
      line-height: 28px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: -3px 0 0 7px;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: middle; }
    .minicart-items .product.options .tooltip.toggle:hover:after {
      color: inherit; }
    .minicart-items .product.options .tooltip.toggle:active:after {
      color: inherit; }
    .minicart-items .product.options .tooltip.toggle .details {
      display: none; }
  .minicart-items .item-qty {
    margin-right: 10px;
    text-align: center;
    width: 40px; }
  .minicart-items .update-cart-item {
    font-size: 11px;
    vertical-align: top; }
  .minicart-items .subtitle {
    display: none; }
  .minicart-items .action.edit, .minicart-items .action.delete {
    display: inline-block;
    text-decoration: none; }
    .minicart-items .action.edit > span, .minicart-items .action.delete > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .minicart-items .action.edit:before, .minicart-items .action.delete:before {
      -webkit-font-smoothing: antialiased;
      font-size: 28px;
      line-height: 28px;
      color: #303030;
      content: "";
      font-family: "icons-blank-theme";
      margin: inherit;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: middle; }
    .minicart-items .action.edit:hover:before, .minicart-items .action.delete:hover:before {
      color: #303030; }
    .minicart-items .action.edit:active:before, .minicart-items .action.delete:active:before {
      color: #303030; }
  .minicart-items .action.delete:before {
    content: ""; }

@media only screen and (max-width: 480px) {
  .minicart-wrapper .block-minicart {
    width: 290px; }
  .quickcart-wrapper .block-quickcart .block-content-quickcart .quickcart-items-wrapper {
    max-height: calc(100vh - 240px) !important; } }

@media only screen and (min-width: 768px) {
  .minicart-wrapper {
    margin-left: 13px; }
    .minicart-wrapper .block-minicart {
      right: -15px;
      width: 390px; } }

.checkout-container {
  padding: 0 15px;
  box-sizing: border-box; }

.checkout-index-index input[type="checkbox"] {
  margin: 4.5px 5px 0 0; }

.checkout-index-index .field .label {
  font-size: 14px; }

.checkout-index-index .billing-address-same-as-shipping-block label {
  font-size: 14px; }

.checkout-index-index .page-wrapper .logo {
  left: 0;
  transform: unset; }

.checkout-index-index .footercms ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0; }

.opc-block-summary > .title {
  display: block;
  font-family: "Gotham";
  font-size: 20px;
  font-weight: 600; }

.checkout-container {
  margin: 0 0 20px; }

.opc-wrapper {
  margin: 0 0 20px; }
  .opc-wrapper .step-content {
    margin: 0 0 40px; }

.checkout-index-index .nav-sections,
.checkout-index-index .nav-toggle {
  display: none; }

.checkout-index-index .logo {
  margin-left: 0; }

@media only screen and (max-width: 640px) {
  .opc-wrapper .step-title {
    font-size: 18px;
    border-bottom: 0;
    padding-bottom: 0; } }

@media only screen and (min-width: 768px) {
  .opc-wrapper {
    width: 66.66667%;
    float: left;
    order: 1;
    padding-right: 30px; }
    .opc-wrapper .billing-address-same-as-shipping-block {
      margin-left: 20%; }
  .checkout-index-index .footercms ul {
    flex-direction: row;
    justify-content: center;
    margin: 0 auto; }
    .checkout-index-index .footercms ul li {
      margin-right: 20px; } }

.checkout-index-index .opc-wrapper .form-login .fieldset > .field {
  width: 100%;
  box-sizing: border-box; }

@media only screen and (max-width: 520px) {
  body .opc-wrapper .fieldset > .field {
    width: 100%; } }

.opc-estimated-wrapper {
  border-bottom: 1px solid #ccc;
  margin: 0 0 15px; }
  .opc-estimated-wrapper .estimated-block {
    font-size: 18px;
    font-weight: 700;
    float: left; }
    .opc-estimated-wrapper .estimated-block .estimated-label {
      display: block;
      margin: 0 0 5px; }
  .opc-estimated-wrapper .minicart-wrapper .action.showcart {
    border: none;
    position: relative; }
    .opc-estimated-wrapper .minicart-wrapper .action.showcart .counter.qty {
      position: absolute;
      top: 0;
      margin: 0;
      width: 25px; }
    .opc-estimated-wrapper .minicart-wrapper .action.showcart:before {
      color: #005266; }

@media only screen and (min-width: 768px) {
  .opc-estimated-wrapper {
    display: none; } }

.stepper-wrapper {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0;
  overflow: hidden; }
  .stepper-wrapper .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1; }
    .stepper-wrapper .stepper-item:before, .stepper-wrapper .stepper-item:after {
      position: absolute;
      content: "";
      border-bottom: 4px solid #ccc;
      width: 100%;
      top: 20px;
      z-index: 2;
      margin-top: -5px; }
    .stepper-wrapper .stepper-item:before {
      left: -50%; }
    .stepper-wrapper .stepper-item:after {
      left: 50%; }
    .stepper-wrapper .stepper-item .step-name {
      color: #014a5b; }
    .stepper-wrapper .stepper-item .step-counter {
      color: #c9d2d9;
      background: #f8f8f8;
      position: relative;
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 33px;
      height: 33px;
      margin-bottom: 6px;
      font-weight: bold; }
    .stepper-wrapper .stepper-item.active .step-counter {
      background: #014A5B; }
    .stepper-wrapper .stepper-item.active:before, .stepper-wrapper .stepper-item.active:after {
      border-bottom: 4px solid #014A5B; }

.stepper-item.completed .step-counter {
  background-color: #4bb543; }

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #4bb543;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3; }

.control .field._required .label {
  display: none; }

.opc-wrapper .fieldset > .field.experius-address-line-one {
  width: 100%; }

.field-tooltip .label {
  display: none; }

.field._required .label::after {
  content: '*';
  color: #e02b27;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-size: 12px;
  margin: 0 0 0 5px;
  margin-bottom: 0px; }

.field.choice .field-tooltip {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: -3px; }

.field.choice .field-tooltip-action {
  line-height: 21px; }

.field .field-note {
  padding-top: 7px;
  font-size: 13px; }

.field._error .control input,
.field._error .control select,
.field._error .control textarea {
  border-color: #ed8380; }

.opc-wrapper #customer-email-fieldset.fieldset {
  padding-bottom: 20px; }
  .opc-wrapper #customer-email-fieldset.fieldset .field.required input.input-text {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    .opc-wrapper #customer-email-fieldset.fieldset .fieldset.hidden-fields .actions-toolbar {
      margin-left: 20%; } }

.opc-wrapper .form.form-login .fieldset.hidden-fields {
  width: 100%;
  margin-top: 40px; }

.opc-wrapper .fieldset > .field {
  width: 100%;
  margin-bottom: 20px; }
  .opc-wrapper .fieldset > .field.experius-address-lines {
    margin-bottom: 0; }
  .opc-wrapper .fieldset > .field input,
  .opc-wrapper .fieldset > .field select {
    border: 1px solid #e8e8e8;
    height: 40px; }
  .opc-wrapper .fieldset > .field input[type="checkbox"] {
    height: unset;
    margin-top: 30px; }
  .opc-wrapper .fieldset > .field .control {
    width: 100%; }
    .opc-wrapper .fieldset > .field .control .field {
      width: 25%;
      float: left;
      margin-top: 0; }
      .opc-wrapper .fieldset > .field .control .field:first-child {
        width: 70%;
        margin-right: 5%; }
  .opc-wrapper .fieldset > .field > .label {
    font-weight: 400;
    width: 100%;
    font-weight: 600; }

@media only screen and (min-width: 768px) {
  .opc-wrapper #customer-email-fieldset.fieldset {
    padding-bottom: unset; }
  .opc-wrapper .fieldset > .field .control {
    width: 80%;
    float: right; }
  .opc-wrapper .fieldset > .field > .label {
    width: 20%; }
  .opc-wrapper .fieldset > .field input[type="checkbox"] {
    margin-top: 10px; }
  .opc-wrapper .fieldset > .field.required, .opc-wrapper .fieldset > .field._required {
    position: relative; }
    .opc-wrapper .fieldset > .field.required > label, .opc-wrapper .fieldset > .field._required > label {
      padding-right: 25px; }
      .opc-wrapper .fieldset > .field.required > label:after, .opc-wrapper .fieldset > .field._required > label:after {
        margin-left: 10px;
        position: absolute;
        top: 9px; } }

@media only screen and (min-width: 768px) {
  .checkout-index-index .modal-popup .form-shipping-address {
    max-width: 500px; }
  .checkout-index-index .modal-popup .modal-footer .action-save-address {
    float: right;
    margin: 0 0 0 10px; } }

@media only screen and (min-width: 1024px) {
  .checkout-index-index .modal-popup .modal-inner-wrap {
    margin-left: -400px;
    width: 800px;
    left: 50%; } }

.field-tooltip {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 1px; }
  .field-tooltip._active {
    z-index: 100; }
    .field-tooltip._active .field-tooltip-content {
      display: block; }
    .field-tooltip._active .field-tooltip-action:before {
      color: #2FDF66; }
  .field-tooltip .field-tooltip-action {
    display: inline-block;
    text-decoration: none; }
    .field-tooltip .field-tooltip-action > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .field-tooltip .field-tooltip-action:before {
      -webkit-font-smoothing: antialiased;
      font-size: 21px;
      line-height: inherit;
      color: #bbb;
      content: "";
      font-family: "icons-blank-theme";
      margin: inherit;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: middle; }
    .field-tooltip .field-tooltip-action:hover:before {
      color: #2FDF66; }
    .field-tooltip .field-tooltip-action:active:before {
      color: false; }
    ._keyfocus .field-tooltip .field-tooltip-action:focus {
      z-index: 100; }
      ._keyfocus .field-tooltip .field-tooltip-action:focus + .field-tooltip-content {
        display: block; }
      ._keyfocus .field-tooltip .field-tooltip-action:focus:before {
        color: #2FDF66; }
  .field-tooltip .field-tooltip-content {
    background: #f4f4f4;
    border: 1px solid #999;
    border-radius: 1px;
    font-size: 14px;
    padding: 12px;
    width: 270px;
    display: none;
    left: 38px;
    position: absolute;
    text-transform: none;
    top: -9px;
    word-wrap: break-word;
    z-index: 2; }
    .field-tooltip .field-tooltip-content:before, .field-tooltip .field-tooltip-content:after {
      border: 10px solid transparent;
      height: 0;
      width: 0;
      border-right-color: #f4f4f4;
      left: -21px;
      top: 12px;
      content: '';
      display: block;
      position: absolute;
      z-index: 3; }
    .field-tooltip .field-tooltip-content:before {
      border-right-color: #666666; }
    .field-tooltip .field-tooltip-content:after {
      border-right-color: #f4f4f4;
      width: 1px;
      z-index: 4; }

@media only screen and (max-width: 480px) {
  .modal-popup .field-tooltip .field-tooltip-content {
    width: 200px; } }

.cart-summary #block-shipping .title {
  padding: 15px 30px 15px 5px; }

.cart-summary #block-shipping .content {
  display: block; }

@media only screen and (min-width: 768px) {
  #checkout-step-shipping_method {
    margin-left: 20%; } }

#calendar-delivery-date-disclaimer {
  padding: 15px;
  font-weight: 600;
  border: 3px solid #ff8d8d; }

#calendar-delivery-date {
  max-width: 420px;
  margin-left: 20%; }
  @media only screen and (max-width: 640px) {
    #calendar-delivery-date {
      margin: 10px auto 0 auto; } }
  #calendar-delivery-date td:not(.ui-datepicker-week-col):not(.ui-datepicker-unselectable):not(.ui-datepicker-header) a {
    color: #2FDF66;
    font-weight: 600; }
  #calendar-delivery-date td:not(.ui-datepicker-week-col):not(.ui-datepicker-unselectable):not(.ui-datepicker-header).ui-state-active {
    background: none; }
    #calendar-delivery-date td:not(.ui-datepicker-week-col):not(.ui-datepicker-unselectable):not(.ui-datepicker-header).ui-state-active a {
      background-color: rgba(47, 223, 102, 0.5);
      color: #000; }
  #calendar-delivery-date tr:hover td:not(.ui-datepicker-unselectable):not(.ui-datepicker-week-col) a {
    background-color: rgba(47, 223, 102, 0.5);
    color: #000; }

.fieldset.hidden-fields .actions-toolbar {
  margin-top: 30px; }

#shipping-new-address-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 20px; }

.opc-wrapper .form-login {
  background: none !important;
  padding: 0 !important;
  border-bottom: none; }
  .opc-wrapper .form-login .field-tooltip {
    display: none !important; }
  .opc-wrapper .form-login .note {
    display: none !important; }
  .opc-wrapper .form-login .field {
    margin-bottom: -20px; }

.opc-block-summary {
  border: solid #f8f8f8 1px;
  background: #f9f9f9;
  padding: 22px 30px;
  margin: 0 0 20px;
  width: 100%; }

.opc-wrapper .form-login,
.opc-wrapper .form-shipping-address {
  margin-top: 28px;
  margin-bottom: 20px; }
  .opc-wrapper .form-login .fieldset .note,
  .opc-wrapper .form-shipping-address .fieldset .note {
    font-size: 14px;
    margin-top: 10px; }

.opc-wrapper .form-shipping-address .street .control .label {
  display: block !important;
  clip: unset !important;
  height: unset !important;
  margin: unset !important;
  overflow: unset !important;
  padding: unset !important;
  position: unset !important;
  width: unset !important; }

.opc-wrapper .shipping-address-items {
  font-size: 0; }

.opc-wrapper .experius-address-line-one input::placeholder {
  color: #dddddd; }

.opc-wrapper .form-shipping-address .field .field-error,
.opc-wrapper .billing-address-form .field .field-error {
  margin-top: 3px;
  font-size: 12px; }

.opc-wrapper .form-shipping-address .street .control .label {
  display: block !important;
  clip: unset !important;
  height: unset !important;
  margin: unset !important;
  overflow: unset !important;
  padding: unset !important;
  position: unset !important;
  width: unset !important; }

.opc-wrapper .shipping-address-item {
  border: 2px solid transparent;
  line-height: 30px;
  margin: 0 0 20px;
  padding: 20px 35px 20px 20px;
  transition: 0.3s border-color;
  width: 50%;
  display: inline-block;
  font-size: 14px;
  position: relative;
  vertical-align: top;
  word-wrap: break-word; }
  .opc-wrapper .shipping-address-item.selected-item {
    border-color: #ff5501; }
    .opc-wrapper .shipping-address-item.selected-item:after {
      background: #ff5501;
      color: #fff;
      content: "";
      font-family: "icons-blank-theme";
      height: 27px;
      width: 29px;
      font-size: 27px;
      line-height: 21px;
      padding-top: 2px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0; }
    .opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
      visibility: hidden; }

.opc-wrapper .action-show-popup {
  margin: 0 0 20px; }
  .opc-wrapper .action-show-popup > span:before {
    content: '+';
    padding-right: 5px; }

.opc-wrapper .action-select-shipping-item {
  float: right;
  margin: 20px 0 0; }

.opc-wrapper .edit-address-link {
  display: block;
  float: left;
  margin: 26px 5px 0 0; }

.checkout-shipping-method .step-title {
  margin-bottom: 5px; }

.checkout-shipping-method .field label {
  font-weight: bold; }

.checkout-shipping-method .no-quotes-block {
  margin: 20px 0; }

.methods-shipping .actions-toolbar .action.primary {
  margin: 20px 0 0; }

.table-checkout-shipping-method thead th {
  display: none; }

.table-checkout-shipping-method tbody td {
  border-top: 1px solid #ccc;
  padding-bottom: 20px;
  padding-top: 20px; }
  .table-checkout-shipping-method tbody td:first-child {
    padding-left: 0;
    padding-right: 0;
    width: 20px; }

.table-checkout-shipping-method tbody tr:first-child td {
  border-top: none; }

.table-checkout-shipping-method tbody .row-error td {
  border-top: none;
  padding-bottom: 10px;
  padding-top: 0; }

.table-checkout-shipping-method .col-price {
  width: 35px; }

.table-checkout-shipping-method .col-method ~ .col-method {
  display: none; }

@media only screen and (max-width: 640px) {
  .opc-wrapper .form-login {
    border-bottom: 1px solid #ccc;
    margin: 0 0 15px;
    padding: 0 0 15px; }
  .opc-wrapper .shipping-address-item {
    border-bottom: 1px solid #ccc;
    margin: 0 0 15px;
    padding: 0 0 15px;
    width: 100%; }
    .opc-wrapper .shipping-address-item.selected-item {
      padding: 15px 35px 15px 18px;
      border-bottom-width: 2px; }
      .opc-wrapper .shipping-address-item.selected-item .edit-address-link {
        right: 39px; }
  .opc-wrapper .form-login,
  .opc-wrapper .form-shipping-address {
    margin-top: 28px; }
  .opc-wrapper .action-select-shipping-item {
    float: none;
    margin-top: 10px;
    width: 100%; }
  .opc-wrapper .action-show-popup {
    width: 100%; }
  .opc-wrapper .edit-address-link {
    display: inline-block;
    text-decoration: none;
    margin: 0;
    position: absolute;
    right: 0;
    top: 1px; }
    .opc-wrapper .edit-address-link > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .opc-wrapper .edit-address-link:before {
      -webkit-font-smoothing: antialiased;
      font-size: 28px;
      line-height: 28px;
      color: #303030;
      content: "";
      font-family: "icons-blank-theme";
      margin: inherit;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: middle; }
    .opc-wrapper .edit-address-link:hover:before {
      color: #303030; }
    .opc-wrapper .edit-address-link:active:before {
      color: #303030; } }

@media only screen and (min-width: 768px) {
  .checkout-shipping-method .actions-toolbar > .primary {
    float: right; }
  .checkout-shipping-method .actions-toolbar .action.primary {
    margin: 0; }
  .opc-wrapper .form-login,
  .opc-wrapper .form-shipping-address {
    max-width: 100%; }
  .table-checkout-shipping-method {
    width: auto; } }

@media only screen and (min-width: 1024px) {
  .opc-wrapper .shipping-address-item {
    width: 33.3333%; }
    .opc-wrapper .shipping-address-item:before {
      background: #ccc;
      height: calc(100% - 20px);
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      width: 1px; }
    .opc-wrapper .shipping-address-item:nth-child(3n + 1):before {
      display: none; }
    .opc-wrapper .shipping-address-item.selected-item:before {
      display: none; }
    .opc-wrapper .shipping-address-item.selected-item + .shipping-address-item:before {
      display: none; }
  .table-checkout-shipping-method {
    min-width: 500px; } }

.checkout-shipping-method {
  position: relative; }

.shipping-policy-block.field-tooltip {
  top: 12px; }
  .shipping-policy-block.field-tooltip .field-tooltip-action {
    color: #1979c3;
    cursor: pointer; }
    .shipping-policy-block.field-tooltip .field-tooltip-action:before {
      display: none; }
  .shipping-policy-block.field-tooltip .field-tooltip-content {
    width: 420px;
    top: 30px; }

@media only screen and (max-width: 640px) {
  .shipping-policy-block.field-tooltip {
    margin-bottom: 20px;
    position: relative;
    right: auto;
    top: auto; }
    .shipping-policy-block.field-tooltip .field-tooltip-content {
      width: 300px;
      right: auto; }
      .shipping-policy-block.field-tooltip .field-tooltip-content:before, .shipping-policy-block.field-tooltip .field-tooltip-content:after {
        right: auto; } }

.experius_postcode_fieldset {
  margin: 0;
  width: 100%; }
  .experius_postcode_fieldset .checkbox {
    height: inherit !important; }
  .experius_postcode_fieldset .mage-error {
    float: left; }
    @media only screen and (min-width: 768px) {
      .experius_postcode_fieldset .mage-error {
        float: none;
        margin-left: 20%; }
        .experius_postcode_fieldset .mage-error:before {
          margin-left: -6px !important; } }

.experius-address-lines input:disabled {
  background: #dddddd; }

.shippingAddress-city input:disabled {
  background: #dddddd; }

#company-switcher {
  height: 40px; }
  #company-switcher input {
    height: unset !important; }
  #company-switcher #billing\:type_business {
    margin-left: 10px; }

.form-shipping-address div[name="shippingAddress.vat_id"] {
  display: none; }

.billing-address-form div[name="billingAddress.vat_id"],
.billing-address-form div[name="billingAddress.company"] {
  display: none; }

.header-checkout-contact {
  display: flex;
  float: right;
  align-items: center;
  margin-top: 8px;
  font-size: 20px; }
  @media only screen and (max-width: 640px) {
    .header-checkout-contact .tel-title {
      display: none; } }
  .header-checkout-contact .phone {
    padding-left: 15px;
    color: #20272b !important; }
    .header-checkout-contact .phone:visited {
      color: #20272b !important; }
    .header-checkout-contact .phone:hover {
      text-decoration: none; }
    .header-checkout-contact .phone:before {
      content: "\e969";
      font-size: 20px;
      font-weight: 200;
      font-family: "icomoon";
      color: #2FDF66; }

@media only screen and (min-width: 768px) {
  .opc-sidebar {
    margin: 0 !important;
    position: sticky;
    top: 20px;
    width: 66.66667%;
    float: right;
    order: 2; }
    .opc-sidebar .opc-block-summary {
      background: none;
      border: 1px solid #D8D8D8; }
    .opc-sidebar .modal-inner-wrap {
      background: none !important; }
  .opc-block-shipping-information {
    padding: 0 !important; }
    .opc-block-shipping-information .shipping-information {
      border: 1px solid #D8D8D8;
      padding: 22px 30px !important; } }

.opc-sidebar {
  margin: 28px 0 20px;
  width: 33.33333% !important;
  float: right;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2; }
  .opc-sidebar .modal-inner-wrap {
    background: #f8f8f8; }
  .opc-sidebar._show {
    width: auto !important;
    left: 50px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; }

.opc-block-summary .minicart-items .product {
  display: flex;
  padding-top: 15px; }
  .opc-block-summary .minicart-items .product .product-item-details {
    overflow: unset; }

.opc-block-summary {
  border: solid #f8f8f8 1px;
  padding: 22px 30px;
  margin: 0 0 20px;
  height: auto;
  display: inline-grid; }
  .opc-block-summary > .title {
    border-bottom: none; }
  .opc-block-summary .items-in-cart {
    margin-top: 10px;
    margin-bottom: 0; }
  .opc-block-summary .product-image-container {
    background: none; }

.opc-block-shipping-information {
  padding: 0 30px; }
  .opc-block-shipping-information .shipping-information-title {
    font-family: "Gotham";
    border-bottom: 1px solid #ccc;
    margin: 0 0 20px;
    position: relative; }
    .opc-block-shipping-information .shipping-information-title .action-edit {
      top: 10px;
      display: inline-block;
      text-decoration: none;
      margin: 0;
      position: absolute;
      right: 0; }
      .opc-block-shipping-information .shipping-information-title .action-edit > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .opc-block-shipping-information .shipping-information-title .action-edit:before {
        -webkit-font-smoothing: antialiased;
        font-size: 18px;
        line-height: 20px;
        color: #8f8f8f;
        content: "";
        font-family: "icons-blank-theme";
        margin: inherit;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
        vertical-align: middle; }
      .opc-block-shipping-information .shipping-information-title .action-edit:hover:before {
        color: #2FDF66; }
      .opc-block-shipping-information .shipping-information-title .action-edit:active:before {
        color: #8f8f8f; }
  .opc-block-shipping-information .shipping-information-content {
    line-height: 27px; }
    .opc-block-shipping-information .shipping-information-content .actions-toolbar {
      margin-left: 0; }
  .opc-block-shipping-information .ship-to,
  .opc-block-shipping-information .ship-via {
    margin: 0 0 20px; }

.opc-block-shipping-information .shipping-information-title {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 5px; }

.opc-block-shipping-information .shipping-information-content {
  font-size: 13px; }

.opc-block-summary {
  border: solid #f8f8f8 1px;
  background: #f8f8f8;
  padding: 22px 30px;
  margin: 0 0 20px;
  width: 100%;
  font-size: 13px; }
  .opc-block-summary span.price {
    font-size: 13px;
    color: #4A4A49; }
  .opc-block-summary > .title {
    display: block; }
  .opc-block-summary .table-totals {
    display: block;
    width: 100%; }
    .opc-block-summary .table-totals tbody {
      border-collapse: collapse;
      display: inline-block;
      width: 100%; }
      .opc-block-summary .table-totals tbody tr {
        display: flex;
        width: 100%;
        justify-content: space-between; }
        .opc-block-summary .table-totals tbody tr th,
        .opc-block-summary .table-totals tbody tr td {
          display: inline-block;
          font-size: 13px; }
      .opc-block-summary .table-totals tbody .totals.charge {
        display: none; }
  .opc-block-summary .mark {
    font-size: 13px; }
    .opc-block-summary .mark .value {
      color: #999;
      display: block; }
  .opc-block-summary .grand.incl + .grand.excl .mark,
  .opc-block-summary .grand.incl + .grand.excl .amount {
    border-top: 0;
    font-size: 13px;
    padding-top: 0; }
  .opc-block-summary .grand.totals .mark strong {
    font-size: 16px; }
  .opc-block-summary .grand.totals span.price {
    font-size: 16px; }
  .opc-block-summary .grand.totals.excl.both strong {
    font-size: 13px;
    font-weight: normal; }
  .opc-block-summary .grand.totals.excl.both span.price {
    font-size: 13px; }
  .opc-block-summary .items-in-cart > .title {
    border-bottom: 1px solid #d1d1d1;
    padding: 10px 40px 10px 0;
    cursor: pointer;
    margin-bottom: 0;
    position: relative; }
    .opc-block-summary .items-in-cart > .title:after {
      position: absolute;
      right: 0;
      top: 10px; }
    .opc-block-summary .items-in-cart > .title strong {
      font-size: 18px;
      font-weight: 300;
      margin: 0; }
  .opc-block-summary .items-in-cart.active > .title:after {
    content: ""; }
  .opc-block-summary .items-in-cart .product {
    position: relative; }
  .opc-block-summary .minicart-items-wrapper {
    max-height: 370px;
    padding: 0;
    border: 0; }
  .column.main .opc-block-summary .product-item {
    margin: 0;
    padding-left: 0; }
  .opc-block-summary .product-item .product-item-inner {
    display: table;
    margin: 0 0 10px;
    width: 100%; }
  .opc-block-summary .product-item .subtotal {
    display: table-cell;
    text-align: right;
    padding-left: 8px; }
  .opc-block-summary .product-item .price {
    font-size: 16px;
    font-weight: 400; }
  .opc-block-summary .product-item .price-including-tax + .price-excluding-tax {
    margin: 0; }
    .opc-block-summary .product-item .price-including-tax + .price-excluding-tax .price {
      font-size: 10px; }

.trustedshops-checkout {
  margin-top: 15px;
  text-align: center; }
  @media only screen and (max-width: 640px) {
    .trustedshops-checkout {
      display: none; } }

@media only screen and (min-width: 768px) {
  .opc-summary-wrapper .modal-header .action-close {
    display: none; } }

.authentication-dropdown {
  box-sizing: border-box; }
  .authentication-dropdown .modal-inner-wrap {
    padding: 25px; }

.authentication-wrapper {
  float: right;
  margin-top: -195px;
  max-width: 50%;
  position: relative;
  z-index: 1; }
  ._has-auth-shown .authentication-wrapper {
    z-index: 900; }

.block-authentication .block-title {
  font-size: 18px;
  border-bottom: 0;
  margin-bottom: 25px; }
  .block-authentication .block-title strong {
    font-weight: 300; }

.block-authentication .field .label {
  font-weight: 400; }

.block-authentication .actions-toolbar {
  margin-bottom: 5px; }
  .block-authentication .actions-toolbar > .secondary {
    padding-top: 25px;
    text-align: left; }

.block-authentication .block[class] {
  margin: 0; }
  .block-authentication .block[class] ul {
    list-style: none;
    padding-left: 10px; }
  .block-authentication .block[class] .field .control,
  .block-authentication .block[class] .field .label {
    float: none;
    width: auto; }
  .block-authentication .block[class] + .block {
    border-top: 1px solid #c1c1c1;
    margin-top: 40px;
    padding-top: 40px;
    position: relative; }
    .block-authentication .block[class] + .block::before {
      height: 36px;
      line-height: 34px;
      margin: -17px 0 0 -18px;
      min-width: 36px;
      background: #fff;
      border: 1px solid #c1c1c1;
      border-radius: 50%;
      box-sizing: border-box;
      color: #c1c1c1;
      content: attr(data-label);
      display: inline-block;
      left: 50%;
      letter-spacing: normal;
      padding: 0 0.2rem;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      top: 0; }

@media only screen and (min-width: 768px) {
  .authentication-dropdown {
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: #fff;
    border: 1px solid #aeaeae;
    text-align: left;
    transform: scale(1, 0);
    transform-origin: 0 0;
    transition: transform linear 0.15s, visibility 0 linear 0.15s;
    visibility: hidden; }
    .authentication-dropdown._show {
      z-index: 100;
      transform: scale(1, 1);
      transition: transform linear 0.15s, visibility 0 linear 0;
      visibility: visible; }
  .authentication-wrapper {
    width: 66.66667%;
    text-align: right; }
  .block-authentication .block-title {
    font-size: 26px;
    border-bottom: 0;
    margin-bottom: 25px; }
  .block-authentication .actions-toolbar > .primary {
    float: right;
    margin-right: 0; }
    .block-authentication .actions-toolbar > .primary .action {
      margin-right: 0; }
  .block-authentication .actions-toolbar > .secondary {
    float: left;
    margin-right: 2rem;
    padding-top: 1rem; }
  .popup-authentication .modal-inner-wrap {
    min-width: 768px;
    width: 60%; }
  .popup-authentication .block-authentication {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #c1c1c1; }
  .popup-authentication .block[class],
  .popup-authentication .form-login,
  .popup-authentication .fieldset,
  .popup-authentication .block-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
  .popup-authentication .block[class] {
    box-sizing: border-box;
    float: left;
    padding: 10px 30px 0 0;
    width: 50%; }
    .popup-authentication .block[class] + .block {
      border-left: 1px solid #c1c1c1;
      border-top: 0;
      margin: 0;
      padding: 10px 0 0 40px; }
      .popup-authentication .block[class] + .block::before {
        left: 0;
        top: 50%; }
  .popup-authentication .actions-toolbar {
    margin-bottom: 0;
    margin-top: auto; } }

@media only screen and (min-width: 768px) {
  #checkoutSteps {
    padding: 30px;
    border: 1px solid #D8D8D8; } }

#checkoutSteps {
  padding-top: 30px; }

.billing-address-form {
  max-width: 100%;
  width: 100%;
  margin-top: 25px; }
  .billing-address-form .step-title {
    margin-bottom: 25px; }
  .billing-address-form .street .control .label {
    display: block !important;
    clip: unset !important;
    height: unset !important;
    margin: unset !important;
    overflow: unset !important;
    padding: unset !important;
    position: unset !important;
    width: unset !important; }

.checkout-payment-method .step-title {
  margin-bottom: 0; }
  @media only screen and (max-width: 640px) {
    .checkout-payment-method .step-title {
      margin-left: 5px; } }

.checkout-payment-method .payment-method:first-child .payment-method-title {
  border-top: 0; }

.checkout-payment-method .payment-method._active .payment-method-content {
  display: block; }

.checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary {
  background-color: #2fdf66; }

.checkout-payment-method .payment-method-title {
  border-top: 1px solid #ccc;
  padding: 20px 0;
  margin: 0; }
  .checkout-payment-method .payment-method-title .payment-icon-wrapper {
    display: inline-block;
    min-width: 100px;
    text-align: center; }
    .checkout-payment-method .payment-method-title .payment-icon-wrapper img {
      max-width: 65px; }
  .checkout-payment-method .payment-method-title .payment-icon {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle; }
  .checkout-payment-method .payment-method-title .action-help {
    display: inline-block;
    margin-left: 5px; }

.checkout-payment-method .payment-method-content {
  display: none;
  padding: 0 0 20px 22px; }
  .checkout-payment-method .payment-method-content .fieldset:not(:last-child) {
    margin: 0 0 20px; }

.checkout-payment-method .field-select-billing,
.checkout-payment-method .billing-address-form {
  max-width: 100%; }

.checkout-payment-method .billing-address-same-as-shipping-block {
  margin: 0 0 10px; }

.checkout-payment-method .payment-method-billing-address {
  margin: 0 0 20px; }
  .checkout-payment-method .payment-method-billing-address .billing-address-same-as-shipping-block {
    display: none; }
  .checkout-payment-method .payment-method-billing-address .primary .action-update {
    margin-right: 0; }
  .checkout-payment-method .payment-method-billing-address .billing-address-details {
    line-height: 27px;
    padding: 0 0 0 23px; }

.checkout-payment-method .payment-method-note + .payment-method-billing-address {
  margin-top: 20px; }

.checkout-payment-method .payment-method-iframe {
  background-color: transparent;
  display: none;
  width: 100%; }

.checkout-payment-method .no-payments-block {
  margin: 20px 0; }

.checkout-payment-method #block-discount-heading span {
  color: #20272B !important; }

@media only screen and (max-width: 640px) {
  .checkout-payment-method .payment-option.discount-code {
    padding: 0 20px; } }

.checkout-payment-method .payment-method-issuers {
  padding-left: 23px; }

.checkout-payment-method .payment-method-afterpay {
  padding: 0 0 0 23px; }

.checkout-payment-method .payment-method-gender {
  margin-bottom: 0 !important; }

@media only screen and (max-width: 768px) {
  .checkout-payment-method .payment-methods {
    margin: 0 -15px; }
  .checkout-payment-method .payment-method-title {
    padding: 15px; }
  .checkout-payment-method .payment-method-content {
    padding: 0 15px 20px; }
  .checkout-payment-method .payment-method-billing-address .action-cancel {
    margin-top: 10px; } }

#billing-new-address-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

@media only screen and (min-width: 768px) {
  .checkout-payment-method .payment-methods .actions-toolbar .primary {
    float: right;
    margin: 0; }
  .checkout-payment-method .fieldset > .field-select-billing > .control {
    float: none;
    width: 100%; }
  .checkout-payment-method .payment-method-content .fieldset > .field {
    margin: 0 0 20px; }
    .checkout-payment-method .payment-method-content .fieldset > .field.type .control {
      margin-left: 25.8%; }
    .checkout-payment-method .payment-method-content .fieldset > .field.type.no-detection .control {
      margin-left: 0; }
  .payment-method-billing-address .action-update {
    float: right; }
  .payment-method-billing-address .actions-toolbar .action-cancel {
    margin: 6px 20px 0 0; } }

.checkout-payment-method .payment-option._active .payment-option-title .action-toggle:after {
  content: ""; }

.checkout-payment-method .payment-option._collapsible .payment-option-title {
  cursor: pointer; }

.checkout-payment-method .payment-option._collapsible .payment-option-content {
  display: none; }

.checkout-payment-method .payment-option-title {
  border-top: 1px solid #ccc;
  padding: 20px 0; }
  .checkout-payment-method .payment-option-title .action-toggle {
    color: #014A5B;
    display: inline-block;
    text-decoration: none; }
    .checkout-payment-method .payment-option-title .action-toggle:after {
      -webkit-font-smoothing: antialiased;
      font-size: 14px;
      line-height: 16px;
      color: #8f8f8f;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0 0 0 10px;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: middle; }
    .checkout-payment-method .payment-option-title .action-toggle:hover:after {
      color: #2FDF66; }
    .checkout-payment-method .payment-option-title .action-toggle:active:after {
      color: #8f8f8f; }

.checkout-payment-method .payment-option-content {
  padding: 0 0 20px 22px; }

.checkout-payment-method .payment-option-inner {
  margin: 0 0 20px; }

.checkout-payment-method .credit-card-types {
  padding: 0; }
  .checkout-payment-method .credit-card-types .item {
    display: inline-block;
    list-style: none;
    margin: 0 5px 0 0;
    vertical-align: top; }
    .checkout-payment-method .credit-card-types .item._active {
      font-weight: 700; }
      .checkout-payment-method .credit-card-types .item._active img {
        filter: grayscale(0%); }
    .checkout-payment-method .credit-card-types .item._inactive {
      opacity: 0.4; }
    .checkout-payment-method .credit-card-types .item span {
      display: inline-block;
      padding-top: 6px;
      vertical-align: top; }
  .checkout-payment-method .credit-card-types img {
    filter: grayscale(100%);
    transition: all 0.6s ease; }

.checkout-payment-method .ccard .fields > .year {
  padding-left: 5px; }

.checkout-payment-method .ccard .fields .select {
  padding-left: 5px;
  padding-right: 5px; }

.checkout-payment-method .ccard .month .select {
  width: 140px; }

.checkout-payment-method .ccard .year .select {
  width: 80px; }

.checkout-payment-method .ccard .number .input-text {
  width: 225px; }

.checkout-payment-method .ccard > .field.cvv > .control {
  padding-right: 20px;
  width: auto; }

.checkout-payment-method .ccard .cvv .input-text {
  width: 55px; }

.checkout-payment-method .ccard.fieldset > .field .fields.group.group-2 .field {
  width: auto !important; }

@media only screen and (max-width: 768px) {
  .checkout-payment-method .payment-option {
    margin: 0 -15px; }
    .checkout-payment-method .payment-option .payment-option-title {
      padding: 15px; }
    .checkout-payment-method .payment-option .payment-option-content {
      padding: 0 15px 20px; } }

@media only screen and (min-width: 768px) {
  .checkout-payment-method .payment-option-title {
    padding-left: 22px; }
  .checkout-payment-method .payment-option-content .payment-option-inner + .actions-toolbar {
    margin-left: 0; } }

.checkout-agreements-block {
  margin-bottom: 20px;
  padding-left: 8px; }
  .checkout-agreements-block .action-show {
    vertical-align: baseline; }
  .checkout-agreements-block .field.choice:before {
    width: unset; }
  .checkout-agreements-block .field.choice input {
    height: unset; }
    @media only screen and (max-width: 640px) {
      .checkout-agreements-block .field.choice input {
        float: left; } }
  @media only screen and (max-width: 640px) {
    .checkout-agreements-block .field.choice label {
      float: left;
      width: 90%; } }

.checkout-agreements-items {
  padding-bottom: 30px; }
  .checkout-agreements-items .checkout-agreements-item {
    margin-bottom: 20px; }
  .checkout-agreements-items .checkout-agreements-item-title {
    border-bottom: 0; }

.checkout-agreement-item-content {
  overflow: auto; }

body.account .page-main, body.customer-account-login .page-main, body.customer-account-create .page-main, body.customer-account-logoutsuccess .page-main, body.checkout-cart-index .page-main {
  padding: 0 15px; }

body .column:not(.sidebar-main) form.form-newsletter-manage .actions-toolbar {
  margin-left: 0; }

body .column:not(.sidebar-main) form.form-newsletter-manage .fieldset > .field.choice::before {
  display: none; }

.login-container .block-new-customer .actions-toolbar {
  margin-top: 25px; }

.login-container .block .block-title {
  font-size: 18px; }

.login-container .fieldset:after {
  border: none;
  margin: 10px 0 0;
  padding: inherit;
  content: attr(data-hasrequired);
  display: block;
  letter-spacing: normal;
  word-spacing: normal;
  color: #e02b27;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-size: 12px; }

.login-container .fieldset:after {
  margin-top: 35px; }

.block-addresses-list .items.addresses > .item {
  margin-bottom: 20px; }
  .block-addresses-list .items.addresses > .item:last-child {
    margin-bottom: 0; }

.form-address-edit .region select {
  display: none; }

.form-edit-account .fieldset.password {
  display: none; }

.box-billing-address .box-content,
.box-shipping-address .box-content,
.box-information .box-content,
.box-newsletter .box-content {
  line-height: 26px; }

.account .column.main h2 {
  margin-top: 0; }

.account .column.main .toolbar {
  text-align: center; }
  .account .column.main .toolbar .limiter-options {
    width: auto; }

.account .sidebar-additional {
  margin-top: 40px; }

.account .table-wrapper:last-child {
  margin-bottom: 0; }

.account .table-wrapper .action {
  margin-right: 15px; }
  .account .table-wrapper .action:last-child {
    margin-right: 0; }

.account-nav .content {
  background: #FBFBFB;
  padding: 15px 0; }

.account-nav .item {
  margin: 3px 0 0; }
  .account-nav .item:first-child {
    margin-top: 0; }
  .account-nav .item a,
  .account-nav .item > strong {
    color: false;
    border-left: 3px solid transparent;
    display: block;
    padding: 5px 18px 5px 15px; }
  .account-nav .item a {
    text-decoration: none;
    color: #014A5B; }
    .account-nav .item a:hover {
      background: #F4F4F4;
      color: #549788;
      font-weight: 400; }
  .account-nav .item.current a,
  .account-nav .item.current strong {
    border-color: #2FDF66;
    color: false;
    font-weight: 600; }
  .account-nav .item.current a {
    border-color: #2FDF66; }

.column.main .block:last-child {
  margin-bottom: 0; }

.block .title {
  margin-bottom: 10px; }
  .block .title strong {
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: 700;
    line-height: 1.1;
    font-size: 14px;
    margin-top: 7px;
    margin-bottom: 7px; }
    .column.main .block .title strong {
      font-size: 18px; }

.block p:last-child {
  margin: 0; }

.block .box-actions {
  margin-top: 5px; }

.field.password .control {
  display: flex;
  flex-direction: column; }
  .field.password .control .mage-error {
    order: 2; }
  .field.password .control .input-text {
    order: 0; }

.password-strength-meter {
  background-color: #f4f4f4;
  height: 32px;
  line-height: 32px;
  padding: 0 9px;
  position: relative;
  z-index: 1;
  font-size: 15px; }
  .password-strength-meter:before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1; }
  .password-strength-meter-0 .password-strength-meter:before {
    background-color: #f4f4f4;
    width: 100%; }
  .password-strength-meter-1 .password-strength-meter:before {
    background-color: #ffafae;
    width: 25%; }
  .password-strength-meter-2 .password-strength-meter:before {
    background-color: #ffd6b3;
    width: 50%; }
  .password-strength-meter-3 .password-strength-meter:before {
    background-color: #c5eeac;
    width: 75%; }
  .password-strength-meter-4 .password-strength-meter:before {
    background-color: #81b562;
    width: 100%; }

.control.captcha-image {
  margin-top: 10px; }
  .control.captcha-image .captcha-img {
    vertical-align: middle; }

.action.back {
  color: #014A5B; }
  .action.back:hover {
    text-decoration: none;
    color: #2FDF66; }

@media only screen and (max-width: 640px) {
  .account .column.main,
  .account .sidebar-additional {
    margin: 0; } }

@media only screen and (max-width: 768px) {
  .login-container .fieldset:after {
    text-align: center; }
  .account .page.messages {
    margin-bottom: 40px; }
  .control.captcha-image .captcha-img {
    margin-bottom: 10px;
    display: block; }
  .customer-account-index .page-title-wrapper {
    position: relative; } }

@media only screen and (min-width: 768px) {
  .login-container .block.login .actions-toolbar > .primary {
    margin-bottom: 0;
    margin-right: 30px; }
  .login-container .block.login .actions-toolbar > .secondary {
    float: left; }
  .login-container .fieldset > .field > .control {
    width: 74.2%; }
  .form-create-account .fieldset-fullname .fields .field {
    float: left;
    margin: 0 10px 10px 0; }
  .form-create-account .fieldset-fullname .field-name-prefix,
  .form-create-account .fieldset-fullname .field-name-suffix {
    width: 50px; }
  .form.password.reset,
  .form.send.confirmation,
  .form.password.forget,
  .form.create.account {
    min-width: 600px;
    width: 50%; }
  .account.page-layout-2columns-left .sidebar-main,
  .account.page-layout-2columns-left .sidebar-additional {
    width: 22.3%; }
  .account.page-layout-2columns-left .column.main {
    width: 77.7%; }
  .account.customer-address-form .field.taxvat .field.gw-euvat-field .label {
    box-sizing: border-box;
    float: left;
    padding: 6px 15px 0 0;
    text-align: left;
    width: 25.8%; }
  .account.customer-address-form .field.taxvat .field.gw-euvat-field .control {
    float: left;
    width: 74.2%; }
  .block-addresses-list .items.addresses > .item {
    margin-bottom: 20px; }
    .block-addresses-list .items.addresses > .item:nth-last-child(1), .block-addresses-list .items.addresses > .item:nth-last-child(2) {
      margin-bottom: 0; }
  .control.captcha-image .captcha-img {
    margin: 0 10px 10px 0; } }

.downloadable.samples {
  margin-bottom: 20px; }
  .downloadable.samples dd {
    margin: 0 0 10px; }

.table-downloadable-products .product-name {
  margin-right: 15px; }

.field.downloads .price-container {
  display: inline;
  white-space: nowrap; }
  .field.downloads .price-container:before {
    content: ' + ';
    display: inline; }

.field.downloads .price-excluding-tax {
  display: inline; }
  .field.downloads .price-excluding-tax:before {
    content: " (" attr(data-label) ": "; }
  .field.downloads .price-excluding-tax:after {
    content: ')'; }

.field.downloads .price-including-tax {
  font-size: 14px;
  display: inline;
  font-weight: 600; }

.field.downloads .sample.link {
  float: right; }

.page-product-downloadable .product-add-form {
  clear: both;
  margin-bottom: 25px; }

.page-product-downloadable .product-options-bottom .field.qty {
  display: none !important; }

@media only screen and (min-width: 768px) {
  .page-product-downloadable .product-options-wrapper {
    float: left;
    width: 55%; }
  .page-product-downloadable .product-options-bottom {
    float: right;
    width: 40%; }
    .page-product-downloadable .product-options-bottom .field.qty + .actions {
      padding-top: 0; } }

h4.choose a {
  color: #2FDF66; }

@media only screen and (min-width: 769px) {
  h4.choose {
    padding: 30px 30px 0;
    display: -webkit-box; } }

.delivery-available {
  display: block; }

.group-buttons {
  margin-top: 25px; }
  @media only screen and (min-width: 769px) {
    .group-buttons {
      padding-left: 30px;
      float: left;
      width: calc(100% - 400px); } }
  @media only screen and (max-width: 768px) {
    .group-buttons {
      text-align: center; } }
  .group-buttons .btn-group {
    font-weight: 700;
    background: #014A5B;
    color: #fff;
    cursor: pointer;
    vertical-align: initial;
    text-align: center;
    padding: 20px 0;
    text-decoration: none;
    margin-bottom: 15px; }
    @media only screen and (max-width: 768px) {
      .group-buttons .btn-group {
        display: block; } }
    @media only screen and (min-width: 769px) {
      .group-buttons .btn-group {
        display: inline-block;
        width: 200px;
        margin-right: 15px; }
        .group-buttons .btn-group:last-child {
          margin-right: 0; } }

div.tier-group:last-child {
  font-weight: bolder; }
  div.tier-group:last-child .tier-qty {
    background-color: #05596B; }

.input-text.input-aantal-meter {
  margin-left: 15px; }
  @media only screen and (max-width: 370px) {
    .input-text.input-aantal-meter {
      margin-left: 2px; } }

@media only screen and (max-width: 768px) {
  .page-title-wrapper.product {
    text-align: center;
    display: block;
    width: 100%; } }

@media only screen and (max-width: 768px) {
  .product-info-main .price-box {
    text-align: center; } }

.table.grouped > thead > tr > th,
.table.grouped > thead > tr > td,
.table.grouped > tbody > tr > th,
.table.grouped > tbody > tr > td,
.table.grouped > tfoot > tr > th,
.table.grouped > tfoot > tr > td {
  border-top: 1px solid #d1d1d1; }

.table.grouped > caption + thead > tr:first-child > th,
.table.grouped > caption + thead > tr:first-child > td,
.table.grouped > colgroup + thead > tr:first-child > th,
.table.grouped > colgroup + thead > tr:first-child > td,
.table.grouped > thead:first-child > tr:first-child > th,
.table.grouped > thead:first-child > tr:first-child > td {
  border-top: 0; }

.table.grouped > tbody + tbody {
  border-top: 1px solid #d1d1d1; }

.table.grouped > thead > tr > th {
  border-bottom: 0; }

.table.grouped .product-item-name {
  font-weight: 400; }

.table.grouped .price-box .price {
  font-size: 14px; }

.table.grouped .control .qty {
  margin-left: 49px;
  margin-top: 20px;
  background: #F8F8F8;
  border-left: solid #eee 1px;
  border-top: solid #eee 1px;
  height: 48px;
  padding: 10px;
  width: 87%; }

.table.grouped .stock.unavailable {
  font-weight: normal;
  margin: 0;
  text-transform: none;
  white-space: nowrap; }

.table.grouped .row-tier-price td {
  border-top: 0; }

.table.grouped .row-tier-price .prices-tier {
  margin: 0 -10px 10px; }

.block.widget.block-products-list.grid {
  margin-bottom: 0 !important; }
  .block.widget.block-products-list.grid .price-label {
    display: block; }
  .block.widget.block-products-list.grid .price-container {
    display: block;
    font-weight: 700;
    font-size: 24px;
    color: #6d6d6d; }

.zigzag-container2 {
  display: inline;
  margin-left: 20px;
  margin-right: 20px; }
  .zigzag-container2 .input-checkbox.input-zigzag {
    margin-right: -22px !important;
    font-size: 10px !important; }
  .zigzag-container2 span.tip {
    font-size: 15px; }
  @media only screen and (max-width: 768px) {
    .zigzag-container2 {
      display: block;
      margin-bottom: 7px; }
      .zigzag-container2 .input-checkbox.input-zigzag {
        margin-right: -10px !important; } }

.table-wrapper.grouped .delivery-icon {
  float: right;
  position: relative;
  right: unset;
  top: unset; }
  @media only screen and (max-width: 768px) {
    .table-wrapper.grouped .delivery-icon {
      float: left;
      width: auto; } }

@media only screen and (min-width: 769px) {
  .table-wrapper.grouped .directly-available,
  .table-wrapper.grouped .stock.unavailable {
    float: right;
    padding: 20px 10px 0 10px;
    font-weight: bold;
    text-align: right; } }

@media only screen and (max-width: 768px) {
  .table-wrapper.grouped .directly-available,
  .table-wrapper.grouped .stock.unavailable {
    clear: both;
    font-weight: bold;
    float: left;
    padding: 20px 0 10px 0; } }

.table-wrapper.simple {
  margin-top: 30px;
  /*.grouped .item */ }
  @media only screen and (max-width: 768px) {
    .table-wrapper.simple {
      text-align: center; } }
  .table-wrapper.simple .input-checkbox.input-zigzag {
    position: absolute;
    margin-top: 13px;
    display: grid; }
  .table-wrapper.simple .zigzag-container {
    display: inline; }
  .table-wrapper.simple .zigzag {
    display: block;
    margin-left: 68px;
    margin-top: 7px; }
  .table-wrapper.simple .actionbar {
    margin-left: 0;
    position: relative;
    margin-top: 23px; }
    @media only screen and (max-width: 768px) {
      .table-wrapper.simple .actionbar {
        text-align: center; } }
  .table-wrapper.simple .control.qty {
    width: auto; }
  .table-wrapper.simple .actionbar .control.qty .total {
    font-weight: 700;
    background: none;
    border: none;
    font-size: 26px;
    display: -webkit-inline-box; }
  .table-wrapper.simple .actionbar .control.qty .btn-cart,
  .table-wrapper.simple .actionbar .control.qty .btn-quote {
    font-weight: 700;
    background: #EB8500;
    color: #fff;
    cursor: pointer;
    height: 66px;
    width: 190px;
    vertical-align: initial;
    line-height: 68px;
    text-align: center;
    float: unset;
    width: auto;
    display: block;
    margin-top: 5px; }
  .table-wrapper.simple .actionbar .control.qty input {
    border: 1px solid #eee;
    width: 62px;
    background: #fff;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    color: #95989A;
    font-size: 20px;
    font-weight: 100;
    margin-bottom: 15px; }
  .table-wrapper.simple .delivery-icon {
    margin-left: 0;
    top: 0;
    position: relative;
    margin-top: 15px;
    width: 300px !important; }
  .table-wrapper.simple .tier-price {
    font-size: 0.5rem;
    border: 1px solid #eee; }
  .table-wrapper.simple .icon-icon-Bezorgen3::before {
    font-family: "icomoon";
    font-size: 30px !important;
    display: inline-block;
    color: #014A5B;
    line-height: 56px;
    text-align: center;
    cursor: pointer;
    content: "\e94e";
    float: left;
    padding-right: 5px; }
  .table-wrapper.simple .table-wrapper.simple .delivery-icon {
    margin-left: 0;
    position: relative;
    margin-top: -10px; }
  .table-wrapper.simple .delivery-icon {
    width: auto !important;
    margin-left: 0;
    display: inline-block;
    line-height: 56px;
    position: relative; }
    .table-wrapper.simple .delivery-icon span {
      font-size: 13px;
      font-weight: 100;
      width: auto;
      display: block;
      line-height: 67px;
      margin-right: 20px; }
      @media only screen and (max-width: 768px) {
        .table-wrapper.simple .delivery-icon span {
          text-align: center; } }
  .table-wrapper.simple input[type="number"] {
    background: #fff;
    background-clip: border-box;
    background-clip: padding-box;
    border: 1px solid #c2c2c2;
    border-radius: 1px;
    color: inherit;
    font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-style: inherit;
    font-weight: inherit;
    height: 32px;
    line-height: 1.42857;
    margin: 0;
    padding: 0 9px;
    vertical-align: baseline;
    width: 280px;
    margin-left: 0; }
  .table-wrapper.simple .actionbar .control.qty input {
    border: 1px solid #eee;
    width: 62px;
    background: #fff;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    color: #95989A;
    font-size: 20px;
    font-weight: 100;
    margin-bottom: 15px;
    margin-left: 0; }

/*CSS grouped products*/
.grouped .item {
  border: 1px solid #eee;
  padding-left: 1rem;
  margin-bottom: 30px;
  overflow: hidden;
  width: 100%; }

.grouped .item .actionbar {
  color: #005464; }

.grouped .item .actionbar .control.qty,
.grouped .item .actionbar .control.qty,
.grouped .item .actionbar .control.qty .total,
.grouped .item .actionbar .tier-prices {
  display: inline-block;
  margin-top: 11px;
  border-top: 1px solid #EEE;
  border-left: 1px solid #EEE;
  background-color: #F8F8F8;
  border-bottom: 1px solid #f8f8f8; }

.actionbar {
  margin-left: 235px;
  margin-top: 23px; }

.levering {
  font-weight: 300;
  font-size: 23px;
  margin-top: 50px;
  margin-bottom: 25px; }

.actionbar .control.qty .total {
  font-weight: 600;
  background: #f8f8f8;
  border: none;
  font-size: 20px;
  border: 0px !important; }

.grouped .item .actionbar .control.qty .tip {
  display: inline-flex;
  margin-top: 23px;
  margin-left: -6px; }

.actionbar .control.qty .btn-cart,
.actionbar .control.qty .btn-quote {
  font-weight: 700;
  background: #EB8500;
  color: #fff;
  cursor: pointer;
  width: 190px;
  vertical-align: initial;
  line-height: 72px;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  height: 100%; }

.btn-shipping-style {
  line-height: 85px !important;
  height: 84px;
  padding-top: 2px; }

.actionbar .control.qty input {
  border: 1px solid #eee;
  width: 60px;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  color: #95989A;
  font-size: 20px;
  font-weight: 100;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-right: 5px; }
  @media only screen and (max-width: 1060px) {
    .actionbar .control.qty input {
      width: 40px; } }

.tier-group {
  display: inline-block;
  text-align: center;
  width: 90px;
  /*20-2*/
  float: left;
  padding-right: 5px; }

.tier-price,
.tier-qty {
  padding: 0.5rem;
  margin-top: 0; }

.tier-qty {
  background-color: #00404D;
  color: #fff;
  font-weight: 700;
  font-size: 0.9rem; }

.tier-price {
  font-size: 0.5rem;
  color: #404243;
  border: 1px solid #eee; }

.delivery-icon {
  width: 190px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 80px; }
  @media only screen and (max-width: 920px) {
    .delivery-icon {
      text-align: left;
      position: relative;
      right: 0;
      top: 0;
      margin-top: 5px;
      width: 100%; } }
  .delivery-icon span {
    font-size: 13px;
    font-weight: 100;
    width: auto;
    display: grid;
    line-height: 30px; }

.input-checkbox.input-zigzag {
  margin-right: -28px; }

.grouped-item-container {
  border: 1px solid #eee;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative; }
  .grouped-item-container .grouped-container-left {
    float: left;
    width: 250px;
    padding: 20px;
    box-sizing: border-box; }
    .grouped-item-container .grouped-container-left .image-staffel {
      max-height: 200px;
      position: relative;
      display: block;
      left: 50%;
      transform: translateX(-50%);
      width: auto; }
      @media only screen and (min-width: 768px) {
        .grouped-item-container .grouped-container-left .image-staffel {
          min-height: 200px; } }
    @media only screen and (max-width: 960px) {
      .grouped-item-container .grouped-container-left {
        padding: 20px 0 0;
        width: 200px; } }
    @media only screen and (max-width: 620px) {
      .grouped-item-container .grouped-container-left {
        padding: 10px 0 0 0;
        width: 200px; } }
    @media only screen and (max-width: 540px) {
      .grouped-item-container .grouped-container-left {
        width: 150px; } }
    @media only screen and (max-width: 440px) {
      .grouped-item-container .grouped-container-left {
        width: 100px; } }
    @media only screen and (max-width: 360px) {
      .grouped-item-container .grouped-container-left {
        margin: 0 auto;
        float: unset; } }
  .grouped-item-container .grouped-container-right {
    position: relative;
    float: left;
    width: calc(100% - 250px);
    box-sizing: border-box;
    padding: 20px 0 0;
    right: 0;
    top: 0;
    bottom: 0; }
    @media only screen and (max-width: 1010px) {
      .grouped-item-container .grouped-container-right .product-item-name h3 {
        line-height: 20px; } }
    .grouped-item-container .grouped-container-right .staffels-row {
      float: left; }
      @media only screen and (min-width: 769px) {
        .grouped-item-container .grouped-container-right .staffels-row {
          width: 350px;
          min-height: 100px; }
          .grouped-item-container .grouped-container-right .staffels-row .product-item-name {
            min-height: 60px;
            height: auto; } }
    .grouped-item-container .grouped-container-right .tier-prices {
      margin-top: 30px;
      margin-right: 5px;
      width: 360px;
      float: right; }
      @media only screen and (max-width: 1064px) {
        .grouped-item-container .grouped-container-right .tier-prices {
          width: 100%; } }
      @media only screen and (max-width: 920px) {
        .grouped-item-container .grouped-container-right .tier-prices {
          /*20-2*/
          display: block;
          justify-content: space-between; } }
      @media only screen and (max-width: 620px) {
        .grouped-item-container .grouped-container-right .tier-prices {
          margin-bottom: 10px; } }
    .grouped-item-container .grouped-container-right .staffels-row strong {
      display: block; }
    @media only screen and (max-width: 960px) {
      .grouped-item-container .grouped-container-right {
        width: calc(100% - 200px);
        padding-left: 10px; } }
    @media only screen and (max-width: 640px) {
      .grouped-item-container .grouped-container-right .product-item-name {
        height: auto; } }
    @media only screen and (max-width: 540px) {
      .grouped-item-container .grouped-container-right {
        width: calc(100% - 150px);
        padding-top: 5px; } }
    @media only screen and (max-width: 440px) {
      .grouped-item-container .grouped-container-right {
        width: calc(100% - 100px); } }
    @media only screen and (max-width: 360px) {
      .grouped-item-container .grouped-container-right {
        width: 100%; } }
  @media only screen and (max-width: 768px) {
    .grouped-item-container .tier-prices {
      display: block;
      width: 100%;
      overflow: hidden;
      transform: unset;
      left: unset; }
      .grouped-item-container .tier-prices .tier-group {
        width: 25%;
        padding-left: 5px;
        padding-right: 5px;
        margin: 20px 0; } }
  .grouped-item-container .actionbar {
    margin-left: 0;
    border: 1px solid #eee;
    border-width: 1px 0 0 1px;
    background: #f8f8f8;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    float: right;
    margin-top: 0; }
    @media only screen and (max-width: 1010px) {
      .grouped-item-container .actionbar {
        text-align: center; } }
    .grouped-item-container .actionbar.zigzag .action-bar-formula {
      height: 62px;
      line-height: 62px; }
      @media only screen and (max-width: 768px) {
        .grouped-item-container .actionbar.zigzag .action-bar-formula {
          height: 30px;
          line-height: 30px;
          padding-top: 10px; } }
      .grouped-item-container .actionbar.zigzag .action-bar-formula .tip {
        line-height: normal; }
      @media only screen and (max-width: 1300px) {
        .grouped-item-container .actionbar.zigzag .action-bar-formula {
          height: auto; } }
      @media only screen and (max-width: 1150px) {
        .grouped-item-container .actionbar.zigzag .action-bar-formula .input {
          display: block !important; } }
    .grouped-item-container .actionbar .action-bar-formula .input {
      display: inline-block; }
      @media only screen and (min-width: 1300px) {
        .grouped-item-container .actionbar .action-bar-formula .input {
          font-size: 20px; } }
    .grouped-item-container .actionbar .action-bar-formula .tip:hover:after {
      left: -100px; }
    @media only screen and (max-width: 1300px) {
      .grouped-item-container .actionbar .control.qty {
        font-size: 13px;
        line-height: 1; }
        .grouped-item-container .actionbar .control.qty input {
          font-size: 13px;
          font-weight: 600; }
        .grouped-item-container .actionbar .control.qty .total {
          font-weight: 600;
          font-size: 20px;
          margin-left: 5px; } }
    @media only screen and (min-width: 1011px) {
      .grouped-item-container .actionbar .control.qty .action-label {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 225px; } }
    @media only screen and (min-width: 1011px) {
      .grouped-item-container .actionbar .control.qty .btn-cart,
      .grouped-item-container .actionbar .control.qty .btn-quote {
        float: none;
        position: absolute;
        right: 0;
        top: 0; } }
    @media only screen and (max-width: 1011px) {
      .grouped-item-container .actionbar .control.qty .btn-cart,
      .grouped-item-container .actionbar .control.qty .btn-quote {
        margin: 0 auto; } }
    @media only screen and (max-width: 777px) {
      .grouped-item-container .actionbar .control.qty .btn-cart,
      .grouped-item-container .actionbar .control.qty .btn-quote {
        position: relative;
        width: 100%;
        box-sizing: border-box; } }
    .grouped-item-container .actionbar .control.qty .total {
      display: inline-block; }
      @media only screen and (max-width: 420px) {
        .grouped-item-container .actionbar .control.qty .total {
          display: block;
          margin-bottom: 10px; } }
      .grouped-item-container .actionbar .control.qty .total .old {
        text-decoration: line-through;
        margin-right: 5px; }
    .grouped-item-container .actionbar .control.qty .price-on-request {
      display: inline-block;
      font-weight: 600; }
      @media only screen and (max-width: 420px) {
        .grouped-item-container .actionbar .control.qty .price-on-request {
          display: block;
          margin-bottom: 10px; } }
      @media only screen and (max-width: 1300px) {
        .grouped-item-container .actionbar .control.qty .price-on-request {
          font-size: 20px; } }
    .grouped-item-container .actionbar .control.qty .action.primary.toquote {
      background: #EB8500;
      font-size: 16px;
      line-height: 70px;
      padding: 0 40px;
      text-transform: uppercase; }
    @media only screen and (max-width: 777px) {
      .grouped-item-container .actionbar .control.qty .action.primary.toquote {
        position: relative;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 0 !important;
        font-weight: 700;
        font-size: 13px; } }
    .grouped-item-container .actionbar.zigzag .control.qty input {
      margin-top: 2px;
      margin-bottom: 2px; }
    .grouped-item-container .actionbar.zigzag .control.qty .zigzag-container2 input {
      width: 40px; }
    .grouped-item-container .actionbar.zigzag .zigzag-container {
      padding-left: 15px;
      padding-top: 1px; }
      .grouped-item-container .actionbar.zigzag .zigzag-container input {
        width: auto; }
    @media only screen and (max-width: 620px) {
      .grouped-item-container .actionbar {
        width: 100%;
        text-align: center;
        left: 0;
        margin-top: 0; } }

.erklaren {
  font-size: 13px;
  font-style: italic;
  margin-bottom: 20px; }

.erklaren-grouped {
  font-size: 11px;
  font-style: italic;
  width: 70%; }

.table-wrapper.simple .simple-product-zigzag {
  display: block;
  width: 200px;
  height: 55px; }
  @media only screen and (max-width: 768px) {
    .table-wrapper.simple .simple-product-zigzag {
      position: relative;
      left: 50%;
      transform: translateX(-50%); } }
  .table-wrapper.simple .simple-product-zigzag input.input-checkbox.input-zigzag {
    float: left;
    position: relative;
    display: block;
    width: auto;
    margin: 5px 10px 0 0; }
  .table-wrapper.simple .simple-product-zigzag .tooltip {
    float: left; }

/*20-2*/
.product-info-main .product.attribute.overview {
  margin: 20px 0;
  display: inline-block; }

.product-info-main .product-info-price .price-box .price-container .price {
  display: none; }

.block.filter {
  margin-bottom: 40px; }
  .block.filter .filter-title {
    margin-bottom: 20px; }
    .block.filter .filter-title strong {
      font-size: 18px; }

.block-subtitle {
  display: inline-block;
  margin-bottom: 10px; }

.filter-current .item {
  margin-bottom: 10px; }

.filter-current .filter-label {
  display: block;
  font-weight: 700; }
  .filter-current .filter-label:after {
    content: ':'; }

.filter-current + .filter-actions {
  margin-bottom: 30px; }

.filter-options {
  margin: 0; }
  .filter-options .filter-options-title {
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: 700;
    line-height: 1.1;
    font-size: 14px;
    margin-top: 7px;
    margin-bottom: 7px;
    margin: 0 0 10px;
    word-break: break-all; }
  .filter-options .filter-options-content {
    margin: 0 0 25px; }
    .filter-options .filter-options-content .item {
      margin-bottom: 3px; }
  .filter-options .count {
    color: #7d7d7d; }
    .filter-options .count:before {
      content: '('; }
    .filter-options .count:after {
      content: ')'; }

.filtered .items {
  margin: 15px 0; }

.filtered .item {
  position: relative;
  padding-left: 22px;
  margin-bottom: 6px; }
  .filtered .item .label {
    font-weight: 700; }
  .filtered .item .action.remove {
    position: absolute;
    left: -6px;
    top: 0; }

.filtered + .actions {
  margin-bottom: 35px; }

@media only screen and (max-width: 768px) {
  .page-with-filter .columns .sidebar-main {
    order: 0; } }

.block.filter .filter-content .filter-current .action.remove {
  color: red;
  display: inline-block;
  text-decoration: none;
  vertical-align: baseline; }
  .block.filter .filter-content .filter-current .action.remove:hover {
    font-weight: 600; }

.block.filter .filter-content .filter-options .filter-options-item .items .item input[type="checkbox"] {
  display: inline-block; }

@media only screen and (max-width: 1040px) {
  .page-layout-2columns-left:not(.account) .columns .sidebar.sidebar-main {
    width: 100%; }
  .block.filter .filter-title {
    display: inline-block;
    text-decoration: none;
    border: 1px solid #000;
    padding: 5px 10px; }
    .block.filter .filter-title:after {
      -webkit-font-smoothing: antialiased;
      font-size: 28px;
      line-height: inherit;
      color: #000;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: middle; }
    .block.filter .filter-title:hover:after {
      color: #333; }
    .block.filter .filter-title:active:after {
      color: inherit; }
    .block.filter .filter-title:after {
      vertical-align: bottom;
      line-height: 1; }
    .block.filter .filter-title [data-role="title"] {
      display: inline-block; }
  .block.filter .filter-options {
    display: none; }
  .block.filter .backdrop {
    display: none; }
  body.filter-active .columns {
    z-index: 999; }
  body.filter-active #trustbadge-container-98e3dadd90eb493088abdc5597a70810 {
    z-index: 0 !important; }
  body .columns .column.main .category-images {
    margin-top: 0; }
  body .columns .column.main .products.wrapper {
    margin-top: 0; }
  body .columns .sidebar.sidebar-main {
    margin-top: 20px !important;
    margin-bottom: -23px; }
  .filter {
    margin-bottom: 0 !important; }
    .filter .block-title.filter-title {
      margin-bottom: 0; }
    .filter .block-content.filter-content .block-subtitle {
      margin-top: 10px; }
    .filter.active {
      position: relative;
      visibility: visible;
      padding-bottom: 82px;
      z-index: 99999; }
      .filter.active .filter-options-item:last-child {
        margin-bottom: 25px; }
      .filter.active .filter-title {
        border: none;
        height: 100vh;
        width: 25%;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 99992;
        display: inline-block;
        text-decoration: none; }
        .filter.active .filter-title strong {
          box-shadow: none;
          background: none;
          border: 0;
          color: transparent;
          left: auto;
          right: 3px;
          top: 10px;
          display: inline-block;
          text-decoration: none; }
        .filter.active .filter-title:after {
          -webkit-font-smoothing: antialiased;
          font-size: 28px;
          line-height: inherit;
          color: #000;
          content: "";
          font-family: "icons-blank-theme";
          margin: 0;
          display: inline-block;
          font-weight: normal;
          overflow: hidden;
          speak: none;
          text-align: center;
          vertical-align: middle; }
        .filter.active .filter-title:hover:after {
          color: #333; }
        .filter.active .filter-title:active:after {
          color: inherit; }
        .filter.active .filter-title:after {
          position: absolute;
          right: 85%;
          top: 5px; }
      .filter.active .block-content.filter-content {
        position: fixed;
        left: 0;
        top: 0;
        width: 80%;
        height: 100%;
        min-height: 100vh;
        overflow: scroll;
        background-color: white;
        z-index: 9991; }
        .filter.active .block-content.filter-content .filter-current, .filter.active .block-content.filter-content .filter-actions {
          display: none; }
        .filter.active .block-content.filter-content .filter-subtitle {
          border-bottom: 1px solid #ccc;
          background: white;
          display: block;
          width: 80%;
          height: 50px;
          position: fixed;
          padding-left: 15px;
          margin-top: 0;
          z-index: 99998; }
          .filter.active .block-content.filter-content .filter-subtitle .subtitle {
            display: block;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            left: 0; }
        .filter.active .block-content.filter-content .filter-options {
          width: 100%;
          background: #fff;
          display: block;
          padding: 15px;
          position: relative;
          z-index: 99997; }
          .filter.active .block-content.filter-content .filter-options:nth-child(2) {
            margin-top: 50px; }
          .filter.active .block-content.filter-content .filter-options .filter-options-item {
            border-bottom: 1px solid #d1d1d1;
            margin: 0 0 25px; }
      .filter.active .backdrop {
        display: block;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: black;
        opacity: 0.4;
        z-index: 9990; } }

.map-popup-wrapper.popup .action.close {
  top: 10px; }

.map-popup {
  background: #fff;
  border: 1px solid #aeaeae;
  padding: 22px;
  width: 100%;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s linear;
  bottom: false;
  left: 0;
  margin: 10px inherit inherit false;
  position: absolute;
  right: false;
  top: 0;
  z-index: 1001;
  display: none;
  opacity: 0;
  box-sizing: border-box; }
  .map-popup .popup-header {
    margin: 0 0 25px;
    padding-right: 22px; }
    .map-popup .popup-header .title {
      color: inherit;
      font-family: inherit;
      font-style: inherit;
      font-weight: 300;
      line-height: 1.1;
      font-size: 18px;
      margin-top: 7px;
      margin-bottom: 7px; }
  .map-popup .popup-content {
    margin: 10px 0 0; }
  .map-popup .popup-footer {
    margin: inherit; }
  .map-popup .popup-actions .action.close {
    position: absolute;
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    bottom: inherit;
    left: inherit;
    right: 10px;
    top: 10px; }
    .map-popup .popup-actions .action.close > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .map-popup .popup-actions .action.close:before {
      -webkit-font-smoothing: antialiased;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: inherit;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: top; }
    .map-popup .popup-actions .action.close:hover:before {
      color: inherit; }
    .map-popup .popup-actions .action.close:active:before {
      color: inherit; }
    .map-popup .popup-actions .action.close:focus, .map-popup .popup-actions .action.close:active {
      background: none;
      border: none; }
    .map-popup .popup-actions .action.close:hover {
      background: none;
      border: none; }
    .map-popup .popup-actions .action.close.disabled, .map-popup .popup-actions .action.close[disabled],
    fieldset[disabled] .map-popup .popup-actions .action.close {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
  .map-popup.active {
    opacity: 1; }
  .map-popup .map-info-price {
    margin-bottom: 15px; }
    .map-popup .map-info-price .price-box {
      margin: 0 0 20px; }
      .map-popup .map-info-price .price-box .label:after {
        content: ': '; }

.map-old-price:not(:last-child),
.product-info-price .map-show-info:not(:last-child) {
  margin-right: 10px; }

.map-old-price {
  text-decoration: none; }
  .map-old-price .price-wrapper {
    text-decoration: line-through; }

.map-form-addtocart {
  display: inline-block;
  text-align: center; }
  .map-form-addtocart img {
    display: block;
    margin: 0 auto; }

@media only screen and (max-width: 768px) {
  .map-popup {
    max-width: 100%; }
  .map-old-price,
  .map-show-info {
    display: inline-block; }
    .map-old-price:not(:last-child),
    .map-show-info:not(:last-child) {
      margin-bottom: 10px; } }

.wishlist.split.button {
  display: inline-block;
  position: relative;
  vertical-align: middle; }
  .wishlist.split.button:before, .wishlist.split.button:after {
    content: '';
    display: table; }
  .wishlist.split.button:after {
    clear: both; }
  .wishlist.split.button .action.split {
    float: left;
    margin: 0; }
  .wishlist.split.button .action.toggle {
    float: right;
    margin: 0; }
  .wishlist.split.button button.action.split, .wishlist.split.button .cart.table-wrapper .actions-toolbar > .action.split, .cart.table-wrapper .wishlist.split.button .actions-toolbar > .action.split {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .wishlist.split.button button + .action.toggle, .wishlist.split.button .cart.table-wrapper .actions-toolbar > .action + .action.toggle, .cart.table-wrapper .wishlist.split.button .actions-toolbar > .action + .action.toggle {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0; }
  .wishlist.split.button .action.split {
    padding: inherit; }
  .wishlist.split.button .action.toggle {
    padding: false;
    display: inline-block;
    text-decoration: none; }
    .wishlist.split.button .action.toggle > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .wishlist.split.button .action.toggle:after {
      -webkit-font-smoothing: antialiased;
      font-size: 22px;
      line-height: 22px;
      color: #20272B;
      content: "";
      font-family: "icons-blank-theme";
      margin: inherit;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: top; }
    .wishlist.split.button .action.toggle:hover:after {
      color: #006bb4; }
    .wishlist.split.button .action.toggle:active:after {
      color: inherit; }
    .wishlist.split.button .action.toggle.active {
      display: inline-block;
      text-decoration: none; }
      .wishlist.split.button .action.toggle.active > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .wishlist.split.button .action.toggle.active:after {
        -webkit-font-smoothing: antialiased;
        font-size: 22px;
        line-height: 22px;
        color: #20272B;
        content: "";
        font-family: "icons-blank-theme";
        margin: inherit;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
        vertical-align: top; }
      .wishlist.split.button .action.toggle.active:hover:after {
        color: #006bb4; }
      .wishlist.split.button .action.toggle.active:active:after {
        color: inherit; }
  .wishlist.split.button .items {
    background: #fff;
    border: 1px solid #bbb;
    z-index: 100;
    margin: 0;
    padding: 0;
    list-style: none none;
    box-sizing: border-box;
    position: absolute;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    height: inherit;
    margin-top: 4px;
    min-width: 200px;
    width: inherit;
    display: none; }
    .wishlist.split.button .items li {
      margin: 0;
      padding: 5px 5px 5px 23px; }
      .wishlist.split.button .items li:hover {
        background: #e8e8e8;
        cursor: pointer; }
    .wishlist.split.button .items:before, .wishlist.split.button .items:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .wishlist.split.button .items:before {
      border: 6px solid;
      border-color: transparent transparent #fff transparent;
      z-index: 99; }
    .wishlist.split.button .items:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .wishlist.split.button .items:before {
      left: 10px;
      top: -12px; }
    .wishlist.split.button .items:after {
      left: 9px;
      top: -14px; }
  .wishlist.split.button.active {
    overflow: visible; }
    .wishlist.split.button.active .items {
      display: block; }
  .wishlist.split.button .items {
    text-align: left; }
    .wishlist.split.button .items .item:last-child:hover {
      background: #e8e8e8; }
  .table-comparison .wishlist.split.button > .action.split:before,
  .product-items .wishlist.split.button > .action.split:before {
    content: ""; }
  .table-comparison .wishlist.split.button > .action:active, .table-comparison .wishlist.split.button > .action:focus, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:focus,
  .product-items .wishlist.split.button > .action:hover {
    color: #006bb4; }
  .table-comparison .wishlist.split.button > .action.toggle:before,
  .product-items .wishlist.split.button > .action.toggle:before {
    border-left: 1px solid #617783;
    content: '';
    float: left;
    height: 15px;
    margin-top: 3px; }
  .product-info-main .wishlist.split.button .action.split, .product-info-main .wishlist.split.button .action.toggle,
  .block-bundle-summary .wishlist.split.button .action.split,
  .block-bundle-summary .wishlist.split.button .action.toggle,
  .product-add-form .wishlist.split.button .action.split,
  .product-add-form .wishlist.split.button .action.toggle {
    line-height: 12px;
    padding: 5px 8px;
    font-size: 11px; }
  .product-info-main .wishlist.split.button .action.toggle,
  .block-bundle-summary .wishlist.split.button .action.toggle,
  .product-add-form .wishlist.split.button .action.toggle {
    padding: 0; }
  .cart.table-wrapper .wishlist.split.button {
    margin-bottom: 10px; }
    .cart.table-wrapper .wishlist.split.button > .action.toggle {
      padding: 4px 5px; }

.wishlist.window.popup {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #aeaeae;
  padding: 22px;
  width: 100%;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s linear;
  bottom: 0;
  left: 0;
  margin: inherit inherit inherit inherit;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1001;
  display: none;
  opacity: 0; }
  .wishlist.window.popup .popup-header {
    margin: 0 0 25px;
    padding-right: 22px; }
    .wishlist.window.popup .popup-header .title {
      color: inherit;
      font-family: inherit;
      font-style: inherit;
      font-weight: 300;
      line-height: 1.1;
      font-size: 18px;
      margin-top: 7px;
      margin-bottom: 7px; }
  .wishlist.window.popup .popup-content {
    margin: inherit; }
  .wishlist.window.popup .popup-footer {
    margin: inherit; }
  .wishlist.window.popup .popup-actions .action.close {
    position: absolute;
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    bottom: inherit;
    left: inherit;
    right: 10px;
    top: 10px; }
    .wishlist.window.popup .popup-actions .action.close > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .wishlist.window.popup .popup-actions .action.close:before {
      -webkit-font-smoothing: antialiased;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: inherit;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: top; }
    .wishlist.window.popup .popup-actions .action.close:hover:before {
      color: inherit; }
    .wishlist.window.popup .popup-actions .action.close:active:before {
      color: inherit; }
    .wishlist.window.popup .popup-actions .action.close:focus, .wishlist.window.popup .popup-actions .action.close:active {
      background: none;
      border: none; }
    .wishlist.window.popup .popup-actions .action.close:hover {
      background: none;
      border: none; }
    .wishlist.window.popup .popup-actions .action.close.disabled, .wishlist.window.popup .popup-actions .action.close[disabled],
    fieldset[disabled] .wishlist.window.popup .popup-actions .action.close {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
  .wishlist.window.popup.active {
    opacity: 1; }
  .wishlist.window.popup.active {
    display: block; }

.wishlist.overlay {
  transition: opacity 0.15s linear;
  background: #000;
  z-index: 1000;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0; }
  .wishlist.overlay.active {
    opacity: 0.5; }
  .wishlist.overlay.active {
    display: block; }

.block-wishlist-management {
  position: relative; }
  .block-wishlist-management .wishlist-select .wishlist-name-label {
    font-weight: 600; }
  .block-wishlist-management .wishlist-title {
    margin-bottom: 10px; }
    .block-wishlist-management .wishlist-title strong {
      font-weight: 300;
      margin-right: 10px; }
  .block-wishlist-management .wishlist-notice {
    color: #7d7d7d;
    display: inline-block;
    margin-right: 20px; }
  .block-wishlist-management .wishlist-toolbar-select, .block-wishlist-management .wishlist-toolbar-actions {
    display: inline-block; }
  .block-wishlist-management .wishlist-toolbar-select {
    margin-right: 20px; }
  .block-wishlist-management .wishlist-dropdown.move {
    margin-right: 20px; }

.block-wishlist-search-form .form-wishlist-search {
  margin-bottom: 60px;
  max-width: 500px; }

.block-wishlist-search-results .toolbar-wishlist-results {
  text-align: center; }

.block-wishlist-info-items .block-title {
  margin-bottom: 20px;
  font-size: 22px; }
  .block-wishlist-info-items .block-title > strong {
    font-weight: 300; }

.block-wishlist-info-items .product-item {
  width: 100%; }

.block-wishlist-info-items .product-item-photo {
  margin-left: 20px; }

.block-wishlist-info-items .product-item-info {
  width: auto; }
  .block-wishlist-info-items .product-item-info:hover .split {
    visibility: visible; }

.block-wishlist-info-items .price-wrapper {
  display: block;
  margin: 0 0 10px; }

.block-wishlist-info-items .split {
  clear: both;
  padding: 10px 0; }

.block-wishlist-info-items .action.towishlist {
  padding: 10px 0; }

.block-wishlist-info-items .input-text.qty {
  margin-right: 10px; }

@media only screen and (max-width: 768px) {
  .block-wishlist-management {
    clear: both;
    margin-top: -21px; }
    .block-wishlist-management .wishlist-select {
      margin: 0 -15px 20px; }
      .block-wishlist-management .wishlist-select-items {
        border-bottom: 1px solid #d1d1d1;
        background: #f5f5f5;
        display: none;
        padding: 15px 0; }
        .block-wishlist-management .wishlist-select-items.active {
          display: block; }
        .block-wishlist-management .wishlist-select-items .item {
          margin: 3px 0 0; }
          .block-wishlist-management .wishlist-select-items .item:first-child {
            margin-top: 0; }
          .block-wishlist-management .wishlist-select-items .item.current {
            display: none; }
          .block-wishlist-management .wishlist-select-items .item a {
            display: block;
            padding: 5px 18px;
            text-decoration: none; }
            .block-wishlist-management .wishlist-select-items .item a:hover {
              background: #e8e8e8; }
    .block-wishlist-management .wishlist-title strong {
      font-size: 26px; }
    .block-wishlist-management .wishlist-info {
      margin-bottom: 15px; }
    .block-wishlist-management .wishlist-toolbar-select, .block-wishlist-management .wishlist-toolbar-actions {
      margin-bottom: 15px; }
  .products-grid.wishlist .product-item-checkbox {
    left: 20px;
    position: absolute;
    top: 20px; }
  .page-multiple-wishlist .products-grid.wishlist .product-item-photo {
    margin-left: 25px; }
  .page-multiple-wishlist .products-grid.wishlist .product-item-name, .page-multiple-wishlist .products-grid.wishlist .product-item-description, .page-multiple-wishlist
  .products-grid.wishlist .product-item .price-box, .page-multiple-wishlist .products-grid.wishlist .product-item-tooltip {
    margin-left: 115px; }
  .products-grid.wishlist .wishlist-dropdown {
    display: none; } }

@media only screen and (min-width: 768px) {
  .wishlist.window.popup {
    bottom: auto;
    top: 20%;
    left: 50%;
    right: auto;
    width: 380px;
    transform: translateX(212px); }
    .wishlist.window.popup .field {
      margin: 0; }
      .wishlist.window.popup .field:not(.choice) > .label {
        box-sizing: content-box;
        float: none;
        width: auto;
        text-align: left;
        padding: 0; }
      .wishlist.window.popup .field:not(.choice) > .control {
        float: none;
        width: auto; }
      .wishlist.window.popup .field > .label {
        margin: 0 0 5px;
        padding: inherit;
        text-align: inherit;
        display: inline-block; }
      .wishlist.window.popup .field.choice:before, .wishlist.window.popup .field.no-label:before {
        display: none; }
  .block-wishlist-management {
    margin-bottom: 20px; }
    .block-wishlist-management .wishlist-select {
      border-bottom: 1px solid #e8e8e8;
      display: table;
      margin-bottom: 15px;
      width: 100%; }
      .block-wishlist-management .wishlist-select .wishlist-name {
        display: table-cell;
        padding: 5px 10px 10px;
        vertical-align: top;
        white-space: nowrap;
        width: 5%; }
      .block-wishlist-management .wishlist-select-items {
        display: table-cell;
        padding-right: 160px;
        vertical-align: top; }
        .block-wishlist-management .wishlist-select-items .item {
          display: inline-block;
          margin-right: 10px;
          padding: 5px 10px 10px; }
          .block-wishlist-management .wishlist-select-items .item:last-child {
            margin-right: 0; }
        .block-wishlist-management .wishlist-select-items .current {
          border-bottom: 3px solid #ff5501;
          font-weight: 600; }
      .block-wishlist-management .wishlist-select .wishlist-name-current {
        display: none; }
    .block-wishlist-management .wishlist-add.item {
      position: absolute;
      right: 0;
      top: 0; }
    .block-wishlist-management .wishlist-title strong {
      font-size: 40px; }
    .block-wishlist-management .wishlist-info {
      float: left; }
    .block-wishlist-management .wishlist-toolbar {
      float: right; }
  .block-wishlist-info-items .product-item-photo {
    margin-left: 0; }
  .products-grid.wishlist .product-item-checkbox {
    float: left; }
    .products-grid.wishlist .product-item-checkbox + .product-item-name {
      margin-left: 25px; } }

.multicheckout .title {
  margin-bottom: 30px; }
  .multicheckout .title strong {
    font-weight: 400; }

.multicheckout .table-wrapper {
  margin-bottom: 0; }
  .multicheckout .table-wrapper .action.delete {
    display: inline-block; }
  .multicheckout .table-wrapper .col .qty {
    display: inline-block; }
  .multicheckout .table-wrapper .col.item .action.edit {
    font-weight: 400;
    margin-left: 10px; }

.multicheckout:not(.address) .table-wrapper .product-item-name {
  margin: 0; }

.multicheckout > .actions-toolbar {
  margin-top: 40px; }

.multicheckout .actions-toolbar > .secondary {
  display: block; }
  .multicheckout .actions-toolbar > .secondary .action {
    margin-bottom: 25px; }
    .multicheckout .actions-toolbar > .secondary .action.back {
      display: block;
      margin-left: 0; }

.multicheckout .actions-toolbar > .primary {
  margin-right: 10px; }

.multicheckout .item-options {
  margin: 10px 0 0; }

.multicheckout .block .methods-shipping .item-content .fieldset > .field:before {
  display: none; }

.multicheckout .block .methods-shipping .item-content .fieldset > .field .control {
  display: inline-block; }

.multicheckout .block-title,
.multicheckout .block-content .title {
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 10px; }
  .multicheckout .block-title strong,
  .multicheckout .block-content .title strong {
    font-weight: 400; }
    .multicheckout .block-title strong span,
    .multicheckout .block-content .title strong span {
      color: #a6a6a6; }

.multicheckout .block-content .title {
  border-bottom: none;
  padding-bottom: 0; }

.multicheckout.order-review .block-title > strong {
  font-size: 24px; }

.multicheckout.order-review .block-shipping .block-content:not(:last-child) {
  margin-bottom: 40px; }

.multicheckout .box-title span {
  margin-right: 10px; }

.multicheckout .box-title > .action {
  margin: 0; }

.multicheckout .box-shipping-method .price {
  font-weight: 700; }

.multicheckout .box-billing-method .fieldset {
  margin: 0; }
  .multicheckout .box-billing-method .fieldset .legend.box-title {
    margin: 0 0 5px; }

.multicheckout .checkout-review .grand.totals {
  font-size: 21px;
  margin-bottom: 40px; }
  .multicheckout .checkout-review .grand.totals .mark {
    font-weight: 400; }

[class^='multishipping-'] .logo {
  margin-left: 0; }

.multishipping-checkout-success .nav-sections {
  display: block; }

@media only screen and (max-width: 640px) {
  .multicheckout .data.table .address:before {
    margin-bottom: 5px; }
  .multicheckout .product-item-name,
  .multicheckout .price-including-tax,
  .multicheckout .price-excluding-tax {
    display: inline-block; }
  .multicheckout .block-content .box:not(:last-child) {
    margin-bottom: 40px; }
  .multicheckout .block-content .box:last-child {
    margin-bottom: 0; }
  .multicheckout.order-review .box-items .data.table thead {
    display: block; }
    .multicheckout.order-review .box-items .data.table thead tr {
      display: block; }
    .multicheckout.order-review .box-items .data.table thead .col.item {
      display: block;
      padding: 0; }
  .multicheckout .actions-toolbar .action {
    margin-bottom: 25px; }
  .multicheckout .actions-toolbar > .primary {
    margin-bottom: 25px;
    margin-right: 0; } }

@media only screen and (min-width: 640px) {
  .multicheckout .actions-toolbar .secondary {
    float: none;
    margin-top: 11px;
    text-align: right; }
    .multicheckout .actions-toolbar .secondary .action {
      margin-left: 10px; }
      .multicheckout .actions-toolbar .secondary .action.back {
        display: block;
        float: left; }
  .multicheckout .item-options {
    margin: 20px 0 0; }
  .multicheckout .block-content .box {
    margin-bottom: 0; }
  .multicheckout .block-shipping .box {
    float: left;
    width: 25%; }
  .multicheckout .block-shipping .box-shipping-method {
    padding-left: 25px;
    padding-right: 25px;
    width: 50%; }
    .multicheckout .block-shipping .box-shipping-method .fieldset .field:before {
      display: none; }
  .multicheckout .block-billing .box-billing-address {
    float: left;
    width: 25%; }
  .multicheckout .block-billing .box-billing-method {
    float: left;
    padding-left: 25px;
    width: 50%; }
  .multicheckout.form.address .table-wrapper .applicable {
    margin: 7px 0 0; }
  .multicheckout.order-review .box-items {
    clear: left;
    float: none;
    padding-top: 40px;
    width: auto; }
  .multicheckout.order-review .col.item {
    width: 75%; }
  .multicheckout .methods-payment .item-content > .fieldset {
    width: auto; }
    .multicheckout .methods-payment .item-content > .fieldset .field.cvv {
      display: inline-block;
      width: auto; }
  .multicheckout .methods-payment .fieldset > .field:not(.choice) > .label {
    float: none;
    margin-bottom: 8px;
    text-align: left;
    width: auto; }
  .multicheckout .methods-payment .fieldset > .field:not(.choice):not(.cvv) .control {
    width: 100%; } }

@media only screen and (max-width: 768px) {
  .multishipping-checkout-success .nav-toggle {
    display: block; }
  .multishipping-checkout-success .logo {
    margin-left: 40px; } }

.paypal.after:before {
  content: "- " attr(data-label) " -";
  display: block;
  margin: 5px 5px 10px;
  text-transform: uppercase; }

.paypal.before:before {
  content: "- " attr(data-label) " -";
  display: block;
  margin: 10px 5px;
  text-align: center;
  text-transform: uppercase; }

.paypal.acceptance {
  display: block;
  margin: 0 0 20px; }
  .paypal.acceptance img {
    max-width: 100%; }

.box-tocart .paypal img,
.block-minicart .paypal img {
  display: block;
  margin: 0 auto; }

.paypal-review .paypa-review-title > strong {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: 300;
  line-height: 1.1;
  font-size: 18px;
  margin-top: 7px;
  margin-bottom: 7px;
  display: inline-block; }

.paypal-review .items-qty .item {
  white-space: nowrap; }

.paypal-review .items-qty .title:after {
  content: ': '; }

.paypal-review .paypal-review-title > strong {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: 300;
  line-height: 1.1;
  font-size: 18px;
  margin-top: 7px;
  margin-bottom: 7px;
  display: inline-block; }

.paypal-review .actions-toolbar {
  margin-top: 10px; }

.paypal-review .item-options dt {
  display: inline-block; }
  .paypal-review .item-options dt:after {
    content: ': '; }

.paypal-review .item-options dd {
  margin: 0; }

.paypal-review-discount {
  border-bottom: 1px solid #d1d1d1;
  margin: 20px 0; }
  .paypal-review-discount .block {
    margin-bottom: 0; }
    .paypal-review-discount .block .fieldset {
      padding: 10px 0 20px; }

@media only screen and (min-width: 768px) {
  .paypal-review .paypal-review-title {
    border-bottom: 1px solid #d1d1d1; }
  .paypal-review .block-content .box-order-shipping-address,
  .paypal-review .block-content .box-order-shipping-method,
  .paypal-review .block-content .box-order-shipping-method + .box-order-billing-address {
    box-sizing: border-box;
    float: left;
    width: 33%; }
  .paypal-review .block-content .box-order-shipping-address {
    padding: 0 5%;
    width: 34%; }
  .paypal-review .col.subtotal,
  .paypal-review .mark,
  .paypal-review .amount {
    text-align: right; } }

.fotorama-video-container:after {
  background: url("../Magento_ProductVideo/img/gallery-sprite.png") bottom right;
  bottom: 0;
  content: '';
  height: 100px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 12px;
  width: 100px; }

.fotorama-video-container .magnify-lens {
  display: none !important; }

.fotorama-video-container.video-unplayed:hover img {
  opacity: 0.6; }

.fotorama-video-container.video-unplayed:hover:after {
  transform: scale(1.25); }

.video-thumb-icon:after {
  background: url("../Magento_ProductVideo/img/gallery-sprite.png") bottom left;
  bottom: 0;
  content: '';
  height: 40px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 10px;
  width: 49px; }

.product-video {
  height: 75%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  margin: auto; }
  .product-video iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999; }

.fotorama__stage__shaft:focus .fotorama__stage__frame.fotorama__active:after {
  bottom: 0;
  content: '';
  height: 100px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 12px;
  width: 100px; }

@media only screen and (min-device-width: 320px) and (max-device-width: 780px) and (orientation: landscape) {
  .product-video {
    height: 100%;
    width: 81%; } }

.rating-summary {
  overflow: hidden;
  white-space: nowrap; }
  .rating-summary .rating-result {
    width: 100px;
    display: inline-block;
    position: relative;
    vertical-align: middle; }
    .rating-summary .rating-result:before {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      content: "";
      -webkit-font-smoothing: antialiased;
      color: #c7c7c7;
      font-family: "icons-blank-theme";
      font-size: 28px;
      height: 28px;
      letter-spacing: -10px;
      line-height: 28px;
      display: block;
      font-style: normal;
      font-weight: normal;
      speak: none; }
    .rating-summary .rating-result > span {
      display: block;
      overflow: hidden; }
      .rating-summary .rating-result > span:before {
        position: relative;
        z-index: 2;
        content: "";
        -webkit-font-smoothing: antialiased;
        color: #ff5601;
        font-family: "icons-blank-theme";
        font-size: 28px;
        height: 28px;
        letter-spacing: -10px;
        line-height: 28px;
        display: block;
        font-style: normal;
        font-weight: normal;
        speak: none; }
      .rating-summary .rating-result > span span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
  .rating-summary .rating-result {
    margin-left: -5px; }

.product-reviews-summary .rating-summary .label,
.table-reviews .rating-summary .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.review-control-vote {
  overflow: hidden; }
  .review-control-vote:before {
    color: #c7c7c7;
    font-family: "icons-blank-theme";
    font-size: 28px;
    height: 28px;
    letter-spacing: -10px;
    line-height: 28px;
    font-style: normal;
    font-weight: normal;
    speak: none;
    -webkit-font-smoothing: antialiased;
    content: "";
    display: block;
    position: absolute;
    z-index: 1; }
  .review-control-vote input[type="radio"] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
    .review-control-vote input[type="radio"]:focus + label:before, .review-control-vote input[type="radio"]:checked + label:before {
      opacity: 1; }
  .review-control-vote label {
    cursor: pointer;
    display: block;
    position: absolute; }
    .review-control-vote label span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .review-control-vote label:before {
      color: #ff5601;
      font-family: "icons-blank-theme";
      font-size: 28px;
      height: 28px;
      letter-spacing: -10px;
      line-height: 28px;
      font-style: normal;
      font-weight: normal;
      speak: none;
      -webkit-font-smoothing: antialiased;
      opacity: 0; }
    .review-control-vote label:hover:before {
      opacity: 1; }
    .review-control-vote label:hover ~ label:before {
      opacity: 0; }
  .review-control-vote .rating-5 {
    z-index: 2; }
    .review-control-vote .rating-5:before {
      content: ""; }
  .review-control-vote .rating-4 {
    z-index: 3; }
    .review-control-vote .rating-4:before {
      content: ""; }
  .review-control-vote .rating-3 {
    z-index: 4; }
    .review-control-vote .rating-3:before {
      content: ""; }
  .review-control-vote .rating-2 {
    z-index: 5; }
    .review-control-vote .rating-2:before {
      content: ""; }
  .review-control-vote .rating-1 {
    z-index: 6; }
    .review-control-vote .rating-1:before {
      content: ""; }

.block.add.review {
  margin-bottom: 40px; }
  .block.add.review > .title {
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: 300;
    line-height: 1.1;
    font-size: 26px;
    margin-top: 7px;
    margin-bottom: 7px; }
  .block.add.review .legend {
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: 300;
    line-height: 1.1;
    font-size: 18px;
    margin-top: 7px;
    margin-bottom: 7px;
    margin: 0 0 20px; }
  .block.add.review .field.rating {
    display: table-row; }
    .block.add.review .field.rating .label {
      display: table-cell;
      padding: 5px 0;
      vertical-align: middle; }
    .block.add.review .field.rating .control {
      display: table-cell;
      vertical-align: top; }

.block.reviews.list > .title {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: 300;
  line-height: 1.1;
  font-size: 26px;
  margin-top: 7px;
  margin-bottom: 7px; }

.block.reviews.list .review.item {
  margin-bottom: 40px; }

.block.reviews.list .review.title {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: 300;
  line-height: 1.1;
  font-size: 18px;
  margin-top: 7px;
  margin-bottom: 7px; }

.data.table.reviews .rating-summary {
  margin-top: -4px; }

.customer-review.view {
  margin-bottom: 20px; }
  .customer-review.view .review.title,
  .customer-review.view .ratings-summary,
  .customer-review.view .product-details {
    margin-bottom: 20px; }

.product-reviews-summary {
  display: table;
  margin: 10px 0 12px; }
  .products.wrapper.list .product-reviews-summary {
    margin: 0 auto; }
  .product-reviews-summary.empty {
    margin-left: 0; }
  .product-reviews-summary .rating-summary {
    display: table-cell;
    left: -5px;
    position: relative;
    text-align: left;
    vertical-align: middle; }
  .product-reviews-summary .reviews-actions {
    display: table-cell;
    line-height: 28px;
    vertical-align: middle; }
    .product-reviews-summary .reviews-actions a {
      margin-right: 5px; }

@media only screen and (min-width: 768px) {
  .products.wrapper.list .product-reviews-summary {
    margin: 0; } }

.customer-review .product-details {
  margin-bottom: 40px; }

.customer-review .product-media {
  float: left;
  margin-right: 3%;
  max-width: 285px;
  width: 30%; }

.customer-review .review-details .customer-review-rating {
  margin-bottom: 20px; }
  .customer-review .review-details .customer-review-rating .item {
    margin-bottom: 10px; }
    .customer-review .review-details .customer-review-rating .item:last-child {
      margin-bottom: 0; }

.customer-review .review-details .review-title {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: 300;
  line-height: 1.1;
  font-size: 18px;
  margin-top: 7px;
  margin-bottom: 7px;
  font-weight: 600;
  margin-bottom: 20px; }

.customer-review .review-details .review-content {
  margin-bottom: 20px; }

.customer-review .product-reviews-summary .rating-summary,
.customer-review .product-reviews-summary .reviews-actions {
  display: block; }

.review-field-ratings .nested {
  display: table; }

.review-field-ratings .review-field-rating {
  display: table-row; }
  .review-field-ratings .review-field-rating .label,
  .review-field-ratings .review-field-rating .control {
    display: table-cell;
    padding-bottom: 10px;
    vertical-align: top; }
  .review-field-ratings .review-field-rating .label {
    padding-right: 10px;
    padding-top: 6px; }
  .review-field-ratings .review-field-rating .control {
    padding-top: 2px; }

.review-toolbar {
  border-top: 1px solid #c9c9c9;
  margin: 0 0 20px;
  text-align: center; }
  .review-toolbar:first-child {
    display: none; }
  .review-toolbar .pages {
    border-bottom: 1px solid #c9c9c9;
    display: block;
    padding: 20px 0;
    text-align: center; }
  .review-toolbar .limiter {
    display: none; }

.review-add .block-title {
  display: none; }

.review-form .action.submit.primary {
  background: #f2f2f2;
  border: 1px solid #cdcdcd;
  color: #2FDF66; }
  .review-form .action.submit.primary:focus, .review-form .action.submit.primary:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #2FDF66; }
  .review-form .action.submit.primary:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #555; }

.fieldset .review-legend.legend {
  margin-bottom: 20px; }
  .fieldset .review-legend.legend strong {
    display: block;
    margin-left: 0; }

.review-field-rating .control {
  margin-bottom: 48px;
  margin-top: 10px; }

.review-list {
  margin-bottom: 30px; }
  .review-list .block-title strong {
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: 300;
    line-height: 1.1;
    font-size: 26px;
    margin-top: 7px;
    margin-bottom: 7px; }

.review-item {
  border-bottom: 1px solid #c9c9c9;
  margin: 0;
  padding: 20px 0; }
  .review-item:after {
    clear: both;
    content: '';
    display: table; }
  .review-item:last-child {
    border-width: 0; }

.review-ratings {
  display: table;
  margin-bottom: 10px;
  max-width: 100%; }

.review-author {
  display: inline; }

.review-title {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: 300;
  line-height: 1.1;
  font-size: 18px;
  margin-top: 7px;
  margin-bottom: 7px;
  margin: 0 0 20px; }

.review-content {
  margin-bottom: 20px; }

@media only screen and (min-width: 640px) {
  .review-form {
    max-width: 500px; }
  .review-ratings {
    float: left;
    margin-bottom: 0;
    max-width: 240px; }
  .review-ratings ~ .review-content,
  .review-ratings ~ .review-details {
    margin-left: 280px; }
  .review-toolbar {
    margin: 0 0 30px; }
    .review-toolbar .pages {
      padding: 30px 0; }
  .fieldset .review-legend.legend {
    margin-bottom: 30px; }
  .review-item {
    padding: 30px 0; }
  .review-title {
    margin: 0 0 30px; } }

.block-reward-info .reward-rates:not(:last-child),
.block-reward-info .reward-limit:not(:last-child),
.block-reward-info .reward-expiring:not(:last-child) {
  margin-bottom: 25px; }

.block-reward-info .reward-rates .title,
.block-reward-info .reward-limit .title,
.block-reward-info .reward-expiring .title {
  display: inline-block;
  margin-bottom: 10px; }

@media only screen and (max-width: 768px) {
  .reward-settings + .actions-toolbar {
    margin-top: 10px; } }

@media only screen and (min-width: 768px) {
  .reward-settings + .actions-toolbar {
    margin-top: -32px; } }

.order-links {
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 10px; }
  .order-links .item {
    display: inline-block;
    margin-right: 20px; }

.order-actions-toolbar .action {
  margin: 0 20px 0 0; }

.order-details-items {
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 20px;
  padding-bottom: 10px; }
  .order-details-items .order-title > strong {
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: 300;
    line-height: 1.1;
    font-size: 18px;
    margin-top: 7px;
    margin-bottom: 7px;
    display: inline-block; }
  .order-details-items .items-qty .item {
    white-space: nowrap; }
  .order-details-items .items-qty .title:after {
    content: ': '; }
  .order-details-items .table-order-items .product-item-name {
    margin-top: 0; }
  .order-details-items .table-order-items tbody + tbody {
    border-top: 1px solid #d1d1d1; }
  .order-details-items .item-options dt {
    margin: 0; }
  .order-details-items .item-options dd {
    margin: 0 0 15px; }
  .order-details-items .item-options.links dt {
    display: inline-block; }
    .order-details-items .item-options.links dt:after {
      content: ': '; }
  .order-details-items .item-options.links dd {
    margin: 0; }

.order-actions-toolbar {
  margin-bottom: 25px; }
  .order-actions-toolbar .action {
    margin-right: 30px; }
    .order-actions-toolbar .action.print {
      margin: 0; }

.page-title-wrapper .order-date {
  margin: 0 0 20px; }

.account .page-title-wrapper .page-title,
[class^='sales-guest-'] .page-title-wrapper .page-title,
.sales-guest-view .page-title-wrapper .page-title {
  margin-right: 25px; }

.form-orders-search .field.email, .form-orders-search .field.zip {
  margin-bottom: 0; }

.block-reorder .product-item .checkbox {
  position: relative;
  top: 2px;
  vertical-align: baseline; }

.block-reorder .product-item .field.item {
  display: inline-block; }

.block-reorder .product-item-name {
  display: inline-block; }

.block-reorder .actions-toolbar {
  margin: 17px 0;
  max-width: 178px; }

.block-order-details-comments {
  margin: 0 0 40px; }
  .block-order-details-comments .comment-date {
    font-weight: 600; }
  .block-order-details-comments .comment-content {
    line-height: 1.6;
    margin: 0 0 20px; }

.block-order-details-view .box-content .payment-method .title {
  font-weight: 400; }

.block-order-details-view .box-content .payment-method .content {
  margin: 0; }
  .block-order-details-view .box-content .payment-method .content > strong {
    font-weight: 400; }
    .block-order-details-view .box-content .payment-method .content > strong:after {
      content: ': '; }

.order-tracking {
  border-bottom: 1px solid #d1d1d1;
  margin: 0;
  padding: 20px 0; }
  .order-tracking .tracking-title {
    display: inline-block; }
  .order-tracking .tracking-content {
    display: inline-block;
    margin: 0 0 0 5px; }

@media only screen and (max-width: 640px) {
  .account .order-details-items .table-order-items .product-item-name,
  .account .order-details-items .table-order-items .price-including-tax,
  .account .order-details-items .table-order-items .price-excluding-tax,
  .account .order-details-items .table-order-items .items-qty {
    display: inline-block;
    vertical-align: top; }
  .account .toolbar .pages {
    float: right; }
  .account .toolbar .limiter {
    clear: both; } }

@media only screen and (min-width: 768px) {
  .table-order-items .subtotal,
  .table-order-items .amount {
    text-align: right; }
  .table-order-items.creditmemo .col.qty, .table-order-items.creditmemo .col.discount, .table-order-items.creditmemo .col.subtotal {
    text-align: center; }
  .table-order-items.creditmemo .col.total {
    text-align: right; }
  .order-actions-toolbar .action.print {
    display: block;
    float: right; }
  .page-title-wrapper .order-date {
    margin-top: -20px; }
  [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box,
  .sales-guest-view .column.main .block:not(.widget) .block-content .box {
    margin-bottom: 20px; } }

.opc-wrapper .step-title.discount-code {
  margin-top: 25px; }

.opc-wrapper .opc-payment-additional.discount-code .discount-description {
  padding: 10px 0; }

.opc-wrapper .opc-payment-additional.discount-code .payment-option-content {
  padding: 10px 0 0 0; }

.opc-wrapper .opc-payment-additional.discount-code .payment-option-title {
  border-top: none;
  padding: 20px 0; }

.opc-wrapper .form-discount {
  width: 100%; }
  .opc-wrapper .form-discount .action-apply {
    line-height: 22px;
    padding: 14px 17px;
    font-size: 18px;
    font-weight: 600 !important; }

.action.mailto.friend {
  margin-left: -7px;
  display: inline-block;
  text-decoration: none; }
  .action.mailto.friend:before {
    -webkit-font-smoothing: antialiased;
    font-size: 28px;
    line-height: 28px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: inherit;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: top; }
  .action.mailto.friend:hover:before {
    color: inherit; }
  .action.mailto.friend:active:before {
    color: inherit; }
  .action.mailto.friend span {
    display: inline-block;
    line-height: 28px;
    vertical-align: top; }

.clearfix:after {
  content: '';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both; }

.swatch-attribute-label {
  font-weight: bold;
  position: relative; }
  .swatch-attribute-label.required {
    padding-right: 10px; }
  .swatch-attribute-label[data-required="1"]::after {
    content: '*';
    color: red;
    position: absolute;
    right: -11px;
    top: -2px;
    font-weight: bold;
    font-size: 1em; }

.swatch-attribute-selected-option {
  color: #646464;
  padding-left: 17px; }

.swatch-attribute-options {
  margin-top: 10px; }

.swatch-option {
  border: 1px solid #d1d1d1;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis; }
  .swatch-option.text {
    background: #f2f2f2;
    color: #7f7f7f;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    padding: 4px 8px;
    min-width: 22px;
    margin-right: 7px; }
  .swatch-option.selected {
    outline: 1px solid black;
    border: 1px solid #fff;
    color: #333; }
  .swatch-option.text.selected {
    background-color: #fff; }
  .swatch-option:not(.disabled):hover {
    outline: 1px solid #999;
    border: 1px solid #fff;
    color: #333; }
  .swatch-option.color:not(.disabled):hover, .swatch-option.image:not(.disabled):hover {
    border: 1px solid #fff; }
  .swatch-option.disabled {
    cursor: default; }
    .swatch-option.disabled::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, white 43%, white 46%, #ff5216 47%, #ff5216 53%, white 54%, white 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%); }

.swatch-attribute.manufacturer .swatch-option, .swatch-attribute.size .swatch-option {
  background: #f0f0f0;
  color: #949494; }
  .swatch-attribute.manufacturer .swatch-option.selected, .swatch-attribute.size .swatch-option.selected {
    color: black;
    background: #fff;
    border: 1px solid #fff; }

.swatch-option-tooltip {
  max-width: 140px;
  max-height: 100%;
  min-height: 20px;
  min-width: 20px;
  position: absolute;
  padding: 5px;
  background: #fff;
  color: #949494;
  border: 1px solid #adadad;
  display: none;
  z-index: 999;
  text-align: center; }

.swatch-option-tooltip .corner,
.swatch-option-tooltip-layered .corner {
  left: 40%;
  position: absolute;
  bottom: 0;
  height: 8px; }
  .swatch-option-tooltip .corner::before,
  .swatch-option-tooltip-layered .corner::before {
    content: '';
    position: relative;
    top: 2px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8.5px 0 8.5px;
    border-color: #adadad transparent transparent transparent;
    font-size: 1px; }
  .swatch-option-tooltip .corner::after,
  .swatch-option-tooltip-layered .corner::after {
    content: '';
    position: relative;
    top: 1px;
    left: -15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7.5px 0 7.5px;
    border-color: #fff transparent transparent transparent;
    font-size: 1px; }

.swatch-option-tooltip .image,
.swatch-option-tooltip-layered .image {
  display: block;
  height: 130px;
  width: 130px;
  margin: 0 auto; }

.swatch-option-tooltip .title {
  max-width: 140px;
  min-height: 20px;
  max-height: 200px;
  color: #282828;
  text-align: center;
  display: block;
  overflow: hidden; }

.swatch-opt {
  margin: 20px 0; }

.swatch-more {
  display: inline-block;
  margin: 2px 0;
  text-decoration: none !important;
  position: static;
  z-index: 1; }

.swatch-layered .swatch-attribute-options {
  max-width: 250px; }

.swatch-option-link-layered {
  display: inline-block;
  position: relative;
  padding: 1px 2px;
  margin: 0 10px 5px 0; }

.swatch-option-tooltip-layered {
  width: 140px;
  position: absolute;
  background: #fff;
  color: #949494;
  border: 1px solid #adadad;
  display: none;
  z-index: 999;
  left: -47px; }

.swatch-visual-tooltip-layered {
  height: 160px;
  top: -170px; }

.swatch-textual-tooltip-layered {
  height: 30px;
  top: -40px; }

.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
  display: block; }

.swatch-attribute-options .swatch-option-tooltip-layered .title {
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: -5px;
  left: 0;
  color: #282828;
  text-align: center;
  margin-bottom: 10px; }

.swatch-option-disabled:after {
  content: '';
  position: absolute;
  width: 42px;
  height: 2px;
  background: red;
  transform: rotate(-30deg);
  z-index: 995;
  left: -4px;
  top: 10px; }

.swatch-option-disabled {
  outline: none !important;
  cursor: default;
  border: 0; }

.swatch-opt-listing {
  margin-bottom: 10px; }

.swatch-option-loading {
  content: url(../Magento_Swatches/images/loader-2.gif); }

.action {
  background: none; }

.cookie-status-message {
  display: none; }

body {
  background-color: #fff;
  color: #20272B;
  font-size: 16px; }

.product-info-stock-sku {
  width: 100%; }

@media only screen and (max-width: 768px) {
  .product-info-main {
    width: 100%; } }

.header .trusted {
  margin-left: 270px;
  margin-top: 23px; }
  @media only screen and (max-width: 1145px) {
    .header .trusted {
      display: none; } }
  .header .trusted img {
    width: 200px; }

.usp-toplinks {
  display: inline-block;
  margin: 0;
  padding: 0;
  float: left; }
  @media only screen and (max-width: 768px) {
    .usp-toplinks {
      display: none; } }
  .usp-toplinks li {
    margin-right: 20px;
    list-style: none;
    margin-top: 0;
    padding-top: 0;
    line-height: 1.4;
    font-weight: 600;
    float: left; }
    @media only screen and (min-width: 1340px) {
      .usp-toplinks li {
        font-size: 14px; } }
    @media only screen and (max-width: 1339px) {
      .usp-toplinks li {
        font-size: 12px; } }
    @media only screen and (max-width: 1203px) {
      .usp-toplinks li {
        font-size: 12px; } }
    .usp-toplinks li:before {
      content: '✔';
      color: #2FDF66;
      margin-right: 5px;
      font-weight: 800; }

.panel.header ul.phone {
  display: inline-block;
  margin: -6px 0 0 0;
  padding: 0;
  float: left; }
  @media only screen and (max-width: 768px) {
    .panel.header ul.phone {
      display: none; } }
  .panel.header ul.phone li {
    margin-right: 35px;
    list-style: none;
    margin-top: 0;
    padding-top: 0;
    line-height: 1.4;
    font-weight: 600;
    float: left; }
    @media only screen and (min-width: 1340px) {
      .panel.header ul.phone li {
        font-size: 14px; } }
    @media only screen and (max-width: 1339px) {
      .panel.header ul.phone li {
        font-size: 12px; } }
    .panel.header ul.phone li a {
      top: 11px;
      position: absolute;
      color: #20272B; }
      .panel.header ul.phone li a:hover {
        color: #20272B; }
    .panel.header ul.phone li:before {
      content: "\e969";
      font-size: 20px;
      font-weight: 200;
      font-family: "icomoon";
      color: #2FDF66; }

@media only screen and (min-width: 769px) {
  .phone-number-fixed {
    display: none; } }

@media only screen and (max-width: 768px) {
  .phone-number-fixed {
    position: fixed;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    background: #EB8500;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 1; }
    .phone-number-fixed ul.phone {
      display: inline-flex;
      margin: 0;
      padding: 0; }
      .phone-number-fixed ul.phone li {
        list-style: none;
        line-height: 1.4;
        font-size: 14px;
        font-weight: 600;
        margin: 0 auto;
        padding: 5px 15px;
        position: relative;
        width: 150px; }
        .phone-number-fixed ul.phone li a {
          color: #fff;
          position: absolute;
          bottom: 10px;
          right: 15px; }
        .phone-number-fixed ul.phone li:before {
          content: "\e969";
          font-size: 22px;
          font-weight: 500;
          font-family: "icomoon";
          color: #fff; } }

.page-header {
  background-color: false;
  margin-bottom: 20px; }

.header.content {
  padding-top: 10px;
  position: relative; }
  @media only screen and (max-width: 480px) {
    .header.content {
      padding-top: 10px !important; } }
  .header.content .phone-number {
    float: left; }
    @media only screen and (max-width: 1040px) {
      .header.content .phone-number {
        display: none; } }
    .header.content .phone-number .phone {
      position: relative; }
      .header.content .phone-number .phone li {
        margin-right: 35px;
        list-style: none;
        margin-top: 0;
        padding-top: 0; }
        .header.content .phone-number .phone li a {
          color: #000; }
        .header.content .phone-number .phone li:before {
          content: "\e969";
          font-size: 22px;
          font-weight: 500;
          font-family: "icomoon";
          color: #000; }

.logo {
  float: left;
  margin: 0 0 10px 40px;
  max-width: 240px;
  position: relative;
  z-index: 5; }
  @media only screen and (max-width: 1040px) {
    .logo {
      float: none;
      display: inline-block;
      position: relative;
      transform: translateX(-50%);
      left: 50%;
      margin: -8px auto 0 0;
      padding: 0;
      max-width: 160px; } }
  @media only screen and (max-width: 420px) {
    .logo {
      left: 45%; } }
  .logo img {
    display: block; }
  @media only screen and (max-width: 768px) {
    .logo {
      width: 160px; }
      .logo img {
        width: 160px;
        height: 60px; } }
  .page-print .logo {
    float: none; }

.page-main > .page-title-wrapper .page-title + .action {
  margin-top: 30px; }

.action.skip:focus {
  background: #f0f0f0;
  padding: 10px;
  box-sizing: border-box;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 15; }

.message.global p {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px; }

.message.global.noscript, .message.global.cookie {
  border-radius: inherit;
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: 1.2;
  font-size: 13px;
  background: #fdf0d5;
  border-color: inherit;
  color: #6f4400;
  margin: 0;
  color: #20272B; }
  .message.global.noscript a, .message.global.cookie a {
    color: #1979c3; }
    .message.global.noscript a:hover, .message.global.cookie a:hover {
      color: #006bb4; }
    .message.global.noscript a:active, .message.global.cookie a:active {
      color: #006bb4; }

.message.global.cookie {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 3;
  text-align: center;
  background: #FBFBFB;
  line-height: 3.2;
  font-size: 16px;
  height: auto; }
  .message.global.cookie a {
    color: #005266;
    font-weight: bold; }
  .message.global.cookie a:hover {
    color: #20272B; }
  .message.global.cookie .actions {
    margin-top: 0px; }

.message.global.demo {
  border-radius: inherit;
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: 1.2;
  font-size: 13px;
  background: #fdf0d5;
  border-color: inherit;
  color: #6f4400;
  margin-bottom: 0;
  text-align: center; }
  .message.global.demo a {
    color: #1979c3; }
    .message.global.demo a:hover {
      color: #006bb4; }
    .message.global.demo a:active {
      color: #006bb4; }

.page-footer {
  background: #014A5B;
  margin-top: auto; }

.footer.content {
  background-color: #005266;
  color: #F4F4F4;
  border-top: 0px !important;
  color: white;
  overflow: hidden;
  padding: 70px 0 100px; }
  .footer.content .footer-links-block {
    margin-top: 30px; }
  .footer.content h2 {
    text-align: left;
    margin-top: 0;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 22px !important; }
  .footer.content h3 {
    margin-top: 25px; }
  .footer.content li a {
    color: white;
    font-size: 16px;
    font-weight: 300; }
  @media only screen and (max-width: 560px) {
    .footer.content {
      padding: 30px 20px;
      text-align: center; }
      .footer.content h2 {
        text-align: center; } }
  .footer.content .links {
    display: inline-block;
    margin-bottom: 20px;
    padding: 0 50px 0 0;
    vertical-align: top; }
    .footer.content .links > li {
      margin: 0 0 8px; }
  .footer.content .switcher-store {
    margin: 0 0 30px;
    display: inline-block;
    padding-right: 50px;
    vertical-align: top; }
  .footer.content .block.newsletter {
    margin-bottom: 0;
    margin-top: 0; }
    .footer.content .block.newsletter .block.newsletter .field .control::before {
      -webkit-font-smoothing: antialiased;
      font-size: 35px;
      line-height: 33px;
      color: white !important;
      content: "";
      font-family: "icons-blank-theme";
      margin: inherit;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      vertical-align: middle; }
    .footer.content .block.newsletter .form.subscribe {
      display: block;
      width: 100%;
      position: relative; }
      .footer.content .block.newsletter .form.subscribe .actions {
        position: absolute;
        top: 0;
        right: 0; }
    .footer.content .block.newsletter .fieldset {
      display: table-cell;
      padding: 0;
      vertical-align: top; }
    .footer.content .block.newsletter .field {
      margin: 0; }
      .footer.content .block.newsletter .field .control {
        display: inline-block;
        text-decoration: none;
        display: block; }
        .footer.content .block.newsletter .field .control:before {
          -webkit-font-smoothing: antialiased;
          font-size: 35px;
          line-height: 33px;
          color: #c2c2c2;
          content: "";
          font-family: "icons-blank-theme";
          margin: inherit;
          display: inline-block;
          font-weight: normal;
          overflow: hidden;
          speak: none;
          text-align: center;
          vertical-align: middle; }
        .footer.content .block.newsletter .field .control:hover:before {
          color: inherit; }
        .footer.content .block.newsletter .field .control:active:before {
          color: inherit; }
        .footer.content .block.newsletter .field .control:before {
          position: absolute; }
    .footer.content .block.newsletter input {
      padding-left: 0px;
      margin-left: 0px;
      margin-right: 0;
      padding-right: 45px;
      width: 100%;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      border-bottom: 1px solid #fff;
      background-color: #005266;
      border-radius: 0; }
      @media only screen and (max-width: 560px) {
        .footer.content .block.newsletter input {
          font-size: 16px; } }
    .footer.content .block.newsletter .title {
      display: none; }
    .footer.content .block.newsletter .action.subscribe {
      min-width: 0;
      margin-left: 0;
      margin-right: 0;
      position: relative;
      margin-bottom: 0 !important;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      padding: 6px 15px;
      border-bottom: 0;
      border-radius: 0; }
      .footer.content .block.newsletter .action.subscribe span {
        display: none; }
  @media only screen and (min-width: 768px) {
    .footer.content .block.newsletter .field {
      margin-right: 5px; }
      .footer.content .block.newsletter .field .control {
        width: 100%; } }

.footer .copyright:before, .footer .copyright:after {
  content: '';
  display: table; }

.footer .copyright:after {
  clear: both; }

.footer .copyright,
.footer .bugs {
  display: block;
  margin: 20px 0 0; }

.footer .action.primary {
  background-image: none;
  background: #005266;
  border: 2px solid white;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 7px 15px;
  width: auto;
  font-size: 14px;
  line-height: 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px; }

.page-header .switcher,
.page-footer .switcher {
  margin-right: 10px; }
  .page-header .switcher .options,
  .page-footer .switcher .options {
    position: relative; }
    .page-header .switcher .options:before, .page-header .switcher .options:after,
    .page-footer .switcher .options:before,
    .page-footer .switcher .options:after {
      content: '';
      display: table; }
    .page-header .switcher .options:after,
    .page-footer .switcher .options:after {
      clear: both; }
    .page-header .switcher .options .action.toggle,
    .page-footer .switcher .options .action.toggle {
      padding: 0;
      cursor: pointer;
      display: inline-block;
      text-decoration: none; }
      .page-header .switcher .options .action.toggle > span,
      .page-footer .switcher .options .action.toggle > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .page-header .switcher .options .action.toggle:before,
      .page-footer .switcher .options .action.toggle:before {
        -webkit-font-smoothing: antialiased;
        font-size: 22px;
        line-height: 22px;
        color: inherit;
        content: "";
        font-family: "icons-blank-theme";
        margin: 0;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
        vertical-align: top; }
      .page-header .switcher .options .action.toggle:hover:before,
      .page-footer .switcher .options .action.toggle:hover:before {
        color: inherit; }
      .page-header .switcher .options .action.toggle:active:before,
      .page-footer .switcher .options .action.toggle:active:before {
        color: inherit; }
      .page-header .switcher .options .action.toggle.active,
      .page-footer .switcher .options .action.toggle.active {
        display: inline-block;
        text-decoration: none; }
        .page-header .switcher .options .action.toggle.active > span,
        .page-footer .switcher .options .action.toggle.active > span {
          border: 0;
          clip: rect(0, 0, 0, 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px; }
        .page-header .switcher .options .action.toggle.active:before,
        .page-footer .switcher .options .action.toggle.active:before {
          -webkit-font-smoothing: antialiased;
          font-size: 22px;
          line-height: 22px;
          color: inherit;
          content: "";
          font-family: "icons-blank-theme";
          margin: 0;
          display: inline-block;
          font-weight: normal;
          overflow: hidden;
          speak: none;
          text-align: center;
          vertical-align: top; }
        .page-header .switcher .options .action.toggle.active:hover:before,
        .page-footer .switcher .options .action.toggle.active:hover:before {
          color: inherit; }
        .page-header .switcher .options .action.toggle.active:active:before,
        .page-footer .switcher .options .action.toggle.active:active:before {
          color: inherit; }
    .page-header .switcher .options ul.dropdown,
    .page-footer .switcher .options ul.dropdown {
      background: #fff;
      border: 1px solid #bbb;
      z-index: 100;
      margin: 0;
      padding: 0;
      list-style: none none;
      box-sizing: border-box;
      position: absolute;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
      height: inherit;
      margin-top: 4px;
      min-width: 160px;
      width: inherit;
      display: none; }
      .page-header .switcher .options ul.dropdown li,
      .page-footer .switcher .options ul.dropdown li {
        margin: 0;
        padding: 0; }
        .page-header .switcher .options ul.dropdown li:hover,
        .page-footer .switcher .options ul.dropdown li:hover {
          background: #e8e8e8;
          cursor: pointer; }
      .page-header .switcher .options ul.dropdown:before, .page-header .switcher .options ul.dropdown:after,
      .page-footer .switcher .options ul.dropdown:before,
      .page-footer .switcher .options ul.dropdown:after {
        border-bottom-style: solid;
        content: '';
        display: block;
        height: 0;
        position: absolute;
        width: 0; }
      .page-header .switcher .options ul.dropdown:before,
      .page-footer .switcher .options ul.dropdown:before {
        border: 6px solid;
        border-color: transparent transparent #fff transparent;
        z-index: 99; }
      .page-header .switcher .options ul.dropdown:after,
      .page-footer .switcher .options ul.dropdown:after {
        border: 7px solid;
        border-color: transparent transparent #bbb transparent;
        z-index: 98; }
      .page-header .switcher .options ul.dropdown:before,
      .page-footer .switcher .options ul.dropdown:before {
        left: 10px;
        top: -12px; }
      .page-header .switcher .options ul.dropdown:after,
      .page-footer .switcher .options ul.dropdown:after {
        left: 9px;
        top: -14px; }
    .page-header .switcher .options.active,
    .page-footer .switcher .options.active {
      overflow: visible; }
      .page-header .switcher .options.active ul.dropdown,
      .page-footer .switcher .options.active ul.dropdown {
        display: block; }
    .page-header .switcher .options ul.dropdown a,
    .page-footer .switcher .options ul.dropdown a {
      display: block;
      padding: 8px; }
      .page-header .switcher .options ul.dropdown a:hover,
      .page-footer .switcher .options ul.dropdown a:hover {
        text-decoration: none; }
  .page-header .switcher li,
  .page-footer .switcher li {
    font-size: 12px;
    margin: 0;
    width: 100%; }
  .page-header .switcher strong,
  .page-footer .switcher strong {
    font-weight: 400; }

.widget {
  clear: both; }

.page-header .widget.block,
.page-footer .widget.block {
  margin: 20px 0; }

.ui-datepicker td {
  padding: 0; }

@media only screen and (min-width: 768px) {
  html,
  body {
    height: 100%; }
  .page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%; }
    .page-wrapper h2 {
      margin-top: 45px;
      font-size: 36px;
      margin-bottom: 15px; }
    .page-wrapper > .breadcrumbs,
    .page-wrapper > .top-container,
    .page-wrapper > .widget {
      box-sizing: border-box;
      width: 100%;
      padding-bottom: 30px; }
    .ie10 .page-wrapper {
      height: 100%; }
  .navigation ul {
    padding: 0 8px; }
  .header.panel > .header.links {
    float: right;
    font-size: 0;
    margin: 0;
    padding: 0;
    list-style: none none;
    margin-left: auto; }
    .header.panel > .header.links > li {
      display: inline-block;
      vertical-align: top; }
    .header.panel > .header.links > li {
      font-size: 14px;
      margin: 0 0 0 15px; }
      .header.panel > .header.links > li.welcome,
      .header.panel > .header.links > li a {
        line-height: 1.4;
        color: #4A4A49; }
      .header.panel > .header.links > li.welcome a {
        padding-left: 5px; }
  .header.content {
    padding: 68px 8px 0 !important; }
  .page-header {
    border: 0;
    margin-bottom: 0; }
    .page-header .panel.wrapper {
      border-bottom: 0 solid #e8e8e8;
      background: #f8f8f8;
      height: 38px;
      position: fixed;
      width: 100%;
      z-index: 99; } }
    @media only screen and (min-width: 768px) and (max-width: 1040px) {
      .page-header .panel.wrapper {
        display: none; } }

@media only screen and (min-width: 768px) {
    .page-header .header.panel {
      padding: 10px 8px; }
    .page-header .switcher {
      display: inline-block; }
  .page-main > .page-title-wrapper .page-title {
    display: inline-block; }
  .page-main > .page-title-wrapper .page-title + .action {
    float: right;
    margin-top: 20px; }
  .logo {
    margin: -8px auto 0 0; }
    .logo img {
      max-height: inherit;
      height: 60px;
      width: 240px; } }

.top_usp {
  color: #20272B; }

.cms-page-view h1 {
  margin-top: 0; }

.cms-page-view .page-wrapper > .breadcrumbs {
  padding-bottom: 0; }

.footer.content .subscribe-footer-block {
  margin-top: 20px; }

.image {
  position: relative; }

.slider-arrow {
  position: relative;
  color: #fff;
  background-color: #15485c;
  width: 500px;
  left: 8px;
  display: block;
  margin-top: auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 75px;
  font-size: 26px;
  font-weight: bold;
  float: left;
  bottom: 0 !important;
  top: calc(100% - 175px); }

.slider-arrow-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 1440px;
  height: 100%;
  left: 50%;
  transform: translate(-50%, 0); }

.slider-arrow-image {
  margin: 0;
  position: absolute;
  right: 20px;
  top: 50%;
  width: 30px;
  height: 31px;
  background-image: url("/media/theme_customization/arrow.png");
  background-repeat: no-repeat;
  transform: translate(0, -50%); }

.home-slider .slick-slide img {
  min-height: 220px;
  object-fit: cover; }

a.button-link {
  text-decoration: none; }

.footer-payment {
  max-width: 1445px;
  padding: 0 8px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box; }
  .footer-payment .footer-payment-inner {
    padding: 25px 0;
    width: 100%;
    float: right; }
    .footer-payment .footer-payment-inner > ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      width: 100%;
      justify-content: center; }
      .footer-payment .footer-payment-inner > ul > li img {
        height: 26px;
        max-width: none;
        width: auto; }
      @media only screen and (max-width: 560px) {
        .footer-payment .footer-payment-inner > ul {
          flex-wrap: wrap;
          justify-content: center; }
          .footer-payment .footer-payment-inner > ul > li {
            margin: auto 10px;
            padding: 25px 0; }
            .footer-payment .footer-payment-inner > ul > li img {
              height: 24px; } }
    @media only screen and (max-width: 980px) {
      .footer-payment .footer-payment-inner {
        margin: auto;
        width: 80%;
        float: none; } }
    @media only screen and (max-width: 750px) {
      .footer-payment .footer-payment-inner {
        width: 95%; } }

.payment-list {
  display: inline-block;
  padding-right: 0;
  vertical-align: middle; }

.payment-icon {
  display: inline-block;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  margin: auto 25px; }
  .payment-icon a {
    display: block;
    width: 100%;
    height: 100%; }
  .payment-icon:last-child {
    margin-right: 0 !important; }
  .payment-icon.ideal {
    height: calc(45px * 0.7);
    width: calc(50px * 0.7);
    background-image: url(../images/payment-icons/ideal.svg); }
  .payment-icon.ideal-qr {
    height: calc(46px * 0.7);
    width: calc(50px * 0.7);
    background-image: url(../images/payment-icons/ideal-qr-logo.svg); }
  .payment-icon.mistercash {
    height: calc(40px * 0.7);
    width: calc(65px * 0.7);
    background-image: url(../images/payment-icons/mistercash.svg); }
  .payment-icon.bancontact {
    height: calc(60px * 0.7);
    width: calc(85px * 0.7);
    background-image: url(../images/payment-icons/bancontact.svg); }
  .payment-icon.visa {
    height: calc(29px * 0.7);
    width: calc(90px * 0.7);
    background-image: url(../images/payment-icons/visa.svg); }
  .payment-icon.mastercard {
    height: calc(39px * 0.7);
    width: calc(50px * 0.7);
    background-image: url(../images/payment-icons/mastercard.svg); }
  .payment-icon.creditcard {
    height: calc(39px * 0.7);
    width: calc(50px * 0.7);
    background-image: url(../images/payment-icons/creditcard.svg); }
  .payment-icon.eps {
    height: calc(48px * 0.7);
    width: calc(90px * 0.7);
    background-image: url(../images/payment-icons/eps.svg); }
  .payment-icon.afterpay {
    height: calc(33px * 0.7);
    width: calc(125px * 0.7);
    background-image: url(../images/payment-icons/afterpay.svg); }
  .payment-icon.klarna {
    height: calc(27px * 0.7);
    width: calc(92px * 0.7);
    background-image: url(../images/payment-icons/klarna.svg); }
  .payment-icon.trustly {
    height: calc(44px * 0.7);
    width: calc(169px * 0.7);
    background-image: url(../images/payment-icons/trustly.svg); }
  .payment-icon.sofort {
    height: calc(39px * 0.7);
    width: calc(116px * 0.7);
    background-image: url(../images/payment-icons/sofort-uberweisung.svg); }
  .payment-icon.banktransfer {
    height: calc(39px * 0.7);
    width: calc(69px * 0.7);
    background-image: url(../images/payment-icons/banktransfer.svg); }
  .payment-icon.giropay {
    height: calc(40px * 0.7);
    width: calc(98px * 0.7);
    background-image: url(../images/payment-icons/giropay.svg); }
  .payment-icon.paypal {
    height: calc(40px * 0.7);
    width: calc(137px * 0.7);
    background-image: url(../images/payment-icons/paypal.svg); }

.my-credit-cards .status {
  font-style: italic; }

.my-credit-cards .action.delete {
  background-image: none;
  background: none;
  box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  display: inline;
  line-height: 1.42857;
  padding: 0;
  background: none;
  border: 0;
  color: #1979c3;
  text-decoration: none; }
  .my-credit-cards .action.delete:focus, .my-credit-cards .action.delete:active {
    background: none;
    border: none; }
  .my-credit-cards .action.delete:hover {
    background: none;
    border: none; }
  .my-credit-cards .action.delete.disabled, .my-credit-cards .action.delete[disabled],
  fieldset[disabled] .my-credit-cards .action.delete {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .my-credit-cards .action.delete:visited {
    color: #1979c3;
    text-decoration: none; }
  .my-credit-cards .action.delete:hover {
    color: #006bb4;
    text-decoration: underline; }
  .my-credit-cards .action.delete:active {
    color: #ff5501;
    text-decoration: underline; }
  .my-credit-cards .action.delete:hover {
    color: #006bb4; }
  .my-credit-cards .action.delete:hover, .my-credit-cards .action.delete:active, .my-credit-cards .action.delete:focus {
    background: none;
    border: 0; }
  .my-credit-cards .action.delete.disabled, .my-credit-cards .action.delete[disabled],
  fieldset[disabled] .my-credit-cards .action.delete {
    color: #1979c3;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

.my-credit-cards-popup .modal-footer {
  text-align: right; }

.my-credit-cards-popup .action.secondary {
  background-image: none;
  background: none;
  box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  display: inline;
  line-height: 1.42857;
  padding: 0;
  background: none;
  border: 0;
  color: #1979c3;
  text-decoration: none;
  margin-right: 20px; }
  .my-credit-cards-popup .action.secondary:focus, .my-credit-cards-popup .action.secondary:active {
    background: none;
    border: none; }
  .my-credit-cards-popup .action.secondary:hover {
    background: none;
    border: none; }
  .my-credit-cards-popup .action.secondary.disabled, .my-credit-cards-popup .action.secondary[disabled],
  fieldset[disabled] .my-credit-cards-popup .action.secondary {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .my-credit-cards-popup .action.secondary:visited {
    color: #1979c3;
    text-decoration: none; }
  .my-credit-cards-popup .action.secondary:hover {
    color: #006bb4;
    text-decoration: underline; }
  .my-credit-cards-popup .action.secondary:active {
    color: #ff5501;
    text-decoration: underline; }
  .my-credit-cards-popup .action.secondary:hover {
    color: #006bb4; }
  .my-credit-cards-popup .action.secondary:hover, .my-credit-cards-popup .action.secondary:active, .my-credit-cards-popup .action.secondary:focus {
    background: none;
    border: 0; }
  .my-credit-cards-popup .action.secondary.disabled, .my-credit-cards-popup .action.secondary[disabled],
  fieldset[disabled] .my-credit-cards-popup .action.secondary {
    color: #1979c3;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

@media only screen and (max-width: 768px) {
  .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status {
    display: none; }
    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status:before {
      display: none; }
    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status._default {
      display: block; }
  .my-credit-cards .card-type img {
    vertical-align: middle; } }

@media only screen and (min-width: 768px) {
  .my-credit-cards .card-type img {
    display: block; } }

.minilist .weee {
  display: table-row;
  font-size: 11px; }
  .minilist .weee:before {
    display: none; }

.price-container.weee:before {
  display: none; }

.products.list.items .action.towishlist:before,
.table-comparison .action.towishlist:before {
  content: ""; }

.account .table-wrapper .data.table.wishlist > thead > tr > th,
.account .table-wrapper .data.table.wishlist > thead > tr > td,
.account .table-wrapper .data.table.wishlist > tbody > tr > th,
.account .table-wrapper .data.table.wishlist > tbody > tr > td,
.account .table-wrapper .data.table.wishlist > tfoot > tr > th,
.account .table-wrapper .data.table.wishlist > tfoot > tr > td {
  border-top: 1px solid #d1d1d1; }

.account .table-wrapper .data.table.wishlist > caption + thead > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > caption + thead > tr:first-child > td,
.account .table-wrapper .data.table.wishlist > colgroup + thead > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > colgroup + thead > tr:first-child > td,
.account .table-wrapper .data.table.wishlist > thead:first-child > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > thead:first-child > tr:first-child > td {
  border-top: 0; }

.account .table-wrapper .data.table.wishlist > tbody + tbody {
  border-top: 1px solid #d1d1d1; }

.account .table-wrapper .data.table.wishlist thead > tr > th {
  border-bottom: 0; }

.account .table-wrapper .data.table.wishlist tbody > tr:last-child > td {
  border-bottom: 1px solid #d1d1d1; }

.account .table-wrapper .data.table.wishlist .product.name {
  display: inline-block;
  margin-bottom: 10px; }

.account .table-wrapper .data.table.wishlist .box-tocart {
  margin: 10px 0; }
  .account .table-wrapper .data.table.wishlist .box-tocart .qty {
    vertical-align: middle; }

.account .table-wrapper .data.table.wishlist .col.item {
  width: 50%; }

.account .table-wrapper .data.table.wishlist .col.photo {
  max-width: 150px; }

.account .table-wrapper .data.table.wishlist .col.selector {
  max-width: 15px; }

.account .table-wrapper .data.table.wishlist textarea {
  margin: 10px 0; }

.account .table-wrapper .data.table.wishlist .input-text.qty {
  margin-bottom: 10px; }

.account .table-wrapper .data.table.wishlist .action.primary {
  vertical-align: top; }

.account .table-wrapper .data.table.wishlist .price {
  font-weight: 700; }

.block-wishlist .product-item-name {
  margin-right: 25px; }

.products-grid.wishlist .product-item-photo {
  display: block;
  margin-bottom: 10px; }

.products-grid.wishlist .product-item-name {
  margin-top: 0; }

.products-grid.wishlist .product-item .price-box {
  margin: 0; }

.products-grid.wishlist .product-item-comment {
  display: block;
  height: 42px;
  margin: 10px 0; }

.products-grid.wishlist .product-item-actions > * {
  margin-right: 15px; }
  .products-grid.wishlist .product-item-actions > *:last-child {
    margin-right: 0; }

.products-grid.wishlist .product-item .box-tocart input.qty {
  height: 32px; }

@media only screen and (max-width: 640px) {
  .products-grid.wishlist .product-item {
    border-bottom: 1px solid #e8e8e8; }
    .products-grid.wishlist .product-item:first-child {
      border-top: 1px solid #e8e8e8; } }

@media only screen and (max-width: 768px) {
  .products-grid.wishlist {
    margin-bottom: 30px;
    margin-right: -10px; }
    .products-grid.wishlist .product-item {
      padding: 20px 10px 20px 20px;
      position: relative; }
      .products-grid.wishlist .product-item-photo {
        float: left;
        margin-right: 20px; }
      .products-grid.wishlist .product-item-name {
        font-size: 16px; }
      .products-grid.wishlist .product-item-actions {
        display: block; }
        .products-grid.wishlist .product-item-actions .action {
          margin-right: 15px; }
          .products-grid.wishlist .product-item-actions .action:last-child {
            margin-right: 0; }
          .products-grid.wishlist .product-item-actions .action.edit {
            float: left; }
          .products-grid.wishlist .product-item-actions .action.delete {
            float: right; }
          .products-grid.wishlist .product-item-actions .action.edit, .products-grid.wishlist .product-item-actions .action.delete {
            margin-top: 7px; }
      .products-grid.wishlist .product-item-name, .products-grid.wishlist .product-item-description,
      .products-grid.wishlist .product-item .price-box, .products-grid.wishlist .product-item-tooltip {
        margin-left: 95px; }
      .products-grid.wishlist .product-item .box-tocart {
        float: left;
        margin-right: 20px; }
        .products-grid.wishlist .product-item .box-tocart .stock {
          margin-top: 7px; }
      .products-grid.wishlist .product-item .giftregisty-dropdown,
      .products-grid.wishlist .product-item .field.qty {
        display: none; }
    .products-grid.wishlist .product-image-container {
      max-width: 80px; }
  .wishlist-index-index .product-item {
    width: 100%; }
    .wishlist-index-index .product-item-info {
      width: auto; } }

@media only screen and (min-width: 640px) {
  .wishlist-index-index .products-grid .product-item {
    margin-bottom: 20px; }
  .wishlist-index-index .products-grid .product-item-actions {
    margin: 0; } }

@media only screen and (min-width: 768px) {
  .products-grid.wishlist .product-item-tooltip {
    display: inline-block; }
  .products-grid.wishlist .product-item-actions {
    margin: 10px 0 0; }
  .products-grid.wishlist .product-item .fieldset .field.qty {
    margin-bottom: 10px;
    padding-right: 10px; }
    .products-grid.wishlist .product-item .fieldset .field.qty .label {
      width: auto; }
  .products-grid.wishlist .product-item .box-tocart .actions-primary {
    margin: 0; }
  .products-grid.wishlist .product-item .box-tocart .stock {
    margin: 20px 0 0; }
  .wishlist-index-index .product-item-info {
    width: 240px; } }

@media only screen and (min-width: 1024px) {
  .wishlist-index-index .products-grid .product-items {
    margin: 0; }
  .wishlist-index-index .products-grid .product-item {
    margin-left: calc((100% - 4 * 24.439%) / 3);
    padding: 0;
    width: 24.439%; }
    .wishlist-index-index .products-grid .product-item:nth-child(4n + 1) {
      margin-left: 0; } }

.block-static-block.widget.cwiw {
  padding: 0 8px;
  margin-top: 40px;
  height: auto; }
  .block-static-block.widget.cwiw .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .block-static-block.widget.cwiw .row .col-md-2 {
      width: calc(25% - 15px);
      display: block;
      margin-bottom: 30px; }
      @media only screen and (max-width: 1040px) {
        .block-static-block.widget.cwiw .row .col-md-2 {
          width: calc(50% - 15px); } }
      @media only screen and (max-width: 640px) {
        .block-static-block.widget.cwiw .row .col-md-2 {
          margin-bottom: 15px;
          width: calc(50% - 7.5px); } }
      @media only screen and (max-width: 480px) {
        .block-static-block.widget.cwiw .row .col-md-2 {
          width: 100%; } }
      .block-static-block.widget.cwiw .row .col-md-2 > a {
        display: block;
        position: relative;
        height: 0;
        overflow: hidden;
        padding-bottom: 65%; }
        .block-static-block.widget.cwiw .row .col-md-2 > a .h3-category {
          font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-size: 24px;
          font-weight: 600;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          text-align: center;
          position: absolute;
          text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1); }
        .block-static-block.widget.cwiw .row .col-md-2 > a img {
          width: 100%;
          position: relative;
          left: 50%;
          transform: translateX(-50%); }
  @media only screen and (max-width: 1040px) {
    .block-static-block.widget.cwiw {
      padding: 0 100px; } }
  @media only screen and (max-width: 767px) {
    .block-static-block.widget.cwiw {
      padding: 0 30px; } }
  @media only screen and (max-width: 640px) {
    .block-static-block.widget.cwiw {
      padding: 0 15px; } }

.smile-es-range-slider .ui-slider {
  background: #dadada;
  margin: 10px 0 10px 0;
  max-width: 85%; }
  .smile-es-range-slider .ui-slider .ui-slider-range {
    background: #b6b6b6;
    height: 10px;
    position: relative; }
  .smile-es-range-slider .ui-slider .ui-slider-handle {
    background: #b6b6b6;
    border-radius: 50%;
    margin-top: -15px;
    width: 20px;
    height: 20px; }
    .smile-es-range-slider .ui-slider .ui-slider-handle:hover {
      background: #e17e17; }

.smile-es-range-slider .result {
  display: block;
  text-align: center;
  max-width: 85%; }

.smile-es-range-slider .actions-toolbar .action.primary {
  float: none; }

.smile-es-range-slider [data-role=from-label] {
  display: inline-block; }
  .smile-es-range-slider [data-role=from-label]:after {
    content: "-";
    display: inline-block;
    margin: 0 5px; }

.smile-es-range-slider [data-role=to-label] {
  display: inline-block; }

#notice-cookie-block .content strong {
  display: block;
  margin-bottom: 5px; }

#notice-cookie-block .content #btn-cookie-allow {
  margin-bottom: 0 !important; }

.blog__post-related {
  background-color: #F4F4F4;
  width: 100%;
  padding: 20px 0;
  margin: 40px 0 !important; }
  .blog__post-related .blog-container {
    max-width: 1445px;
    margin: 0 auto; }
    .blog__post-related .blog-container .block-title {
      display: block;
      margin-bottom: 10px;
      padding: 0 20px; }
      @media only screen and (min-width: 992px) {
        .blog__post-related .blog-container .block-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 15px 0 0; } }
      .blog__post-related .blog-container .block-title .blog-title-container {
        font-size: 22px;
        padding: 0 20px; }
        @media only screen and (max-width: 565px) {
          .blog__post-related .blog-container .block-title .blog-title-container {
            font-size: 20px; } }
      .blog__post-related .blog-container .block-title .blog-show-all {
        font-size: 20px; }
        @media only screen and (max-width: 565px) {
          .blog__post-related .blog-container .block-title .blog-show-all {
            font-size: 15px;
            margin: 5px 0; } }
        .blog__post-related .blog-container .block-title .blog-show-all a.show-all-link {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: black; }
          .blog__post-related .blog-container .block-title .blog-show-all a.show-all-link:after {
            display: inline-block;
            font-family: "icomoon";
            content: "\e900";
            font-size: 22px;
            line-height: 24px;
            text-align: center;
            color: #ED8400;
            cursor: pointer;
            transform: rotate(-90deg); }
    .blog__post-related .blog-container .block-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0 20px; }
      @media only screen and (min-width: 565px) {
        .blog__post-related .blog-container .block-content {
          display: grid;
          grid-template-columns: 1fr 1fr; } }
      @media only screen and (min-width: 992px) {
        .blog__post-related .blog-container .block-content {
          grid-template-columns: 1fr 1fr 1fr 1fr; } }
      .blog__post-related .blog-container .block-content .item {
        background-color: white;
        border-radius: 5px;
        overflow: hidden; }
        .blog__post-related .blog-container .block-content .item .blog-image-container {
          width: 100%; }
          .blog__post-related .blog-container .block-content .item .blog-image-container img.blog__post-featured-image {
            object-fit: cover;
            overflow: hidden;
            min-height: 160px;
            width: 100%; }
        .blog__post-related .blog-container .block-content .item a.blog-name {
          display: block;
          font-weight: 300;
          color: black;
          position: relative;
          padding: 15px 10px; }
          .blog__post-related .blog-container .block-content .item a.blog-name:after {
            position: absolute;
            bottom: 10px;
            right: 10px;
            font-family: "icomoon";
            content: "\e900";
            font-size: 22px;
            line-height: 24px;
            text-align: center;
            color: #ED8400;
            cursor: pointer;
            margin: 0 0 0 5px;
            transform: rotate(-90deg); }

.blog__post-list-wrapper .blog__post-list {
  list-style: none;
  padding: 0; }
  .blog__post-list-wrapper .blog__post-list li {
    margin-bottom: 1.4rem;
    border-bottom: 1px dotted #ddd; }

.blog__post-list-wrapper .toolbar {
  position: relative;
  margin-bottom: 1rem; }

.blog__post-item:before, .blog__post-item:after {
  content: '';
  display: table; }

.blog__post-item:after {
  clear: both; }

.blog__post-item .blog__post-header .blog__post-title {
  margin-bottom: 1rem;
  margin-top: 0; }
  .blog__post-item .blog__post-header .blog__post-title a {
    color: #333;
    font-size: 2rem; }
    .blog__post-item .blog__post-header .blog__post-title a:hover {
      text-decoration: none;
      color: #000; }

.blog__post-item .blog__post-excerpt:before, .blog__post-item .blog__post-excerpt:after {
  content: '';
  display: table; }

.blog__post-item .blog__post-excerpt:after {
  clear: both; }

@media only screen and (min-width: 768px) {
  .blog__post-item .blog__post-excerpt img {
    float: left;
    padding: 0 2rem 2rem 0;
    max-width: 30%; }
  .blog__post-item .blog__post-excerpt a img {
    float: left;
    padding: 0 2rem 2rem 0;
    max-width: 30%; } }

@media only screen and (max-width: 768px) {
  .blog__post-item .blog__post-excerpt img {
    padding: 0 0 2rem 0;
    max-width: 100%;
    width: 100%; } }

.blog__post-item .blog__post-excerpt .read-more {
  display: block;
  text-align: right;
  margin-top: 1rem; }

.blog__main-recent .item:before, .blog__main-recent .item:after {
  content: '';
  display: table; }

.blog__main-recent .item:after {
  clear: both; }

.blog__main-recent .item img {
  padding: 0 1rem 1rem 0;
  float: left;
  max-width: 10rem;
  border-radius: 2px; }

.blog__main-recent .item a {
  color: #333;
  font-size: 1.2rem;
  display: block;
  text-decoration: none; }

.blog__post-meta {
  color: #a3a3a3;
  margin-bottom: 0.5rem; }
  .blog__post-meta:before, .blog__post-meta:after {
    content: '';
    display: table; }
  .blog__post-meta:after {
    clear: both; }
  .blog__post-meta .blog__post-meta-time, .blog__post-meta .blog__post-meta-comments, .blog__post-meta .blog__post-meta-sharing {
    margin-right: 2rem; }
  .blog__post-meta .blog__post-meta-author {
    display: none; }
  .blog__post-meta .blog__post-meta-sharing {
    display: inline-block;
    margin-left: 2rem; }

.blog__post-footer {
  display: none; }

.blog__post-pinned {
  margin-bottom: 3rem;
  position: relative; }
  .blog__post-pinned:before, .blog__post-pinned:after {
    content: '';
    display: table; }
  .blog__post-pinned:after {
    clear: both; }
  .blog__post-pinned .block-title {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.5rem 1rem;
    background: rgba(255, 255, 255, 0.6);
    color: #333333;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    width: 100%; }
  .blog__post-pinned .items .item {
    float: left;
    width: 32%;
    margin-right: 2%; }
    .blog__post-pinned .items .item img {
      width: 100%;
      margin-bottom: 1rem; }
    .blog__post-pinned .items .item:last-child {
      margin-right: 0; }
    .blog__post-pinned .items .item h2 {
      display: block;
      margin-top: 0;
      font-weight: 500; }
      .blog__post-pinned .items .item h2 a.title {
        color: #333;
        font-size: 1.8rem; }

.blog__post-recent {
  width: 100vw;
  max-width: 100vw;
  margin-left: calc(50% - 50vw);
  background-color: #F4F4F4;
  padding: 20px 0; }
  .blog__post-recent .blog-container {
    max-width: 1445px;
    margin: 0 auto; }
    .blog__post-recent .blog-container .block-title {
      display: block;
      margin-bottom: 10px;
      padding: 0 20px; }
      @media only screen and (min-width: 992px) {
        .blog__post-recent .blog-container .block-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 15px 0 0; } }
      .blog__post-recent .blog-container .block-title .blog-title-container {
        font-size: 30px; }
        @media only screen and (max-width: 565px) {
          .blog__post-recent .blog-container .block-title .blog-title-container {
            font-size: 20px; } }
      .blog__post-recent .blog-container .block-title .blog-show-all {
        font-size: 20px; }
        @media only screen and (max-width: 565px) {
          .blog__post-recent .blog-container .block-title .blog-show-all {
            font-size: 15px;
            margin: 5px 0; } }
        .blog__post-recent .blog-container .block-title .blog-show-all a.show-all-link {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: black; }
          .blog__post-recent .blog-container .block-title .blog-show-all a.show-all-link:after {
            display: inline-block;
            font-family: "icomoon";
            content: "\e900";
            font-size: 22px;
            line-height: 24px;
            text-align: center;
            color: #ED8400;
            cursor: pointer;
            transform: rotate(-90deg); }
    .blog__post-recent .blog-container .block-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0 20px; }
      @media only screen and (min-width: 565px) {
        .blog__post-recent .blog-container .block-content {
          display: grid;
          grid-template-columns: 1fr 1fr; } }
      @media only screen and (min-width: 992px) {
        .blog__post-recent .blog-container .block-content {
          grid-template-columns: 1fr 1fr 1fr 1fr; } }
      .blog__post-recent .blog-container .block-content .item {
        background-color: white;
        border-radius: 5px;
        overflow: hidden; }
        .blog__post-recent .blog-container .block-content .item .blog-image-container {
          width: 100%; }
          .blog__post-recent .blog-container .block-content .item .blog-image-container img.blog__post-featured-image {
            object-fit: cover;
            overflow: hidden;
            min-height: 160px;
            width: 100%; }
        .blog__post-recent .blog-container .block-content .item a.blog-name {
          display: block;
          font-weight: 300;
          color: black;
          position: relative;
          padding: 15px 10px; }
          .blog__post-recent .blog-container .block-content .item a.blog-name:after {
            position: absolute;
            bottom: 10px;
            right: 10px;
            font-family: "icomoon";
            content: "\e900";
            font-size: 22px;
            line-height: 24px;
            text-align: center;
            color: #ED8400;
            cursor: pointer;
            margin: 0 0 0 5px;
            transform: rotate(-90deg); }

body.blog-post-view .page-main,
body.blog-tag-view .page-main,
body.blog-author-view .page-main,
body.blog-category-index .page-main {
  padding: 0 10px; }
  body.blog-post-view .page-main .page-title,
  body.blog-tag-view .page-main .page-title,
  body.blog-author-view .page-main .page-title,
  body.blog-category-index .page-main .page-title {
    margin-top: 0;
    margin-bottom: 20px; }

@media only screen and (min-width: 992px) {
  body.blog-post-view .column.main,
  body.blog-tag-view .column.main,
  body.blog-author-view .column.main,
  body.blog-category-index .column.main {
    display: inline-block;
    padding-right: 25px;
    width: 75%; }
  body.blog-post-view .sidebar.sidebar-main,
  body.blog-tag-view .sidebar.sidebar-main,
  body.blog-author-view .sidebar.sidebar-main,
  body.blog-category-index .sidebar.sidebar-main {
    display: inline-block;
    vertical-align: top;
    width: 25%; } }

.blog__post-view:before, .blog__post-view:after {
  content: '';
  display: table; }

.blog__post-view:after {
  clear: both; }

.blog__post-view img.blog__post-featured-image {
  float: left;
  padding: 0 2rem 2rem 0; }
  @media only screen and (max-width: 768px) {
    .blog__post-view img.blog__post-featured-image {
      padding: 0 0 2rem 0;
      width: 100%;
      max-width: 100%; } }

.blog__post-view-related-posts {
  margin: 2rem 0; }
  .blog__post-view-related-posts:before, .blog__post-view-related-posts:after {
    content: '';
    display: table; }
  .blog__post-view-related-posts:after {
    clear: both; }
  .blog__post-view-related-posts .block-title {
    font-size: 1.8rem;
    margin-bottom: 1rem; }
  .blog__post-view-related-posts ol {
    margin: 0 -1.5rem;
    list-style: none;
    padding: 0; }
    .blog__post-view-related-posts ol li {
      float: left;
      width: 50%;
      padding-left: 1.5rem;
      box-sizing: border-box;
      margin-bottom: 2rem;
      color: #999;
      font-size: 1.2rem; }
      .blog__post-view-related-posts ol li img {
        max-width: 7rem;
        margin: 0 1rem 1rem 0;
        float: left;
        border-radius: 2px; }
      .blog__post-view-related-posts ol li a.post-name {
        display: block;
        margin-top: -5px;
        font-weight: 500;
        color: #333;
        font-size: 1.4rem; }

.blog__post-view-related-products {
  margin: 2rem 0; }
  .blog__post-view-related-products:before, .blog__post-view-related-products:after {
    content: '';
    display: table; }
  .blog__post-view-related-products:after {
    clear: both; }
  .blog__post-view-related-products .block-title {
    font-size: 1.8rem; }
  .blog__post-view-related-products ol {
    margin: 0;
    list-style: none;
    padding: 0; }
    .blog__post-view-related-products ol .products.wrapper.grid.products-grid {
      margin: 30px 0 0 20px; }
      .blog__post-view-related-products ol .products.wrapper.grid.products-grid ol.products.list.items.product-items li {
        padding-left: 0;
        padding-right: 20px; }
  .blog__post-view-related-products .product-image-container {
    display: block;
    width: 100% !important; }

.addthis_bar_fixed {
  display: none !important; }

.blog__copyright-wrapper {
  display: block;
  float: right;
  clear: both;
  margin-top: 50px;
  font-size: 10px !important;
  text-transform: none;
  color: #c2c2c2 !important; }
  .blog__copyright-wrapper * {
    font-size: 10px !important;
    color: #c2c2c2 !important; }
  .blog__copyright-wrapper h3 {
    margin-top: 0;
    display: block; }
  .blog__copyright-wrapper a {
    text-decoration: underline !important; }

.blog__sidebar-main .block {
  margin-bottom: 2rem !important; }
  .blog__sidebar-main .block .block-title {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 0.5rem; }
    .blog__sidebar-main .block .block-title strong {
      font-weight: 300; }

.blog__sidebar-search {
  margin-bottom: 2rem; }
  .blog__sidebar-search form {
    position: relative; }
    .blog__sidebar-search form input {
      font-size: 1.3rem;
      border-radius: 3px; }
    .blog__sidebar-search form button, .blog__sidebar-search form .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .blog__sidebar-search form .actions-toolbar > .action {
      right: 0;
      top: 0;
      position: absolute;
      border-radius: 0 3px 3px 0; }

.blog__sidebar-recent .item:before, .blog__sidebar-recent .item:after {
  content: '';
  display: table; }

.blog__sidebar-recent .item:after {
  clear: both; }

.blog__sidebar-recent .item img {
  padding: 0 1rem 1rem 0;
  float: left;
  max-width: 10rem;
  border-radius: 2px; }

.blog__sidebar-recent .item a {
  color: #333;
  font-size: 1.2rem;
  display: block;
  text-decoration: none; }

.blog__sidebar-category-tree li.item.level-2 a {
  padding-left: 4rem; }

.blog__sidebar-category-tree li.item.level-3 a {
  padding-left: 7rem; }

.blog__sidebar-category-tree li.item.level-4 a {
  padding-left: 9rem; }

.blog__sidebar-category-tree li.item.level-5 a {
  padding-left: 12rem; }

.blog__sidebar-tag-cloud {
  overflow: hidden;
  text-overflow: ellipsis; }
  .blog__sidebar-tag-cloud a {
    color: #666; }

.blog__sidebar-rss-link {
  text-align: center; }
  .blog__sidebar-rss-link a {
    color: #999;
    font-size: 1.5rem; }
    .blog__sidebar-rss-link a:hover {
      text-decoration: none; }

@media only screen and (max-width: 768px) {
  .blog .page-main {
    position: relative;
    padding-top: 41px; } }

@media only screen and (min-width: 768px) {
  .navigation .blog-mx.level0:last-child {
    float: right; } }

.sidebar .block-addbysku .fieldset {
  margin: 0; }
  .sidebar .block-addbysku .fieldset .fields {
    position: relative; }
    .sidebar .block-addbysku .fieldset .fields .field {
      display: inline-block;
      margin-bottom: 10px;
      vertical-align: top; }
      .sidebar .block-addbysku .fieldset .fields .field.sku {
        margin-right: -85px;
        padding-right: 90px;
        width: 100%; }
      .sidebar .block-addbysku .fieldset .fields .field.qty .qty + .mage-error {
        width: 80px; }
    .sidebar .block-addbysku .fieldset .fields .actions-toolbar {
      position: absolute;
      right: 0;
      top: 6px; }

.sidebar .block-addbysku .form-addbysku .actions-toolbar {
  margin-bottom: 20px; }
  .sidebar .block-addbysku .form-addbysku .actions-toolbar .primary {
    text-align: left; }
    .sidebar .block-addbysku .form-addbysku .actions-toolbar .primary .action {
      width: auto; }
  .sidebar .block-addbysku .form-addbysku .actions-toolbar .secondary {
    float: none;
    text-align: left; }

.sidebar .block-addbysku .action.add {
  display: inline-block;
  text-decoration: none;
  line-height: normal;
  padding: 2px 0;
  width: auto; }
  .sidebar .block-addbysku .action.add > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .sidebar .block-addbysku .action.add:before {
    -webkit-font-smoothing: antialiased;
    font-size: 31px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: top; }
  .sidebar .block-addbysku .action.add:hover:before {
    color: inherit; }
  .sidebar .block-addbysku .action.add:active:before {
    color: inherit; }

.sidebar .block-addbysku .action.links {
  display: inline-block;
  margin: 20px 0 0; }

.sidebar .block-addbysku .action.reset {
  display: block;
  margin: 10px 0; }

.sidebar .block-addbysku .action.remove {
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: none;
  box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400; }
  .sidebar .block-addbysku .action.remove > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .sidebar .block-addbysku .action.remove:before {
    -webkit-font-smoothing: antialiased;
    font-size: 28px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: top; }
  .sidebar .block-addbysku .action.remove:hover:before {
    color: inherit; }
  .sidebar .block-addbysku .action.remove:active:before {
    color: inherit; }
  .sidebar .block-addbysku .action.remove:focus, .sidebar .block-addbysku .action.remove:active {
    background: none;
    border: none; }
  .sidebar .block-addbysku .action.remove:hover {
    background: none;
    border: none; }
  .sidebar .block-addbysku .action.remove.disabled, .sidebar .block-addbysku .action.remove[disabled],
  fieldset[disabled] .sidebar .block-addbysku .action.remove {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }

.block-banners .banner-item,
.block-banners-inline .banner-item {
  display: block; }

.block-banners .banner-item-content,
.block-banners-inline .banner-item-content {
  margin-bottom: 20px; }
  .block-banners .banner-item-content img,
  .block-banners-inline .banner-item-content img {
    display: block;
    margin: 0 auto; }

.block.widget .products-grid .product-items {
  padding: 0 15px; }

.block-category-link.widget,
.block-product-link.widget {
  display: block;
  margin-bottom: 20px; }

.block-product-link-inline.widget {
  margin: 0; }

.block.widget .product-item-info {
  width: auto; }

.block.widget .pager {
  padding: 0; }
  .block.widget .pager .toolbar-amount {
    float: none;
    font-size: 12px; }
  .block.widget .pager .pages-item-previous {
    padding-left: 0; }
  .block.widget .pager .pages-item-next {
    position: relative; }
  .block.widget .pager .items {
    white-space: nowrap; }

@media only screen and (min-width: 640px) {
  .block.widget .products-grid .product-item,
  .page-layout-1column .block.widget .products-grid .product-item,
  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 33.3333%; } }

@media only screen and (min-width: 768px) {
  .block.widget .products-grid .product-item {
    width: 33.3333%; }
  .page-layout-1column .block.widget .products-grid .product-item {
    width: 25%; }
  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 50%; } }

@media only screen and (min-width: 1024px) {
  .block.widget .products-grid .product-item {
    width: 23%;
    /*20%*/ }
  .page-layout-1column .block.widget .products-grid .product-item {
    width: 23.21%;
    /*16.6666%;*/
    margin-left: 2%;
    /*calc((100% - 5 * (100%/6)) / 4);*/ }
    .page-layout-1column .block.widget .products-grid .product-item:nth-child(4n+1) {
      margin-left: calc((100% - 5 * (100%/5)) / 4);
      /*calc((100% - 5 * (100%/6)) / 4);*/ }
    .page-layout-1column .block.widget .products-grid .product-item:nth-child(5n+1) {
      margin-left: 0; }
  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 25%; }
  .block.widget .products-grid .product-items {
    margin: 0; }
  .block.widget .products-grid .product-item {
    width: 24.439%;
    margin-left: calc((100% - 4 * 24.439%) / 3);
    padding: 0; }
    .block.widget .products-grid .product-item:nth-child(4n+1) {
      margin-left: 0; } }

@media only screen and (min-width: 768px) {
  .sidebar .block.widget .pager .pages-item-next {
    padding: 0; }
    .sidebar .block.widget .pager .pages-item-next .action {
      margin: 0; } }

.columns .block-event {
  position: relative;
  z-index: 2; }

.block-event .block-content {
  position: relative; }
  .block-event .block-content > .action {
    cursor: pointer;
    margin-top: -6px;
    position: absolute;
    top: 50%;
    z-index: 3; }
    .block-event .block-content > .action.backward, .block-event .block-content > .action.forward {
      opacity: 0.5;
      overflow: hidden;
      width: 20px;
      display: inline-block;
      text-decoration: none; }
      .block-event .block-content > .action.backward > span, .block-event .block-content > .action.forward > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .block-event .block-content > .action.backward:before, .block-event .block-content > .action.forward:before {
        -webkit-font-smoothing: antialiased;
        font-size: 55px;
        line-height: 30px;
        color: inherit;
        content: "";
        font-family: "icons-blank-theme";
        margin: inherit;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
        vertical-align: middle; }
      .block-event .block-content > .action.backward:hover:before, .block-event .block-content > .action.forward:hover:before {
        color: inherit; }
      .block-event .block-content > .action.backward:active:before, .block-event .block-content > .action.forward:active:before {
        color: inherit; }
      .block-event .block-content > .action.backward:before, .block-event .block-content > .action.forward:before {
        margin-left: -15px; }
    .block-event .block-content > .action.backward {
      left: 0; }
    .block-event .block-content > .action.forward {
      right: 0; }
      .block-event .block-content > .action.forward:before {
        content: ""; }
      .block-event .block-content > .action.forward:before {
        margin-left: -20px; }
    .block-event .block-content > .action.inactive {
      cursor: default;
      display: none; }
    .block-event .block-content > .action:hover {
      opacity: 1; }

.block-event .slider-panel {
  overflow: hidden;
  position: relative;
  z-index: 2; }
  .block-event .slider-panel .slider {
    white-space: nowrap; }
    .block-event .slider-panel .slider .item {
      background: #f0f0f0;
      display: inline-block;
      text-align: center;
      vertical-align: top;
      white-space: normal;
      width: 16.35%; }
      .sidebar-main .block-event .slider-panel .slider .item,
      .sidebar-additional .block-event .slider-panel .slider .item {
        width: 100%; }
      .block-event .slider-panel .slider .item img {
        max-width: 100%; }
      .block-event .slider-panel .slider .item .category-name {
        display: inline-block;
        font-size: 18px;
        padding: 15px 5px 0;
        word-break: break-all; }
      .block-event .slider-panel .slider .item .ticker {
        margin: 0;
        padding: 0;
        list-style: none none; }
        .block-event .slider-panel .slider .item .ticker li {
          display: none;
          margin: 0 5px; }
        .block-event .slider-panel .slider .item .ticker .value {
          font-size: 26px;
          font-weight: 300; }
        .block-event .slider-panel .slider .item .ticker .label {
          display: block;
          font-size: 13px; }
      .block-event .slider-panel .slider .item .dates {
        font-weight: 300; }
        .block-event .slider-panel .slider .item .dates .start,
        .block-event .slider-panel .slider .item .dates .end,
        .block-event .slider-panel .slider .item .dates .date {
          display: block; }
        .block-event .slider-panel .slider .item .dates .start:after {
          font-size: 18px;
          content: '\2013';
          display: block; }
        .block-event .slider-panel .slider .item .dates .date {
          font-size: 18px;
          font-weight: 300; }
        .block-event .slider-panel .slider .item .dates .time {
          font-size: 13px; }
      .block-event .slider-panel .slider .item .box-event .box-content {
        padding: 5px 0 10px; }
      .block-event .slider-panel .slider .item .box-event .box-title {
        display: block;
        font-weight: 300;
        margin: 10px 0 0; }
      .block-event .slider-panel .slider .item .action.show {
        display: block;
        padding: 0; }
        .block-event .slider-panel .slider .item .action.show .category.name {
          display: inline-block;
          padding: 30px 10px 0; }
        .block-event .slider-panel .slider .item .action.show img {
          display: block; }
      .block-event .slider-panel .slider .item .action.event {
        background-image: none;
        background: #1979c3;
        border: 1px solid #1979c3;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-family: "Lexend", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 700;
        margin: 0;
        padding: 7px 15px;
        width: auto;
        font-size: 14px;
        line-height: 16px;
        box-sizing: border-box;
        vertical-align: middle;
        margin-top: 15px; }
        .block-event .slider-panel .slider .item .action.event:focus, .block-event .slider-panel .slider .item .action.event:active {
          background: #006bb4;
          border: 1px solid #1979c3;
          color: #fff; }
        .block-event .slider-panel .slider .item .action.event:hover {
          background: #006bb4;
          border: 1px solid #1979c3;
          color: #fff; }
        .block-event .slider-panel .slider .item .action.event.disabled, .block-event .slider-panel .slider .item .action.event[disabled],
        fieldset[disabled] .block-event .slider-panel .slider .item .action.event {
          opacity: 0.5;
          cursor: default;
          pointer-events: none; }
        .block-event .slider-panel .slider .item .action.event:hover {
          text-decoration: none; }

.block-static-block.widget,
.block-cms-link.widget {
  margin-bottom: 20px; }
  .links .block-static-block.widget, .links
  .block-cms-link.widget {
    margin-bottom: 0; }

.block-cms-link-inline.widget {
  margin: 0; }

.block-wishlist-search .form-wishlist-search {
  margin: 20px 0 0; }

.block-cms-hierarchy-link.widget {
  display: block;
  margin-bottom: 20px; }

.block-cms-hierarchy-link-inline.widget {
  margin: 0; }

html:not([lang="de-DE"]) .hide_non_german {
  display: none; }

body.cms-home.cms-index-index.page-layout-1column .breadcrumbs {
  display: none; }

.quotation-quote-index .page-main {
  padding: 0 15px; }
