.form-container {
    img{
        float: left;
    }

    .field.name.required {
        display: inline-grid;
            width: 50%;
            padding-right: 15px;
        }
    .field.email.required {
        display: inline-grid;
            width: 50%;

    }

    .field.comment.required{
            display: inline-grid;
            width: 100%;
    }

    .fieldset > .field:not(.choice) > .control {
        width: 100%;
        float: left;
    }
    .fieldset > .field:not(.choice) > .label {
        padding: 6px 15px 0 0;
        text-align: left;

    }


    input[type="text"],
    input[type="email"]{
        background: #fff;
        border: 1px solid #D0E5D0;
        border-radius: 1px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        opacity: 0.6;
        height: 40px;
        margin-top: 15px;
    }

    textarea {
        background: #fff;
        border: 1px solid #D0E5D0;
        border-radius: 1px;
        font-size: 14px;
        height: 170px;
        line-height: 1.42857;
        margin: 0;
        padding: 10px;
        vertical-align: baseline;

        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        opacity: 0.6;
        margin-top: 15px;
    }

    .contact-img{
        // max-width: 600px;
        background: $primary__color6;
    }

    .send-btn{
        background: white;
        border: solid $primary__color 3px;
        width: 230px;
        height: 56px;
        float: right;
        &:hover{
            border: solid $primary__color1 3px;
            background: $primary__color1;
            color: white;
        }
    }

    .contact-text{
        margin-bottom: 60px;
    }
}

.form-bg{
    height: 800px;
    width: 100%;
    margin-top: -790px;
    position: absolute;
    background: $primary__color6;
    z-index: -999;
}

@include max-screen(360px) {
    .contact-img{
        max-width: 0px;
        display: none;
    }
    .form-container {
        .field.name.required {
                width: 95%;
                padding-left: 5px;
                padding-right:5px;
            }
        .field.email.required {
                width: 95%;
                padding-left: 5px;
                padding-right:5px;
        }

        .field.comment.required{
                padding-left: 5px;
                padding-right:5px;
                width: 95%;
        }

        .contact-text{
                text-align: center;
                padding: 5px;
                margin-bottom: 20px;
        }

        .send-btn{
            margin-bottom: 20px;
            margin-right:10px;
        }

    }
}

.fieldset > .field > .label, .fieldset > .fields > .field > .label {
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: 400;
    line-height: inherit;
    font-size: inherit;
}
