.block.filter {
    .filter-content {
        .filter-options {
            .filter-options-item {
                .items {
                    .item {
                        input[type="checkbox"] {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1040px) {
    .page-layout-2columns-left:not(.account) .columns .sidebar.sidebar-main {
        width: 100%;
    }

    .block.filter {
        .filter-title {
            @include lib-icon-font(
                $icon-menu,
                $_icon-font-size : 28px,
                $_icon-font-color : #000,
                $_icon-font-color-hover: $header-icons-color-hover,
                $_icon-font-position: after,
                $_icon-font-display: inline-block,
                $_icon-font-margin: 0
            );

            &:after {
                vertical-align: bottom;
                line-height: 1;
            }

            border: 1px solid #000;
            padding: 5px 10px;

            [data-role="title"] {
                display: inline-block;
            }
        }

        .filter-options {
            display: none;
        }

        .backdrop {
            display: none;
        }
    }

    body {
        &.filter-active {
            .page-header {

            }

            .page-wrapper {

            }

            .columns {
                z-index: 999;
            }

            #trustbadge-container-98e3dadd90eb493088abdc5597a70810 {
                z-index: 0 !important;
            }
        }

        .columns {
            .column.main {
                .category-images {
                    margin-top: 0;
                }

                .products.wrapper {
                    margin-top: 0;
                }
            }

            .sidebar.sidebar-main {
                margin-top: 20px !important;
                margin-bottom: -23px;
            }
        }
    }

    .filter {
        margin-bottom: 0 !important;

        .block-title.filter-title {
            margin-bottom: 0;
        }

        .block-content.filter-content {
            .block-subtitle {
                margin-top: 10px;
            }
        }

        &.active {
            position: relative;
            visibility: visible;
            padding-bottom: 82px;
            z-index: 99999;

            .filter-options-item:last-child {
                margin-bottom: 25px;
            }

            .filter-title {
                border: none;
                height: 100vh;
                width: 25%;
                position: fixed;
                right: 0;
                top: 0;
                z-index: 99992;

                strong {
                    box-shadow: none;
                    background: none;
                    border: 0;
                    color: transparent;
                    left: auto;
                    right: 3px;
                    top: 10px;
                    display: inline-block;
                    text-decoration: none;
                }

                @include lib-icon-font(
                        $icon-remove,
                    $_icon-font-size : 28px,
                    $_icon-font-color : #000,
                    $_icon-font-color-hover: $header-icons-color-hover,
                    $_icon-font-position: after,
                    $_icon-font-display: inline-block,
                    $_icon-font-margin: 0
                );

                &:after {
                    position: absolute;
                    right: 85%;
                    top: 5px;
                }
            }

            .block-content.filter-content {
                position: fixed;
                left: 0;
                top: 0;
                width: 80%;
                height: 100%;
                min-height: 100vh;
                overflow: scroll;
                background-color: white;
                z-index: 9991;

                .filter-current,.filter-actions {
                    display: none;
                }

                .filter-subtitle {
                    border-bottom: 1px solid #ccc;
                    background: white;
                    display: block;
                    width: 80%;
                    height: 50px;
                    position: fixed;
                    padding-left: 15px;
                    margin-top: 0;
                    z-index: 99998;

                    .subtitle {
                        display: block;
                        position: relative;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                    }
                }

                .filter-options {
                    width: 100%;
                    background: #fff;
                    display: block;
                    padding: 15px;
                    position: relative;
                    z-index: 99997;

                    &:nth-child(2) {
                        margin-top: 50px;
                    }

                    .filter-options-item {
                        border-bottom: 1px solid $border-color__base;
                        margin: 0 0 25px;
                    }
                }
            }

            .backdrop {
                display: block;
                width: 100vw;
                height: 100vh;
                position: fixed;
                top: 0;
                left: 0;
                background-color: black;
                opacity: 0.4;
                z-index: 9990;
            }
        }
    }
}
