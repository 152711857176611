#notice-cookie-block {
    .content {
        strong {
            display: block;
            margin-bottom: 5px;
        }

        #btn-cookie-allow {
            margin-bottom: 0 !important;
        }
    }
}
