.nav-sections {
    .account-section {
        padding: 0 15px;

        .account-links-wrapper {
            list-style: none;
            font-size: 16px;
            font-weight: 700;
            line-height: 40px;
            padding: 0;
            margin-bottom: 0;

            li {
                border-bottom: 1px solid #f7f7f7;
                font-size: 16px;
                font-weight: 700;
                line-height: 40px;
                padding: 8px 40px 8px 10px;
                margin: 0;
            }

            a {
                color: #000;
            }

            .authorization-link {
                &:before {
                    display: none;
                }
            }
        }
    }

    .quote-section {
        padding: 0 15px;

        .my-quote-link-wrapper {
            list-style: none;
            font-size: 16px;
            font-weight: 700;
            line-height: 40px;
            padding: 0;
            margin: 0;

            li {
                font-size: 16px;
                font-weight: 700;
                line-height: 40px;
                padding: 8px 40px 8px 10px;
                margin-top: 0;
            }

            a {
                color: #000;
            }
        }
    }

    .nav-sections-items {
        .section-item-content {
            .store-switch-section {
                background: #f7f7f7;

                .switcher {
                    .view-current-store-switch,
                    .switcher-option {
                        &:before {
                            content: "";
                            display: inline-block;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            width: 30px;
                            height: 20px;
                            vertical-align: middle;
                        }
                    }

                    .view-default:before {
                        background-image: url("../images/flags/de.svg");
                    }

                    .view-storeview_at:before {
                        background-image: url("../images/flags/at.svg");
                    }

                    .view-storeview_be:before {
                        background-image: url("../images/flags/be.svg");
                    }

                    .view-storeview_ch:before {
                        background-image: url("../images/flags/ch.svg");
                    }

                    .view-storeview_dk:before {
                        background-image: url("../images/flags/dk.svg");
                    }

                    .view-storeview_fr:before {
                        background-image: url("../images/flags/fr.svg");
                    }

                    .view-storeview_gb:before {
                        background-image: url("../images/flags/gb.svg");
                    }

                    .view-storeview_nl:before {
                        background-image: url("../images/flags/nl.svg");
                    }

                    .view-storeview_se:before {
                        background-image: url("../images/flags/se.svg");
                    }
                }

                .language-switch-mobile {
                    padding: 0 15px;

                    .mobile-hint {
                        font-weight: 700;
                        padding: 8px 40px 8px 10px;
                    }

                    .switcher-language {
                        display: block;
                        border-top: 1px solid #f7f7f7;
                        padding: 0;

                        .switcher-options {
                            border: none;
                            padding: 0;

                            .switcher-trigger {
                                padding: 8px 40px 8px 10px;
                                position: relative;

                                .view-current-store-switch {
                                    color: #000;
                                    font-size: 16px;
                                    font-weight: 700;
                                    line-height: 40px;
                                    padding: 0;
                                    position: static;
                                }

                                strong {
                                    @include lib-icon-font(
                                        $_icon-font-content : $icon-down,
                                        $_icon-font-size : 42px,
                                        $_icon-font-position: after,
                                        $_icon-font-display : block
                                    );

                                    &:after {
                                        position: absolute;
                                        right: 0;
                                        width: 40px;
                                        height: 40px;
                                        line-height: 40px;
                                        top: 8px;
                                    }
                                }

                                &.active {
                                    strong {
                                        @include lib-icon-font-symbol(
                                            $_icon-font-content : $icon-up,
                                            $_icon-font-position: after
                                        );
                                    }
                                }
                            }

                            .switcher-option {
                                border: none;
                                padding: 0 10px;
                            }

                            a {
                                display: inline-block;
                                font-size: 16px;
                                padding-left: 10px;
                                line-height: 40px;
                                vertical-align: middle;

                                &:before {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1040px) {
    .account-section,
    .quote-section,
    .store-switch-section {
        display: none;
    }
}
