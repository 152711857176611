//
//  Variables
//  _____________________________________________

$checkout-shipping-address__max-width          : 600px !default;
$checkout-shipping-address__margin-top         : 28px !default;

$checkout-shipping-item__border                : 2px solid transparent !default;
$checkout-shipping-item__line-height           : 30px !default;
$checkout-shipping-item__margin                : 0 0 $indent__base !default;
$checkout-shipping-item__padding               : $indent__base ($indent__l + 5px) $indent__base $indent__base !default;
$checkout-shipping-item__transition            : 0.3s border-color !default;
$checkout-shipping-item__width                 : 33.3333% !default;
$checkout-shipping-item-tablet__width          : 50% !default;
$checkout-shipping-item-mobile__width          : 100% !default;
$checkout-shipping-item__active__border-color  : $color-orange-red1 !default;

$checkout-shipping-item-icon__background-color : $checkout-shipping-item__active__border-color !default;
$checkout-shipping-item-icon__color            : $color-white !default;
$checkout-shipping-item-icon__content          : $icon-checkmark !default;

$checkout-shipping-item-icon__selected__height : 27px !default;
$checkout-shipping-item-icon__selected__width  : 29px !default;

$checkout-shipping-item-mobile__padding        : 0 0 15px !default;
$checkout-shipping-item-mobile__margin         : $checkout-shipping-item-mobile__padding !default;
$checkout-shipping-item-mobile__active__padding: 15px ($indent__l + 5px) 15px 18px !default;

$checkout-shipping-item-before__border-color   : $color-gray80 !default;
$checkout-shipping-item-before__height         : calc(100% - 20px) !default;

$checkout-shipping-method__border              : $checkout-step-title__border !default;
$checkout-shipping-method__padding             : $indent__base !default;

//
//  Common
//  _____________________________________________

.cart-summary {
    #block-shipping {
        .title {
            padding: 15px 30px 15px 5px;
        }

        .content {
            display: block;
        }
    }
}

#checkout-step-shipping_method {
    @include min-screen($screen__m) {
        margin-left: 20%;
    }
}

#calendar-delivery-date-disclaimer {
    padding: 15px;
    font-weight: 600;
    border: 3px solid #ff8d8d;
}

#calendar-delivery-date {
    max-width: 420px;
    margin-left: 20%;

    @include max-screen($screen__s) {
        margin: 10px auto 0 auto;
    }

    td {
        &:not(.ui-datepicker-week-col):not(.ui-datepicker-unselectable):not(.ui-datepicker-header) {
            a {
                color: $primary__color;
                font-weight: 600;
            }

            &.ui-state-active {
                background: none;

                a {
                    background-color: rgba($primary__color, .5);
                    color: #000;
                }
            }
        }
    }

    tr {
        &:hover {
            td:not(.ui-datepicker-unselectable):not(.ui-datepicker-week-col) {
                a {
                    background-color: rgba($primary__color, .5);
                    color: #000;
                }
            }
        }
    }
}

.fieldset.hidden-fields {
    .actions-toolbar {
        margin-top: 30px;
    }
}

#shipping-new-address-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 20px;
}

.opc-wrapper .form-login {
    background: none !important;
    padding: 0 !important;
    border-bottom: none;

    .field-tooltip {
        display: none !important;
    }

    .note {
        display: none !important;
    }

    .field {
        margin-bottom: -20px;
    }
}

.opc-block-summary {
    border: solid #f8f8f8 1px;
    background: #f9f9f9;
    padding: 22px 30px;
    margin: 0 0 20px;
    width: 100%;
}

.opc-wrapper {

    //
    //  Shipping Address
    //  _____________________________________________

    .form-login,
    .form-shipping-address {
        margin-top: $checkout-shipping-address__margin-top;
        margin-bottom: $indent__base;

        .fieldset {
            .note {
                font-size: $font-size__base;
                margin-top: $indent__s;
            }
        }
    }

    .form-shipping-address {
        .street {
            .control {
                .label {
                    display: block !important;
                    clip: unset !important;
                    height: unset !important;
                    margin: unset !important;
                    overflow: unset!important ;
                    padding: unset !important ;
                    position: unset !important ;
                    width: unset !important;
                }
            }
        }
    }

    .shipping-address-items {
        font-size: 0;
    }


    .experius-address-line-one {
        input {
            &::placeholder {
                color: #dddddd;
            }
        }
    }

    .form-shipping-address,
    .billing-address-form {
        .field {
            .field-error {
                margin-top: 3px;
                font-size: 12px;
            }
        }
    }

    .form-shipping-address {
        .street {
            .control {
                .label {
                    display: block !important;
                    clip: unset !important;
                    height: unset !important;
                    margin: unset !important;
                    overflow: unset!important ;
                    padding: unset !important ;
                    position: unset !important ;
                    width: unset !important;
                }
            }
        }
    }


    .shipping-address-item {
        @extend .abs-add-box-sizing;
        border: $checkout-shipping-item__border;
        line-height: $checkout-shipping-item__line-height;
        margin: $checkout-shipping-item__margin;
        padding: $checkout-shipping-item__padding;
        transition: $checkout-shipping-item__transition;
        width: $checkout-shipping-item-tablet__width;
        display: inline-block;
        font-size: $font-size__base;
        position: relative;
        vertical-align: top;
        word-wrap: break-word;

        &.selected-item {
            border-color: $checkout-shipping-item__active__border-color;

            &:after {
                background: $checkout-shipping-item-icon__background-color;
                color: $checkout-shipping-item-icon__color;
                content: $checkout-shipping-item-icon__content;
                font-family: $icons__font-name;
                height: $checkout-shipping-item-icon__selected__height;
                width: $checkout-shipping-item-icon__selected__width;
                font-size: 27px;
                line-height: 21px;
                padding-top: 2px;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
            }

            .action-select-shipping-item {
                @extend .abs-no-display-s;
                visibility: hidden;
            }
        }
    }

    .field {
        &.addresses {
            @extend .abs-add-clearfix;
        }
    }

    .action-show-popup {
        margin: 0 0 $indent__base;

        > span {
            &:before {
                content: '+';
                padding-right: $indent__xs;
            }
        }
    }

    .action-select-shipping-item {
        float: right;
        margin: $indent__base 0 0;
    }

    .edit-address-link {
        @extend .abs-action-button-as-link;
        display: block;
        float: left;
        margin: 26px 5px 0 0;
    }
}

//
//  Shipping Methods
//  _____________________________________________

.checkout-shipping-method {
    .step-title {
        margin-bottom: 5px;
    }

    .field {
        label {
            font-weight: bold;
        }
    }

    .no-quotes-block {
        margin: $indent__base 0;
    }
}

.methods-shipping {
    .actions-toolbar {
        .action {
            &.primary {
                @extend .abs-button-l;
                margin: $indent__base 0 0;
            }
        }
    }
}

.table-checkout-shipping-method {
    thead {
        th {
            display: none;
        }
    }

    tbody {
        td {
            border-top: $checkout-shipping-method__border;
            padding-bottom: $checkout-shipping-method__padding;
            padding-top: $checkout-shipping-method__padding;

            &:first-child {
                padding-left: 0;
                padding-right: 0;
                width: 20px;
            }
        }

        tr {
            &:first-child {
                td {
                    border-top: none;
                }
            }
        }
        .row-error {
            td {
                border-top: none;
                padding-bottom: $indent__s;
                padding-top: 0;
            }
        }
    }

    .col-price {
        width: 35px;
    }

    .col-method ~ .col-method {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .form-login {
            border-bottom: 1px solid $checkout-shipping-item-before__border-color;
            margin: $checkout-shipping-item-mobile__padding;
            padding: $checkout-shipping-item-mobile__padding;
        }

        .shipping-address-item {
            border-bottom: 1px solid $checkout-shipping-item-before__border-color;
            margin: $checkout-shipping-item-mobile__margin;
            padding: $checkout-shipping-item-mobile__padding;
            width: 100%;

            &.selected-item {
                padding: $checkout-shipping-item-mobile__active__padding;
                border-bottom-width: 2px;

                .edit-address-link {
                    right: $checkout-shipping-item-icon__selected__width + $indent__s;
                }
            }
        }

        .form-login,
        .form-shipping-address {
            margin-top: $checkout-shipping-address__margin-top;
        }

        .action-select-shipping-item {
            float: none;
            margin-top: $indent__s;
            width: 100%;
        }

        .action-show-popup {
            width: 100%;
        }

        .edit-address-link {
            @include lib-icon-font(
                $icon-settings,
                $_icon-font-size        : 28px,
                $_icon-font-line-height : 28px,
                $_icon-font-text-hide   : true,
                $_icon-font-color       : $color-gray19,
                $_icon-font-color-hover : $color-gray19,
                $_icon-font-color-active: $color-gray19
            );
            margin: 0;
            position: absolute;
            right: 0;
            top: 1px;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-shipping-method {
        .actions-toolbar {
            > .primary {
                float: right;
            }

            .action {
                &.primary {
                    margin: 0;
                }
            }
        }
    }

    .opc-wrapper {
        .form-login,
        .form-shipping-address {
            max-width: 100%;
        }

    }
    .table-checkout-shipping-method {
        width: auto;
    }
}

@include min-screen($screen__l) {
    .opc-wrapper {
        .shipping-address-item {
            width: $checkout-shipping-item__width;

            &:before {
                background: $checkout-shipping-item-before__border-color;
                height: $checkout-shipping-item-before__height;
                content: '';
                left: 0;
                position: absolute;
                top: 0;
                width: 1px;
            }

            &:nth-child(3n + 1) {
                &:before {
                    display: none;
                }
            }

            &.selected-item {
                &:before {
                    display: none;
                }

                + .shipping-address-item {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
    .table-checkout-shipping-method {
        min-width: 500px;
    }
}
