$paymentIconsDir: "../images/payment-icons/";
$paymentIconSizeFooter: .7;

.footer-payment {
    max-width:  1445px;
    padding: 0 8px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;

    .footer-payment-inner {
        padding: 25px 0;
        width: 100%;
        float: right;

        > ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            width: 100%;
            justify-content: center;

            > li {
                img {
                    height: 26px;
                    max-width: none;
                    width: auto;
                }
            }

            @media only screen and (max-width: 560px) {
                flex-wrap: wrap;
                justify-content: center;

                > li {
                    margin: auto 10px;
                    padding: 25px 0;

                    img {
                        height: 24px;
                    }
                }
            }
        }

        @media only screen and (max-width: 980px) {
            margin: auto;
            width: 80%;
            float: none;
        }

        @media only screen and (max-width: 750px) {
            width: 95%;
        }
    }
}

.payment-list {
    display: inline-block;
    padding-right: 0;
    vertical-align: middle;
}

.payment-icon {
    display: inline-block;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
    margin: auto 25px;

    a {
        display: block;
        width: 100%;
        height: 100%;
    }

    &:last-child {
        margin-right: 0 !important;
    }

    &.ideal {
        height: calc(45px * #{$paymentIconSizeFooter});
        width: calc(50px * #{$paymentIconSizeFooter});
        background-image: url(#{$paymentIconsDir}ideal.svg);
    }

    &.ideal-qr {
        height: calc(46px * #{$paymentIconSizeFooter});
        width: calc(50px * #{$paymentIconSizeFooter});
        background-image: url(#{$paymentIconsDir}ideal-qr-logo.svg);
    }

    &.mistercash {
        height: calc(40px * #{$paymentIconSizeFooter});
        width: calc(65px * #{$paymentIconSizeFooter});
        background-image: url(#{$paymentIconsDir}mistercash.svg);
    }

    &.bancontact {
        height: calc(60px * #{$paymentIconSizeFooter});
        width: calc(85px * #{$paymentIconSizeFooter});
        background-image: url(#{$paymentIconsDir}bancontact.svg);
    }

    &.visa {
        height: calc(29px * #{$paymentIconSizeFooter});
        width: calc(90px * #{$paymentIconSizeFooter});
        background-image: url(#{$paymentIconsDir}visa.svg);
    }

    &.mastercard {
        height: calc(39px * #{$paymentIconSizeFooter});
        width: calc(50px * #{$paymentIconSizeFooter});
        background-image: url(#{$paymentIconsDir}mastercard.svg);
    }

    &.creditcard {
        height: calc(39px * #{$paymentIconSizeFooter});
        width: calc(50px * #{$paymentIconSizeFooter});
        background-image: url(#{$paymentIconsDir}creditcard.svg);
    }

    &.eps {
        height: calc(48px * #{$paymentIconSizeFooter});
        width: calc(90px * #{$paymentIconSizeFooter});
        background-image: url(#{$paymentIconsDir}eps.svg);
    }

    &.afterpay {
        height: calc(33px * #{$paymentIconSizeFooter});
        width: calc(125px * #{$paymentIconSizeFooter});
        background-image: url(#{$paymentIconsDir}afterpay.svg);
    }

    &.klarna {
        height: calc(27px * #{$paymentIconSizeFooter});
        width: calc(92px * #{$paymentIconSizeFooter});
        background-image: url(#{$paymentIconsDir}klarna.svg);
    }

    &.trustly {
        height: calc(44px * #{$paymentIconSizeFooter});
        width: calc(169px * #{$paymentIconSizeFooter});
        background-image: url(#{$paymentIconsDir}trustly.svg);
    }

    &.sofort {
        height: calc(39px * #{$paymentIconSizeFooter});
        width: calc(116px * #{$paymentIconSizeFooter});
        background-image: url(#{$paymentIconsDir}sofort-uberweisung.svg);
    }

    &.banktransfer {
        height: calc(39px * #{$paymentIconSizeFooter});
        width: calc(69px * #{$paymentIconSizeFooter});
        background-image: url(#{$paymentIconsDir}banktransfer.svg);
    }

    &.giropay {
        height: calc(40px * #{$paymentIconSizeFooter});
        width: calc(98px * #{$paymentIconSizeFooter});
        background-image: url(#{$paymentIconsDir}giropay.svg);
    }

    &.paypal {
        height: calc(40px * #{$paymentIconSizeFooter});
        width: calc(137px * #{$paymentIconSizeFooter});
        background-image: url(#{$paymentIconsDir}paypal.svg);
    }
}
