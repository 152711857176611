$slider-handle-color: #0e7633;
$slider-handle-hover-color: #e17e17;
$slider-color: #dadada;
$slider-range-color: #b6b6b6;

.block.filter {
    &.filter-no-options {
        display: none;
    }

    .filter-current {
        .block-subtitle {
            font-weight: 400;
        }
    }
}

.catalog-product-view {
    .availability.only {
        color: $primary__color9;
        display: block;
        font-weight: 700;
        padding: 10px 0;
    }

    &.page-product-grouped {
        .availability.only {
            clear: both;
            float: right;
            padding: 0 10px;
        }
    }

    .action-label {
        clear: both;
        color: $primary__color5;
        display: inline-block;
        float: right;
        font-weight: 600;
        line-height: 20px;
        padding: 10px;
        user-select: none;
    }
}

.filter-options {
    .filter-options-item {
        .filter-options-title {
            font-size: 16px;
        }

        .items {
            .item {
                input[type="checkbox"] {
                    display: none;
                }

                a {
                    text-decoration: none;

                    label {
                        color: #333;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.product.attribute.overview {
    h2 {
        display: none;
    }
}
.grouped-item-container .actionbar .control.qty .action.primary.toquote {
    padding: 0 !important;
}

.catalog-product-view {
    .product-info-main {
        .item {
            .btn-wrapper {
                .action.primary.toquote {
                    width: 100%;
                }
            }
        }
    }
}

.action.primary.toquote {
    background: #EB8500;
    float: none;
    top: 0;
    right: 0;
    font-size: 16px;
    line-height: 70px;
    height: 100%;
}

#mobile-click-open {
    @media only screen and (max-width: 768px) {
        text-align: center;
        cursor: pointer;

        &:after {
            content: "\e900";
            font-family: 'icomoon';
            display: block;
            float: right;
            margin-right: 10px;
        }

        &.open:after {
            transform: rotate(180deg);
        }
    }
}

.mobile-height-container {
    overflow: hidden;

    @media only screen and (max-width: 768px) {
        height: 0;
        transition: height 0.3s;
    }

    @media only screen and (min-width: 769px) {
        height: auto !important;
    }
}

.product-info-main .price-box {
    margin-bottom: 10px;
}


@media only screen and (max-width: 768px) {
    .UPS-text,
    .trusted {
        text-align: center;
    }

    .callout-block {
        width: 320px;
        position: relative;
        left: 50%;
        margin-left: -160px;
    }

    .catalog-product-view {
        &.page-product-grouped {
            .availability.only {
                clear: both;
                float: left;
                padding: 0;
            }

            .delivery-available {
                clear: both;
            }

            .action-label {
                clear: both;
                float: left;
                padding: 0 0 20px 0;
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: 340px) {
    .callout-block {
        width: 100%;
        left: 0;
        margin-left: 0;
    }
}


/*op homepage*/
.cms-index-index {
    .column.main {
        .product-item {
            .product-item-details {
                .price-box {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-end;
                    gap: 10px;

                    .special-price {
                        .price-container {
                            span.price {
                                color: $primary__color;
                            }
                        }
                    }

                    .old-price {
                        .price-label {
                            display: none;
                        }

                        .price {
                            text-decoration: line-through;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}

.product-item-photo2 {
    background: #fcfcfc;
    padding-top: 55px;
    padding-bottom: 45px;
    text-align: left;
    display: block;
}

.product-item-photo {
    text-align: center;
    display: block;
    height: auto;
}

.item.product.product-item {
    .product-item-photo {
        background: #fcfcfc;
        text-align: center;
        display: block;
        height: auto;
        width: 100%;
        height: 0;
        position: relative;
        overflow: hidden;
        padding-top: 0;
        padding-bottom: 100%;

        .height-reset {
            position: absolute;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                max-width: 100%;
                height: auto;
                left: 50%;
                top: 50%;
                position: relative;
                display: block;
                transform: translate(-50%, -50%);
            }

        }

        img {
            width: auto;
            height: auto;
        }

    }
}

.value h2 {
    text-align: left !important;
    margin-top: 0px !important;

    @media only screen and (max-width: 768px) {
        text-align: center !important;
    }
}

//tooltip
.tooltip {
    display: inline;
    position: relative;
}

.tooltip:hover:after {
    background: #333;
    background: rgba(0, 0, 0, .8);
    border-radius: 5px;
    bottom: 30px;
    color: #fff;
    content: attr(title);
    left: 3%;
    padding: 5px 15px;
    position: absolute;
    z-index: 98;
    width: 220px;
}

.tooltip:hover:before {
    border: solid;
    border-color: #333 transparent;
    border-width: 6px 6px 0 6px;
    bottom: 26px;
    content: "";
    left: 5%;
    position: absolute;
    z-index: 99;
}

a.tooltip {
    color: $primary__color1;
    text-decoration: none;
}

.star {
    font-size: 21px;
}

.versandkosten {
    font-size: 13px;
    line-height: 24px;
    // float: left;
    margin-left: 15px;
    margin-bottom: 5px;
}

.input-checkbox.input-zigzag:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

//
//  Category view
//  ---------------------------------------------

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &:before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                font-size: 11px;
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.product.name a {
    @extend .abs-product-link;
}

.category {
    &-view {
        overflow: hidden;
        display: flex;
        position: relative;

        @media only screen and (max-width: 890px) {
            display: block;
        }

    }

    &-image,
    &-description-container {
        background: $primary__color2;
        color: white;
        width: 50%;
        padding: 0;
        box-sizing: border-box;

        @media only screen and (max-width: 890px) {
            width: 100%;
        }

        img {
            width: 100%;
        }

        a, .alink {
            color: white !important;
            text-decoration: underline;
            display: inline;

            &:hover {
                color: $primary__color !important;
            }
        }

        h2 {
            text-align: left;
            font-size: 25px !important;
            margin-bottom: 33px;
            font-weight: 600;
        }

        h3 {
            color: inherit;
            font-family: inherit;
            font-style: inherit;
            font-weight: 600;
            line-height: 1.1;
            font-size: 18px;
            margin-top: 59px;
            margin-bottom: 7px;
        }
    }

    &-image {
        .image {
            display: block;
            height: auto;
            max-width: 100%;
            max-height: 259px;
            overflow: hidden;
        }

        @media only screen and (max-width: 1250px) {
            .image {
                max-width: none;
                width: 150%;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            overflow: hidden;
        }

        @media only screen and (max-width: 890px) {
            .image {
                max-width: 100%;
                width: 100%;
                position: relative;
                top: 0;
                left: 0;
                transform: none;
            }

            overflow: hidden;
        }
    }

    &-description-container {
        padding: 20px;

        h2 {
            margin-top: 15px !important;
        }

        @media only screen and (max-width: 890px) {
            box-sizing: border-box;

            .category-short-description {
                * {
                    text-align: center;
                }
            }
        }
    }
}

//
//  Product images general container
//  ---------------------------------------------

.product-image {
    &-container {
        display: inline-block;
        max-width: 100%;
        background-color: $primary__color11;
    }

    &-wrapper {
        display: block;
        height: 0;
        overflow: hidden;
        position: relative;
        z-index: 1;
    }

    &-photo {
        bottom: 0;
        display: inline-block;
        height: auto;
        left: 0;
        margin: auto;
        max-width: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }
}

//
//  Product view
//  ---------------------------------------------

.product.media {
    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        color: $text__color__muted;
        font-size: $font-size__s;
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

.product.info.detailed {
    clear: both;
    margin-bottom: 30px;

    .additional-attributes {
        width: auto;
        @include lib-table-resize(
            $_th-padding-left: 0,
            $_th-padding-right: $indent__l,
            $_th-padding-bottom: $indent__s,
            $_td-padding-bottom: $indent__s
        );
    }
}

.product-info-main {

    .product.pricing {
        display: block;
        clear: both;
        margin-top: 90px;
    }

    .page-title-wrapper {
        .page-title {
            line-height: $line-height__base;
            margin-bottom: $indent__s;

            @media only screen and (min-width: 769px) {
                margin-top: 50px !important;
            }
        }
    }

    .stock {
        &.available {
            display: inline-block;
            font-size: 15px;
            vertical-align: top;
            float: right;

            @media only screen and (max-width: 768px) {
                float: none;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &.unavailable {
            display: inline-block;
            font-size: 15px;
            vertical-align: top;
            color: $primary__color9;
            float: right;


        }

        &.available::before {
            font-size: 30px;
            display: inline-block;
            color: $primary__color;
            line-height: 20px;
            text-align: center;
            cursor: pointer;
            content: "•";
            float: left;
            padding-right: 5px;
        }
    }

    .product {
        &.attribute {
            &.sku {
                display: inline-block;
                vertical-align: top;
                color: $text__color__muted;

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }

            &.overview {
                margin: $indent__base 0;

                @media only screen and (max-width: 768px) {
                    text-align: center;
                }
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .price-box {
        margin-top: $indent__s;

        .special-price,
        .old-price {
            .price-label {
                display: none;
            }
        }
    }

    .product-reviews-summary .reviews-actions {
        font-size: $font-size__base;
    }
}

.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            font-weight: $font-weight__bold;
            margin: 0 0 $indent__xs;
            font-size: 14px;
            border: none;
            display: inline-block;
            padding: 0;
        }

        &.required,
        &._required {
            .legend {
                &:after {
                    content: '*';
                    @include lib-typography(
                        $_font-size: $form-field-label-asterisk__font-size,
                        $_color: $form-field-label-asterisk__color,
                        $_font-family: $form-field-label-asterisk__font-family,
                        $_font-weight: $form-field-label-asterisk__font-weight,
                        $_line-height: $form-field-label-asterisk__line-height,
                        $_font-style: $form-field-label-asterisk__font-style
                    );
                    margin: $form-field-label-asterisk__margin;
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-info-main,
.product-options-bottom {
    .price-box {
        .price-including-tax + .price-excluding-tax,
        .weee + .price-excluding-tax,
        .weee {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: $indent__xs;

            .price {
                font-size: 12px;
                font-weight: $font-weight__bold;
            }
        }

        .price-wrapper .price {
            font-size: 18px;
            font-weight: $font-weight__bold;
        }

        .price {
            white-space: nowrap;
        }
    }

    .special-price {
        display: block;
        margin: $indent__s 0;

        .price-container {
            font-size: 14px;
        }

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .box-tocart {
        margin: $indent__base 0;

        .field.qty {
            padding-right: 0.75 * $indent__base;
        }

        .input-text.qty {
            $tocart-input-size: $button__line-height__l + 28px;
            height: $tocart-input-size + 2px;
            text-align: center;
            width: $tocart-input-size + 2px;
        }

        .actions {
            text-align: center;
        }

        .action.tocart {
            @extend .abs-button-l;
        }
    }

    .product-addto-links {
        margin: $indent__base 0;
    }

    .action.tocompare {
        @extend .abs-action-addto-product;
        vertical-align: top;
    }
}

.prices-tier {
    @extend .abs-reset-list;
    background: $sidebar__background-color;
    margin: $indent__s 0;
    padding: $indent__s (0.75 * $indent__base);

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;

        .price {
            font-size: 14px;
            font-weight: $font-weight__bold;
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

//.block.related {
//    .action.select {
//        margin: 0 $indent__xs;
//    }
//}

//
//  Grouped product page
//  ---------------------------------------------

.page-product-grouped {
    &.page-layout-1column {
        .columns {
            @media only screen and (min-width: 769px) {
                display: flex;
                justify-content: center;
            }
            width: 100%;
            padding: 0 8px;
            box-sizing: border-box;
        }

        .table-wrapper.grouped {
            .stock {
                &.unavailable {
                    color: $primary__color9;
                    font-weight: bold;

                    @media only screen and (max-width: 768px) {
                        text-align: center;
                        padding-bottom: 10px;
                    }
                    @media only screen and (min-width: 769px) {
                        float: right;
                        padding-right: 10px;
                    }
                }
            }
        }

        .UPS-text,
        .trusted,
        .callout-block {
            width: 100%;
        }
    }
}


//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            margin-bottom: $indent__base;
            position: relative;

            &-info {
                position: relative;
                width: auto;

                .product-item-photo {
                    left: 0;
                    position: absolute;
                    top: 0;
                }
            }

            &-name {
                margin-top: 0;
            }

            &-details {
                margin: 0 0 0 85px;
            }

            &-actions {
                display: block;
                margin-top: $indent__s;
            }
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            color: $primary__color__lighter;
            font-size: 12px;
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

.product-item-name {
    @media (max-width: $screen__s) {
        font-size: 16px;
        height: 46px;
    }
    @media (max-width: $screen__m) {
        font-size: 16px !important;
    }

    h3 {
        font-size: 25px;
    }
}

@include max-screen(1300px) {
    #product-attribute-specs-table {
        font-size: 13px;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .tier_prices {
        display: inline-block;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .catalog-product-view {
        .column.main {
            display: flex;
            flex-direction: column;
        }

        .product.media {
            order: -1;
        }
    }

    .product-info-main .box-tocart {
        .actions {
            .action.tocart {
                @extend .abs-button-responsive-smaller;
            }
        }
    }

    //.block.related {
    //    .action.select {
    //        display: block;
    //        margin: $indent__xs 0;
    //    }
    //}

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product-info-main,
    .product-options-bottom {
        .box-tocart {
            display: table;

            .field.qty {
                display: table-cell;
            }

            .actions {
                display: table-cell;
                text-align: center;
                vertical-align: bottom;
            }
        }
    }

    .product-info-main {
        .page-title-wrapper {
            .page-title {
                margin-top: -13px;
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item {
                &-info {
                    .product-item-photo {
                        float: left;
                        left: auto;
                        margin: 0 $indent__s $indent__s 0;
                        position: relative;
                        top: auto;
                    }
                }

                &-details {
                    margin: 0;
                }

                &-actions {
                    clear: left;
                }
            }
        }
    }

    .product-add-form {
        @extend .abs-revert-field-type-desktop;
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item {
                &-info {
                    .product-item-photo {
                        position: absolute;
                        top: 0;
                        left: 0;
                        margin: 0;
                    }
                }

                &-details {
                    margin-left: 85px;
                }
            }
        }
    }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m + 1) {
    .product-info-main {
        float: right;
    }

    .product.media {
        float: left;
        margin-bottom: $indent__m;
    }

    .page-layout-1column {
        .product-info-main {
            width: calc(100% - 400px);
            padding-left: 30px;
        }

        .product.media {
            @media only screen and (min-width: 769px) {
                width: 400px;
            }
            @media only screen and (max-width: 768px) {
                width: 100%;
            }
        }
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .product-info-main {
            width: 47%;
        }

        .product.media {
            width: 50%;
        }
    }

    //
    //  Grouped product page
    //  _____________________________________________

    .page-product-grouped {
        &.page-layout-1column {
            .product-info-main {
                .page-title-wrapper {
                    .page-title {
                        margin-top: -13px !important;
                    }
                }

                .product.attribute.overview {
                    margin: 0;
                }
            }

            .table-wrapper.grouped {
                width: calc(100% - 400px);
                padding-left: 30px;
                float: right;
                min-height: 1500px;
            }

            .store-information {
                float: left;
                width: 400px;

                &.fixed {
                    position: fixed;
                }
            }
        }
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 15px 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;

    .cell.label.remove,
    .cell.label.product {
        span {
            @extend .abs-visually-hidden;
        }
    }

    .cell.label,
    td:last-child {
        border-right: $table__border-width $table__border-style $table__border-color;
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label {
            border-bottom: $table__border-width $table__border-style $table__border-color;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }

        &.attribute {
            font-size: 13px;

            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product-addto-links {
        margin-top: 15px;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .cell.remove {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;

        .action.delete {
            @extend .abs-remove-button-for-blocks;
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    background: $page__background-color;
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }

        &.compare {
            @extend .abs-revert-secondary-color;
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}


.page-layout-2columns-left:not(.account) {
    .columns {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 8px;
        box-sizing: border-box;

        .column.main {
            order: 2;
            width: 75%;
            margin: 0 auto;
        }

        .sidebar.sidebar-additional,
        .sidebar.sidebar-main {
            order: 1;
            width: 25%;
            margin-top: 59px;
        }

        @media only screen and (max-width: 1250px) {
            flex-wrap: wrap;

            .category-images {
                padding-left: 0;
            }

            .column.main {
                width: 100%;
            }
        }
    }
}

.page-layout-2columns-left.account {
    .page-main {
        box-sizing: border-box;
        padding: 0;
    }

    .columns {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 8px;
        box-sizing: border-box;
        flex-wrap: wrap;

        .column.main {
            order: 2;
            width: 74%;

            @include max-screen(768px) {
                width: 100%;
            }
        }

        .sidebar.sidebar-additional {
            display: none;
        }

        .sidebar.sidebar-main {
            order: 1;
            width: 24%;

            @include max-screen(768px) {
                width: 100%;
            }

            .account-nav {
                margin-top: 40px;
            }
        }

    }
}

.category-images {
    width: 100%;
    overflow: hidden;
    list-style: none;
    padding: 0;
    margin-top: 30px;

    .sub-category-image {
        width: 31.5%;
        padding-bottom: 20.7%;
        position: relative;
        margin-bottom: 2.75%;
        float: left;
        overflow: hidden;
        margin-top: 0;

        &:nth-child(3n+2) {
            margin-left: 2.75%;
            margin-right: 2.75%;
        }

        @media only screen and (max-width: 768px) {
            width: 48%;
            margin-bottom: 2%;
            padding-bottom: 32%;
            margin-right: 1%;
            margin-left: 1%;

            &:nth-child(3n+2) {
                margin-left: 1%;
                margin-right: 1%;
            }

            &:nth-child(2n+2) {
                margin-left: 2%;
                margin-right: 1%;
            }

            &:nth-child(2n+1) {
                margin-left: 1%;
                margin-right: 0;
            }
        }

        @media only screen and (max-width: 420px) {
            width: 100%;
            margin-bottom: 2%;
            padding-bottom: 66%;

            &:nth-child(3n+2) {
                margin-left: 0;
                margin-right: 0;
            }

            &:nth-child(2n+2) {
                margin-left: 0;
                margin-right: 0;
            }

            &:nth-child(2n+1) {
                margin-left: 0;
                margin-right: 0;
            }
        }

        > a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
            }
        }

        &.empty {
            background: lighten(#000, 90);
            min-height: 4px;

            .content-container {
                color: #333;
            }
        }

        img {
            display: block;
        }

        &:hover {
            img {
                filter: brightness(1.5);
                transition: filter 1s;
            }
        }

        .content-container {
            position: absolute;
            left: 0;
            top: 50%;
            width: 90%;
            margin-left: 5%;
            color: #fff;
            transform: translateY(-50%);

            .category-name {
                font-weight: 600;
                position: relative;
                display: block;
                text-align: center;
                font-size: 22px;
                text-shadow: -1px -1px 8px rgba(0, 0, 0, 1);
            }

            .category-name-sub {
                font-weight: 600;
                position: relative !important;
                display: block;
                text-align: center;
                font-size: 15px;
                text-shadow: -1px -1px 8px black;
                font-style: italic;
                color: #fffc;
            }

            .category-fromprice {
                font-weight: 600;
                position: relative;
                display: block;
                text-align: center;
                font-size: 16px;
                text-shadow: -1px -1px 8px rgba(0, 0, 0, 1);

                .price-box {
                    display: inline-block;

                    .minimal-price {
                        .price-label {
                            display: none;
                        }
                    }

                    .special-price {
                        .price-label {
                            display: none;
                        }

                        .price-container {
                            span.price {
                                color: $primary__color;
                            }
                        }
                    }

                    .old-price {
                        .price-label {
                            display: none;
                        }

                        .price {
                            text-decoration: line-through;
                        }
                    }
                }
            }
        }
    }
}

.price-box.starting-at {
    float: right;
    padding: 15px 30px;
    width: calc(100% - 400px);

    @media only screen and (max-width: 768px) {
        float: none;
        margin: 0 auto;
        text-align: center;
        width: 100%;
    }

    .price-label {
        font-size: 25px;
    }

    .price {
        font-size: 25px;
        font-weight: 600;
    }
}
