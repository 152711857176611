.cms-over-ons {
    .column.main {
        padding-left: 8px;
        padding-right: 8px;
    }
}
// 2 foto's met groene kader achtergrond
.img-green-border1 img {
    position: relative;
    margin-left: 180px;
    z-index: -5;
}

.img-green-border2 img {
    margin-top: -220px;
    z-index: 10;
}

.green-border {
    border: 10px solid #2FDF66;
    min-height: 366px;
    min-width: 268px;
    position: absolute;
    z-index: -8;
    margin-left: 49px;
    margin-top: 124px;
}
//tekstkader naast de foto's
.kader {
    float: left;
    margin-right: 50px;
    margin-bottom: 100px;
}

.text-kader {
    padding-top: 40px;
    h2 {

    }
}

.div-center {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 115px;

    ul {
        margin-top: 5px;
    }
}

.medewerkers {
    padding: 0 8px;
    box-sizing: border-box;
    max-width: 1445px;
    margin: 0 auto;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
            display: block;
            margin: 1% 0 0 0 ;
            color: $primary__color;
            width: 24.25%;
            max-width: 257px;



            &:nth-child(2) {
                margin-left: 1%;
            }

            &:nth-child(3) {
                margin-right: 1%;
                margin-left: 1%;
            }

            @include max-screen(580px) {
                width: 48.5%;

                &:nth-child(3) {
                    margin-right: 0;
                    margin-left: 0;
                }

                &:nth-child(2) {
                    margin-left: 0;
                }

                &:nth-child(2n) {
                    margin-right: 0;
                    margin-left: 1%;
                }
                &:nth-child(2n + 1) {
                    margin-right: 1%;
                    margin-left: 1%;
                }
            }

            @include max-screen(400px) {
                width: 100%;

                &:nth-child(3),
                &:nth-child(2),
                &:nth-child(2n),
                &:nth-child(2n + 1) {
                    margin-right: auto;
                    margin-left: auto;
                }
            }

            a {
                width: 100%;
                display: block;
                background-color: #F1F8F1;
                border-radius: 50%;
                overflow: hidden;
                height: 0;
                padding-bottom: 100%;

                img {
                    display: block;
                    filter: brightness(100%);
                    transition: filter 0.2s ease;

                    :hover {
                        opacity: 0.5;
                        filter: brightness(0%);
                    }
                }
            }

            p {
                font-weight: 100;
                font-size: 20px;
                margin-bottom: 0;
            }

            span {
                color: #95989A;
                font-weight: bold;
                font-size: 20px;
            }
        }
    }
}

.center-div {
    background-color: #f1f8f1;
}

.center-div-white {
    background-color: white;
    height: 400px;
    position: inherit;
    margin-top: 50px;
    margin-bottom: 180px;
}
/*header image van reviews*/

.review-img {
    margin: 0 auto;
    text-align: center;

    p {
        font-size: 33px;
        color: white;
        padding-top: 150px;
        font-weight: bolder;
        margin:0px;
    }

    span {
        font-size: 14px;
        color: white;
        font-weight: bold;
    }
}

.center-div-bg {
    background: no-repeat Url("../images/Over-ons/header.png") center;
    background-size: cover;
    height: 390px;
}
/*USPs op rij in groene kader */

.USP-bar {
    text-align: center;
    color: white;
    height: 100%;
    max-width: 1445px;
    margin: 0 auto;

    ul {
        display: flex;
        padding: 0;
        margin: 0;

        @include max-screen(940px) {
            flex-wrap: wrap;
        }

        li {
            list-style-type: none;
            display: block;
            width: 25%;
            box-sizing: border-box;
            background-color: #F1F8F1;
            margin-bottom: 50px;
            margin-top: 0;
            color: $primary__color3;
            font-weight: normal;
            padding: 10px;

            @include max-screen(940px) {
                width: 50%;
                margin-bottom: 20px;
                margin-top: 0;
            }

            @include max-screen(420px) {
                width: 100%;
            }

            span[class*='icon-'] {
                display: inline-block;

            }

            b {
                margin-left: 0;
                display: block;
            }
        }
    }


}


@media (max-width: $screen__s) {

    .text-kader {
        text-align: center;
    }

    .kader {
        margin-bottom: 50px;
    }

    .green-border {
        border: 6px solid #2FDF66;
        min-height: 218px ;
        min-width: 176px ;
        z-index: -8;
        margin-left: 24px ;
        margin-top: 33px ;
    }

    .img-green-border1 img {
        width: 180px ;
        margin-left: 125px;
    }

    .img-green-border2 img {
        width: 180px ;
        margin-left: 15px;
    }

}
