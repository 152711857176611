.gallery-placeholder {
    height: 0;
    padding-bottom: calc(100% + 94px);
    line-height: 0;

    .loading-mask {
        position: static;
        padding: 0 0 50%;
    }

    .loader img {
        position: absolute;
    }
}
