.checkout-agreements-block {
    margin-bottom: $indent__base;
    padding-left: 8px;

    .action-show {
        @extend .abs-action-button-as-link;
        vertical-align: baseline;
    }

    .field.choice {
        &:before {
            width: unset;
        }

        input {
            height: unset;

            @include max-screen($screen__s) {
                float: left;
            }
        }

        label {
            @include max-screen($screen__s) {
                float: left;
                width: 90%;
            }
        }
    }
}

//  Checkout Agreements in popup
.checkout-agreements-items {
    @extend .abs-reset-list;
    padding-bottom: $indent__l;

    .checkout-agreements-item {
        margin-bottom: $indent__base;
    }

    .checkout-agreements-item-title {
        @extend .abs-checkout-title;
        border-bottom: 0;
    }
}

//  Checkout Agreements
.checkout-agreement-item-content {
    overflow: auto;
}
