.catalog-product-view {
    .block.related {
        .block-title {
            color: #000000;
            font-family: inherit;
            font-style: inherit;
            line-height: 1.1;
            font-size: 18px;
            font-weight: 600;
            margin-top: 25px;
            margin-bottom: 20px;
        }

        .products.products-related {
            margin: 30px 0 0 20px;
        }

        .product-item {
            display: block;
            width: 100%;
            padding-left: 0;

            .product-item-info {
                display: grid;
                grid-template-columns: minmax(0, 55px) minmax(0, 1fr) minmax(0, 1fr);

                @media only screen and (min-width: 425px) {
                    grid-template-columns: 65px minmax(0, 3fr) minmax(0, 65px);
                }

                @media only screen and (min-width: 1024px) {
                    grid-template-columns: 65px 1fr 65px;
                }

                &.out-of-stock {
                    grid-template-columns: minmax(0, 55px) minmax(0, 1fr) minmax(0, 120px);

                    @media only screen and (min-width: 425px) {
                        grid-template-columns: 65px minmax(0, 3fr) minmax(0, 80px);
                    }

                    @media only screen and (min-width: 1024px) {
                        grid-template-columns: 65px 1fr 120px;
                    }
                }

                .stock {
                    float: none;
                }
            }

            .product-item-photo {
                all: unset;
                background: none;
                display: inline-block;
            }

            .product-item-name {
                all: unset;
                color: #000;
                display: block;
                height: auto;
                text-decoration: underline;
            }

            .related-price {
                display: block;

                .price-box {
                    text-align: left;

                    .price {
                        color: #000;
                        font-size: 16px;
                    }
                }
            }

            .product-item-details {
                display: inline-block;
                padding-left: 0;
            }

            .product-item-link {
                .label-icon {
                    span {
                        display: none;
                    }
                }
            }

            .product-item-actions {
                display: block;
                text-align: right;

                .actions-primary {
                    display: block !important;

                    .action.tocart.primary {
                        background-color: $primary__color5;
                        width: 65px;
                        height: 50px;
                        padding: 5px;
                        margin-bottom: 0 !important;
                        line-height: 1;

                        &:before {
                            color: #fff;
                            content: "+";
                            display: inline-block;
                            font-size: 30px;
                            font-weight: 100;
                        }

                        &:after {
                            font-family: $icons-name;
                            font-size: 30px;
                            display: inline-block;
                            color: white;
                            line-height: 30px;
                            text-align: center;
                            cursor: pointer;
                            content: "\e967";
                        }

                        > span {
                            display: none;
                        }
                    }
                }
            }
        }

        .checkbox {
            display: none;
        }
    }
}
