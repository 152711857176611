.product.attribute.description{
    margin: 40px 0;

    h3 {
        font-size: 22px;
        margin: 30px 0 10px;
    }

}

// table{background-color:#FCFCFC; }

.additional-attributes-wrapper.table-wrapper {
    table {
        border-collapse: collapse;
        width: 100%;
        background: #FCFCFC;
    }

    td,
    th {
        text-align: left;
    }

    tr:nth-child(even) {
        background-color: #F8F8F8;
    }

    .col.label {
        font-weight: 400;
    }
}

.additional-attributes-wrapper {
    h3{
        font-weight: 600;
        margin-top: 25px;
        margin-bottom: 20px;
    }
}

.color_thumb{
     height: 15px;
     width: 15px;
     margin-top: 5px;
     border: 1px #efefef solid;
}

.multicolor{
    height: 15px;
    width: 15px;
    margin-top:5px;
    background: rgb(255,0,0); /* Old browsers */
    background: -moz-linear-gradient(-45deg, rgba(255,0,0,1) 0%, rgba(255,63,172,1) 33%, rgba(41,137,216,1) 64%, rgba(255,255,0,1) 92%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(255,0,0,1) 0%,rgba(255,63,172,1) 33%,rgba(41,137,216,1) 64%,rgba(255,255,0,1) 92%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(255,0,0,1) 0%,rgba(255,63,172,1) 33%,rgba(41,137,216,1) 64%,rgba(255,255,0,1) 92%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#ffff00',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
