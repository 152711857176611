//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin             : $indent__base !default;
$checkout-wrapper__columns            : 8 !default;

$checkout-step-title__border          : $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size       : 26px !default;
$checkout-step-title__font-weight     : $font-weight__light !default;
$checkout-step-title__padding         : $indent__s !default;

$checkout-step-title-mobile__font-size: 18px !default;

//
//  Common
//  _____________________________________________

.checkout-container {
    padding: 0 15px;
    box-sizing: border-box;
}

.checkout-index-index {
    input[type="checkbox"] {
        margin: 4.5px 5px 0 0;
    }

    .field {
        .label {
            font-size: 14px;
        }
    }

    .billing-address-same-as-shipping-block {
        label {
            font-size: 14px;
        }
    }

    .page-wrapper {
        .logo {
            left: 0;
            transform: unset;
        }
    }

    .footercms {
        ul {
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0;
        }
    }
}


.opc-block-summary > .title {
    display: block;
    font-family: "Gotham";
    font-size: 20px;
    font-weight: 600;
}

.checkout-index-index,
.checkout-onepage-success {
    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }
}

.checkout-container {
    @extend .abs-add-clearfix;
    margin: 0 0 $checkout-wrapper__margin;
}

.opc-wrapper {
    margin: 0 0 $checkout-wrapper__margin;

    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @extend .abs-checkout-title;
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }
}

.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .logo {
        margin-left: 0;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            font-size: $checkout-step-title-mobile__font-size;
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__columns);
        padding-right: $indent__l;

        .billing-address-same-as-shipping-block {
            margin-left: 20%;
        }
    }

    .checkout-index-index {
        .footercms {
            ul {
                flex-direction: row;
                justify-content: center;
                margin: 0 auto;

                li {
                    margin-right: 20px;
                }
            }
        }
    }
}

.checkout-index-index .opc-wrapper .form-login {
    .fieldset > .field {
        width: 100%;
        box-sizing: border-box;
    }

}

@include max-screen(520px) {
    body .opc-wrapper .fieldset > .field {
        width: 100%;
    }
}
