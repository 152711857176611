#company-switcher {
    height: 40px;
    input {
        height: unset !important;
    }

    #billing\:type_business {
        margin-left: 10px;
    }
}

.form-shipping-address {
    div[name="shippingAddress.vat_id"] {
        display: none;
    }
}

.billing-address-form {
    div[name="billingAddress.vat_id"],
    div[name="billingAddress.company"] {
        display: none;
    }
}
