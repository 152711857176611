//
//  Common
//  _____________________________________________

.cookie-status-message {
    display: none;
}

body {
    background-color: $page__background-color;
    color: $primary__color3;
    font-size: 16px;
}

.product-info-stock-sku {
    width: 100%;
}

.product-info-main {
    @include max-screen(768px) {
        width: 100%;
    }
}
.header{
    .trusted{
        margin-left: 270px;
        margin-top: 23px;
        @media only screen and (max-width: 1145px) {
            display: none;
        }
        img{width: 200px;}
    }
}

.usp-toplinks {
    display: inline-block;
    margin: 0;
    padding: 0;
    float: left;
    @media only screen and (max-width: 768px) {
        display: none;
    }
    li {
        margin-right: 20px;
        list-style: none;
        margin-top: 0;
        padding-top: 0;
        line-height: 1.4;
        font-weight: 600;
        float: left;
        @media only screen and (min-width: 1340px) {
            font-size: 14px;
        }
        @media only screen and (max-width: 1339px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 1203px) {
            font-size: 12px;
        }

        &:before {
            content: '✔';
            color: $primary__color;
            margin-right: 5px;
            font-weight: 800;

        }
    }
}

.panel {
    &.header {
        ul.phone {
            display: inline-block;
            margin: -6px 0 0 0;
            padding: 0;
            float: left;
            @media only screen and (max-width: 768px) {
                display: none;
            }

            li {
                margin-right: 35px;
                list-style: none;
                margin-top: 0;
                padding-top: 0;
                line-height: 1.4;
                @media only screen and (min-width: 1340px) {
                    font-size: 14px;
                }
                @media only screen and (max-width: 1339px) {
                    font-size: 12px;
                }
                font-weight: 600;
                float: left;

                a {
                    top: 11px;
                    position: absolute;
                    color: #20272B;

                    &:hover {
                        color: #20272B;
                    }
                }

                &:before {
                    content: "\e969";
                    font-size: 20px;
                    font-weight: 200;
                    font-family: "icomoon";
                    color: $primary__color;
                }
            }
        }
    }
}

.phone-number-fixed {
    @media only screen and (min-width: 769px) {
        display: none;
    }
    @media only screen and (max-width: 768px) {
        position: fixed;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
        background: #EB8500;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        z-index: 1;

        ul.phone {
            display: inline-flex;
            margin: 0;
            padding: 0;

            li {
                list-style: none;
                line-height: 1.4;
                font-size: 14px;
                font-weight: 600;
                margin: 0 auto;
                padding: 5px 15px;
                position: relative;
                width: 150px;

                a {
                    color: $color-white;
                    position: absolute;
                    bottom: 10px;
                    right: 15px;
                }

                &:before {
                    content: "\e969";
                    font-size: 22px;
                    font-weight: 500;
                    font-family: "icomoon";
                    color: $color-white;
                }
            }
        }
    }
}

//
//  Header
//  ---------------------------------------------

.page-header {
    background-color: $header__background-color;
    margin-bottom: $indent__base;
}

.header {
    &.content {
        @extend .abs-add-clearfix;
        padding-top: $indent__s;
        position: relative;

        @media only screen and (max-width: 480px) {
            padding-top: 10px !important;
        }

        .phone-number {
            float: left;
            @include max-screen(1040px) {
                display: none;
            }

            .phone {
                position: relative;

                li {
                    margin-right: 35px;
                    list-style: none;
                    margin-top: 0;
                    padding-top: 0;

                    a {
                        color: $color-black;
                    }


                    &:before {
                        content: "\e969";
                        font-size: 22px;
                        font-weight: 500;
                        font-family: "icomoon";
                        color: $color-black;
                    }
                }
            }

        }
    }
}

.logo {
    float: left;
    margin: 0 0 $indent__s $indent__xl;
    max-width: 240px;
    position: relative;
    z-index: 5;

    @media only screen and (max-width: 1040px) {
        float: none;
        display: inline-block;
        position: relative;
        transform: translateX(-50%);
        left: 50%;
        margin: -8px auto 0 0;
        padding: 0;
        max-width: 160px;
    }

    @media only screen and (max-width: 420px) {
        left: 45%;
    }

    img {
        display: block;
    }

    @media only screen and (max-width: 768px) {
        width: 160px;

        img {
            width: 160px;
            height: 60px;
        }
    }

    .page-print & {
        float: none;
    }
}

.page-main {
    & > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        background: $color-gray94;
        padding: $indent__s;
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 10px;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
        color: $primary__color3;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;
        text-align: center;
        background: #FBFBFB;
        line-height: 3.2;
        font-size: 16px;
        height: auto;
        a {
            color: $primary__color2;
        font-weight: bold;}
            a:hover {
                color: $primary__color3;}

        .actions {
            margin-top: 0px;
        }

    }


    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}


//
//  Footer
//  ---------------------------------------------

.page-footer {
    background: $primary__color1;
    margin-top: auto;
}

.footer {
    &.content {
        background-color: $primary__color2;
        color: $primary__color11;
        border-top: 0px !important;
        color: white;
        overflow: hidden;
        padding: 70px 0 100px;

        .footer-links-block {
            margin-top: 30px;
        }

        h2 {
            text-align: left;
            margin-top: 0;
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 22px!important;
        }

        h3 {
            margin-top: 25px;
        }

        li {
            a{
                color: $primary__color4;
                font-size: 16px;
                font-weight: 300;
            }
        }

        @media only screen and (max-width: 560px) {
            padding: 30px 20px;
            text-align: center;

            h2 {
                text-align: center;
            }
        }

        ul {
            @extend .abs-reset-list;
        }

        .links {
            display: inline-block;
            margin-bottom: 20px;
            padding: 0 50px 0 0;
            vertical-align: top;

            & > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
            display: inline-block;
            padding-right: 50px;
            vertical-align: top;
        }

        .block.newsletter {
            margin-bottom: 0;
            margin-top: 0;

            .block.newsletter .field .control::before {
                -webkit-font-smoothing: antialiased;
                font-size: 35px;
                line-height: 33px;
                color: white !important;
                content: "";
                font-family: "icons-blank-theme";
                margin: inherit;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
                vertical-align: middle;
            }

            .form.subscribe {
                display: block;
                width: 100%;
                position: relative;

                .actions {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }

            .fieldset {
                display: table-cell;
                padding: 0;
                vertical-align: top;
            }

            .field {
                margin: 0;

                .control {
                    @include lib-icon-font(
                                    $icon-envelope,
                            $_icon-font-size       : 35px,
                            $_icon-font-line-height: 33px,
                            $_icon-font-color      : $form-element-input-placeholder__color
                    );
                    display: block;

                    &:before {
                        position: absolute;
                    }
                }
            }

            input {
                padding-left: 0px;
                margin-left: 0px;
                margin-right: 0;
                padding-right: 45px;
                width: 100%;
                border-left: 0;
                border-right: 0;
                border-top: 0;
                border-bottom: 1px solid #fff;
                background-color: #005266;
                border-radius: 0;

                @media only screen and (max-width: 560px) {
                    font-size: 16px;
                }

            }

            .title {
                display: none;
            }

            .label {
                @extend .abs-visually-hidden;
            }


            .action.subscribe {
                min-width:0;
                margin-left: 0;
                margin-right: 0;
                position: relative;
                margin-bottom: 0 !important;
                border-left: 0;
                border-right: 0;
                border-top: 0;
                padding: 6px 15px;
                border-bottom: 0;
                border-radius: 0;

                span {
                    display: none;
                }
            }
        }
        @include min-screen($screen__m) {
            .block.newsletter {
                .field {
                    margin-right: 5px;

                    .control {
                        width: 100%;
                    }
                }
            }
        }
    }

    .copyright {
        @include lib-clearfix();
    }

    .copyright,
    .bugs {
        display: block;
        margin: 20px 0 0;
    }

    .action.primary {
        background-image: none;
        background: $primary__color2;
        border: 2px solid white;
        color: white;
        cursor: pointer;
        display: inline-block;
        font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 700;
        margin: 0;
        padding: 7px 15px;
        width: auto;
        font-size: 14px;
        line-height: 16px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        vertical-align: middle;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 0px;
    }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding           : 0,
                $_dropdown-list-item-padding         : 0,
                $_dropdown-toggle-icon-content       : $icon-down,
                $_dropdown-toggle-active-icon-content: $icon-up,
                $_icon-font-text-hide                : true,
                $_icon-font-size                     : 22px,
                $_icon-font-line-height              : 22px,
                $_dropdown-list-min-width            : 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
            width: 100%;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        margin: $indent__base 0;
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    html,
    body {
        height: 100%;
    }

    .page-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        h2 {
            margin-top: 45px;
            font-size: 36px;
            margin-bottom: 15px;//33px;
        }

        & > .breadcrumbs,
        & > .top-container,
        & > .widget {
            box-sizing: border-box;
            width: 100%;
            padding-bottom: 30px;
        }

        .ie10 &{
            height: 100%;
        }
    }

    .navigation ul {
        padding: 0 8px;
    }

    .header {
        &.panel {
            & > .header.links {
                float: right;
                font-size: 0;
                @include lib-list-inline();
                margin-left: auto;

                & > li {
                    font-size: $font-size__base;
                    margin: 0 0 0 15px;

                    &.welcome,
                    a {
                        line-height: 1.4;
                        color: #4A4A49;
                    }

                    &.welcome {
                        a {
                            padding-left: $indent__xs;
                        }
                    }
                }
            }
        }

        &.content {
            @extend .abs-add-clearfix-desktop;
            //padding: $indent__l $indent__base 0;
            padding: 68px 8px 0 !important;
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        .panel.wrapper {
            border-bottom: 0 solid $secondary__color;
            background: #f8f8f8;
            height: 38px;
            position: fixed;
            width: 100%;
            z-index: 99;

            @media only screen and (max-width: 1040px) {
                display: none;
            }
        }

        .header.panel {
            @extend .abs-add-clearfix-desktop;
            padding: $indent__s 8px;
        }

        .switcher {
            display: inline-block;
        }
    }

    .page-main {
        & > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .logo {
        margin: -8px auto 0 0;

        img {
            max-height: inherit;
            height: 60px;
            width: 240px;
        }
    }


}

.top_usp{
    color:$primary__color3;
}

//
//  CMS page
//  _____________________________________________

.cms-page-view {
    h1 {
        margin-top: 0;
    }

    .page-wrapper>.breadcrumbs {
        padding-bottom: 0;
    }
}

.footer.content .subscribe-footer-block {
    margin-top: 20px;
}

.image {
    position:relative;
}
.slider-arrow {
    position: relative;
    color: #fff;
    background-color: #15485c;
    width: 500px;
    left: 8px;
    display: block;
    margin-top: auto;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 75px;
    font-size: 26px;
    font-weight: bold;
    float: left;
    bottom: 0 !important;
    top: calc(100% - 175px);
}
.slider-arrow-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 1440px;
    height: 100%;
    left: 50%;
    transform: translate(-50%, 0);
}
.slider-arrow-image {
    margin: 0;
    position:absolute;
    right: 20px;
    top: 50%;
    width:30px;
    height:31px;
    background-image:url("/media/theme_customization/arrow.png");
    background-repeat:no-repeat;
    transform: translate(0, -50%);
}
.home-slider .slick-slide img {
    min-height: 220px;
    object-fit: cover;
}
a.button-link {
    text-decoration: none;
}
