.block.filter {
    .filter-content {
        .filter-current {
            .action.remove {
                color: red;
                display: inline-block;
                text-decoration: none;
                vertical-align: baseline;

                &:hover {
                    font-weight: 600;
                }
            }
        }
    }
}
