@include lib-font-face(
    $family-name: $icons-name-garmundo,
    $font-path  : '../fonts/Icomoon-Theme-Icons/icomoon',
    $font-weight: 100,
    $font-style : normal,
    $font-display: swap
);

@include lib-font-face(
    $family-name: $font-family-name__secondary,
    $font-path  : '../fonts/gotham/light/gotham-light',
    $font-weight: 100,
    $font-style : normal,
    $font-display: swap
);

@include lib-font-face(
    $family-name: $font-family-name__secondary,
    $font-path  : '../fonts/gotham/regular/gotham-regular',
    $font-weight: 400,
    $font-style : normal,
    $font-display: swap
);

@include lib-font-face(
    $family-name: $font-family-name__secondary,
    $font-path  : '../fonts/gotham/medium/gotham-medium',
    $font-weight: 600,
    $font-style : normal,
    $font-display: swap
);

@include lib-font-face(
    $family-name: $font-family-name__secondary,
    $font-path  : '../fonts/gotham/medium-italic/gotham-medium-italic',
    $font-weight: 600,
    $font-style : italic,
    $font-display: swap
);

@include lib-font-face(
    $family-name: $font-family-name__secondary,
    $font-path  : '../fonts/gotham/bold/gotham-bold',
    $font-weight: 800,
    $font-style : normal,
    $font-display: swap
);

@include lib-font-face(
    $family-name: $font-family-name__secondary,
    $font-path  : '../fonts/gotham/black/gotham-black',
    $font-weight: 900,
    $font-style : normal,
    $font-display: swap
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path  : '../fonts/lexend/Lexend-Light',
    $font-weight: 300,
    $font-style : normal,
    $font-display: swap
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path  : '../fonts/lexend/Lexend-Regular',
    $font-weight: 400,
    $font-style : normal,
    $font-display: swap
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path  : '../fonts/lexend/Lexend-Medium',
    $font-weight: 500,
    $font-style : normal,
    $font-display: swap
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path  : '../fonts/lexend/Lexend-Semibold',
    $font-weight: 600,
    $font-style : normal,
    $font-display: swap
);

.items {
    @include lib-list-reset-styles();
}

//
p{
  margin-top: 7px;
  margin-bottom: 7px;
  font-weight: 100;
}

.category-full-description {
    ul,
    li {
        font-weight: 100;
    }
}

h2{
  font-weight: 100;
  font-weight: lighter;
}

//
//    Desktop
//--------------------------------------

@include min-screen($screen__m) {
    h1 {
        font-size: $h1__font-size-desktop;
        margin-bottom: $h1__margin-bottom__desktop;
        margin-top: $h1__margin-bottom__desktop;
    }
}
