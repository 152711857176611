//
//  Common
//  _____________________________________________
.block-search {
    margin-bottom: 0;

    .block {
        &-title {
            display: none;
        }
    }

    .block-content {
        margin-bottom: 0;
    }

    .label {
        @include lib-icon-font(
            $_icon-font-content     : $icon-search,
            $_icon-font-size        : 35px,
            $_icon-font-line-height : 33px,
            $_icon-font-color       : $header-icons-color,
            $_icon-font-color-hover : $minicart-icons-color-hover,
            $_icon-font-color-active: $minicart-icons-color-hover,
            $_icon-font-text-hide   : true,
            $_icon-font-display     : block
        );
        float: right;

        &.active {
            + .control {
                input {
                    position: static;
                }
            }
        }
    }

    .action.search {
        display: none;
    }

    .control {
        clear: both;
        margin: 0;
        padding: 0;
    }

    input {
        left: -300%;
        margin: 15px 0;
        position: absolute;
    }

    .nested {
        display: none;
    }
}

.search-autocomplete {
    @extend .abs-add-box-sizing;
    background: #fff;
    display: none;
    margin-top: -15px;
    overflow: hidden;
    position: absolute;
    z-index: 3;

    dl, ul {
        @include lib-list-reset-styles();

        dt {
            pointer-events: none;

            &:hover {
                background: transparent;
            }
        }

        .title-product {
            display: none;
        }

        dt,
        dd,
        li {
            border-top: $autocomplete-item__border;
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:not(:empty) {
                border-top: 0;
                border: $autocomplete__border;
                background: $autocomplete__background-color;
            }

            &:first-child {
                border-top: none;
            }

            &:not(:last-child) {
                border-bottom: 0;
            }

            &:hover,
            &.selected {
                background: $autocomplete-item__hover__color;
            }

            .amount {
                color: $primary__color;
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }

            .product-image-box {
                display: inline-block;
            }

            .product-item {
                display: inline-block;

                .product-primary {
                    display: inline-block;
                }

                .price-label,
                .price-wrapper {
                    display: inline-block;
                    font-size: 18px;
                }

                .minimal-price-link {
                    display: inline-block;
                    float: none;
                }
            }
        }
    }
}

.form.search.advanced {
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 25px;

                    &:after {
                        content: ' \2013 ';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 6px;
                        width: 25px;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error[generated] {
                    left: 0;
                    position: absolute;
                    top: 32px;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 45px;
                }
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: 6px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search.summary {
    margin-bottom: $indent__s;
}

//
//  Mobile
//  _____________________________________________

@include max-screen(1040px) {
    .block-search {
        .block-content {
            margin-bottom: 0;
        }
    }
}


//
//  Desktop
//  _____________________________________________

@include min-screen(1041px) {
    .block-search {
        float: right;
        padding-left: 15px;
        position: relative;
        width: 250px;
        z-index: 4;

        .label {
            display: none;
            // @extend .abs-visually-hidden-desktop;
        }

        .control {
            border-top: 0;
            margin: 0;
            padding: 0 0 25px;
        }

        .nested {
            display: block;
            padding-top: 5px;
            position: absolute;
        }

        input {
            @include lib-input-placeholder() {
                color: $form-element-input-placeholder__color;
            }
            margin: 0;
            padding-right: 35px;
            position: static;
        }

        .action.search {
            display: inline-block;
            @include lib-button-icon(
                $_icon-font-content  : $icon-search,
                $_icon-font-text-hide: true,
                $_icon-font-color    : $header-icons-color
            );
            @include lib-button-reset();
            padding: $indent__xs 0;
            position: absolute;
            right: 10px;
            top: 0;
            z-index: 1;

            &:focus {
                &:before {
                    color: $color-gray20;
                }
            }
        }
    }

    .search-autocomplete {
        margin-top: 0;
    }
}

.block-search {
    float: right;
    padding-left: 15px;
    position: relative;
    z-index: 4;
    margin-right: 40px;
    width: 560px ;

    @media only screen and (max-width: 1040px) {
        padding-left: 0;
        width: 100%;
        margin-right: 0;

        .label {
            margin-top: -50px;

            &:before {
                font-family: 'icomoon';
                content: "\e968";
                color: $primary__color2;
                display: inline;
            }
        }
    }

    input {
        border: 1px solid #CFCFCF;
        border-radius: 1px;
        color: #6D6D6D;
        font-family: "gotham", sans-serif;
        font-size: 16px;
        font-style: inherit;
        margin: 0;
        height: 51px;
        line-height: 51px;
        vertical-align: baseline;
        width: 100%;
        box-sizing: border-box;
        font-weight: 100 !important;
    }

    .action.search {
        display: inline-block;
        text-decoration: none;
        background-image: none;
        background: none;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        line-height: inherit;
        padding: 0;
        text-decoration: none;
        text-shadow: none;
        font-weight: 400;
        padding: 8px 10px 0 8px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }

    .action.search::before {
        -webkit-font-smoothing: antialiased;
        font-size: 28px !important;
        line-height: 50px !important;
        color: #8f8f8f;
        content: "\e968";
        font-family: 'icomoon';
        margin: 0;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
        vertical-align: top;
        background-color: #F8F8F8;
        width: 49px;
        height: 49px;
        margin-top: -7px !important;
        margin-right: -9px !important;
        border-left: solid 1px #CFCFCF;
    }
}

@include max-screen(1040px) {
    .block-search {
        .label {
            margin-top: -50px;
            right: 80px;
            position: relative;
        }

        input {
        }

        .control {
            top: 15px;
            position: relative;
        }

        .action.search {
            &:before {
                content: none;
            }
            span {
                display: none;
            }
        }

    }

}

@include min-screen($screen__m) {
    .search.results dl.block {
        margin: 30px 0 0 35px;
    }
}

@include max-screen(550px) {
    .block-search {
        .label {
            right: 60px;
        }
    }

}
