// Mixins
@import "mixins/media-queries";

// Vendor libs
@import 'vendor/normalize/lib'; // Normalize.css
@import 'vendor/magento-ui/lib'; // Magento UI
// @import 'vendor/owl-carousel/owl.carousel'; // OWL Slider

//Toegevoegd Theme variables
@import 'variables';

// Reset default styles with magento-reset
@include lib-magento-reset();

// Theme blocks
@import 'blocks/extends';
@import 'blocks/typography';
@import 'blocks/buttons';
@import 'blocks/layout';
@import 'blocks/tables';
@import 'blocks/messages';
@import 'blocks/navigation';
@import 'blocks/navigation-sections';
@import 'blocks/tooltips';
@import 'blocks/loaders';
@import 'blocks/forms';
@import 'blocks/icons';
@import 'blocks/buttons';
@import 'blocks/sections';
@import 'blocks/pages'; // Theme pager
@import 'blocks/actions-toolbar';
@import 'blocks/breadcrumbs';
@import 'blocks/popups';
@import 'blocks/price';
@import 'blocks/grid';
@import 'blocks/slick';
@import 'blocks/slick-theme';
@import 'blocks/quickcart';

//Toegevoegd
// @import 'blocks/keuzehulp';
@import 'blocks/social';
@import 'blocks/homepage';
@import 'blocks/over-ons';
@import 'blocks/FAQ';
@import 'blocks/header';
@import 'blocks/contact';
@import 'blocks/actions-toolbar';//vanuit de vendor map
@import 'blocks/callout_productpage';// callout en USP op de productpagina
@import 'blocks/contact-page';// callout en USP op de productpagina


// Components styles (modal/sliding panel)
@import 'blocks/components/modals'; // from lib
@import 'blocks/components/modals_extend'; // local

// Modules
@import '../Magento_AdvancedCheckout/styles/module';
@import '../Magento_AdvancedSearch/styles/module';
@import '../Magento_Braintree/styles/module';
@import '../Magento_BraintreeTwo/styles/module';
@import '../Magento_Bundle/styles/module';
@import '../Magento_Catalog/styles/module';
@import '../Magento_CatalogEvent/styles/module';
@import '../Magento_CatalogSearch/styles/module';
@import '../Magento_Checkout/styles/module';
@import '../Magento_Customer/styles/module';
@import '../Magento_Downloadable/styles/module';
@import '../Magento_GroupedProduct/styles/module';
@import '../Magento_LayeredNavigation/styles/module';
@import '../Magento_LayeredNavigation/styles/current-filter';
@import '../Magento_LayeredNavigation/styles/filters';
@import '../Magento_Msrp/styles/module';
@import '../Magento_MultipleWishlist/styles/module';
@import '../Magento_Multishipping/styles/module';
@import '../Magento_Newsletter/styles/module';
@import '../Magento_Paypal/styles/module';
@import '../Magento_ProductVideo/styles/module';
@import '../Magento_Review/styles/module';
@import '../Magento_Reward/styles/module';
@import '../Magento_Sales/styles/module';
@import '../Magento_SalesRule/styles/module';
@import '../Magento_SendFriend/styles/module';
@import '../Magento_Swatches/styles/swatches';
@import '../Magento_Theme/styles/module';
@import '../Magento_Vault/styles/module';
@import '../Magento_Weee/styles/module';
@import '../Magento_Wishlist/styles/module';
@import '../ProgrammingAtKstark_CatewithimgWidget/styles/module';
@import '../Smile_ElasticsuiteCatalog/styles/module';
@import '../Magento_Cookie/styles/module';
@import '../Mirasvit_Blog/styles/_module';

// Widgets
@import '../Magento_AdvancedCheckout/styles/widgets';
@import '../Magento_Banner/styles/widgets';
@import '../Magento_Catalog/styles/widgets';
@import '../Magento_CatalogEvent/styles/widgets';
@import '../Magento_Cms/styles/widgets';
@import '../Magento_MultipleWishlist/styles/widgets';
@import '../Magento_VersionsCms/styles/widgets';



// HACKS
html:not([lang="de-DE"]) .hide_non_german {
    display: none;
}

body.cms-home.cms-index-index.page-layout-1column{
    .breadcrumbs{
        display: none;
    }
}

.quotation-quote-index {
    .page-main {
        padding: 0 15px;
    }
}
