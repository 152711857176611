.callout-block {
    background-color: #014a5b;
    height: 107px;
    max-width: 320px;
    width: 100%;
    color: white;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 30px;
    box-sizing: border-box;

    p {
        line-height: 0;
        font-size: 15px;
        font-style: italic;
    }
}

.callout-text {
    font-size: 33px;
    color: white;
    text-transform: uppercase;
    padding-top: 17px;
}

li b {
    margin-left: 10px;
    color: black;
}

.UPS-text {
    ul li {
        list-style-type: none;
        margin-left: -40px;

    }

    span {
        color: #2FDF66;
    }

    p {
        color: #95989A;
        font-size: 14px;
    }

    H3 {
        margin-bottom: 30px;
        margin-top: 30px;
        margin-top: 85px;
        font-size: 16px;
    }
}
