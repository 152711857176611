

.block-static-block.widget.cwiw {
  padding: 0 8px;
  margin-top: 40px;
  height: auto;

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .col-md-2 {
      width: calc(25% - 15px);
      display: block;
      margin-bottom: 30px;

      @media only screen and (max-width: 1040px) {
        width: calc(50% - 15px);
      }

      @media only screen and (max-width: 640px) {
        margin-bottom: 15px;
        width: calc(50% - 7.5px);
      }

      @media only screen and (max-width: 480px) {
        width: 100%;
      }

      > a {
        display: block;
        position: relative;
        height: 0;
        overflow: hidden;
        padding-bottom: 65%;

        .h3-category {
          font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-size: 24px;
          font-weight: 600;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          text-align: center;
          position: absolute;
          text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);
        }

        img {
          width: 100%;
          position: relative;
          left: 50%;
          transform: translateX(-50%)
        }
      }
    }
  }

  @media only screen and (max-width: 1040px) {
    padding: 0 100px;
  }

  @media only screen and (max-width: 767px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 640px) {
    padding: 0 15px;
  }

}