.fotorama__dot {
    display: block;
    position: relative;
    top: 12px;
    left: 6px;
    width: 25px;
    height: 5px;
    border-radius: 0px;
    background-color: white;
    opacity: 0.8;

    .fotorama__nav__frame--dot:focus & {
        box-shadow: none;
        &:after {
            border-radius: inherit;
            bottom: $fotorama-inner-box-shadow;
            box-shadow: $focus__box-shadow;
            content: '';
            left: $fotorama-inner-box-shadow;
            position: absolute;
            right: $fotorama-inner-box-shadow;
            top: $fotorama-inner-box-shadow;
            z-index: $z-index-10;
        }
    }

    .fotorama__nav__frame--thumb:focus &:after,
    .fotorama__nav__frame--dot:focus &:after {
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
    }

    .fotorama__active & {
        opacity: 1;
    }
}


@media only screen and (min-width: 769px) {

    .fotorama__nav__frame {
        position: relative;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        padding-right: 15px;
        padding-left: 15px;
    }

    .fotorama__nav.fotorama__nav--dots {
        margin-top: -110px;
        margin-bottom: 20px;
    }
}
