.smile-es-range-slider {
    .ui-slider {
        background: $slider-color;
        margin: 10px 0 10px 0;
        max-width: 85%;

        .ui-slider-range {
            background: $slider-range-color;
            height: 10px;
            position: relative;
        }

        .ui-slider-handle {
            background: $slider-range-color;
            border-radius: 50%;
            margin-top: -15px;
            width: 20px;
            height: 20px;

            &:hover {
                background: $slider-handle-hover-color;
            }
        }
    }

    .result {
        display: block;
        text-align: center;
        max-width: 85%;
    }

    .actions-toolbar {
        .action.primary {
            float: none;
        }
    }

    [data-role=from-label] {
        display: inline-block;

        &:after {
            content: "-";
            display: inline-block;
            margin: 0 5px;
        }
    }

    [data-role=to-label] {
        display: inline-block;
    }
}
