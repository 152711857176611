//
//  Variables
//  _____________________________________________

$checkout-sidebar__margin: $indent__base !default;
$checkout-sidebar__margin__xl: 46px !default;
$checkout-sidebar__columns: 4 !default;

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-sidebar {
        margin: 0 !important;
        position: sticky;
        top: 20px;
        @include lib-layout-column(2, 2, $checkout-sidebar__columns);

        .opc-block-summary {
            background: none;
            border: 1px solid #D8D8D8;
        }

        .modal-inner-wrap {
            background: none !important;
        }
    }


    .opc-block-shipping-information {
        padding: 0 !important;

        .shipping-information {
            border: 1px solid #D8D8D8;
            padding: 22px 30px !important;
        }
    }
}


.opc-sidebar {
    margin: 28px 0 20px;
    width: 33.33333% !important;
    float: right;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;

    .modal-inner-wrap {
        background: #f8f8f8;
    }

    &._show {
        width: auto !important;
        left: 50px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
}

.opc-block-summary {
    .minicart-items {
        .product {
            display: flex;
            padding-top: 15px;

            .product-item-details {
                overflow: unset;
            }
        }
    }
}

.opc-block-summary {
    border: solid #f8f8f8 1px;
    padding: 22px 30px;
    margin: 0 0 20px;
    height: auto;
    display: inline-grid;

    > .title {
        border-bottom: none;
    }

    .items-in-cart {
        margin-top: 10px;
        margin-bottom: 0;
    }

    .product-image-container {
        background: none;
    }
}
