
.blog__post-list-wrapper {
    .blog__post-list {
        list-style: none;
        padding: 0;

        li {
            margin-bottom: 1.4rem;
            border-bottom: 1px dotted #ddd;
        }
    }

    .toolbar {
        position: relative;
        margin-bottom: 1rem;
    }
}

.blog__post-item {
    @include lib-clearfix();

    .blog__post-header {
        .blog__post-title {
            margin-bottom: 1rem;
            margin-top: 0;

            a {
                color: #333;
                font-size: 2rem;

                &:hover {
                    text-decoration: none;
                    color: #000;
                }
            }
        }
    }

    .blog__post-excerpt {
        @include lib-clearfix();

        @media only screen and (min-width: 768px) {
            img {
                float: left;
                padding: 0 2rem 2rem 0;
                max-width: 30%;
            }

            a {
                img {
                    float: left;
                    padding: 0 2rem 2rem 0;
                    max-width: 30%;
                }
            }
        }

        @media only screen and (max-width: 768px) {
            img {
                padding: 0 0 2rem 0;
                max-width: 100%;
                width: 100%;
            }
        }

        .read-more {
            display: block;
            text-align: right;
            margin-top: 1rem;
        }
    }
}

.blog__main-recent {
    .item {
        @include lib-clearfix();

        img {
            padding: 0 1rem 1rem 0;
            float: left;
            max-width: 10rem;
            border-radius: 2px;
        }

        a {
            color: #333;
            font-size: 1.2rem;
            display: block;
            text-decoration: none;
        }
    }
}


.blog__post-meta {
    @include lib-clearfix();

    color: #a3a3a3;
    margin-bottom: 0.5rem;

    .blog__post-meta-time, .blog__post-meta-comments, .blog__post-meta-sharing {
        margin-right: 2rem;
    }

    .blog__post-meta-author {
        display: none;
    }

    .blog__post-meta-sharing {
        display: inline-block;
        margin-left: 2rem;
    }
}

.blog__post-footer {
    display: none;
}
