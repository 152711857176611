.category-image {
    @media only screen and (max-width: 768px) {
        min-height: 140px;
    }

    .image {
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.category-description-container {
    @media only screen and (max-width: 768px) {
        min-height: 300px;
    }
}

.category-full-description {
    margin-bottom: 100px;

    h3 {
        font-size: 22px;
        margin: 30px 0 10px;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m + 1) {

    //
    //  Grouped product page
    //  ---------------------------------------------

    .page-product-grouped {
        &.page-layout-1column {
            .product.attribute.description {
                clear: both;
            }
        }
    }
}
