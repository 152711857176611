.experius_postcode_fieldset {
    margin: 0;
    width: 100%;

    .checkbox {
        height: inherit !important;
    }

    .mage-error {
        float: left;

        @include min-screen($screen__m) {
            float: none;
            margin-left: 20%;


            &:before {
                margin-left: -6px !important;
            }
        }

    }
}

.experius-address-lines  {
    input:disabled {
        background: #dddddd;
    }
}

.shippingAddress-city {
    input:disabled {
        background: #dddddd;
    }
}

