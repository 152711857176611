

.contact-index-index.page-layout-1column {
    .page-bottom {
        background-color: $primary__color6;
    }
}

.questions-container {
    display: inline-block;
    width: 100%;
    min-height: 540px;

    .expand{
        width: 100%;
    }
}

.fold{
    display: inline-block;

    .icon-icon-Bezorgen22::before {
        float: none;
        padding-bottom: 0px;
    }
}


.faq-icons{
    color: white;
    width: 100%;
    background: $primary__color2;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
    box-sizing: border-box;
    border-left: 1px solid #003F4E;
    border-top: 1px solid #003F4E;
        height: 155px;

    &:hover{
        background:#003F4E;
    }

    &:focus{
        background:#003F4E;
    }

    b{
        font-weight: 100;
    }
}


.cms-faq {
    .faq-container {
        width: 100%;
        padding: 0 8px;
        max-width: 1445px;
        margin: 0 auto;
        display: flex;
        box-sizing: border-box;
        flex-wrap: wrap;
        margin-top: 100px;
        @include max-screen(768px) {
            margin-top: 0px;
        }


        .faq-container-left {
            width: 40%;

            @include max-screen(1200px) {
                width: 50%;
            }

            @include max-screen(900px) {
                display: none;
            }

            .content {
                height: 100%;
            }

            .fullscreen-background-green {
                margin-top: 20px;
                margin-right: 20px;
                position: relative;
                height: calc(100% - 20px);
                width: calc(100% - 20px);
            }

            img {
                display: block;
                position: absolute;
                bottom: 0;
            }
        }

        .faq-container-right {
            width: 60%;

            @include max-screen(1200px) {
                width: 50%;
            }

            @include max-screen(900px) {
                width: 100%;
            }

            > .faq-container {
                padding: 0;
                margin: 0;
                width: 100%;
                max-width: none;
                display: block;

                > .block {
                    width: 100%;

                    #faq-element {
                        width: 100%;

                        .faq-container {
                            padding: 0;
                            margin: 0;
                            width: 100%;
                            max-width: none;
                            display: flex;

                            .fold {
                                width: 25%;
                                display: block;
                                cursor: pointer;

                                @include max-screen(500px) {
                                    width: 50%;
                                }
                            }
                        }
                    }

                }
            }
        }
    }


    dd {
        display: none;
    }

    dt {
        color: $primary__color3;
        font-weight: 600;
    }

    dl {
        margin-bottom: 20px;
        margin-top: 0;
        border-top: #D0E5D0 solid 1px;
        padding-top: 20px;

        a {
            color: $primary__color3;
            text-decoration: none;
        }
    }

    .faq-expanded h2 {
        margin-top: 80px;
        font-size: 36px;
        margin-bottom: 33px;
        color: $primary__color1;
        margin-left: 5px;
    }
    .page-bottom {
        background-color: $primary__color6;
    }
}

.change-toggle {
    max-width: 770px;
    &:before {
        font-family: $icons-name;
        font-size: 30px;
        display: none;
        color: $primary__color !important;
        -webkit-text-stroke: 1px $primary__color;
        line-height: 12px;
        content: "\e900";
        font-weight: bolder;
        text-decoration: none;
        background-color: #F1F8F1;
       -ms-transform: rotate(180deg); /* IE 9 */
       -webkit-transform: rotate(180deg); /* Safari 3-8 */
       transform: rotate(180deg);

    }
}

.cms-faq .page-bottom {
    background-color: #F1F8F1;
    background-image: url("/media/wysiwyg/faq.png");
    background-repeat: no-repeat;
    background-position: calc(12% - -34px) calc(100% - 0px);
    background-size: auto 720px;

    @include min-screen(2000px){
        background-position: calc(24% - -34px) calc(100% - 0px);
    }
    @include max-screen(1080px){
        background-position: calc(0% - -0px) calc(100% - 0px);
    }

    @include max-screen(900px) {
        background-size: auto 0px;
    }

}
