
//  Common
//  _____________________________________________
.page-bottom {
    width:100% ;
    max-width: 100% ;
    li{display: inline;
    text-decoration: none;
    }
}

.footercms{
    text-align: center;
    background: $primary__color1;
    padding-top: 25px;
    padding-bottom: 25px;
    color: white;
    a{color: white;}
    a:hover{color: $primary__color;}
    ul, ol {
    color: inherit;
    font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857;
    font-size: inherit;
    list-style: none;
    display: inline-flex;
    li{
    margin-right: 10px;}
    }
    img{
        width: 125px;
        height: auto;
    }
}



//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {


    .page-main {
        .account &,
        .cms-privacy-policy & {
            padding-top: 41px;
            position: relative;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .navigation,
    .breadcrumbs,
    .page-header .header.panel,
    .header.content,
    .page-wrapper > .widget,
    .page-wrapper >
    .block.category.event,
    .top-container,
    .page-main {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: 1445px;

        width: auto;
    }



    .page-main {
        width: 100%;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;
        .ie9 & {
            width: auto;
        }
    }

    .columns {
        display: block;
    }



    .page-layout-2columns-left {
        .sidebar-additional {
            clear: left;
            float: left;
            padding-left: 0;

        }
    }

    .panel.header {
        padding: 10px 0px;
    }
}

@include max-screen(767px) {
  .footer.content{




    li{
      font-size: 13px;

    }
    h2{
      font-size: 17px;
      font-weight: bold;
    }
  }
}

@include max-screen(560px) {
  .footer.content{
    padding: 30px 20px;

    li{
      font-size: 17px;

    }
    h2{
      font-size: 20px;
      font-weight: bold;
    }
  }
}
