body.blog-post-view,
body.blog-tag-view,
body.blog-author-view,
body.blog-category-index {
    .page-main {
        padding: 0 10px;

        .page-title {
            margin-top: 0;
            margin-bottom: 20px;
        }
    }

    @media only screen and (min-width: 992px) {
        .column.main {
            display: inline-block;
            padding-right: 25px;
            width: 75%;
        }

        .sidebar.sidebar-main {
            display: inline-block;
            vertical-align: top;
            width: 25%;
        }
    }
}

.blog__post-view {
    @include lib-clearfix();

    img.blog__post-featured-image {
        float: left;
        padding: 0 2rem 2rem 0;

        @media only screen and (max-width: 768px) {
            padding: 0 0 2rem 0;
            width: 100%;
            max-width: 100%;
        }
    }
}

.blog__post-view-related-posts {
    @include lib-clearfix();
    margin: 2rem 0;

    .block-title {
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }

    ol {
        margin: 0 -1.5rem;
        list-style: none;
        padding: 0;

        li {
            float: left;
            width: 50%;
            padding-left: 1.5rem;
            box-sizing: border-box;
            margin-bottom: 2rem;

            img {
                max-width: 7rem;
                margin: 0 1rem 1rem 0;
                float: left;
                border-radius: 2px;
            }

            a.post-name {
                display: block;
                margin-top: -5px;
                font-weight: 500;
                color: #333;
                font-size: 1.4rem;
            }

            color: #999;
            font-size: 1.2rem;
        }
    }
}

.blog__post-view-related-products {
    @include lib-clearfix();
    margin: 2rem 0;

    .block-title {
        font-size: 1.8rem;
    }

    ol {
        margin: 0;
        list-style: none;
        padding: 0;

        .products.wrapper.grid.products-grid {
            margin: 30px 0 0 20px;

            ol.products.list.items.product-items {
                li {
                    padding-left: 0;
                    padding-right: 20px;
                }
            }
        }
    }

    .product-image-container {
        display: block;
        width: 100% !important;
    }
}

.addthis_bar_fixed {
    display: none !important;
}
