//
//  Vertnav-menu
//  ---------------------------------------------

.categorie-title {
    color: $primary__color1 !important;
    font-size: 28px;
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 40px;

    @media only screen and (max-width: 1400px) {
        font-size: 20px;
    }

}


.vertnav-mobile {
    ul {
        list-style-type: none;
        font-size: 16px;
        padding-left: 0;

        &.active-sub {
            padding-left: 10px;
        }

        li {
            line-height: 30px;
        }
    }

    a {
        color: $primary__color3;

        &:hover {
            text-decoration: none;
        }
    }
}

.vertnav-menu {
    margin-bottom: 100px;

    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            line-height: 42px;
        }
    }
}

.active-sub {
    padding-left: 15px;
}

.vertnav-mobile li.level-top.active a {
    border-bottom: 2px solid $primary__color;
    padding-bottom: 5px;
    padding-right: 15px;
}

.catalog-product-view {
    .columns .sidebar.sidebar-additional {

    }

    .vertnav-menu {
        @media only screen and (max-width: 1400px) {
            .categorie-title {
                margin-top: 0;
                margin-bottom: 20px;
            }
            ul {
                font-size: 14px;
                li {
                    line-height: 25px;
                }
            }
        }

    }
}
